import React from "react"
import OAuthRedirect from "../views/OAuthRedirect"
import Profile from "../views/Profile"
import { Redirect, Route, Switch } from "react-router-dom"
import { MenuItemEntry, UserLayoutProps } from "../typings"
import AppHero from "../components/AppHero"
import Trade from "../views/Trade"
import Terms from "../views/Terms"
import SupportServiceTerms from "../views/SupportServiceTerms"
import Privacy from "../views/Privacy"
import { useTranslation } from "react-i18next"

export const UserMenuItems = (signoutHandler: () => void): MenuItemEntry[] => [
  {
    id: "menuitemProfile",
    text: "プロフィール編集",
    path: "/profile",
  },
  {
    id: "menuitemSignout",
    text: "ログアウト",
    path: "#",
    signoutHandler,
  },
]

export default function UserLayout(props: UserLayoutProps) {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route path="/oauth" render={() => <OAuthRedirect {...props} />} />
      <Route
        path="/profile"
        render={() => (
          <Route
            path="/profile"
            render={() => (
              <AppHero
                title={t("pageTitles.editProfilePage")}
                subtext={t("editProfile.description")}
              >
                <Profile {...props} />
              </AppHero>
            )}
          />
        )}
      />
      <Route
        path="/trade"
        render={() => (
          <AppHero title={t("pageTitles.actPage")}>
            <Trade />
          </AppHero>
        )}
      />
      <Route
        path="/terms"
        render={() => (
          <AppHero title={t("pageTitles.termsPage")}>
            <Terms />
          </AppHero>
        )}
      />
      <Route
        path="/supportServiceTerms"
        render={() => (
          <AppHero title={t("pageTitles.supportServiceTermsPage")}>
            <SupportServiceTerms />
          </AppHero>
        )}
      />
      <Route
        path="/privacy"
        render={() => (
          <AppHero title={t("pageTitles.privacyPage")}>
            <Privacy />
          </AppHero>
        )}
      />
      <Redirect path="/" to="/profile" />
    </Switch>
  )
}
