export interface AppsData {
  apps: AppData[]
  itemCount: number
  page: number
  pageCount: number
}

export interface AllAppsData {
  apps: AppData[]
}

export interface AppCategory {
  id: number
  name: string
}

export interface AppBannerSlide {
  imgSrc: string
  url: string
}

export interface AppData {
  images: {
    main: string
    sub: string
    tag: string
  }
  aboutApp: {
    summary: string
    feature: string
    recommendedFor: string
    notes: string
  }
  i18nAboutApp: {
    en: {
      summary: string
      feature: string
      recommendedFor: string
      notes: string
    }
  }
  soldOnlyViaProductCode: boolean
  display: boolean
  name: string
  amount: number
  id: string
  mode: "payment" | "subscription"
  category: AppCategory
  i18nNames: {
    en: string
  }
}

export interface AppDetailData extends AppData {
  appUrl: string
  creator: {
    name: string
    url: string
  }
  helpUrl: string
  isOwned: boolean
  subscriptionData: {
    trialPeriodDays: number
    trialEnd: Date | null
    billingCycle: "monthly" | "annually"
  }
  inquiryUrl: string
  clickPurchaseHandler?: () => void
  clickCancelHandler?: () => void
}

export type CategoryId = 1 | 2 | 3 | 4 | 5 | 6 | 99

export interface IAppCategoryButton {
  id: CategoryId
  name: string
}

export interface IAppCategoryButtonsProps {
  categories: IAppCategoryButton[]
  selectedIds: CategoryId[]
  clickHandler: (ids: CategoryId[]) => void
}

export interface IAppServiceButton {
  name: string
  url: string
}

export interface IAppServiceButtonsProps {
  services: IAppServiceButton[]
  selected: string
  clickHandler: (name: string) => void
}

export type ServiceNameArrowEmpty = string | ""

export type CategoryIdArrowEmpty = CategoryId | ""

export interface InquiryParams {
  email: string
  appName: string
  companyName: string
  companyId: string
  personInCharge: string
  inquiry: string
}

export enum SORT_TYPE {
  Popularity = "Popularity",
  Newest = "Newest",
  AppNameAsc = "AppNameAsc",
  AppNameDesc = "AppNameDesc",
}

export interface IAppSortKey {
  key: SORT_TYPE
  name: string
}
