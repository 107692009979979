import React, { useEffect } from "react"
export default function FormInquiry() {
  useEffect(() => {
    // 外部のURLにリダイレクト
    window.location.href =
      "https://marketing.saastainer.com/SaaStainer-userinfo"
  }, [])
  return (
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "1.5rem" }}
    >
      リダイレクト中...
    </div>
  )
}
