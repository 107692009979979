import { Pagination } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import React, { useContext, useEffect, useRef, useState } from "react"
import { GetProductCodesApi } from "../../../api/ProductCodeApi"
import {
  PageAccessPermissions,
  PaginableResponse,
  SortOrder,
} from "../../../types/Common"
import { ProductCodeType, ProductCodeSortKey } from "../../../types/ProductCode"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { ProductCodeManagementContext } from "../../../contexts/ProductCodeManagement/ProductCodeManagementContextProvider"
import icon_search from "../../../images/icon_search.png"
import icon_clear from "../../../images/icon_clear.png"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonArrowStyle,
} from "../../globalStyles"
import button_white_arrow from "../../../images/button_white_arrow.png"
import { useHistory } from "react-router-dom"
import { productCodeManagementRoot } from "../../../router/AdminRouter"
import { AdminBaseLayoutContext } from "../../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"
import { SortComponent } from "../../uiParts/Common/SortInput"

/**
 * プロダクトコード一覧画面のコンポーネント
 */

export const ProductCodeListPage = ({ scope }: PageAccessPermissions) => {
  const [results, setResults] = useState<PaginableResponse<ProductCodeType[]>>()
  const [searchTextEnable, setSearchTextEnable] = useState<boolean>(true)
  const { productCodeListState, productCodeListDispatch } = useContext(
    ProductCodeManagementContext
  )
  const { productCodeEditDispatch } = useContext(ProductCodeManagementContext)
  const { adminAuthState } = useContext(AdminAuthContext)
  const { circularProgressDispatch } = useContext(AdminBaseLayoutContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * プロダクトコードの一覧を取得し、表示を更新する関数
   */
  const updateResults = async () => {
    try {
      circularProgressDispatch({ type: "on" })
      const res = await new GetProductCodesApi(
        productCodeListState.page,
        productCodeListState.sortKey,
        productCodeListState.sortOrder,
        productCodeListState.keyword,
        adminAuthState.token
      ).sendRequest()

      // キーワードが設定されている場合、検索ボックスにキーワードを表示して無効化する
      if (productCodeListState.keyword && inputKeywordRef.current) {
        inputKeywordRef.current.value = productCodeListState.keyword
        setSearchTextEnable(false)
      }

      // プロダクトコードが見つからなかった場合は、スナックバーで通知する
      if (res.data && !res.data.payload.length) {
        enqueueSnackbar(
          "条件に一致するプロダクトコードが見つかりませんでした",
          { variant: "info", preventDuplicate: true }
        )
      }
      setResults(res.data)
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response
        ? errorObj.response.data
        : errorObj.message

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
      })
    } finally {
      circularProgressDispatch({ type: "off" })
    }
  }

  /**
   * キーワードの入力があった場合、キーワードを設定する関数
   */
  const dispatchKeyward = () => {
    if (!inputKeywordRef.current) return
    if (!searchTextEnable) inputKeywordRef.current.value = ""

    setSearchTextEnable((prev) => !prev)
    productCodeListDispatch({
      type: "keyword",
      payload: { keyword: inputKeywordRef.current.value.replace(/%/g, "") },
    })
  }

  /**
   * ソートキーが変更された場合の処理
   */
  const handleSortKeyChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    productCodeListDispatch({
      type: "sortkey",
      payload: { sortKey: e.target!.value as ProductCodeSortKey },
    })
  }

  /**
   * ソート順が変更された場合の処理
   */
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    productCodeListDispatch({
      type: "sortorder",
      payload: { sortOrder: e.target!.value as SortOrder },
    })
  }

  /**
   * 検索ボックスでEnterキーが押された場合の処理
   */
  const handleSearchTextKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key !== "Enter") return
    e.preventDefault()
    dispatchKeyward()
  }

  /**
   * 利用開始日とトライアル日数を受け取り、それらを整形して表示用のテキストを返します
   */
  const getTrialPeriod = (startedAt: string, trialPeriodDays: number) => {
    // トライアル日数が未入力、0の場合は"-"表示
    if (!trialPeriodDays || trialPeriodDays === 0) return "-"
    // 表示用のトライアル開始日
    const startDateTimeString = `${startedAt.substring(
      0,
      4
    )}/${startedAt.substring(4, 6)}/${startedAt.substring(6)}`
    // 表示用のトライアル期限日
    const endDateTimeDate = new Date(
      `${startedAt.substring(0, 4)}/${startedAt.substring(
        4,
        6
      )}/${startedAt.substring(6)}`
    )
    endDateTimeDate.setDate(endDateTimeDate.getDate() + trialPeriodDays)

    return `${startDateTimeString} - ${endDateTimeDate.getFullYear()}/${(
      endDateTimeDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${endDateTimeDate
      .getDate()
      .toString()
      .padStart(2, "0")}`
  }

  /**
   * 利用開始日と有効期限日を受け取り、それらを整形して表示用のテキストを返します
   */
  const getExpirationPeriod = (startedAt: string, expireIn: string) => {
    // 表示用の利用開始日
    const startDateTimeString = `${startedAt.substring(
      0,
      4
    )}/${startedAt.substring(4, 6)}/${startedAt.substring(6)}`
    // 表示用の有効期限日
    const endDateTimeString = `${expireIn.substring(0, 4)}/${expireIn.substring(
      4,
      6
    )}/${expireIn.substring(6)}`

    return `${startDateTimeString} - ${endDateTimeString}`
  }

  /**
   * 一覧から選択したプロダクトコードの編集ページを開きます
   */
  const handleTableRowClick = async (productCode: ProductCodeType) => {
    productCodeEditDispatch({ type: "id", payload: productCode.id })
    history.push(`${productCodeManagementRoot}/edit`)
  }

  // 検索条件の変更を監視して画面を更新する
  useEffect(() => {
    updateResults()
  }, [productCodeListState])

  const sortKeyMap: { key: ProductCodeSortKey; name: string }[] = [
    { key: "id", name: "発行日" },
    { key: "appName", name: "アプリ名" },
    { key: "companyName", name: "企業名" },
    { key: "startedAt", name: "利用開始日" },
    { key: "expireIn", name: "有効期限" },
  ]

  const inputKeywordRef = useRef<HTMLInputElement>(null)
  const styles = useStyles("14px", "10px")()

  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h3>プロダクトコード一覧</h3>
        <div className={styles.searchTextBox}>
          <input
            ref={inputKeywordRef}
            type="text"
            maxLength={256}
            placeholder={"キーワードで検索"}
            onKeyPress={handleSearchTextKeyPress}
            disabled={!searchTextEnable}
          />
          <button type="button" onClick={dispatchKeyward}></button>
          <img src={searchTextEnable ? icon_search : icon_clear} />
        </div>
        <button
          type="button"
          className={
            readOnly
              ? `${styles.addButton} ${styles.disabledButton}`
              : styles.addButton
          }
          onClick={() => history.push(`${productCodeManagementRoot}/issue`)}
          disabled={readOnly}
        >
          プロダクトコード発行
          <img src={button_white_arrow} />
        </button>
      </div>
      <SortComponent
        sortKey={productCodeListState.sortKey}
        sortOrder={productCodeListState.sortOrder}
        sortKeyMap={sortKeyMap}
        handleSortKeyChange={handleSortKeyChange}
        handleSortOrderChange={handleSortOrderChange}
      />
      <div className={styles.lineArea}>
        <table>
          <tbody>
            <tr>
              <th>アプリ名</th>
              <th>企業名</th>
              <th>メールアドレス</th>
              <th>トライアル期間</th>
              <th>有効期間</th>
            </tr>
            {results && results.payload.length
              ? results.payload.map((v, index) => {
                  const getFieldStyle = (fieldValue: string) => {
                    return fieldValue === "unknown"
                      ? { color: "#B22222" }
                      : { color: "black" }
                  }

                  return (
                    <tr key={index} onClick={() => handleTableRowClick(v)}>
                      <td style={getFieldStyle(v.appName)}>{v.appName}</td>
                      <td style={getFieldStyle(v.companyName)}>
                        {v.companyName}
                      </td>
                      <td style={getFieldStyle(v.employerEmailAddr)}>
                        {v.employerEmailAddr}
                      </td>
                      <td>{getTrialPeriod(v.startedAt, v.trialPeriodDays)}</td>
                      <td>{getExpirationPeriod(v.startedAt, v.expireIn)}</td>
                    </tr>
                  )
                })
              : null}
          </tbody>
        </table>
      </div>
      {/* ページネーション */}
      {results && results.payload.length ? (
        <div className={styles.pagination}>
          <Pagination
            count={results ? results.pageInfo.totalPages : 1}
            page={results ? results.pageInfo.page : 1}
            onChange={(_, page) =>
              productCodeListDispatch({ type: "page", payload: { page } })
            }
            color="primary"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  )
}

const useStyles = (fontSize: string, hPadding: string) => {
  // ルート
  const root = {
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
  }

  // 検索タイトル
  const title = {
    display: "grid" as const,
    gap: "20px",
    gridTemplateColumns: "auto 300px 250px",
    alignItems: "center" as const,
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize,
    whiteSpace: "nowrap" as const,
  }

  // プロダクトコード発行ボタン
  const addButton = {
    ...globalSystemButtonArrowStyle,
  }

  // ボタン非活性
  const disabledButton = {
    ...globalDisabledButtonStyle,
  }

  // キーワード検索テキストボックス
  const searchTextBox = {
    position: "relative" as const,
    width: "100%",
    "& input": {
      boxSizing: "border-box" as const,
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 40px 0 20px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "2px solid gray",
    },
    "& img": {
      position: "absolute" as const,
      top: "13px",
      right: "20PX",
      width: "14px",
      pointerEvents: "none" as const,
    },
    "& button": {
      position: "absolute" as const,
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer" as const,
    },
  }

  // 明細エリア
  const lineArea = {
    display: "flex" as const,
    alignItems: "center" as const,
    justifyContent: "center" as const,
    paddingTop: "20px",
    "& table": {
      tableLayout: "auto" as const,
      borderCollapse: "separate" as const,
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
      fontSize: "12px",
      "& th, & td": {
        padding: "10px",
        width: "auto",
      },
      "& th": {
        textAlign: "center",
        backgroundColor: "#536381",
        color: "#fff",
      },
      "& tr:nth-child(even)": {
        backgroundColor: "#f2f2f2",
      },
      "& tr": {
        "&:hover": {
          backgroundColor: "rgba(79, 121, 193, 0.3)",
          cursor: "pointer" as const,
        },
      },
    },
    "& div[id=noResults]": {
      textAlign: "center" as const,
    },
  }

  // ページネーション
  const pagination = {
    paddingTop: "20px",
    display: "flex" as const,
    justifyContent: "center" as const,
  }

  return makeStyles((_: Theme) => ({
    root: { ...root },
    title: { ...title },
    addButton: { ...addButton },
    disabledButton: { ...disabledButton },
    searchTextBox: { ...searchTextBox },
    lineArea: { ...lineArea },
    pagination: { ...pagination },
  }))
}
