import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormSignUp from "../components/FormSignUp"
import Grid from "@material-ui/core/Grid"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { RouterLink } from "../components/RouterLink"
import { SignUpProps } from "../typings"
import { SignUpStyles } from "../styles/form"
import { useTranslation } from "react-i18next"

export default function SignUp(props: SignUpProps) {
  const { alert } = props
  const { t, i18n } = useTranslation()
  const classes = SignUpStyles()

  return (
    <Container component="main" style={{ maxWidth: "375px" }}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountBoxIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("signup.title")}
        </Typography>
        <Box mt={1}>
          <Typography variant="caption">{t("signup.subSubTitle")}</Typography>
        </Box>
        <Typography component="p" className={classes.linkTextHeader}>
          {i18n.language === "ja" ? t("signup.aboutSaaStainer") : ""}
          <Link
            style={{ color: "#27aebd" }}
            href="https://saastainer.zendesk.com/hc/ja/articles/900003506546--SaaStainer%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
            target="_blank"
          >
            {t("signup.inquiry")}
          </Link>
          {i18n.language === "en" ? t("signup.aboutSaaStainer") : ""}
        </Typography>

        <Typography component="p" className={classes.linkTextHeader}>
          {i18n.language === "ja" ? t("signup.registerSaaStainer") : ""}
          <Link
            style={{ color: "#27aebd" }}
            href="https://saastainer.zendesk.com/hc/ja/articles/900003540006--SaaStainer%E3%81%94%E7%99%BB%E9%8C%B2%E6%96%B9%E6%B3%95"
            target="_blank"
          >
            {t("signup.inquiry")}
          </Link>
          {i18n.language === "en" ? t("signup.registerSaaStainer") : ""}
        </Typography>
        <FormSignUp {...props} />
        <Grid container justify="flex-end" style={{ marginBottom: "20px" }}>
          <Grid item>
            <RouterLink to="/signin" variant="body2">
              {t("signup.login")}
            </RouterLink>
          </Grid>
        </Grid>
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
