import React from "react"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

export default function Trade() {
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">販売事業者名</Box>
        <Typography>株式会社ストラテジット</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">販売事業者所在地</Box>
        <Typography>〒108-0014</Typography>
        <Typography>東京都港区芝5-31-17 PMO田町7F</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">代表者</Box>
        <Typography>加藤 史恵</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">連絡先／電子メール</Box>
        <Typography>info@strategit.jp</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">連絡先／電話番号</Box>
        <Typography>03-6811-1431</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">販売価格帯</Box>
        <Typography>
          サービスごとに販売価格（消費税込）を設定し、該当ページで表示します。
        </Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">
          商品等の引き渡し時期（日数）・発送方法
        </Box>
        <Typography>オンラインにおけるサービス利用</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">代金の支払い時期および方法</Box>
        <Typography>
          サービスごとに支払時期および方法を指定し、該当ページで表示します。
        </Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">
          商品代金以外に必要な費用/送料、消費税等
        </Box>
        <Typography>なし（料金は税込）</Typography>
      </Box>
      <Box mb={3}>
        <Box fontWeight="fontWeightBold">
          返品の取扱条件／返品期限、返品時の送料負担または解約や退会条件
        </Box>
        <Typography>
          解約はサービスごとに当社指定の手続きにより可能。ただし、既に支払われた分に対する返金は不可。
        </Typography>
      </Box>
    </Container>
  )
}
