import React, { useContext, useEffect, useState } from "react"
import { makeStyles, Theme, Tooltip } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalRedAsterisk,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalSystemButtonDeleteActionStyle,
  globalTextboxStyle,
} from "../../globalStyles"
import { ProductCodeManagementContext } from "../../../contexts/ProductCodeManagement/ProductCodeManagementContextProvider"
import { useHistory } from "react-router-dom"
import {
  DeleteProductCodeApi,
  GetProductCodeApi,
  PutProductCodeApi,
} from "../../../api/ProductCodeApi"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { CompanyUserList } from "../../uiParts/ProductCode/common/CompanyUserList"
import { useSnackbar } from "notistack"
import { ProductCodeType } from "../../../types/ProductCode"
import { Save, DeleteForever, FileCopy } from "@material-ui/icons"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import { UsageStartDatePicker } from "../../uiParts/ProductCode/common/UsageStartDatePicker"
import { UsageExpirationDatePicker } from "../../uiParts/ProductCode/common/UsageExpirationDatePicker"
import { LicenseCountInput } from "../../uiParts/ProductCode/common/LicenseCountInput"
import { ProductCodeEditAction } from "../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { UnitPriceInput } from "../../uiParts/ProductCode/common/UnitPriceInput"
import { TrialPeriodInput } from "../../uiParts/ProductCode/common/TrialPeriodInput"
import { AgencyInput } from "../../uiParts/ProductCode/common/AgencyInput"
import { AgencyfeeInput } from "../../uiParts/ProductCode/common/AgencyfeeInput"
import { formatStateToDb } from "../../../utility/ProductCodeUtil"
import { BillingIntervalInput } from "../../uiParts/Common/BillingIntervalInput"

/**
 * プロダクトコード編集ページコンポーネント
 */
export const ProductCodeEditPage = ({ scope }: PageAccessPermissions) => {
  const { productCodeEditState, productCodeEditDispatch } = useContext(
    ProductCodeManagementContext
  )
  const [productCode, setProductCode] = useState<ProductCodeType>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { adminAuthState } = useContext(AdminAuthContext)
  const history = useHistory()

  // 初回処理
  useEffect(() => {
    if (!productCodeEditState.id) {
      history.goBack()
      enqueueSnackbar("プロダクトコードのIDを指定してください", {
        variant: "error",
        preventDuplicate: true,
      })
      return
    }

    const fetchData = async (id: string) => {
      try {
        const res = await new GetProductCodeApi(
          id,
          adminAuthState.token
        ).sendRequest()

        setProductCode(res.data.payload)
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    fetchData(productCodeEditState.id)

    // cleanup
    return () => {
      productCodeEditDispatch({ type: "init" })
    }
  }, [])

  const { enqueueSnackbar } = useSnackbar()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.goBack()
  }

  // 「保存」ボタンが押された時の処理
  const handleSaveButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 保存関数定義
    const saveAction = async () => {
      if (!productCodeEditState.errors.length) {
        try {
          const result = await new PutProductCodeApi(
            productCode?.id!,
            productCodeEditState.licenseCount!,
            productCodeEditState.amount!,
            productCodeEditState.interval!,
            productCodeEditState.intervalCount!,
            productCodeEditState.trialPeriodDays!,
            formatStateToDb(productCodeEditState.usageStartedAt),
            formatStateToDb(productCodeEditState.usageExpireIn),
            productCodeEditState.agency!,
            productCodeEditState.agencyfee!,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(
            `プロダクトコード(${result.data.payload})を更新しました`,
            { variant: "success", preventDuplicate: true }
          )
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
          return
        }
      } else {
        productCodeEditState.errors.forEach((v) =>
          enqueueSnackbar(v.message, {
            variant: "warning",
            preventDuplicate: true,
          })
        )
      }
    }

    setDialogMessage("プロダクトコードを上書き保存しますか？")
    showDialog(() => saveAction)
  }

  // 「削除」ボタンが押された時の処理
  const handleDeleteButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 削除関数定義
    const deleteAction = async () => {
      if (productCode?.id) {
        try {
          const result = await new DeleteProductCodeApi(
            productCode?.id,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(
            `プロダクトコード(${result.data.payload})を削除しました`,
            { variant: "success", preventDuplicate: true }
          )
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
          return
        }
      } else {
        enqueueSnackbar("入力が不十分です", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    setDialogMessage("プロダクトコードを削除しますか？")
    showDialog(() => deleteAction)
  }

  // プロダクトコードのコピーボタンを押したときの処理
  const handleProductCodeCopy = () => {
    if (productCode) {
      navigator.clipboard.writeText(productCode.code)
      enqueueSnackbar("プロダクトコードをクリップボードにコピーしました", {
        variant: "info",
        preventDuplicate: true,
      })
    }
  }

  // 日付のフォーマットをDBからステート向けに整形する関数
  const formatDbToState = (dateString?: string): Date | undefined => {
    if (!dateString) return undefined
    const year = parseInt(dateString.substring(0, 4))
    const month = parseInt(dateString.substring(4, 6))
    const day = parseInt(dateString.substring(6, 8))
    return new Date(year, month - 1, day, 0, 0, 0)
  }

  const styles = createStyles("20px")()

  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>プロダクトコード編集</h3>
      </div>
      {productCode && (
        <>
          <div className={styles.inputLeftArea}>
            <label>プロダクトコード（編集不可）</label>
            <div id="productcodeTextbox">
              <input type="text" value={productCode.code} disabled />
              <Tooltip title="コピー">
                <button type="button" onClick={handleProductCodeCopy}></button>
              </Tooltip>
              <FileCopy />
            </div>
            <label>アプリ名（編集不可）</label>
            <input type="text" value={productCode.appName} disabled />
            <label>企業名（編集不可）</label>
            <input type="text" value={productCode.companyName} disabled />
            <label>ライセンス数</label>
            <LicenseCountInput<ProductCodeEditAction>
              dispatch={productCodeEditDispatch}
              initialValue={productCode.licenses}
            />
            <label>{"単価(税抜)"}</label>
            <UnitPriceInput<ProductCodeEditAction>
              dispatch={productCodeEditDispatch}
              initialValue={productCode.amount}
            />
            <label>請求間隔</label>
            <BillingIntervalInput<ProductCodeEditAction>
              dispatch={productCodeEditDispatch}
              initialIntervalValue={productCode.interval}
            />
            <label>トライアル日数（利用開始日にトライアル開始）</label>
            <TrialPeriodInput<ProductCodeEditAction>
              dispatch={productCodeEditDispatch}
              initialValue={productCode.trialPeriodDays}
            />
          </div>
          <div className={styles.userlistArea}>
            <CompanyUserList
              employer={productCode.employerEmailAddr}
              employee={productCode.employeeEmailAddrs}
            />
          </div>
          <div className={styles.inputRightArea}>
            <label className={styles.requiredField}>利用開始日</label>
            {productCode && (
              <UsageStartDatePicker
                dispatch={productCodeEditDispatch}
                state={productCodeEditState}
                initialDate={formatDbToState(productCode.startedAt)}
              />
            )}
            <label className={styles.requiredField}>有効期限</label>
            {productCode && (
              <UsageExpirationDatePicker
                dispatch={productCodeEditDispatch}
                state={productCodeEditState}
                initialDate={formatDbToState(productCode.expireIn)}
              />
            )}
            <label>代理店</label>
            <AgencyInput<ProductCodeEditAction>
              dispatch={productCodeEditDispatch}
              initialValue={productCode.agency}
            />
            <label>代理店手数料</label>
            <AgencyfeeInput<ProductCodeEditAction>
              dispatch={productCodeEditDispatch}
              initialValue={productCode.agencyfee}
            />
          </div>
        </>
      )}

      <div className={styles.buttonArea}>
        <div id="buttons">
          <button
            type="button"
            className="arrow"
            onClick={handleCancelButtonClick}
          >
            戻る
            <img src={button_white_arrow} />
          </button>
          <button
            type="button"
            className={readOnly ? "actionDisabled" : "action"}
            onClick={handleSaveButtonClick}
            disabled={readOnly}
          >
            保存
            <Save />
          </button>
          <button
            type="button"
            className={readOnly ? "deleteDisabled" : "delete"}
            onClick={handleDeleteButtonClick}
            disabled={readOnly}
          >
            削除
            <DeleteForever />
          </button>
        </div>
      </div>
      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={handleDialogClose}
        message={dialogMessage}
      />
    </div>
  )
}

// スタイル作成inputLeftArea
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea      titleArea     " auto
                   "inputLeftArea  userlistArea  " 1fr
                   "inputLeftArea  inputRightArea" auto
                   "buttonArea     buttonArea    " auto
                   / 1fr 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
  }

  // プロダクトコード情報の入力エリア（左）
  const inputLeftArea = {
    gridArea: "inputLeftArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
    ...globalTextboxStyle,
    "& div[id=productcodeTextbox]": {
      position: "relative" as const,
      width: "100%",
      "& svg": {
        position: "absolute" as const,
        top: "10px",
        right: "20PX",
        width: "18px",
        pointerEvents: "none" as const,
      },
      "& button": {
        position: "absolute" as const,
        top: "10px",
        right: "20PX",
        width: "18px",
        height: "18px",
        opacity: "0",
        cursor: "pointer" as const,
      },
    },
  }

  // プロダクトコード情報の入力エリア(右)
  const inputRightArea = {
    gridArea: "inputRightArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // 企業ユーザーリスト表示エリア
  const userlistArea = {
    gridArea: "userlistArea",
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "600px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
    "& div[id=buttons] .delete": {
      ...globalSystemButtonDeleteActionStyle,
    },
    "& div[id=buttons] .deleteDisabled": {
      ...globalSystemButtonDeleteActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputLeftArea: { ...inputLeftArea },
    inputRightArea: { ...inputRightArea },
    userlistArea: { ...userlistArea },
    buttonArea: { ...buttonArea },
    requiredField: { ...globalRedAsterisk },
  }))
}
