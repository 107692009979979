// 管理画面共通のスタイルを定義します。

// 抽象ボタンスタイル
const abstructButtonStyle = {
  position: "relative" as const,
  display: "inline-block" as const,
  fontSize: "14px",
  fontWeight: "bold" as const,
  textAlign: "center" as const,
  border: "none" as const,
  outline: "none" as const,
  borderRadius: "50px",
  boxSizing: "border-box" as const,
  textDecoration: "none" as const,
  boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
  cursor: "pointer" as const,
}

// ボタン内画像スタイル
const buttonPicStyle = (
  buttonHeight: string,
  imageHeight: string,
  rightPosition: string
) => ({
  position: "absolute" as const,
  right: rightPosition,
  top: `calc(${buttonHeight} / 2 - ${imageHeight} / 2)`,
  width: "auto",
  height: imageHeight,
})

// 抽象ボタンスタイルの拡張スタイル
export const flexibleButtonStyle = (
  color: string,
  startBackgroundColor: string,
  endBackgroundColor?: string
) => (
  buttonWidth: string,
  buttonHeight: string,
  imageHeight: string,
  imageRightPosition: string
) => ({
  ...abstructButtonStyle,
  width: buttonWidth,
  height: buttonHeight,
  color,
  ...(endBackgroundColor
    ? {
        background: `linear-gradient(top left, ${startBackgroundColor}, ${endBackgroundColor})`,
      }
    : {
        background: `linear-gradient(top left, ${startBackgroundColor}, ${startBackgroundColor})`,
      }),
  "& img": {
    ...buttonPicStyle(buttonHeight, imageHeight, imageRightPosition),
  },
  "& svg": {
    ...buttonPicStyle(buttonHeight, imageHeight, imageRightPosition),
  },
})

// 基本カラーボタンスタイル
export const baseColorButtonStyle = flexibleButtonStyle(
  "#ffffff",
  "#517bc4",
  "#2e5598"
)

// システムボタンスタイル(画面遷移用)
export const globalSystemButtonArrowStyle = baseColorButtonStyle(
  "100%",
  "40px",
  "13px",
  "20px"
)

// システムボタンスタイル(汎用アクション用)
export const globalSystemButtonActionStyle = baseColorButtonStyle(
  "100%",
  "40px",
  "20px",
  "20px"
)

// システムボタンスタイル(削除アクション用)
export const globalSystemButtonDeleteActionStyle = flexibleButtonStyle(
  "#ffffff",
  "#8b0000",
  "#a52a2a"
)("100%", "40px", "20px", "20px")

// ボタン非活性スタイル
export const globalDisabledButtonStyle = {
  background: "rgba(128, 128, 128, 0.5)",
  cursor: "not-allowed",
}

// テキストボックス内画像スタイル
const textboxPicStyle = {
  position: "absolute" as const,
  top: "12px",
  right: "20PX",
  width: "18px",
  height: "18px",
  pointerEvents: "none" as const,
}

// input共有スタイル
export const globalTextboxStyle = {
  position: "relative" as const,
  "& input": {
    boxSizing: "border-box",
    width: "100%",
    height: "40px",
    borderRadius: "20px",
    border: "1px solid gray",
    paddingLeft: "20px",
    paddingRight: "40px",
    transition: "all 0.1s ease-in",
  },
  "& input[type=checkbox]": {
    width: "20px", // checkboxだけは幅を20pxにする
    height: "20px", // checkboxだけは高さを20pxにする
  },
  "& input[type=radio]": {
    width: "20px", // checkboxだけは幅を20pxにする
    height: "20px", // checkboxだけは高さを20pxにする
  },
  "& input::placeholder": {
    fontSize: "12px",
  },
  "& input:focus": {
    outline: "2px solid gray",
  },
  "& svg": {
    ...textboxPicStyle,
  },
  "& img": {
    ...textboxPicStyle,
  },
  "& button": {
    position: "absolute" as const,
    top: "12px",
    right: "20PX",
    width: "18px",
    height: "18px",
    opacity: "0",
    cursor: "pointer" as const,
  },
}

const baseSelectStyle = {
  position: "relative" as const,
  backgroundColor: "transparent" as const,
  borderBottom: "1px solid rgba(0,0,0, 0.3)",
  cursor: "pointer" as const,
  "&:focus": {
    outline: "none" as const,
  },
}

// 下線スタイルのセレクトボックス
export const globalBorderBottomSelectStyle = {
  ...baseSelectStyle,
  width: "140px",
  height: "30px",
  border: 0,
  borderBottom: "1px solid rgba(0,0,0,0.3)",
}

// カスタムのドロップダウン画像
export const globalCustomDropDownArrow = {
  position: "absolute",
  top: "50%",
  right: "20px", // この値を調整して、カスタム▼画像の位置を制御
  transform: "translateY(-50%)",
  pointerEvents: "none",
  content: '""',
  borderTop: "5px solid #000",
  borderRight: "5px solid transparent",
  borderLeft: "5px solid transparent",
}

// 角丸のセレクトボックス
export const globalRoundedBorderSelectStyle = {
  ...baseSelectStyle,
  width: "100%",
  height: "38px",
  fontSize: "16px",
  borderRadius: "20px",
  border: "1px solid rgba(0,0,0,0.3)",
  padding: "0 20px",
  appearance: "none",
  background: "transparent",
}

// 必須項目のスタイル
export const globalRedAsterisk = {
  "&::after": {
    content: '"*"',
    color: "red",
  },
}
