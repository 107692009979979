import React from "react"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { IFormTextField, IValidateField } from "../typings"
import { SignUpStyles } from "../styles/form"

const passwordHelperText =
  "パスワードは小文字、大文字、数字を1つ以上含む8文字以上"

/**
 * ログイン
 */

export const SignInEmailField = GenerateValidateField(
  "signInEmail",
  "email",
  "メールアドレス",
  "email"
)

export const SignInPasswordField = GenerateValidateField(
  "signInPassword",
  "password",
  "パスワード",
  "password"
)

/**
 * サインアップ
 */

export const SignUpCompanyNameField = GenerateValidateField(
  "signUpCompanyName",
  "companyName",
  "企業名",
  "text",
  "正式名称で登録ください。（例：株式会社ストラテジット）\n【個人利用の方はお名前をご記入ください】"
)

export const SignUpLastNameField = GenerateValidateField(
  "signUpLastName",
  "lastName",
  "姓",
  "text"
)

export const SignUpFirstNameField = GenerateValidateField(
  "signUpFirstName",
  "firstName",
  "名",
  "text"
)

export const SignUpEmailField = GenerateValidateField(
  "signUpEmail",
  "email",
  "メールアドレス",
  "email"
)

export const SignUpPasswordField = GenerateValidateField(
  "signUpPassword",
  "password",
  "パスワード",
  "password",
  passwordHelperText
)

export const SignUpPasswordConfirmField = GenerateValidateField(
  "signUpPasswordConfirm",
  "passwordConfirm",
  "パスワード(確認用)",
  "password"
)

/**
 * パスワードの再設定
 */

export const ForgotPasswordEmailField = GenerateValidateField(
  "forgotPasswordEmail",
  "email",
  "メールアドレス",
  "email"
)

/**
 * 従業員管理
 */

export const EmployeeLastNameField = GenerateValidateField(
  "employeeLastName",
  "lastName",
  "姓",
  "text"
)

export const EmployeeFirstNameField = GenerateValidateField(
  "employeeFirstName",
  "firstName",
  "名",
  "text"
)

export const EmployeeEmailField = GenerateValidateField(
  "employeeEmail",
  "email",
  "メールアドレス",
  "email"
)

export const EmployeePasswordField = GenerateValidateField(
  "employeePassword",
  "password",
  "パスワード",
  "password",
  passwordHelperText
)

export const EmployeePasswordConfirmField = GenerateValidateField(
  "employeePasswordConfirm",
  "passwordConfirm",
  "パスワード(確認用)",
  "password"
)

/**
 * プロフィール編集
 */

export const ProfileLastNameField = GenerateValidateField(
  "profileLastName",
  "lastName",
  "姓",
  "text"
)

export const ProfileFirstNameField = GenerateValidateField(
  "profileFirstName",
  "firstName",
  "名",
  "text"
)

export const ProfileEmailField = GenerateValidateField(
  "profileEmail",
  "email",
  "メールアドレス",
  "email"
)

export const ProfilePasswordField = GenerateValidateField(
  "profilePassword",
  "password",
  "パスワード",
  "password",
  passwordHelperText
)

export const ProfilePasswordConfirmField = GenerateValidateField(
  "profilePasswordConfirm",
  "passwordConfirm",
  "パスワード(確認用)",
  "password"
)

/**
 * お問い合わせ
 */

export const InquiryEmailField = GenerateValidateField(
  "email",
  "email",
  "返信先メールアドレス",
  "email"
)

export const InquiryCompanyIdField = GenerateValidateField(
  "inquiryCompanyId",
  "companyId",
  "企業ID",
  "text",
  "",
  false
)

export const InquiryCompanyNameField = GenerateValidateField(
  "inquiryCompanyName",
  "companyName",
  "企業名",
  "text"
)

export const InquiryPersonInChargeField = GenerateValidateField(
  "inquiryPersonInCharge",
  "personInCharge",
  "担当者名",
  "text"
)

function GenerateValidateField(
  id: string,
  name: string,
  label: string,
  type: "text" | "email" | "password",
  helperText?: string,
  required = true
) {
  return (props: IValidateField) => {
    const classes = SignUpStyles()
    const { forwardRef, error, defaultValue, onChange, t } = props
    const displayName = t ? t(`formTextField.${name}`) : label
    const displayHelperText =
      t && helperText ? t(`formTextField.${name}HelperText`) : helperText
    return (
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          type={type}
          id={id}
          label={displayName}
          name={name}
          autoComplete={id}
          helperText={
            <span className={classes.helperText}>{displayHelperText}</span>
          }
          inputRef={forwardRef}
          error={error !== undefined}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </Grid>
    )
  }
}

export const InquiryTextField = GenerateTextBox(
  "inquiryText",
  "inquiryText",
  "お問い合わせ内容"
)

function GenerateTextBox(
  id: string,
  name: string,
  label: string,
  rows = 8,
  helperText?: string
) {
  return (props: IValidateField) => {
    const { forwardRef, defaultValue, onChange, t } = props
    const displayName = t ? t(`formTextField.${name}`) : label
    return (
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
          id={id}
          label={displayName}
          name={name}
          helperText={helperText}
          inputRef={forwardRef}
          defaultValue={defaultValue}
          onChange={onChange}
          multiline
          rows={rows}
        />
      </Grid>
    )
  }
}

/**
 * OAuth2
 */

export const OAuthAppEmailField = GenerateOAuthTextField(
  "oauthEmail",
  "email",
  "メールアドレス",
  "email"
)

export const OAuthAppPasswordField = GenerateOAuthTextField(
  "oauthPassword",
  "password",
  "パスワード",
  "password"
)

function GenerateOAuthTextField(
  id: string,
  name: string,
  label: string,
  type: "text" | "email" | "password"
) {
  return (props: IFormTextField) => {
    const { changeHandler, value, hasError, t } = props
    const displayName = t ? t(`formTextField.${name}`) : label

    const helperText = hasError ? t("formTextField.hasError") : undefined

    return (
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type={type}
          id={id}
          label={displayName}
          name={name}
          autoComplete={id}
          autoFocus
          onChange={changeHandler}
          value={value}
          error={hasError}
          helperText={helperText}
        />
      </Grid>
    )
  }
}

export const SignUpCompanyIdField = GenerateHiddenField(
  "signUpCompanyId",
  "companyId",
  "企業ID",
  "hidden"
)

function GenerateHiddenField(
  id: string,
  name: string,
  label: string,
  type: "hidden"
) {
  return (props: IValidateField) => {
    const { forwardRef, error, defaultValue, t } = props
    return (
      <Grid item xs={12}>
        <TextField
          margin="normal"
          fullWidth
          type={type}
          id={id}
          name={name}
          autoComplete={id}
          inputRef={forwardRef}
          error={error !== undefined}
          defaultValue={defaultValue}
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
    )
  }
}
