import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import icon_link from "../img/toppage/icon_link_white.png"
import { MediaQueryContext } from "../App"

/*
コンポーネントの説明：
  フッター前に配置するコンテンツです。
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/
const BeforeFooterContents = React.memo(() => {
  // コンテキストから必要なリソースを読み込む
  const { isPC } = useContext(MediaQueryContext)
  // 国際化対応
  const { t } = useTranslation()
  // PCスタイル
  const pcStyles = usePCStyles()
  // SPスタイル
  const spStyles = useSPStyles()

  const pcElements = (
    <div className={pcStyles.root}>
      <div className={pcStyles.leftElements}>
        <img src="https://saastainer.s3.ap-northeast-1.amazonaws.com/img/apps/joint-for-ipaas.png" />
      </div>
      <div className={pcStyles.rightElements}>
        <p className={pcStyles.mainText}>
          {/* SaaStainerにないアプリでも、 */}
          {t("beforeFooterContents.upperMainText")}
          <br />
          {/* 開発いたしますのでご相談ください。 */}
          {t("beforeFooterContents.lowerMainText")}
        </p>
        <p className={pcStyles.descriptionText}>
          {/* JOINTは、自社プロダクトと他SaaSとの連携開発、管理、運用までを効率的に、簡単に、素早く対応できるプラットフォームです。*/}
          {t("beforeFooterContents.upperDescriptionText")}
          <br />
          {/* ストラテジットは国内外50以上のSaaS連携の実装実績もあるため、コンサルティングやサポートなど柔軟に対応させていただきますので、まずはお気軽に無料相談下さい。*/}
          {t("beforeFooterContents.lowerDescriptionText")}
          <br />
        </p>
        <a
          className={pcStyles.devRequestButton}
          href="https://joint-data.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* JOINT for iPaaS */}
          {t("beforeFooterContents.devRequest")}
          <img src={icon_link} />
        </a>
      </div>
    </div>
  )

  const spElements = (
    <div className={spStyles.root}>
      <div className={spStyles.img_master_hub}>
        <img src="https://saastainer.s3.ap-northeast-1.amazonaws.com/img/apps/joint-for-ipaas.png" />
      </div>
      <p className={spStyles.mainText}>
        {/* SaaStainerにないアプリでも、 */}
        {t("beforeFooterContents.upperMainText")}
        <br />
        {/* 開発いたしますのでご相談ください。 */}
        {t("beforeFooterContents.lowerMainText")}
      </p>
      <p className={pcStyles.descriptionText}>
        {/* JOINTは、自社プロダクトと他SaaSとの連携開発、管理、運用までを効率的に、簡単に、素早く対応できるプラットフォームです。*/}
        {t("beforeFooterContents.upperDescriptionText")}
        <br />
        {/* ストラテジットは国内外50以上のSaaS連携の実装実績もあるため、コンサルティングやサポートなど柔軟に対応させていただきますので、まずはお気軽に無料相談下さい。*/}
        {t("beforeFooterContents.lowerDescriptionText")}
        <br />
      </p>
      <a
        className={spStyles.devRequestButton}
        href="https://joint-data.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* SaaS連携開発ページへ */}
        {t("beforeFooterContents.devRequest")}
        <img src={icon_link} />
      </a>
    </div>
  )

  return isPC ? pcElements : spElements
})

// PC固有のスタイル定義
const usePCStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    textAlign: "center",
    color: "#FA7D3D",
    backgroundColor: "#fafafa",
    padding: "30px 70px",
  },
  // コンテンツ左側の要素
  leftElements: {
    width: "340px",
    lineHeight: "340px",
    backgroundColor: "#f4f5f7",
    "& img": {
      width: "100%",
      verticalAlign: "middle",
    },
  },
  // コンテンツ右側の要素
  rightElements: {
    display: "flex",
    flexFlow: "column",
    width: "625px",
    height: "340px",
    paddingLeft: "50px",
  },
  // メインテキスト
  mainText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "black",
    letterSpacing: "0.05em",
    marginTop: "10px",
  },
  // 説明テキスト
  descriptionText: {
    textAlign: "left",
    color: "black",
    fontSize: "14px",
    lineHeight: "2.2",
    marginTop: "10px",
  },
  // SaaS連携開発ページボタン
  devRequestButton: {
    position: "relative",
    display: "block",
    width: "335px",
    height: "54px",
    margin: "40px auto",
    padding: "18px",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "0.1em",
    borderRadius: "100px",
    boxSizing: "border-box",
    textDecoration: "none",
    boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
    background: "linear-gradient(top left, #527CC5, #325BA1)",
    "& img": {
      position: "absolute",
      width: "15px",
      height: "15px",
      right: "40px",
      top: "20px",
    },
    cursor: "pointer",
  },
}))

// SP固有のスタイル定義
const useSPStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    textAlign: "center",
    color: "#FA7D3D",
    backgroundColor: "#fafafa",
    padding: "30px 20px",
  },
  // MasterHubイメージ
  img_master_hub: {
    "& img": {
      width: "100%",
    },
  },
  // メインテキスト
  mainText: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "black",
    letterSpacing: "0.05em",
    marginTop: "10px",
  },
  // 説明テキスト
  descriptionText: {
    textAlign: "left",
    color: "black",
    fontSize: "14px",
    lineHeight: "2.2",
    marginTop: "10px",
  },
  // SaaS連携開発ページボタン
  devRequestButton: {
    position: "relative",
    display: "block",
    maxWidth: "335px",
    height: "100%",
    margin: "40px auto",
    padding: "18px",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: "0.1em",
    borderRadius: "100px",
    boxSizing: "border-box",
    textDecoration: "none",
    boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
    background: "linear-gradient(top left, #527CC5, #325BA1)",
    "& img": {
      position: "absolute",
      width: "15px",
      height: "15px",
      left: "calc(100% - 40px)",
      top: "20px",
    },
    cursor: "pointer",
  },
}))
export default BeforeFooterContents
