import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import eyecatch_pc_background from "../img/toppage/eyecatch_pc_background.jpg"
import icon_saastainer from "../img/toppage/icon_saastainer.png"
import { MediaQueryContext } from "../App"
/*
コンポーネントの説明：
  トップページのアイキャッチ画像が表示されるエリアのコンポーネント。
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/
function MainVisual() {
  // コンテキストからメディアクエリーに必要なリソースを取得
  const { isPC, pcWidth, spWidth } = useContext(MediaQueryContext)

  // 国際化対応
  const { t } = useTranslation()

  // 画面遷移用のフック
  const history = useHistory()

    // PC固有のスタイル定義
  const usePCStyles = makeStyles((_: Theme) => ({
    // ルート
    root: {
      position: "relative",
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      paddingTop: "120px",
      background: `Url(${eyecatch_pc_background}) no-repeat`,
      width: pcWidth,
      height: "600px",
    },
    // アイキャッチ画像（左）
    eyeCatchImageLeft: {
      position: "absolute",
      width: "292px",
      height: "100%",
      paddingTop: "20px",
    },
    // アイキャッチ画像（右）
    eyeCatchImageRight: {
      position: "absolute",
      width: "337px",
      height: "100%",
      paddingTop: "20px",
      left: "929px",
    },
    // メインテキスト
    mainText: {
      textAlign: "center",
      fontSize: "38px",
      fontWeight: "bold",
      color: "white",
      letterSpacing: "0.1em",
      lineHeight: "1.5",
      filter: "drop-shadow(3px 3px 3px #a75629)",
    },
    // トライアルボタン
    trialButton: {
      display: "block",
      width: "380px",
      padding: "10px",
      marginLeft: "auto",
      marginRight: "auto",
      color: "white",
      fontSize: "18px",
      fontWeight: "normal",
      textAlign: "center",
      letterSpacing: "0.2em",
      borderRadius: "100px",
      boxSizing: "border-box",
      textDecoration: "none",
      boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
      background: "linear-gradient(top left, #527CC5, #325BA1)",
      "& img": {
        width: "18px",
        height: "25px",
        marginRight: "15px",
        verticalAlign: "-5px",
      },
      cursor: "pointer",
    },
    // YouTube動画表示エリア
    videoArea: {
      marginTop: "20px",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "bold",
      color: "white",
      letterSpacing: "0.1em",
      "& div": {
        border: "2px solid gray",
        width: "380px",
        height: "212px",
        overflow: "hidden",
        borderRadius: "20px",
        left: "-1px",
      },
    },
  }))

  // SP固有のスタイル定義
  const useSPStyles = makeStyles((_: Theme) => ({
    // ルート
    root: {
      display: "flex",
      flexFlow: "column",
      background: "linear-gradient(109.42deg, #FA7D3D 3.44%, #FFC945 100%)",
      width: spWidth,
      height: "823px",
      paddingTop: "70px",
    },
    // メインテキスト
    mainText: {
      textAlign: "center",
      fontSize: "26px",
      fontWeight: "bold",
      color: "white",
      letterSpacing: "0.1em",
      lineHeight: "1.5",
      filter: "drop-shadow(3px 3px 3px #a75629)",
    },
    // トライアルボタン
    trialButton: {
      display: "block",
      width: "335px",
      padding: "10px",
      marginLeft: "auto",
      marginRight: "auto",
      color: "white",
      fontSize: "16px",
      fontWeight: "normal",
      textAlign: "center",
      letterSpacing: "0.2em",
      borderRadius: "100px",
      boxSizing: "border-box",
      textDecoration: "none",
      boxShadow: "0 2px 4px rgba(0,0,0,0.3)",
      background: "linear-gradient(top left, #527CC5, #325BA1)",
      "& img": {
        width: "16px",
        height: "22px",
        marginRight: "15px",
        verticalAlign: "-5px",
      },
      cursor: "pointer",
    },
    // YouTube動画表示エリア
    videoArea: {
      position: "relative",
      marginTop: "20px",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
      color: "white",
      letterSpacing: "0.1em",
      "& div": {
        border: "2px solid gray",
        width: "335px",
        height: "188px",
        overflow: "hidden",
        borderRadius: "20px",
        left: "-1px",
      },
    },
    // アイキャッチ画像エリア
    eyeCatchArea: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: "20px",
      width: "335px",
      height: "276px",
      "& img": {
        width: "100%",
        height: "100%",
      },
      transform: "scale(1.05)",
    },
    // アイキャッチの一部のサークル
    eyeCatchCircle: {
      display: "block",
      position: "absolute",
      width: "51px",
      height: "51px",
      top: "250px",
      left: "320px",
    },
  }))

  // PC固有のスタイルを読み込む
  const pcStyles = usePCStyles()

  // 変更頻度が多いリソースをAWS S3で管理する
  const eyecatch = {
    pcLeft:
      "https://saastainer.s3-ap-northeast-1.amazonaws.com/img/site/eyecatch_pc_left.svg",
    pcRight:
      "https://saastainer.s3-ap-northeast-1.amazonaws.com/img/site/eyecatch_pc_right.svg",
    sp:
      "https://saastainer.s3-ap-northeast-1.amazonaws.com/img/site/eyecatch_sp.svg",
  }

  // PCサイトメインビジュアル
  const pcMainVisual = (
    <div className={pcStyles.root}>
      <div className={pcStyles.eyeCatchImageLeft}>
        <img src={eyecatch.pcLeft} />
      </div>
      <div className={pcStyles.eyeCatchImageRight}>
        <img src={eyecatch.pcRight} />
      </div>
      <p className={pcStyles.mainText}>
        {/* すべてのSaaSを */}
        {t("mainVisual.upperMainText")}
        <br />
        {/* ボタン１つでラクラク連携 */}
        {t("mainVisual.lowerMainText")}
      </p>
      <a
        className={pcStyles.trialButton}
        onClick={() => history.push("/signup")}
      >
        <img src={icon_saastainer} />
        {/* 無料で始める */}
        {t("mainVisual.freeTrial")}
      </a>
      <div className={pcStyles.videoArea}>
        {/* 動画でわかるSaaStainer */}
        <p>{t("mainVisual.videoTitle")}</p>
        <div>
          <iframe
            width="380"
            height="213"
            src="https://www.youtube.com/embed/Qy3EnuD42f0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; 
            autoplay; clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )

  // SP固有のスタイルを読み込む
  const spStyles = useSPStyles()

  // SPサイトメインビジュアル
  const spMainVisual = (
    <div className={spStyles.root}>
      <p className={spStyles.mainText}>
        {/* すべてのSaaSを */}
        {t("mainVisual.upperMainText")}
        <br />
        {/* ボタン１つでラクラク連携 */}
        {t("mainVisual.lowerMainText")}
      </p>
      <a
        className={spStyles.trialButton}
        onClick={() => history.push("/signup")}
      >
        <img src={icon_saastainer} />
        {/* 無料で始める */}
        {t("mainVisual.freeTrial")}
      </a>
      <div className={`${spStyles.videoArea} video`}>
        {/* 動画でわかるSaaStainer */}
        <p>{t("mainVisual.videoTitle")}</p>
        <div>
          <iframe
            width="335"
            height="188"
            src="https://www.youtube.com/embed/Qy3EnuD42f0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; 
            autoplay; clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className={spStyles.eyeCatchArea}>
        <img src={eyecatch.sp} />
      </div>
    </div>
  )

  // 横幅768px以上はPCスタイル。横幅767px以下はSPスタイル。
  if (isPC) return pcMainVisual
  return spMainVisual
}

export default MainVisual
