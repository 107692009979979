import React, { useContext } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import {
  AdminAuthContext,
  AdminAuthContextProvider,
} from "../contexts/AdminAuthManagement/AdminAuthContext"
import { ProductCodeManagementContextProvider } from "../contexts/ProductCodeManagement/ProductCodeManagementContextProvider"
import { AdminBaseLayout } from "../components/layouts/AdminBaseLayout"
import { ContentsLayout } from "../components/layouts/ContentsLayout"
import { DashboardPage } from "../components/pages/DashboardPage"
import { LoginPage } from "../components/pages/LoginPage"
import { ROLE } from "../types/AdminUser"
import { AdminUserManagementContextProvider } from "../contexts/AdminUserManagement/AdminUserManagementContextProvider"
import { AdminUserListPage } from "../components/pages/AdminUser/AdminUserListPage"
import { AdminUserAddPage } from "../components/pages/AdminUser/AdminUserAddPage"
import { AdminUserEditPage } from "../components/pages/AdminUser/AdminUserEditPage"
import { ProductCodeEditPage } from "../components/pages/ProductCode/productCodeEditPage"
import { ProductCodeIssuePage } from "../components/pages/ProductCode/ProductCodeIssuePage"
import { ProductCodeListPage } from "../components/pages/ProductCode/ProductCodeListPage"
import { PageAccessPermissions } from "../types/Common"
import { SalesManagementContextProvider } from "../contexts/SalesManagement/SalesManagementContextProvider"
import { SaleListPage } from "../components/pages/Sale/SaleListPage"
import { SaleEditPage } from "../components/pages/Sale/SaleEditPage"
import { SaleAddPage } from "../components/pages/Sale/SaleAddPage"
import { AdminBaseLayoutContextProvider } from "../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"
import { ProfileEditPage } from "../components/pages/ProfileEditPage"
import { SaaStainerUserManagementContextProvider } from "../contexts/SaaStainerUserManagement/SaaStainerUserManagementContextProvider"
import { SaaStainerUserListPage } from "../components/pages/SaaStainerUser/SaaStainerUserListPage"
import { AppManagementContextProvider } from "../contexts/AppManagement/AppManagementContextProvider"
import { AppListPage } from "../components/pages/App/AppListPage"
import { AppEditPage } from "../components/pages/App/AppEditPage"
import { ChurnSurveyListPage } from "../components/pages/SaaStainerUser/ChurnSurveyListPage"

/**
 * 管理画面全体のルーティングと認証・認可を一元管理します。
 *
 * ルートの定義は以下の２つがあります。
 *  1.NonAuthenticationRoute : 未認証ユーザーのみ許可するルート（ログイン画面の表示条件）
 *  2.PrivateRoute : 認証済のユーザーのみ許可するルート。認可が必要なページはロールを指定する。
 */
const root = "/admin"
export const productCodeManagementRoot = `${root}/productcode-management`
export const adminUserManagementRoot = `${root}/adminuser-management`
export const saastainerUserManagementRoot = `${root}/saastaineruser-management`
export const appManagementRoot = `${root}/app-management`
export const salesManagementRoot = `${root}/sale-management`

const AdminRouter = () => {
  return (
    <Switch>
      <Redirect from={root} to={`${root}/dashboard`} exact />
      <Route path={`${root}/*`} exact>
        <AdminAuthContextProvider>
          <AdminBaseLayoutContextProvider>
            <AdminBaseLayout>
              <Switch>
                {/* ログインページのルート */}
                <NonAuthenticationRoute
                  path={`${root}/login`}
                  element={<LoginPage />}
                />

                {/* ダッシュボードページのルート */}
                <PrivateRoute
                  path={`${root}/dashboard`}
                  element={<DashboardPage />}
                />

                {/* プロフィール編集ページのルート */}
                <PrivateRoute
                  path={`${root}/profile`}
                  element={<ProfileEditPage />}
                />

                {/* プロダクトコード管理のルート */}
                <Route path={`${productCodeManagementRoot}/:path?`} exact>
                  <ProductCodeManagementContextProvider>
                    <Switch>
                      <PrivateRoute
                        path={`${productCodeManagementRoot}/list`}
                        element={<ProductCodeListPage />}
                      />
                      <PrivateRoute
                        path={`${productCodeManagementRoot}/issue`}
                        element={<ProductCodeIssuePage />}
                        requiredRoles={["owner", "admin"]}
                      />
                      <PrivateRoute
                        path={`${productCodeManagementRoot}/edit`}
                        element={<ProductCodeEditPage />}
                        requiredRoles={["owner", "admin"]}
                      />
                    </Switch>
                  </ProductCodeManagementContextProvider>
                </Route>

                {/* SaaStainer登録者管理のルート */}
                <Route path={`${saastainerUserManagementRoot}/:path?`} exact>
                  <SaaStainerUserManagementContextProvider>
                    <Switch>
                      <PrivateRoute
                        path={`${saastainerUserManagementRoot}/list`}
                        element={<SaaStainerUserListPage />}
                      />
                      <PrivateRoute
                        path={`${saastainerUserManagementRoot}/churnsurveys`}
                        element={<ChurnSurveyListPage />}
                      />
                    </Switch>
                  </SaaStainerUserManagementContextProvider>
                </Route>

                {/* アプリ管理のルート */}
                <Route path={`${appManagementRoot}/:path?`} exact>
                  <AppManagementContextProvider>
                    <Switch>
                      <PrivateRoute
                        path={`${appManagementRoot}/list`}
                        element={<AppListPage />}
                      />
                      <PrivateRoute
                        path={`${appManagementRoot}/edit`}
                        element={<AppEditPage />}
                        requiredRoles={["owner"]}
                      />
                    </Switch>
                  </AppManagementContextProvider>
                </Route>

                {/* 管理者ユーザー管理のルート */}
                <Route path={`${adminUserManagementRoot}/:path?`} exact>
                  <AdminUserManagementContextProvider>
                    <Switch>
                      <PrivateRoute
                        path={`${adminUserManagementRoot}/list`}
                        element={<AdminUserListPage />}
                      />
                      <PrivateRoute
                        path={`${adminUserManagementRoot}/add`}
                        element={<AdminUserAddPage />}
                        requiredRoles={["owner"]}
                      />
                      <PrivateRoute
                        path={`${adminUserManagementRoot}/edit`}
                        element={<AdminUserEditPage />}
                        requiredRoles={["owner"]}
                      />
                    </Switch>
                  </AdminUserManagementContextProvider>
                </Route>

                {/* 販売管理のルート */}
                <Route path={`${salesManagementRoot}/:path?`} exact>
                  <SalesManagementContextProvider>
                    <Switch>
                      <PrivateRoute
                        path={`${salesManagementRoot}/list`}
                        element={<SaleListPage />}
                      />
                      <PrivateRoute
                        path={`${salesManagementRoot}/add`}
                        element={<SaleAddPage />}
                        requiredRoles={["owner", "admin"]}
                      />
                      <PrivateRoute
                        path={`${salesManagementRoot}/edit`}
                        element={<SaleEditPage />}
                        requiredRoles={["owner", "admin"]}
                      />
                    </Switch>
                  </SalesManagementContextProvider>
                </Route>
              </Switch>
            </AdminBaseLayout>
          </AdminBaseLayoutContextProvider>
        </AdminAuthContextProvider>
      </Route>
      <Redirect from="/admin/*" to={`${root}/dashboard`} />
    </Switch>
  )
}

// １．未認証ユーザーのみ許可するルートの定義
type NonAuthProps = {
  element: React.ReactElement
  path?: string
}
const NonAuthenticationRoute = (props: NonAuthProps) => {
  const { adminAuthState } = useContext(AdminAuthContext)
  if (adminAuthState.token) return <Redirect to={`${root}/dashboard`} />
  return <Route path={props.path}>{props.element}</Route>
}

// ２．認証または認可済のユーザーのみ許可するルートの定義
type PrivateRouteProps = {
  element: React.ReactElement // 表示対象のページ
  requiredRoles?: Array<ROLE> // 編集を認可するユーザロール
  path?: string
}
const PrivateRoute = (props: PrivateRouteProps) => {
  const { element, requiredRoles, path } = props
  const contentsLayout = <ContentsLayout>{element}</ContentsLayout>
  return (
    <Route path={path}>
      <PrivateElement element={contentsLayout} requiredRoles={requiredRoles} />
    </Route>
  )
}
const PrivateElement = (props: PrivateRouteProps) => {
  const { element, requiredRoles } = props
  const { adminAuthState } = useContext(AdminAuthContext)
  // 認証
  if (!adminAuthState.token) return <Redirect to={`${root}/login`} />
  if (!requiredRoles)
    // 認可不要ルート
    return React.cloneElement<PageAccessPermissions>(element, {
      scope: { read: true, write: true },
    })

  // 認可
  const included = requiredRoles.includes(adminAuthState.payload.role)

  return React.cloneElement<PageAccessPermissions>(element, {
    scope: { read: true, write: included },
  })
}

export default AdminRouter
