import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { makeStyles, fade } from "@material-ui/core/styles"

interface Props {
  dialogOpen: boolean
  onClose: (isAgree: boolean, checkboxValue?: boolean) => void
  message: string
  checkboxLabel?: string
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    backgroundColor: "#f3f4f6",
  },
  textColor: {
    color: "#4a5568",
  },
  button: {
    borderRadius: "10px", // 丸みを加える
    fontSize: "12px", // フォントサイズを変更
    padding: "10px", // パディングを調整
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.3), // ホバー時の背景色を少し透明にする
    },
  },
  checkbox: {
    color: "#4a5568",
  },
  checkboxCenter: {
    display: "flex",
    justifyContent: "center",
  },
}))

/**
 * AlertDialog コンポーネント
 * @param {Props} props - ダイアログの設定
 */
export default function AlertDialog(props: Props) {
  const { dialogOpen, onClose, message, checkboxLabel } = props
  const [checked, setChecked] = useState(false)
  const classes = useStyles()

  const handleClose = (isAgree: boolean) => {
    onClose(isAgree, checked)
    setChecked(false)
  }

  return (
    <Dialog
      open={dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.textColor}
        >
          {message}
        </DialogContentText>
        {checkboxLabel && (
          <div className={classes.checkboxCenter}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  className={classes.checkbox}
                />
              }
              label={checkboxLabel}
              className={classes.textColor}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          color="primary"
          className={classes.button}
        >
          いいえ
        </Button>
        <Button
          onClick={() => handleClose(true)}
          color="primary"
          autoFocus
          className={classes.button}
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  )
}
