// useTooltipStyles.ts
import { makeStyles, Theme } from "@material-ui/core/styles"

export interface TooltipProps {
  content: string
}

const useTooltipStyles = makeStyles<Theme, TooltipProps>({
  content: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tooltip: {
    position: "relative",
    whiteSpace: "pre-line",

    "&:hover::after": {
      content: (props) => `"${props.content.replace(/\n/g, ` `)}"`,
      position: "absolute",
      top: "100%",
      left: "0%",
      whiteSpace: "pre-wrap",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      color: "white",
      borderRadius: "4px",
      padding: "5px",
      zIndex: "100",
      pointerEvents: "none",
    },
  },
})

export default useTooltipStyles
