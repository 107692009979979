import React, { useEffect } from "react"
import { ProductCodeIssueAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { ProductCodeEditAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { NumericInput } from "../../Common/NumericInput"
import { useSnackbar } from "notistack"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: number
}

/**
 * プロダクトコードのライセンス数を入力するコンポーネント
 */
export const LicenseCountInput = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  initialValue = 1,
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "licenseCount", payload: initialValue } as T)
  }, [initialValue])

  const { enqueueSnackbar } = useSnackbar()

  const errorObj = {
    errorCode: 1,
    message: "ライセンス数は１以上の値を設定してください",
  }

  /**
   * ライセンス数変更時のハンドラー
   * @param number 変更後のライセンス数
   */
  const handleValueChange = (licenseCount: number) => {
    dispatch({ type: "licenseCount", payload: licenseCount } as T)
    if (licenseCount < 1) {
      dispatch({
        type: "addErrors",
        payload: { type: "licenseCount", ...errorObj },
      } as T)
      enqueueSnackbar(errorObj.message, {
        variant: "warning",
        preventDuplicate: true,
      })
    } else {
      dispatch({
        type: "removeErrors",
        payload: { type: "licenseCount", errorCode: errorObj.errorCode },
      } as T)
    }
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "ライセンス数を入力してください"
  }

  return (
    <div>
      <NumericInput
        onValueChange={handleValueChange}
        getPlaceholder={getPlaceholder}
        initialValue={initialValue}
      />
    </div>
  )
}
