import { Employee, EmployeeParams } from "../typings/companies"
import {
  deleteCompaniesApi,
  getCompaniesApi,
  postCompaniesApi,
  putCompaniesApi,
} from "./v1"

export async function getEmployees(token: string): Promise<Employee[]> {
  try {
    const res = await getCompaniesApi("/", null, token)

    return res.data.company.employees
  } catch (err) {
    throw err
  }
}

export async function registerEmployee(
  token: string,
  employee: EmployeeParams
): Promise<void> {
  try {
    await postCompaniesApi("/", employee, token)
  } catch (err) {
    throw err
  }
}

export async function showEmployee(
  token: string,
  employeeId: string
): Promise<Employee> {
  try {
    const res = await getCompaniesApi(employeeId, null, token)

    return res.data.company.employee
  } catch (err) {
    throw err
  }
}

export async function updateEmployee(
  token: string,
  employeeId: string,
  params: EmployeeParams
): Promise<void> {
  try {
    await putCompaniesApi(employeeId, params, token)
  } catch (err) {
    throw err
  }
}

export async function deleteEmployee(
  token: string,
  employeeId: string
): Promise<void> {
  try {
    await deleteCompaniesApi(employeeId, null, token)
  } catch (err) {
    throw err
  }
}
