import React from "react"
import * as FormTextField from "./FormTextField"
import Grid from "@material-ui/core/Grid"
import { FormSubmitProfileButtton } from "./FormSubmitButton"
import { ResetPasswordParams } from "../typings/users"
import { ResetPasswordProps } from "../typings"
import { SignInStyles } from "../styles/form"
import { useForm } from "react-hook-form"
import { useHistory, useLocation } from "react-router-dom"
import * as registers from "../typings/formRegisters"
import { useTranslation } from "react-i18next"

export default function FormResetPassword(props: ResetPasswordProps) {
  const { submitHandler, errorHandler } = props
  const { t } = useTranslation()

  const classes = SignInStyles()

  const history = useHistory()

  const location = useLocation()

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    formState,
    trigger,
  } = useForm<ResetPasswordParams>()

  const passwordRegister = registers.passwordRegister(register, true)
  const confirmRegister = registers.passwordConfirmRegister(register, getValues)

  const validateConfirm = () => {
    if (formState.isSubmitted) {
      trigger("passwordConfirm")
    }
  }

  const onSubmit = (data: ResetPasswordParams) => {
    submitHandler(data, location.search)
      .then(() => {
        history.push("/login")
      })
      .catch(errorHandler)
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <FormTextField.SignUpPasswordField
          forwardRef={passwordRegister}
          error={errors.password}
          onChange={validateConfirm}
          t={t}
        />
        <FormTextField.SignUpPasswordConfirmField
          forwardRef={confirmRegister}
          error={errors.passwordConfirm}
          t={t}
        />
        <FormSubmitProfileButtton submitClass={classes.submit} t={t} />
      </Grid>
    </form>
  )
}
