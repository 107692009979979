import {
  AppDetailData,
  AppsData,
  AllAppsData,
  InquiryParams,
  SORT_TYPE,
  ServiceNameArrowEmpty,
  CategoryIdArrowEmpty,
  AppData,
} from "../typings/apps"
import { deleteAppsApi, getAppsApi, postAppsApi } from "./v1"
import { loadStripe } from "@stripe/stripe-js"

interface AppsPageParams {
  appPage?: number
  keyword?: string
  categoryIds: CategoryIdArrowEmpty[]
  service: ServiceNameArrowEmpty
  appSortKey: SORT_TYPE
}

export async function getRelatedApps(
  appId: string,
  token?: string
): Promise<{ relatedapps: AppData[] }> {
  try {
    const res = await getAppsApi(`/${appId}/related`, null, token)
    return res.data
  } catch (err) {
    throw err
  }
}

export async function getApps(
  params: AppsPageParams,
  token?: string
): Promise<AppsData> {
  try {
    const page = params.appPage || 1
    const categoryIds = params.categoryIds
    const service = params.service
    const appsSortKey = params.appSortKey || 1

    let pageUrl = `/?page=${page}`
    if (params.keyword) {
      pageUrl += `&keyword=${params.keyword}`
    }
    if (categoryIds.length) {
      pageUrl += `&categoryIds=${categoryIds.join(",")}`
    }
    if (service) {
      pageUrl += `&service=${service}`
    }
    pageUrl += `&appsSortKey=${appsSortKey}`

    const res = await getAppsApi(pageUrl, null, token)

    return res.data
  } catch (err) {
    throw err
  }
}

export async function getAllApps(): Promise<AllAppsData> {
  try {
    const res = await getAppsApi("all", null)

    return res.data
  } catch (err) {
    throw err
  }
}

export async function getAppDetail(
  appId: string,
  token?: string
): Promise<AppDetailData> {
  try {
    const pageUrl = token !== undefined ? `${appId}/user` : appId

    const res = await getAppsApi(pageUrl, null, token)

    return res.data.app
  } catch (err) {
    throw err
  }
}

export async function getMyApps(
  appPage?: number,
  token?: string
): Promise<AppsData> {
  try {
    const res = await getAppsApi(
      `myapps/user/?page=${appPage || 1}`,
      null,
      token
    )

    return res.data
  } catch (err) {
    throw err
  }
}

export async function getSearchApps(
  appPage?: number,
  keyword?: string,
  token?: string
): Promise<AppsData> {
  try {
    const page = appPage || 1

    let pageUrl = `/search/?page=${page}`
    if (keyword) {
      pageUrl += `&keyword=${keyword}`
    }

    const res = await getAppsApi(pageUrl, null, token)

    return res.data
  } catch (err) {
    throw err
  }
}

const publishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string

export async function createNewSession(
  token: string,
  appId: string
): Promise<void> {
  try {
    const stripe = await loadStripe(publishableKey)

    if (stripe === null) throw new Error("stripe fail to initialize")

    const res = await postAppsApi("session", { appId }, token)

    const sessionId = res.data.sessionId

    const result = await stripe.redirectToCheckout({
      sessionId,
    })

    if (result.error) throw result.error

    return res.data.app
  } catch (err) {
    throw err
  }
}

export async function cancelSubscription(
  token: string,
  appId: string,
  reason: string,
  additionalInfo: string
): Promise<void> {
  try {
    await deleteAppsApi(
      "subscription",
      { appId, reason, additionalInfo },
      token
    )
  } catch (err) {
    throw err
  }
}

export async function sendInquiry(params: InquiryParams): Promise<void> {
  try {
    await postAppsApi("inquiry", params)
  } catch (err) {
    throw err
  }
}
