import { Pagination } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import React, { useContext, useEffect, useRef, useState } from "react"
import { GetSaaStainerUsersApi } from "../../../api/SaaStainerUserApi"
import {
  PageAccessPermissions,
  PaginableResponse,
  SortOrder,
} from "../../../types/Common"
import {
  SaaStainerUserType,
  SaaStainerUserSortKey,
} from "../../../types/SaaStainerUser"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { SaaStainerUserManagementContext } from "../../../contexts/SaaStainerUserManagement/SaaStainerUserManagementContextProvider"
import icon_search from "../../../images/icon_search.png"
import icon_clear from "../../../images/icon_clear.png"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonArrowStyle,
  globalTextboxStyle,
} from "../../globalStyles"
import { useHistory } from "react-router-dom"
import { formatDateToJstString } from "../../../utility/CommonUtil"
import { AdminBaseLayoutContext } from "../../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"
import { SortComponent } from "../../uiParts/Common/SortInput"
import { SimpleCheckBoxInput } from "../../uiParts/Common/SimpleCheckboxInput"
import button_white_arrow from "../../../images/button_white_arrow.png"
import { saastainerUserManagementRoot } from "../../../router/AdminRouter"

/**
 * アプリ登録者一覧画面のコンポーネント
 */

export const SaaStainerUserListPage = ({ scope }: PageAccessPermissions) => {
  const [results, setResults] = useState<
    PaginableResponse<SaaStainerUserType[]>
  >()
  const [searchTextEnable, setSearchTextEnable] = useState<boolean>(true)
  const { saastainerUserListState, saastainerUserListDispatch } = useContext(
    SaaStainerUserManagementContext
  )
  const { adminAuthState } = useContext(AdminAuthContext)
  const { circularProgressDispatch } = useContext(AdminBaseLayoutContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * アプリユーザーの一覧を取得し、表示を更新する関数
   */
  const updateResults = async () => {
    try {
      circularProgressDispatch({ type: "on" })
      const res = await new GetSaaStainerUsersApi(
        saastainerUserListState.page,
        saastainerUserListState.sortKey,
        saastainerUserListState.sortOrder,
        saastainerUserListState.isStrategitExcluded,
        saastainerUserListState.keyword,
        adminAuthState.token
      ).sendRequest()

      // キーワードが設定されている場合、検索ボックスにキーワードを表示して無効化する
      if (saastainerUserListState.keyword && inputKeywordRef.current) {
        inputKeywordRef.current.value = saastainerUserListState.keyword
        setSearchTextEnable(false)
      }

      // アプリユーザーが見つからなかった場合は、スナックバーで通知する
      if (res.data && !res.data.payload.length) {
        enqueueSnackbar("条件に一致するアプリ登録者が見つかりませんでした", {
          variant: "info",
          preventDuplicate: true,
        })
      }
      setResults(res.data)
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response
        ? errorObj.response.data
        : errorObj.message

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
      })
    } finally {
      circularProgressDispatch({ type: "off" })
    }
  }

  /**
   * キーワードの入力があった場合、キーワードを設定する関数
   */
  const dispatchKeyward = () => {
    if (!inputKeywordRef.current) return
    if (!searchTextEnable) inputKeywordRef.current.value = ""

    setSearchTextEnable((prev) => !prev)
    saastainerUserListDispatch({
      type: "keyword",
      payload: { keyword: inputKeywordRef.current.value.replace(/%/g, "") },
    })
  }

  /**
   * ソートキーが変更された場合の処理
   */
  const handleSortKeyChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    saastainerUserListDispatch({
      type: "sortkey",
      payload: { sortKey: e.target!.value as SaaStainerUserSortKey },
    })
  }

  /**
   * ソート順が変更された場合の処理
   */
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    saastainerUserListDispatch({
      type: "sortorder",
      payload: { sortOrder: e.target!.value as SortOrder },
    })
  }

  /**
   * 検索ボックスでEnterキーが押された場合の処理
   */
  const handleSearchTextKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key !== "Enter") return
    e.preventDefault()
    dispatchKeyward()
  }

  // 検索条件の変更を監視して画面を更新する
  useEffect(() => {
    updateResults()
  }, [saastainerUserListState])

  const sortKeyMap: { key: SaaStainerUserSortKey; name: string }[] = [
    { key: "company.name", name: "企業名" },
    { key: "kind", name: "ユーザー種別" },
    { key: "confirmedAt", name: "メール認証日時" },
    { key: "createdAt", name: "作成日時" },
  ]

  const inputKeywordRef = useRef<HTMLInputElement>(null)
  const styles = useStyles("14px", "10px")()

  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h3>SaaStainer登録者一覧</h3>
        <div className={styles.searchTextBox}>
          <input
            ref={inputKeywordRef}
            type="text"
            maxLength={256}
            placeholder={"キーワードで検索"}
            onKeyPress={handleSearchTextKeyPress}
            disabled={!searchTextEnable}
          />
          <button type="button" onClick={dispatchKeyward}></button>
          <img src={searchTextEnable ? icon_search : icon_clear} />
        </div>
        <button
          type="button"
          className={styles.surveyButton}
          onClick={() =>
            history.push(`${saastainerUserManagementRoot}/churnsurveys`)
          }
        >
          解約者アンケート一覧
          <img src={button_white_arrow} />
        </button>
      </div>
      <div className={styles.lineHeaderArea}>
        <div className={styles.totalCountArea}>
          <span
            style={{
              color: "#32cb54",
              fontWeight: "bold",
              paddingRight: "5px",
            }}
          >{`${results?.pageInfo.totalCount || 0}`}</span>
          <span>件該当</span>
          <SimpleCheckBoxInput
            getLabel={() => "@strategitを除外"}
            onValueChange={(checked) =>
              saastainerUserListDispatch({
                type: "isStrategitExcluded",
                payload: { isStrategitExcluded: checked },
              })
            }
            initialValue={true}
          />
        </div>
        <SortComponent
          sortKey={saastainerUserListState.sortKey}
          sortOrder={saastainerUserListState.sortOrder}
          sortKeyMap={sortKeyMap}
          handleSortKeyChange={handleSortKeyChange}
          handleSortOrderChange={handleSortOrderChange}
        />
      </div>
      <div className={styles.lineArea}>
        <table>
          <tbody>
            <tr>
              <th>UID</th>
              <th>企業名</th>
              <th>氏</th>
              <th>名</th>
              <th>メールアドレス</th>
              <th>ユーザー種別</th>
              <th>作成日時</th>
              <th>メール認証日時</th>
            </tr>
            {results && results.payload.length
              ? results.payload.map((v, index) => {
                  return (
                    <tr
                      key={index}
                      className={v.confirmedAt ? "" : styles.greyOut}
                      onClick={() => {}}
                    >
                      <td>{v.id}</td>
                      <td>{v.companyName}</td>
                      <td>{v.familyName}</td>
                      <td>{v.firstName}</td>
                      <td>{v.email}</td>
                      <td>{v.kind}</td>
                      <td>{formatDateToJstString(new Date(v.createdAt))}</td>
                      <td>
                        {v.confirmedAt
                          ? formatDateToJstString(new Date(v.confirmedAt))
                          : "未認証"}
                      </td>
                    </tr>
                  )
                })
              : null}
          </tbody>
        </table>
      </div>
      {/* ページネーション */}
      {results && results.payload.length ? (
        <div className={styles.pagination}>
          <Pagination
            count={results ? results.pageInfo.totalPages : 1}
            page={results ? results.pageInfo.page : 1}
            onChange={(_, page) =>
              saastainerUserListDispatch({ type: "page", payload: { page } })
            }
            color="primary"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  )
}

const useStyles = (fontSize: string, hPadding: string) => {
  // ルート
  const root = {
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
  }

  // 検索タイトル
  const title = {
    display: "grid" as const,
    gap: "20px",
    gridTemplateColumns: "auto 300px 250px",
    alignItems: "center" as const,
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize,
    whiteSpace: "nowrap" as const,
  }

  // 解約者アンケート一覧ボタン
  const surveyButton = {
    ...globalSystemButtonArrowStyle,
  }

  // ボタン非活性
  const disabledButton = {
    ...globalDisabledButtonStyle,
  }

  // キーワード検索テキストボックス
  const searchTextBox = {
    position: "relative" as const,
    width: "100%",
    "& input": {
      boxSizing: "border-box" as const,
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 40px 0 20px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "2px solid gray",
    },
    "& img": {
      position: "absolute" as const,
      top: "13px",
      right: "20PX",
      width: "14px",
      pointerEvents: "none" as const,
    },
    "& button": {
      position: "absolute" as const,
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer" as const,
    },
  }

  // 明細ヘッダーエリア
  const lineHeaderArea = {
    display: "flex" as const,
    alignItems: "center" as const,
    justifyContent: "space-between" as const,
  }

  // 件数エリア
  const totalCountArea = {
    display: "flex" as const,
    alignItems: "center" as const,
    justifyContent: "flex-start" as const,
    "& span:nth-child(2)": {
      marginRight: "20px",
    },
    ...globalTextboxStyle,
  }

  // 明細エリア
  const lineArea = {
    display: "flex" as const,
    alignItems: "center" as const,
    justifyContent: "center" as const,
    paddingTop: "20px",
    "& table": {
      tableLayout: "auto" as const,
      borderCollapse: "separate" as const,
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
      fontSize: "12px",
      "& th, & td": {
        padding: "10px",
        width: "auto",
      },
      "& th": {
        textAlign: "center",
        backgroundColor: "#536381",
        color: "#fff",
      },
      "& tr:nth-child(even)": {
        backgroundColor: "#f2f2f2",
      },
      "& tr": {
        "&:hover": {
          backgroundColor: "rgba(79, 121, 193, 0.3)",
          cursor: "pointer" as const,
        },
      },
    },
    "& div[id=noResults]": {
      textAlign: "center" as const,
    },
  }

  // ページネーション
  const pagination = {
    paddingTop: "20px",
    display: "flex" as const,
    justifyContent: "center" as const,
  }

  return makeStyles((_: Theme) => ({
    root: { ...root },
    title: { ...title },
    surveyButton: { ...surveyButton },
    disabledButton: { ...disabledButton },
    searchTextBox: { ...searchTextBox },
    lineHeaderArea: { ...lineHeaderArea },
    totalCountArea: { ...totalCountArea },
    lineArea: { ...lineArea },
    pagination: { ...pagination },
    greyOut: {
      color: "darkgray",
    },
  }))
}
