import React from "react"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import { PublicLayoutProps } from "../typings"
import { PublicStyles } from "../styles/public"
import { useTranslation } from "react-i18next"

export default function ThanksSignUp(props: PublicLayoutProps) {
  const { alert } = props
  const { t } = useTranslation()
  const classes = PublicStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h5" variant="h5">
          {t("thanksSignup.text")}
        </Typography>
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
