import { Pagination } from "@material-ui/lab"
import { useSnackbar } from "notistack"
import React, { useContext, useEffect, useRef, useState } from "react"
import { GetAppsApi } from "../../../api/AppApi"
import {
  PageAccessPermissions,
  PaginableResponse,
  SortOrder,
} from "../../../types/Common"
import { AppType, AppSortKey } from "../../../types/App"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { AppManagementContext } from "../../../contexts/AppManagement/AppManagementContextProvider"
import icon_search from "../../../images/icon_search.png"
import icon_clear from "../../../images/icon_clear.png"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonArrowStyle,
} from "../../globalStyles"
import button_white_arrow from "../../../images/button_white_arrow.png"
import { useHistory } from "react-router-dom"
import { appManagementRoot } from "../../../router/AdminRouter"
import { formatDateToJstString } from "../../../utility/CommonUtil"
import { AdminBaseLayoutContext } from "../../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"
import { SortComponent } from "../../uiParts/Common/SortInput"

/**
 * アプリ一覧画面のコンポーネント
 */

export const AppListPage = ({ scope }: PageAccessPermissions) => {
  const [results, setResults] = useState<PaginableResponse<AppType[]>>()
  const [searchTextEnable, setSearchTextEnable] = useState<boolean>(true)
  const { appListState, appListDispatch } = useContext(AppManagementContext)
  const { appEditDispatch } = useContext(AppManagementContext)
  const { adminAuthState } = useContext(AdminAuthContext)
  const { circularProgressDispatch } = useContext(AdminBaseLayoutContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * アプリの一覧を取得し、表示を更新する関数
   */
  const updateResults = async () => {
    try {
      circularProgressDispatch({ type: "on" })

      const res = await new GetAppsApi(
        appListState.page,
        appListState.sortKey,
        appListState.sortOrder,
        appListState.keyword,
        adminAuthState.token
      ).sendRequest()

      // キーワードが設定されている場合、検索ボックスにキーワードを表示して無効化する
      if (appListState.keyword && inputKeywordRef.current) {
        inputKeywordRef.current.value = appListState.keyword
        setSearchTextEnable(false)
      }

      // アプリが見つからなかった場合は、スナックバーで通知する
      if (res.data && !res.data.payload.length) {
        enqueueSnackbar("条件に一致するアプリが見つかりませんでした", {
          variant: "info",
          preventDuplicate: true,
        })
      }
      setResults(res.data)
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response
        ? errorObj.response.data
        : errorObj.message

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
      })
    } finally {
      circularProgressDispatch({ type: "off" })
    }
  }

  /**
   * キーワードの入力があった場合、キーワードを設定する関数
   */
  const dispatchKeyward = () => {
    if (!inputKeywordRef.current) return
    if (!searchTextEnable) inputKeywordRef.current.value = ""

    setSearchTextEnable((prev) => !prev)
    appListDispatch({
      type: "keyword",
      payload: { keyword: inputKeywordRef.current.value.replace(/%/g, "") },
    })
  }

  /**
   * ソートキーが変更された場合の処理
   */
  const handleSortKeyChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    appListDispatch({
      type: "sortkey",
      payload: { sortKey: e.target!.value as AppSortKey },
    })
  }

  /**
   * ソート順が変更された場合の処理
   */
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    appListDispatch({
      type: "sortorder",
      payload: { sortOrder: e.target!.value as SortOrder },
    })
  }

  /**
   * 検索ボックスでEnterキーが押された場合の処理
   */
  const handleSearchTextKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key !== "Enter") return
    e.preventDefault()
    dispatchKeyward()
  }

  /**
   * 一覧から選択したアプリの編集ページを開きます
   */
  const handleTableRowClick = async (app: AppType) => {
    appEditDispatch({ type: "id", payload: app._id })
    history.push(`${appManagementRoot}/edit`)
  }

  // 検索条件の変更を監視して画面を更新する
  useEffect(() => {
    updateResults()
  }, [appListState])

  const sortKeyMap: { key: AppSortKey; name: string }[] = [
    { key: "name", name: "アプリ名" },
    { key: "category", name: "カテゴリー" },
    { key: "display", name: "公開 / 非公開" },
    { key: "popularity", name: "人気" },
    { key: "createdAt", name: "作成日時" },
  ]

  const inputKeywordRef = useRef<HTMLInputElement>(null)
  const styles = useStyles("14px", "10px")()

  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h3>アプリ一覧</h3>
        <div className={styles.searchTextBox}>
          <input
            ref={inputKeywordRef}
            type="text"
            maxLength={256}
            placeholder={"キーワードで検索"}
            onKeyPress={handleSearchTextKeyPress}
            disabled={!searchTextEnable}
          />
          <button type="button" onClick={dispatchKeyward}></button>
          <img src={searchTextEnable ? icon_search : icon_clear} />
        </div>
        <button
          type="button"
          className={
            true // 追加機能が実装されたらreadOnlyフラグに戻す
              ? `${styles.addButton} ${styles.disabledButton}`
              : styles.addButton
          }
          onClick={() => history.push(`${appManagementRoot}/add`)}
          disabled={true}
        >
          アプリ登録
          <img src={button_white_arrow} />
        </button>
      </div>
      <SortComponent
        sortKey={appListState.sortKey}
        sortOrder={appListState.sortOrder}
        sortKeyMap={sortKeyMap}
        handleSortKeyChange={handleSortKeyChange}
        handleSortOrderChange={handleSortOrderChange}
      />
      <div className={styles.lineArea}>
        <table>
          <tbody>
            <tr>
              <th>アプリ名</th>
              <th>アプリID</th>
              <th>カテゴリー</th>
              <th>サービス</th>
              <th>価格（税込）</th>
              <th>トライアル日数</th>
              <th>公開状況</th>
              <th>人気</th>
              <th>作成日時</th>
            </tr>
            {results && results.payload.length
              ? results.payload.map((v, index) => {
                  return (
                    <tr key={index} onClick={() => handleTableRowClick(v)}>
                      <td>{v.name}</td>
                      <td>{v.appId}</td>
                      <td>{v.category.name}</td>
                      <td>{v.service.join("/ ")}</td>
                      <td>{v.amount}</td>
                      <td>{v.subscriptionData?.trialPeriodDays || 0}</td>
                      <td>{v.display ? "公開" : "非公開"}</td>
                      <td>{v.popularity}</td>
                      <td>
                        {v.createdAt
                          ? formatDateToJstString(new Date(v.createdAt))
                          : "不明"}
                      </td>
                    </tr>
                  )
                })
              : null}
          </tbody>
        </table>
      </div>
      {/* ページネーション */}
      {results && results.payload.length ? (
        <div className={styles.pagination}>
          <Pagination
            count={results ? results.pageInfo.totalPages : 1}
            page={results ? results.pageInfo.page : 1}
            onChange={(_, page) =>
              appListDispatch({ type: "page", payload: { page } })
            }
            color="primary"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  )
}

const useStyles = (fontSize: string, hPadding: string) => {
  // ルート
  const root = {
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
  }

  // 検索タイトル
  const title = {
    display: "grid" as const,
    gap: "20px",
    gridTemplateColumns: "auto 300px 250px",
    alignItems: "center" as const,
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize,
    whiteSpace: "nowrap" as const,
  }

  // プロダクトコード発行ボタン
  const addButton = {
    ...globalSystemButtonArrowStyle,
  }

  // ボタン非活性
  const disabledButton = {
    ...globalDisabledButtonStyle,
  }

  // キーワード検索テキストボックス
  const searchTextBox = {
    position: "relative" as const,
    width: "100%",
    "& input": {
      boxSizing: "border-box" as const,
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 40px 0 20px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "2px solid gray",
    },
    "& img": {
      position: "absolute" as const,
      top: "13px",
      right: "20PX",
      width: "14px",
      pointerEvents: "none" as const,
    },
    "& button": {
      position: "absolute" as const,
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer" as const,
    },
  }

  // 明細エリア
  const lineArea = {
    display: "flex" as const,
    alignItems: "center" as const,
    justifyContent: "center" as const,
    paddingTop: "20px",
    "& table": {
      tableLayout: "auto" as const,
      borderCollapse: "separate" as const,
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
      fontSize: "12px",
      "& th, & td": {
        padding: "10px",
        width: "auto",
      },
      "& th": {
        textAlign: "center",
        backgroundColor: "#536381",
        color: "#fff",
      },
      "& tr:nth-child(even)": {
        backgroundColor: "#f2f2f2",
      },
      "& tr": {
        "&:hover": {
          backgroundColor: "rgba(79, 121, 193, 0.3)",
          cursor: "pointer" as const,
        },
      },
    },
    "& div[id=noResults]": {
      textAlign: "center" as const,
    },
  }

  // ページネーション
  const pagination = {
    paddingTop: "20px",
    display: "flex" as const,
    justifyContent: "center" as const,
  }

  return makeStyles((_: Theme) => ({
    root: { ...root },
    title: { ...title },
    addButton: { ...addButton },
    disabledButton: { ...disabledButton },
    searchTextBox: { ...searchTextBox },
    lineArea: { ...lineArea },
    pagination: { ...pagination },
  }))
}
