import { makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useState } from "react"

type NumericInputProps = {
  onValueChange: (value: number) => void
  getPlaceholder: () => string
  initialValue?: number
}

/**
 * 半角数字のみ入力可能な input 要素を表す NumericInput コンポーネント
 */
export const NumericInput = ({
  onValueChange,
  getPlaceholder,
  initialValue = 0,
}: NumericInputProps) => {
  const [value, setValue] = useState<number>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  /**
   * インプットの値が変更されたときの処理
   * @param event イベントオブジェクト
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (/^\d+$/.test(newValue)) {
      const intValue = parseInt(newValue, 10)
      if (intValue < 0) {
        setValue(0)
        onValueChange(0)
      } else {
        setValue(intValue)
        onValueChange(intValue)
      }
    } else {
      setValue(0)
      onValueChange(0)
    }
  }

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <input
        type="number"
        min={0}
        max={Number.MAX_VALUE}
        value={value}
        onChange={handleChange}
        placeholder={getPlaceholder()}
      />
    </div>
  )
}

const useStyles = makeStyles((_: Theme) => ({
  root: {
    "& input[type=number]": {
      boxSizing: "border-box",
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 20px 0 20px",
      transition: "all 0.1s ease-in",
      "-moz-appearance": "textfield",
      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::placeholder": {
        fontSize: "12px",
      },
      "&:focus": {
        outline: "none",
        border: "2px solid gray",
      },
    },
  },
}))
