import React, { useEffect } from "react"
import SingleDatePicker from "../../Common/SingleDatePicker"

/**
 * 支払日を入力するコンポーネント
 */
type Props<T> = {
  dispatch: React.Dispatch<T>
  epochTime?: number
}

const jstOffset = 9 * 60 * 60

export const PaymentDueDatePicker = <T,>({ dispatch, epochTime }: Props<T>) => {
  useEffect(() => {
    if (epochTime) {
      dispatch({ type: "paidAt", payload: epochTime } as T)
    }
  }, [epochTime])

  /**
   * 日付変更時のハンドラー
   * @param date 変更後の日付
   */
  const handleDateChange = (date: Date) => {
    dispatch({ type: "paidAt", payload: date.getTime() / 1000 } as T)
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "支払日"
  }

  /**
   * DBから取得したエポック時間を日本時間のDATE型に変換する関数
   * @param epochTime DBの日付
   * @returns 変換した日付（日本時間）
   */
  const convertEpochToDate = (epochTime: number | undefined): Date => {
    let date
    if (epochTime) {
      date = new Date(epochTime * 1000)
    } else {
      date = new Date()
      date.setHours(0, 0, 0, 0)
    }

    const jstDate = new Date(date.getTime() + jstOffset * 1000)
    return new Date(
      jstDate.getFullYear(),
      jstDate.getMonth(),
      jstDate.getDate()
    )
  }

  return (
    <div>
      <SingleDatePicker
        onDateChange={handleDateChange}
        getPlaceholder={getPlaceholder}
        initialDate={convertEpochToDate(epochTime)}
      />
    </div>
  )
}
