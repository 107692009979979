import React from "react"
import { useSnackbar } from "notistack"
import SingleDatePicker from "../../Common/SingleDatePicker"
import {
  ProductCodeIssueAction,
  ProductCodeIssueState,
} from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import {
  ProductCodeEditAction,
  ProductCodeEditState,
} from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  state: ProductCodeIssueState | ProductCodeEditState
  initialDate?: Date
}
/**
 * プロダクトコードの利用開始日を入力するコンポーネント
 */
export const UsageStartDatePicker = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  state,
  initialDate,
}: Props<T>) => {
  const { enqueueSnackbar } = useSnackbar()
  /**
   * 日付変更時のハンドラー
   * @param date 変更後の日付
   */
  const handleDateChange = (date: Date) => {
    if (state.usageExpireIn) {
      if (date > state.usageExpireIn) {
        enqueueSnackbar("利用期限よりあとに開始日が設定されています", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    dispatch({ type: "usageStartedAt", payload: date } as T)
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "プロダクトコードの利用開始日"
  }

  return (
    <div>
      <SingleDatePicker
        onDateChange={handleDateChange}
        getPlaceholder={getPlaceholder}
        initialDate={initialDate ?? new Date()}
      />
    </div>
  )
}
