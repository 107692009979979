import { makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useState } from "react"

type Props = {
  getLabel: () => string
  onValueChange: (checked: boolean) => void
  initialValue?: boolean
  readonly?: boolean
}

/**
 * シンプルなチェックボックスコンポーネント
 */
export const SimpleCheckBoxInput = ({
  getLabel,
  onValueChange,
  initialValue = false,
  readonly = false,
}: Props) => {
  const [value, setValue] = useState<boolean>(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  /**
   * チェックボックスの値が変更されたときの処理
   * @param event イベントオブジェクト
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
    onValueChange(event.target.checked)
  }

  const styles = useStyles()

  return (
    <label htmlFor="display" className={styles.root}>
      <input
        id="display"
        type="checkbox"
        checked={value}
        onChange={(event) => handleChange(event)}
        readOnly={readonly}
      />
      {getLabel()}
    </label>
  )
}

const useStyles = makeStyles((_: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& input": {
      marginRight: "10px",
    },
  },
}))
