import { makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useState } from "react"

/**
 * カレンダーコンポーネント
 *
 * @param {DatePickerProps} onDateChange - 日付変更時のコールバック関数を指定します
 * @returns {JSX.Element} カレンダーのコンポーネント
 */

type DatePickerProps = {
  onDateChange: (date: Date) => void
  getPlaceholder: () => string
  initialDate?: Date
}

const SingleDatePicker = ({
  onDateChange,
  getPlaceholder,
  initialDate,
}: DatePickerProps) => {
  const [date, setDate] = useState("")

  useEffect(() => {
    if (initialDate) {
      const year = initialDate.getFullYear().toString()
      const month = (initialDate.getMonth() + 1).toString().padStart(2, "0")
      const day = initialDate.getDate().toString().padStart(2, "0")

      onDateChange(initialDate)
      setDate(`${year}-${month}-${day}`)
    }
  }, [])

  /**
   * 日付が変更されたときのコールバック関数
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - 日付変更イベント
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [year, month, day] = e.target.value.split("-")

    if (!year || !month || !day) return

    const jstDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      0,
      0,
      0
    )
    onDateChange(jstDate)
    setDate(e.target.value)
  }

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <input
        type="date"
        value={date}
        onChange={onChange}
        placeholder={getPlaceholder()}
      />
    </div>
  )
}

export default SingleDatePicker

const useStyles = makeStyles((_: Theme) => ({
  root: {
    "& input[type=date]": {
      boxSizing: "border-box",
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 20px 0 20px",
      transition: "all 0.1s ease-in",
      "&::placeholder": {
        fontSize: "12px",
      },
      "&:focus": {
        outline: "none",
        border: "2px solid gray",
      },
    },
  },
}))
