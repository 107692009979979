import React, { createContext } from "react"
import { AdminUserType } from "../../types/AdminUser"
import { ResponseType } from "../../types/Common"
import {
  useAdminAuthReducer,
  initialState,
  AdminAuthAction,
} from "./reducers/useAuthReducer"

/**
 * 認証管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {ResponseType<AdminUserType>} 0 管理するProductCodeの情報
 * @property {React.Dispatch<AdminAuthAction>} 1 ContextTypeで管理するActionをdispatchする関数
 */
type ContextType = {
  adminAuthState: ResponseType<AdminUserType>
  adminAuthDispatch: React.Dispatch<AdminAuthAction>
}

/*
 * createContextに渡す引数の構造体を定義
 */
const initCtxValue: ContextType = {
  adminAuthState: initialState,
  adminAuthDispatch: (_: AdminAuthAction) => {},
}

/*
 * 管理者認証情報コンテキストの作成
 */
export const AdminAuthContext = createContext<ContextType>(initCtxValue)

/*
 * 管理者認証情報コンテキストのプロバイダー
 * 子コンポーネントにcontextを提供するためのもの
 */
export const AdminAuthContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useAdminAuthReducer()
  return (
    <AdminAuthContext.Provider
      value={{ adminAuthState: state, adminAuthDispatch: dispatch }}
    >
      {children}
    </AdminAuthContext.Provider>
  )
}
