import React, { createContext, useState } from "react"
import EmployerLayout, { EmployerMenuItems } from "./layouts/EmployerLayout"
import PublicLayout from "./layouts/PublicLayout"
import UserLayout, { UserMenuItems } from "./layouts/UserLayout"
import { AppAlert, MenuItemEntry, UserLayoutProps } from "./typings"
import { AxiosError } from "axios"
import { useRouteMatch } from "react-router-dom"
import { EmployeeParams } from "./typings/companies"
import { getMessage } from "./typings/messageTypes"
import { registerEmployee, updateEmployee } from "./api/companies"
import { sendInquiry } from "./api/apps"
import {
  sendSignIn,
  sendSignOut,
  sendSignUp,
  sendForgotPassword,
  sendResetPassword,
  updateProfile,
} from "./api/users"
import {
  ProfileParams,
  SignInParams,
  SignUpParams,
  ForgotPasswordParams,
  ResetPasswordParams,
  SuccessRespond,
} from "./typings/users"
import { InquiryParams } from "./typings/apps"
import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"
import ScrollToTop from "./components/ScrollToTop"
import GlobalHeader from "./components/GlobalHeader"
import GlobalFooter from "./components/GlobalFooter"
import { CssBaseline, useMediaQuery } from "@material-ui/core"
import {
  initialSearchCondition,
  useAppSearchReducer,
} from "./hooks/useAppSearchReducer"
import AdminRouter from "./admin/router/AdminRouter"

const storage = window.sessionStorage

// 認証情報を共有するためのコンテキスト定義

type AuthType = {
  isLogin: boolean
}

export const AuthContext = createContext<AuthType>({
  isLogin: false,
})

// PCサイト・SPサイトのレスポンシブ対応のためのコンテキスト定義
export type MediaQueryType = {
  isPC: boolean
  pcHeaderHeight: string
  spHeaderHeight: string
  pcFooterHeight: string
  spFooterHeight: string
  pcWidth: string
  spWidth: string
}
export const MediaQueryContext = createContext<MediaQueryType>({
  isPC: false,
  pcHeaderHeight: "0px",
  spHeaderHeight: "0px",
  pcFooterHeight: "0px",
  spFooterHeight: "0px",
  pcWidth: "0px",
  spWidth: "0%",
})

// アプリ検索のためのコンテキスト定義
export const AppSearchContext = createContext(initialSearchCondition)

function App() {
  const [alert, setAlert] = useState<AppAlert | null>(null)
  const [token, setToken] = useState<string | null>(storage.getItem("token"))
  const [kind, setKind] = useState<string | null>(storage.getItem("kind"))
  const { t } = useTranslation()
  const closeHandler = () => setAlert(null)

  function handleSuccess(user: SuccessRespond) {
    const params = new URLSearchParams(document.location.search.substring(1))
    const redirectTo = params.get("redirect_to")
    setAlert({ message: t("alerts.loggedIn"), closeHandler })
    storage.setItem("token", user.token)
    setToken(user.token)
    storage.setItem("kind", user.kind)
    setKind(user.kind)
    if (typeof redirectTo === "string") window.location.href = redirectTo
  }

  function handleErrors(err: AxiosError) {
    const response = err.response

    if (response != null && response.data.messageType != null) {
      const message = getMessage(parseInt(response.data.messageType, 10))
      setAlert({ message, closeHandler })
      return
    }

    setAlert({ message: err.message, closeHandler })
  }

  const submitEmployeeForm = (employee: EmployeeParams, selectedId: string) => {
    if (token === null) return

    return (typeof selectedId === "string"
      ? updateEmployee(token, selectedId, employee)
      : registerEmployee(token, employee)
    ).catch(handleErrors)
  }

  const sendLoginRequest = (params: SignInParams) => {
    sendSignIn(params).then(handleSuccess).catch(handleErrors)
  }

  const sendSignUpRequest = (params: SignUpParams, search: string) => {
    return sendSignUp(params, search)
  }

  const sendForgotPasswordRequest = (params: ForgotPasswordParams) => {
    return sendForgotPassword(params)
  }

  const sendResetPasswordRequest = (
    params: ResetPasswordParams,
    search: string
  ) => {
    return sendResetPassword(params, search)
  }

  const sendSignOutRequest = () => {
    if (token == null) return

    sendSignOut(token)
      .then(() => clearToken())
      .catch(handleErrors)
  }

  const sendUpdateProfile = (params: ProfileParams) => {
    if (token == null) return Promise.resolve()

    return updateProfile(token, params)
      .then((profileData) => {
        if (profileData !== undefined && profileData !== null) {
          setAlert({ message: t("alerts.updated"), closeHandler })
          return profileData
        }

        clearToken()
      })
      .catch(handleErrors)
  }

  const sendInquiryRequest = (params: InquiryParams) => {
    return sendInquiry(params)
  }

  const clearToken = (err?: Error) => {
    const alertMessage = err ? t("alerts.getDataError") : t("alerts.loggedOut")
    setAlert({ message: alertMessage, closeHandler })
    storage.removeItem("token")
    setToken(null)
    storage.removeItem("kind")
    setKind(null)
  }

  const isSignedIn =
    typeof token === "string" &&
    typeof kind === "string" &&
    token !== "" &&
    kind !== ""

  let layout: JSX.Element | undefined

  if (isSignedIn) {
    const props: UserLayoutProps = {
      alert,
      token: token as string,
      kind: kind as string,
      clickSignOutHandler: sendSignOutRequest,
      clickUpdateProfileHandler: sendUpdateProfile,
      errorHandler: clearToken,
      submitEmployeeForm: submitEmployeeForm as any,
      inquiryHandler: sendInquiryRequest,
    }

    layout =
      kind === "EmployerUser" ? (
        <EmployerLayout {...props} />
      ) : (
        <UserLayout {...props} />
      )
  }

  layout = layout || (
    <PublicLayout
      alert={alert}
      signInHandler={sendLoginRequest}
      signUpHandler={sendSignUpRequest}
      forgotPasswordHandler={sendForgotPasswordRequest}
      resetPasswordHandler={sendResetPasswordRequest}
      inquiryHandler={sendInquiryRequest}
      errorHandler={handleErrors}
    />
  )

  // menu items
  let menuItems: MenuItemEntry[] = []
  if (kind === "EmployerUser") menuItems = EmployerMenuItems(sendSignOutRequest)
  if (kind === "User") menuItems = UserMenuItems(sendSignOutRequest)

  const match = useRouteMatch("/admin")

  // メディアクエリーコンテキスト用のデータ
  const isPC = useMediaQuery("(min-width:768px)")
  const pcWidth = "1280px"
  const spWidth = "100%"
  const pcHeaderHeight = "76px"
  const spHeaderHeight = "65px"
  const pcFooterHeight = "151px"
  const spFooterHeight = "299px"

  // SearchContextに渡すstateとdispatchの生成
  const searchCondition = useAppSearchReducer()

  return (
    <>
      <ScrollToTop />
      <Box>
        {match ? (
          // 管理者用ページへ遷移
          <AdminRouter />
        ) : (
          // SaaStainerトップページへ遷移
          <AuthContext.Provider value={{ isLogin: isSignedIn }}>
            <MediaQueryContext.Provider
              value={{
                isPC,
                pcHeaderHeight,
                spHeaderHeight,
                pcFooterHeight,
                spFooterHeight,
                pcWidth,
                spWidth,
              }}
            >
              <AppSearchContext.Provider value={searchCondition}>
                <CssBaseline />
                <GlobalHeader menuItems={menuItems} />
                <Box
                  style={{
                    minHeight: `calc(100vh - ${
                      isPC ? pcFooterHeight : spFooterHeight
                    })`,
                  }}
                >
                  {layout}
                </Box>
                <GlobalFooter />
              </AppSearchContext.Provider>
            </MediaQueryContext.Provider>
          </AuthContext.Provider>
        )}
      </Box>
    </>
  )
}

export default App
