import { makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import {
  globalCustomDropDownArrow,
  globalRoundedBorderSelectStyle,
} from "../../globalStyles"

type Props = {
  onValueChange: (value: unknown) => void
  renderOptions: () => React.ReactElement<HTMLOptionElement>[]
  initialValue: string
  backgroundColor?: string
}

/**
 * シンプルなセレクト入力コンポーネント
 */
export const SimpleSelectInput = ({
  onValueChange,
  renderOptions,
  initialValue,
  backgroundColor,
}: Props) => {
  const [value, setValue] = useState<string>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (onValueChange) onValueChange(value)
  }, [value])

  /**
   * 選択値が変更されたときの処理
   * @param event イベントオブジェクト
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value)
  }

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <select
        value={value}
        onChange={handleChange}
        required={true}
        style={backgroundColor ? { backgroundColor } : {}}
      >
        {renderOptions()}
      </select>
      <span></span>
    </div>
  )
}

const useStyles = makeStyles((_: Theme) => ({
  root: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    width: "100%",
    "& select": {
      ...globalRoundedBorderSelectStyle,
    },
    "& span": {
      ...globalCustomDropDownArrow,
    },
  },
}))
