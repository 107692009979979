import React, { useEffect } from "react"
import { SaleAddAction } from "../../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import { SaleEditAction } from "../../../../contexts/SalesManagement/reducers/useSaleEditReducer"
import { SimpleTextInput } from "../../Common/SimpleTextInput"

type Props<T extends SaleAddAction | SaleEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: string
}

/**
 * 関連情報を入力するコンポーネント
 */
export const RelatedInfoInput = <T extends SaleAddAction | SaleEditAction>({
  dispatch,
  initialValue = "",
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "relatedInfo", payload: initialValue } as T)
  }, [initialValue])

  /**
   * 関連情報を設定する
   * @param value 関連情報
   */
  const handleValueChange = (value: string) => {
    dispatch({ type: "relatedInfo", payload: value } as T)
  }

  return (
    <SimpleTextInput
      onValueChange={handleValueChange}
      getPlaceholder={() => "関連情報があれば入力してください"}
      initialValue={initialValue}
    />
  )
}
