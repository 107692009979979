import React, { useContext, useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { GetAppByIdApi, GetAppsByNameApi } from "../../../api/AppApi"
import { AppType } from "../../../types/App"
import { PaginableResponse } from "../../../types/Common"
import { SuggestInput } from "./SuggestInput"
import {
  ProductCodeIssueAction,
  ProductCodeIssueState,
} from "../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { ProductCodeEditAction } from "../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import {
  SaleAddAction,
  SaleAddState,
} from "../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import {
  SaleEditAction,
  SaleEditState,
} from "../../../contexts/SalesManagement/reducers/useSaleEditReducer"

type ActionType =
  | ProductCodeIssueAction
  | ProductCodeEditAction
  | SaleAddAction
  | SaleEditAction
type StateType = ProductCodeIssueState | SaleAddState | SaleEditState
type Props<T extends ActionType, S extends StateType> = {
  dispatch: React.Dispatch<T>
  state: S
  initialAppOid?: string
}

/**
 * アプリ名の候補を入力するコンポーネント
 */
export const AppSuggestInput = <T extends ActionType, S extends StateType>({
  dispatch,
  state,
  initialAppOid,
}: Props<T, S>) => {
  const [app, setApp] = useState<AppType>()
  /**
   * 初期表示するアプリが指定されていれば設定する
   */
  useEffect(() => {
    const getApp = async () => {
      if (!initialAppOid) return
      try {
        const app = await new GetAppByIdApi(
          initialAppOid,
          adminAuthState.token
        ).sendRequest()
        dispatch({ type: "app", payload: app.data.payload } as T)
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response ?
          errorObj.response.data : errorObj.message

        enqueueSnackbar(
          errorMessage,
          { variant: "error", preventDuplicate: true }
        )
        return
      }
    }
    getApp()
  }, [initialAppOid])

  useEffect(() => {
    setApp(state.app)
  }, [state.app])

  /**
   * 選択されたアプリを設定する
   * @param app 選択されたアプリ
   */
  const handleSuggestionSelect = (app: AppType | undefined) => {
    dispatch({ type: "app", payload: app } as T)
  }

  /**
   * 表示用の値を取得する
   * @param app 選択されたアプリ
   */
  const getDisplayValue = (app: AppType) => {
    return app.name
  }

  const { adminAuthState } = useContext(AdminAuthContext)
  const { enqueueSnackbar } = useSnackbar()

  /**
   * アプリ名で候補を取得する関数を返す
   * @param token 認証トークン
   * @returns アプリ名で候補を取得する関数
   */
  const getSuggestions = (token?: string) => async (
    inputValue: string,
    page: number = 1
  ) => {
    try {
      const result = await new GetAppsByNameApi(
        inputValue,
        page,
        token
      ).sendRequest()
      const paginableResp: PaginableResponse<AppType[]> = {
        payload: result.data.payload,
        pageInfo: result.data.pageInfo,
      }
      return paginableResp
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response ?
        errorObj.response.data : errorObj.message

      enqueueSnackbar(
        errorMessage,
        { variant: "error", preventDuplicate: true }
      )
      return undefined
    }
  }

  /**
   * 候補をレンダリングする関数
   * @param suggestion レンダリングする候補
   * @returns 候補のレンダリング結果
   */
  const renderSuggestion = <T extends AppType>(suggestion: T) => {
    return <div>{suggestion.name}</div>
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "アプリ名を入力しサジェストから選択してください"
  }

  return (
    <div>
      <SuggestInput<AppType>
        getSuggestions={getSuggestions(adminAuthState.token)}
        renderSuggestion={renderSuggestion}
        onSuggestionSelect={handleSuggestionSelect}
        getDisplayValue={getDisplayValue}
        getPlaceholder={getPlaceholder}
        initialValue={app}
      />
    </div>
  )
}
