import React from "react"
import * as registers from "../typings/formRegisters"
import * as FormTextField from "./FormTextField"
import Grid from "@material-ui/core/Grid"
import { FormSubmitProfileButtton } from "./FormSubmitButton"
import { ProfileParams } from "../typings/users"
import { ProfileProps } from "../typings"
import { SignUpStyles } from "../styles/form"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

enum Role {
  EmployerUser = "EmployerUser",
  User = "User",
}
export default function FormProfile(props: ProfileProps) {
  const { submitHandler, profile } = props

  const { t } = useTranslation()

  const classes = SignUpStyles()

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState,
    trigger,
  } = useForm<ProfileParams>()

  const emailRegister = registers.emailRegister(register)
  const requiredRegister = registers.requiredRegister(register)
  const passwordRegister = registers.passwordRegister(register, false)
  const confirmRegister = registers.passwordConfirmRegister(register, getValues)

  const validateConfirm = () => {
    if (formState.isSubmitted) {
      trigger("passwordConfirm")
    }
  }

  const onSubmit = (data: ProfileParams) => {
    submitHandler(data)
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {profile.role &&
          profile.role === Role.EmployerUser &&
          profile.company && (
            <FormTextField.SignUpCompanyNameField
              forwardRef={requiredRegister}
              defaultValue={profile.company.name}
              error={errors.lastName}
              t={t}
            />
          )}
        <FormTextField.ProfileLastNameField
          forwardRef={requiredRegister}
          defaultValue={profile.lastName}
          error={errors.lastName}
          t={t}
        />
        <FormTextField.ProfileFirstNameField
          forwardRef={requiredRegister}
          defaultValue={profile.firstName}
          error={errors.firstName}
          t={t}
        />
        <FormTextField.ProfileEmailField
          forwardRef={emailRegister}
          defaultValue={profile.email}
          error={errors.email}
          t={t}
        />
        <FormTextField.ProfilePasswordField
          forwardRef={passwordRegister}
          error={errors.password}
          onChange={validateConfirm}
          t={t}
        />
        <FormTextField.ProfilePasswordConfirmField
          forwardRef={confirmRegister}
          error={errors.passwordConfirm}
          t={t}
        />
        <FormSubmitProfileButtton submitClass={classes.submit} t={t} />
      </Grid>
    </form>
  )
}
