import { Pagination } from "@material-ui/lab"
import { enqueueSnackbar, useSnackbar } from "notistack"
import React, { useContext, useEffect, useRef, useState } from "react"
import { GetChurnSurveysApi } from "../../../api/SaaStainerUserApi"
import {
  PageAccessPermissions,
  PaginableResponse,
  SortOrder,
} from "../../../types/Common"
import {
  ChurnSurveySortKey,
  ChurnSurveyType,
} from "../../../types/SaaStainerUser"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { SaaStainerUserManagementContext } from "../../../contexts/SaaStainerUserManagement/SaaStainerUserManagementContextProvider"
import icon_search from "../../../images/icon_search.png"
import icon_clear from "../../../images/icon_clear.png"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonArrowStyle,
} from "../../globalStyles"
import { useHistory } from "react-router-dom"
import { AdminBaseLayoutContext } from "../../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"
import { SortComponent } from "../../uiParts/Common/SortInput"
import { formatDateToJstString } from "../../../utility/CommonUtil"
import useTooltipStyles from "../../../styles/useTooltipStyles"
import { saastainerUserManagementRoot } from "../../../router/AdminRouter"
import button_white_arrow from "../../../images/button_white_arrow.png"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import IconButton from "@material-ui/core/IconButton"

/**
 * 解約者アンケート一覧画面のコンポーネント
 */
export const ChurnSurveyListPage = ({ scope }: PageAccessPermissions) => {
  const [results, setResults] = useState<PaginableResponse<ChurnSurveyType[]>>()
  const [searchTextEnable, setSearchTextEnable] = useState<boolean>(true)
  const { churnSurveyListState, churnSurveyListDispatch } = useContext(
    SaaStainerUserManagementContext
  )
  const { adminAuthState } = useContext(AdminAuthContext)
  const { circularProgressDispatch } = useContext(AdminBaseLayoutContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  /**
   * 解約者アンケートの一覧を取得し、表示を更新する関数
   */
  const updateResults = async () => {
    try {
      circularProgressDispatch({ type: "on" })
      const res = await new GetChurnSurveysApi(
        churnSurveyListState.page,
        churnSurveyListState.sortKey,
        churnSurveyListState.sortOrder,
        churnSurveyListState.keyword,
        adminAuthState.token
      ).sendRequest()

      // キーワードが設定されている場合、検索ボックスにキーワードを表示して無効化する
      if (churnSurveyListState.keyword && inputKeywordRef.current) {
        inputKeywordRef.current.value = churnSurveyListState.keyword
        setSearchTextEnable(false)
      }

      // 解約者アンケートが見つからなかった場合は、スナックバーで通知する
      if (res.data && !res.data.payload.length) {
        enqueueSnackbar(
          "条件に一致する解約者アンケートが見つかりませんでした",
          {
            variant: "info",
            preventDuplicate: true,
          }
        )
      }
      setResults(res.data)
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response
        ? errorObj.response.data
        : errorObj.message

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
      })
    } finally {
      circularProgressDispatch({ type: "off" })
    }
  }

  /**
   * キーワードの入力があった場合、キーワードを設定する関数
   */
  const dispatchKeyward = () => {
    if (!inputKeywordRef.current) return
    if (!searchTextEnable) inputKeywordRef.current.value = ""

    setSearchTextEnable((prev) => !prev)
    churnSurveyListDispatch({
      type: "keyword",
      payload: { keyword: inputKeywordRef.current.value.replace(/%/g, "") },
    })
  }

  /**
   * ソートキーが変更された場合の処理
   */
  const handleSortKeyChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    churnSurveyListDispatch({
      type: "sortkey",
      payload: { sortKey: e.target!.value as ChurnSurveySortKey },
    })
  }

  /**
   * ソート順が変更された場合の処理
   */
  const handleSortOrderChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    churnSurveyListDispatch({
      type: "sortorder",
      payload: { sortOrder: e.target!.value as SortOrder },
    })
  }

  /**
   * 検索ボックスでEnterキーが押された場合の処理
   */
  const handleSearchTextKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key !== "Enter") return
    e.preventDefault()
    dispatchKeyward()
  }

  // 検索条件の変更を監視して画面を更新する
  useEffect(() => {
    updateResults()
  }, [churnSurveyListState])

  const sortKeyMap: { key: ChurnSurveySortKey; name: string }[] = [
    { key: "companyName", name: "企業名" },
    { key: "userName", name: "氏名" },
    { key: "email", name: "登録アドレス" },
    { key: "appName", name: "登録アドレス" },
    { key: "reason", name: "登録アドレス" },
    { key: "createdAt", name: "解約日" },
  ]

  const inputKeywordRef = useRef<HTMLInputElement>(null)
  const styles = useStyles("14px", "10px")()

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h3>解約者アンケート一覧</h3>
        <div className={styles.searchTextBox}>
          <input
            ref={inputKeywordRef}
            type="text"
            maxLength={256}
            placeholder={"キーワードで検索"}
            onKeyPress={handleSearchTextKeyPress}
            disabled={!searchTextEnable}
          />
          <button type="button" onClick={dispatchKeyward}></button>
          <img src={searchTextEnable ? icon_search : icon_clear} />
        </div>
        <button
          className={styles.backButton}
          type="button"
          onClick={() => history.push(`${saastainerUserManagementRoot}/list`)}
        >
          戻る
          <img src={button_white_arrow} />
        </button>
      </div>
      <SortComponent
        sortKey={churnSurveyListState.sortKey}
        sortOrder={churnSurveyListState.sortOrder}
        sortKeyMap={sortKeyMap}
        handleSortKeyChange={handleSortKeyChange}
        handleSortOrderChange={handleSortOrderChange}
      />
      <div className={styles.lineArea}>
        <table>
          <tbody>
            <tr>
              <th>解約日</th>
              <th>会社名</th>
              <th>氏名</th>
              <th>登録アドレス</th>
              <th>連携アプリ</th>
              <th>解約理由</th>
              <th>選択理由</th>
              <th>行コピー</th>
            </tr>
            {results && results.payload.length
              ? results.payload.map((v, index) => {
                  return <TableRow item={v} key={index} />
                })
              : null}
          </tbody>
        </table>
      </div>
      {/* ページネーション */}
      {results && results.payload.length ? (
        <div className={styles.pagination}>
          <Pagination
            count={results ? results.pageInfo.totalPages : 1}
            page={results ? results.pageInfo.page : 1}
            onChange={(_, page) =>
              churnSurveyListDispatch({ type: "page", payload: { page } })
            }
            color="primary"
            shape="rounded"
          />
        </div>
      ) : null}
    </div>
  )
}

/**
 * 集計テーブル内の行のコンポーネント。
 * アプリ名をホバーするとツールチップが表示される。
 */
interface TableRowProps {
  item: ChurnSurveyType
}

/**
 * 行をクリップボードにコピーする関数
 * @param row - ChurnSurveyTypeのオブジェクト
 */
const copyRowToClipboard = (row: ChurnSurveyType) => {
  const formatCell = (cell: string) => {
    const escaped = cell.replace('"', '""')
    return cell.includes("\n") || cell.includes(",") ? `"${escaped}"` : escaped
  }

  const rowArray = [
    formatDateToJstString(new Date(row.createdAt)),
    row.companyName,
    row.userName,
    row.email,
    row.appName,
    row.reason,
    row.additionalInfo,
  ]

  const rowString = rowArray.map(formatCell).join("\t")
  navigator.clipboard.writeText(rowString)

  enqueueSnackbar("選択した行をクリップボードにコピーしました", {
    variant: "info",
    preventDuplicate: true,
  })
}

const TableRow = ({ item }: TableRowProps) => {
  const styles = useTooltipStyles({ content: item.additionalInfo })

  return (
    <tr>
      <td>{formatDateToJstString(new Date(item.createdAt))}</td>
      <td>{item.companyName}</td>
      <td>{item.userName}</td>
      <td>{item.email}</td>
      <td>{item.appName}</td>
      <td>{item.reason}</td>
      <td className={styles.tooltip} data-content={item.additionalInfo}>
        <div className={styles.content}>{item.additionalInfo}</div>
      </td>
      <td style={{ textAlign: "center" }}>
        <IconButton onClick={() => copyRowToClipboard(item)}>
          <FileCopyIcon />
        </IconButton>
      </td>
    </tr>
  )
}

const useStyles = (fontSize: string, hPadding: string) => {
  // ルート
  const root = {
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
  }

  // 検索タイトル
  const title = {
    display: "grid" as const,
    gap: "20px",
    gridTemplateColumns: "auto 300px 250px",
    alignItems: "center" as const,
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize,
    whiteSpace: "nowrap" as const,
  }

  // 戻るボタン
  const backButton = {
    ...globalSystemButtonArrowStyle,
  }

  // ボタン非活性
  const disabledButton = {
    ...globalDisabledButtonStyle,
  }

  // キーワード検索テキストボックス
  const searchTextBox = {
    position: "relative" as const,
    width: "100%",
    "& input": {
      boxSizing: "border-box" as const,
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 40px 0 20px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "2px solid gray",
    },
    "& img": {
      position: "absolute" as const,
      top: "13px",
      right: "20PX",
      width: "14px",
      pointerEvents: "none" as const,
    },
    "& button": {
      position: "absolute" as const,
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer" as const,
    },
  }

  // 明細エリア
  const lineArea = {
    display: "flex" as const,
    alignItems: "center" as const,
    justifyContent: "center" as const,
    paddingTop: "20px",
    "& table": {
      tableLayout: "fixed" as const,
      borderCollapse: "separate" as const,
      width: "100%",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
      fontSize: "12px",
      "& th, & td": {
        padding: "10px",
        width: "auto",
      },
      "& th": {
        textAlign: "center",
        backgroundColor: "#536381",
        color: "#fff",
      },
      "& tr:nth-child(even)": {
        backgroundColor: "#f2f2f2",
      },
      "& tr": {
        "&:hover": {
          backgroundColor: "rgba(79, 121, 193, 0.3)",
          cursor: "pointer" as const,
        },
      },
    },
    "& div[id=noResults]": {
      textAlign: "center" as const,
    },
  }

  // ページネーション
  const pagination = {
    paddingTop: "20px",
    display: "flex" as const,
    justifyContent: "center" as const,
  }

  return makeStyles((_: Theme) => ({
    root: { ...root },
    title: { ...title },
    backButton: { ...backButton },
    disabledButton: { ...disabledButton },
    searchTextBox: { ...searchTextBox },
    lineArea: { ...lineArea },
    pagination: { ...pagination },
  }))
}
