import { IService } from "../typings/service"
import { getServicesApi } from "./v1"

export async function getAllServices(): Promise<IService[]> {
  try {
    const res = await getServicesApi("/")
    return res.data
  } catch (err) {
    throw err
  }
}
