import React, { useEffect, useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"
import CssBaseline from "@material-ui/core/CssBaseline"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import FormProfile from "../components/FormProfile"
import SnackAlert from "../components/SnackAlert"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import { EmployerLayoutProps, UserLayoutProps } from "../typings"
import { getProfile } from "../api/users"
import { ProfileData, ProfileParams } from "../typings/users"
import { ProfileStyles } from "../styles/profile"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function UserProfile(
  props: UserLayoutProps | EmployerLayoutProps
) {
  const { alert, token, errorHandler, clickUpdateProfileHandler } = props

  const classes = ProfileStyles()

  const history = useHistory()

  const { t } = useTranslation()

  const [profile, setProfile] = useState<ProfileData | null>(null)

  useEffect(() => {
    if (token.length) {
      getProfile(token)
        .then((profileData) => {
          setProfile(profileData)
        })
        .catch(errorHandler)
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = (params: ProfileParams) => {
    clickUpdateProfileHandler(params).then((profileData) => {
      if (profileData !== undefined && profileData !== null) {
        setProfile(profileData)
        return
      }

      history.push("/thanks")
    })
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper className={classes.paper}>
        {profile != null ? (
          <>
            <Box>
              <Avatar className={classes.avatar}>
                <AccountBoxIcon />
              </Avatar>
            </Box>
            <Box>
              <Typography
                color="secondary"
                component="p"
                className={classes.textHeader}
              >
                {t("editProfile.header")}
              </Typography>
            </Box>
            <Box width="100%" mt={1} mb={2}>
              <Divider />
            </Box>
            <Typography
              color="secondary"
              component="p"
              className={classes.textSubHeader}
            >
              {t("formTextField.companyId")}
              <Box ml={1} component="span" fontWeight={400}>
                {profile.company ? profile.company.companyId : ""}
              </Box>
            </Typography>
            <Container maxWidth="xs">
              <FormProfile submitHandler={submitHandler} profile={profile} />
            </Container>
          </>
        ) : (
          <CircularProgress />
        )}
      </Paper>
      <SnackAlert alert={alert} />
    </Container>
  )
}
