import Axios from "axios"
import path from "path"

// Enables cookie
Axios.defaults.withCredentials = true

const callApi = (
  method: "GET" | "POST" | "PUT" | "DELETE",
  baseURL: string
) => (location: string, data?: any, token?: string) => {
  const url = path.join("/api/v1", baseURL, location).replace(/\/$/, "")

  if (process.env.NODE_ENV === "development") {
    const infoData = data !== undefined || data !== null ? "data" : ""
    const infoToken = typeof token === "string" ? "token" : ""
    const info = `[${method}] ${[url, infoData, infoToken].join(", ")}`
    // tslint:disable-next-line: no-console
    console.info(info)
  }

  const headers =
    typeof token === "string" ? { Authorization: `Bearer ${token}` } : {}

  return Axios({
    method,
    url,
    data,
    headers,
  })
}

// apps.ts
export const getAppsApi = callApi("GET", "apps")
export const postAppsApi = callApi("POST", "apps")
export const putAppsApi = callApi("PUT", "apps")
export const deleteAppsApi = callApi("DELETE", "apps")

// companies.ts
export const getCompaniesApi = callApi("GET", "companies/employees")
export const postCompaniesApi = callApi("POST", "companies/employees")
export const putCompaniesApi = callApi("PUT", "companies/employees")
export const deleteCompaniesApi = callApi("DELETE", "companies/employees")

// users.ts
export const getUsersApi = callApi("GET", "users")
export const postUsersApi = callApi("POST", "users")
export const putUsersApi = callApi("PUT", "users")
export const deleteUsersApi = callApi("DELETE", "users")

// services.ts
export const getServicesApi = callApi("GET", "services")
