import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { AccountCircle } from "@material-ui/icons"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { MenuItemEntry } from "../typings"

type Props = {
  postFunc?: () => void // メニュー実行後処理（オプショナル）
  menuItems: MenuItemEntry[] // ログインアカウントに応じたメニュー用のアイテム
}

/*
コンポーネントの説明：
  画面右上にログイン時に表示されるアカウントメニュー
  旧グローバルヘッダーコンポーネント（GlobalAppBar）にAuthIconという定数で内包されてたのでコンポーネントとして退避
*/

export default function AccountMenu(props: Props) {
  const { menuItems, postFunc } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const history = useHistory()
  const open = Boolean(anchorEl)
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (menuItem?: MenuItemEntry) => () => {
    setAnchorEl(null)
    if (menuItem !== undefined && menuItem.signoutHandler !== undefined) {
      menuItem.signoutHandler()
      // 後処理（SPサイトの場合はドロワーメニューを閉じる）
      if (postFunc !== undefined) postFunc()
      return
    }
    if (menuItem !== undefined) {
      history.push(menuItem.path)
      // 後処理（SPサイトの場合はドロワーメニューを閉じる）
      if (postFunc !== undefined) postFunc()
      return
    }
  }
  return (
    <div>
      <IconButton
        id="menu-icon"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle color="action" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose()}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            id={menuItem.id}
            onClick={handleClose(menuItem)}
          >
            {t(`globalHeader.${menuItem.id}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
