import React, { useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalRedAsterisk,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
} from "../../globalStyles"
import { ProductCodeManagementContext } from "../../../contexts/ProductCodeManagement/ProductCodeManagementContextProvider"
import { UsageStartDatePicker } from "../../uiParts/ProductCode/common/UsageStartDatePicker"
import { UsageExpirationDatePicker } from "../../uiParts/ProductCode/common/UsageExpirationDatePicker"
import { useHistory } from "react-router-dom"
import { PostProductCodeApi } from "../../../api/ProductCodeApi"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { CompanyUserList } from "../../uiParts/ProductCode/common/CompanyUserList"
import { useSnackbar } from "notistack"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import {
  ProductCodeIssueAction,
  ProductCodeIssueState,
} from "../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { CompanySuggestInput } from "../../uiParts/Common/CompanySuggestInput"
import { AppSuggestInput } from "../../uiParts/Common/AppSuggestInput"
import { LicenseCountInput } from "../../uiParts/ProductCode/common/LicenseCountInput"
import { UnitPriceInput } from "../../uiParts/ProductCode/common/UnitPriceInput"
import { TrialPeriodInput } from "../../uiParts/ProductCode/common/TrialPeriodInput"
import { AgencyInput } from "../../uiParts/ProductCode/common/AgencyInput"
import { AgencyfeeInput } from "../../uiParts/ProductCode/common/AgencyfeeInput"
import { formatStateToDb } from "../../../utility/ProductCodeUtil"
import { BillingIntervalInput } from "../../uiParts/Common/BillingIntervalInput"

/**
 * プロダクトコードの発行ページです。
 */
export const ProductCodeIssuePage = ({ scope }: PageAccessPermissions) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { productCodeIssueState, productCodeIssueDispatch } = useContext(
    ProductCodeManagementContext
  )
  const { adminAuthState } = useContext(AdminAuthContext)

  useEffect(() => {
    // cleanup
    return () => {
      productCodeIssueDispatch({ type: "init" })
    }
  }, [])

  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
    history.goBack()
  }

  // 「発行」ボタンが押された時の処理
  const handleIssueClick = async (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 発行関数定義
    const issueAction = async () => {
      if (!productCodeIssueState.errors.length) {
        try {
          const result = await new PostProductCodeApi(
            productCodeIssueState.app!.appId,
            productCodeIssueState.company!.companyId,
            productCodeIssueState.licenseCount!,
            productCodeIssueState.amount!,
            productCodeIssueState.interval!,
            productCodeIssueState.intervalCount!,
            productCodeIssueState.trialPeriodDays!,
            formatStateToDb(productCodeIssueState.usageStartedAt!),
            formatStateToDb(productCodeIssueState.usageExpireIn!),
            productCodeIssueState.agency!,
            productCodeIssueState.agencyfee!,
            adminAuthState.token
          ).sendRequest()
          history.goBack()
          enqueueSnackbar(
            `新しいプロダクトコード(${result.data.payload})を発行しました`,
            { variant: "success", preventDuplicate: true }
          )
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
        }
      } else {
        productCodeIssueState.errors.forEach((v) =>
          enqueueSnackbar(v.message, {
            variant: "warning",
            preventDuplicate: true,
          })
        )
      }
    }

    setDialogMessage("プロダクトコードを発行しますか？")
    showDialog(() => issueAction)
  }

  const styles = createStyles("20px")()

  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>プロダクトコード発行</h3>
      </div>
      <div className={styles.inputLeftArea}>
        <label className={styles.requiredField}>アプリ名</label>
        <AppSuggestInput<ProductCodeIssueAction, ProductCodeIssueState>
          dispatch={productCodeIssueDispatch}
          state={productCodeIssueState}
        />
        <label className={styles.requiredField}>企業名</label>
        <CompanySuggestInput<ProductCodeIssueAction, ProductCodeIssueState>
          dispatch={productCodeIssueDispatch}
          state={productCodeIssueState}
        />
        <label>ライセンス数</label>
        <LicenseCountInput<ProductCodeIssueAction>
          dispatch={productCodeIssueDispatch}
        />
        <label>{"単価(税抜)"}</label>
        <UnitPriceInput<ProductCodeIssueAction>
          dispatch={productCodeIssueDispatch}
        />
        <label>請求頻度</label>
        <BillingIntervalInput<ProductCodeIssueAction>
          dispatch={productCodeIssueDispatch}
        />
        <label>トライアル日数（利用開始日にトライアル開始）</label>
        <TrialPeriodInput<ProductCodeIssueAction>
          dispatch={productCodeIssueDispatch}
        />
      </div>
      <div className={styles.userlistArea}>
        <CompanyUserList
          employer={productCodeIssueState.company?.employerEmailAddr}
          employee={productCodeIssueState.company?.employeeEmailAddrs}
        />
      </div>
      <div className={styles.inputRightArea}>
        <label className={styles.requiredField}>利用開始日</label>
        <UsageStartDatePicker
          dispatch={productCodeIssueDispatch}
          state={productCodeIssueState}
        />
        <label className={styles.requiredField}>有効期限</label>
        <UsageExpirationDatePicker
          dispatch={productCodeIssueDispatch}
          state={productCodeIssueState}
        />
        <label>代理店</label>
        <AgencyInput<ProductCodeIssueAction>
          dispatch={productCodeIssueDispatch}
        />
        <label>代理店手数料</label>
        <AgencyfeeInput<ProductCodeIssueAction>
          dispatch={productCodeIssueDispatch}
        />
      </div>
      <div className={styles.buttonArea}>
        <div id="buttons">
          <button type="button" className="arrow" onClick={handleCancelClick}>
            戻る
            <img src={button_white_arrow} />
          </button>
          <button
            type="button"
            className={readOnly ? "actionDisabled" : "action"}
            onClick={handleIssueClick}
            disabled={readOnly}
          >
            発行
            <AddCircleIcon />
          </button>
        </div>
      </div>
      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={handleDialogClose}
        message={dialogMessage}
      />
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea      titleArea     " auto
                   "inputLeftArea  userlistArea  " 1fr
                   "inputLeftArea  inputRightArea" auto
                   "buttonArea     buttonArea    " auto
                   / 1fr 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "500px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  // プロダクトコード情報の入力エリア(左)
  const inputLeftArea = {
    gridArea: "inputLeftArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // プロダクトコード情報の入力エリア(右)
  const inputRightArea = {
    gridArea: "inputRightArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // 企業ユーザーリスト表示エリア
  const userlistArea = {
    gridArea: "userlistArea",
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputLeftArea: { ...inputLeftArea },
    inputRightArea: { ...inputRightArea },
    userlistArea: { ...userlistArea },
    buttonArea: { ...buttonArea },
    requiredField: { ...globalRedAsterisk },
  }))
}
