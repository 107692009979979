import { makeStyles, Theme } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext, MediaQueryContext, MediaQueryType } from "../App"
import breadcrumb_home from "../img/app_detail/breadcrumb_home.svg"
import breadcrumb_arrow from "../img/app_detail/breadcrumb_arrow.svg"
import { useHistory, useParams } from "react-router-dom"
import { getAppDetail } from "../api/apps"
import { EmployerLayoutProps, PublicLayoutProps } from "../typings"
import { AppDetailData } from "../typings/apps"
import { useTranslation } from "react-i18next"
import blueArrow from "../img/app_detail/button_blue_arrow.png"
import orangeArrow from "../img/toppage/arrow_app_detail.png"
import AppDetailContextButtons from "../components/AppDetailContextButtons"
import BeforeFooterContents from "../components/BeforeFooterContents"
import RelatedApps from "../components/RelatedApps"
import { sanitize } from "../components/AppSummaryCard"

const ReactFacebook = require("react-facebook") // eslint-disable-line @typescript-eslint/no-var-requires
const TwitterEmbed = require("react-twitter-embed") // eslint-disable-line @typescript-eslint/no-var-requires

/*
ビューの説明：
  アプリ詳細ビュー。トップページのアプリ検索で表示された各アプリのカード(AppSummaryCard)内の「詳しく見る」の
  リンクをクリックしたときに表示されるページで以下の機能を提供します。
  ・アプリのトライアル
  ・アプリの購入
  ・アプリの解約（サブスクリプション契約のみ）
  ・関連のあるアプリの表示
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/
export const AppDetailView = React.memo(
  (props: EmployerLayoutProps | PublicLayoutProps) => {
    // 認証情報のコンテキストからログイン状態を取得
    const { isLogin } = useContext(AuthContext)

    // メディアクエリーのコンテキストから必要なリソースを取得
    const mqContext = useContext(MediaQueryContext)
    const { isPC } = mqContext

    // スタイル作成
    const styles = createStyles(mqContext)()

    // 詳細表示するアプリのIDをURLから取得
    const { appId } = useParams<{ appId: string }>()

    // トークンを取得
    const token: string | undefined = props.hasOwnProperty("token")
      ? (props as EmployerLayoutProps).token
      : undefined

    const { errorHandler } = props

    const [app, setApp] = useState<AppDetailData>()

    useEffect(() => {
      getAppDetail(appId, token)
        .then((app) => {
          setApp(app)
        })
        .catch((e) => {
          errorHandler(e)
          history.push("/apps")
        })
    }, [appId])

    const history = useHistory()

    // データベース側で管理しているフィールドの国際化対応
    const { t, i18n } = useTranslation()
    const appName =
      (i18n.language !== "ja" ? app?.i18nNames.en : app?.name) ?? ""
    const summary =
      (i18n.language !== "ja"
        ? app?.i18nAboutApp.en.summary
        : app?.aboutApp.summary) ?? ""
    const feature =
      (i18n.language !== "ja"
        ? app?.i18nAboutApp.en.feature
        : app?.aboutApp.feature) ?? ""
    const recommendedFor =
      (i18n.language !== "ja"
        ? app?.i18nAboutApp.en.recommendedFor
        : app?.aboutApp.recommendedFor) ?? ""
    const notes =
      (i18n.language !== "ja"
        ? app?.i18nAboutApp.en.notes
        : app?.aboutApp.notes) ?? ""

    // コンテキストボタンの生成に必要な情報
    const info = {
      // ログイン中？
      login: isLogin,
      // 無料アプリ？
      freeApp: app?.amount === 0,
      // ユーザーが所有しているアプリ？
      usersApp: app?.isOwned,
      // プロダクトコード販売のみのアプリ？
      soldOnlyViaProductCode: app?.soldOnlyViaProductCode,
      // トライアル可能なアプリ？
      trialAvailable:
        app?.subscriptionData?.trialEnd ||
        app?.subscriptionData?.trialPeriodDays
          ? true
          : false,
      // アプリの支払いモード
      paymentMode: app?.mode,
      // アプリのURL
      appUrl: app?.appUrl,
      // トークン
      token: token,
      // アプリID
      appId: appId,
      // 問い合わせURL
      inquiryUrl: app?.inquiryUrl,
      // エラーハンドラー,
      errorHandler,
    }

    // 表示用価格取得
    const displayPrice = () => {
      // 非表示
      // プロダクトコード限定販売
      if (app?.soldOnlyViaProductCode) return undefined
      // アプリURLの指定なし かつ　プロダクトコード限定販売以外
      if (!app?.appUrl && !app?.soldOnlyViaProductCode) return undefined

      // 無料
      if (!app?.amount) return t("appDetailCard.free")

      const price = `${app?.amount.toLocaleString()} ${t("appDetailCard.yen")} `

      // 単発購入
      if (app?.mode === "payment") return price

      // サブスクリプション
      if (app?.mode === "subscription") {
        // 年額
        if (app?.subscriptionData?.billingCycle === "annually")
          return `${t("appDetailCard.annually")} ${price}`
        // 月額 ※billingCycleフィールド自体がないアプリは月額とする仕様
        return `${t("appDetailCard.monthly")} ${price}`
      }

      // 支払いモード未指定
      return price
    }

    // PCの場合のみ概要エリアにコンテキストボタンを表示
    const summaryContextButtonsArea = isPC ? (
      <div className={styles.summaryContextButtonsArea}>
        <AppDetailContextButtons {...info} showInquiryButton={true} />
      </div>
    ) : null

    // PCの場合かつヘルプURLが設定されている場合のみ概要エリアにヘルプボタンエリアを表示
    const helpButtonArea =
      isPC && app?.helpUrl ? (
        <div className={styles.helpButtonArea}>
          <a
            className={styles.helpButton}
            href={app ? app.helpUrl : undefined}
            target="_blank"
            rel="noopener"
          >
            <img src={blueArrow} />
            {t("appDetailCard.helpButton")}
          </a>
        </div>
      ) : null

    // PCの場合のみカードエリアに問い合わせ・コンテキストボタンボタンを表示
    const cardButtonsArea = isPC ? (
      <div className={styles.cardButtonsArea}>
        {info.inquiryUrl && (
          <a
            className={styles.inquiryButton}
            href={info.inquiryUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={blueArrow} />
            {t("appDetailCard.inquiry")}
          </a>
        )}
        <AppDetailContextButtons {...info} showInquiryButton={false} />
      </div>
    ) : null

    // SPの場合のみ「アプリに関するお問い合わせはこちら」ボタンとアプリのコンテキストボタンを表示（Sticky）
    const stickyButtonsArea = isPC ? null : (
      <div className={styles.stickyButtonArea}>
        {/* アプリコンテキストボタン */}
        <div id="context_buttons">
          <AppDetailContextButtons {...info} showInquiryButton={false} />
        </div>
        {/* 「アプリに関するお問い合わせはこちら」ボタン */}
        {info.inquiryUrl && (
          <a
            id="inquiry_button"
            href={info.inquiryUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={blueArrow} />
            {t("appDetailCard.inquiry")}
          </a>
        )}
      </div>
    )

    return (
      <div>
        <div className={styles.main}>
          {/* パンくずリストエリア */}
          <div className={styles.breadcrumbArea}>
            <img
              id="breadcrumb_home"
              src={breadcrumb_home}
              onClick={() => {
                history.push("/apps")
              }}
            />
            <img id="breadcrumb_arrow" src={breadcrumb_arrow} />
            <span>{appId}</span>
          </div>
          {/* アプリカード表示エリア */}
          <div className={styles.appCardArea}>
            {/* アプリイメージ表示エリア */}
            <div className={styles.imageMainArea}>
              <img src={app?.images.main} />
            </div>
            <div className={styles.imageSubArea}>
              {app?.images.sub ? <img src={app?.images.sub} /> : null}
            </div>
            <div className={styles.imageTagArea}>
              {app?.images.tag ? <img src={app?.images.tag} /> : null}
            </div>
            {/* アプリ概要表示エリア */}
            <div className={styles.summaryArea}>
              {/* アプリ名表示エリア */}
              <div className={styles.appNameArea}>{appName}</div>
              {/* ソーシャルボタン表示エリア */}
              <div className={styles.socialButtonsArea}>
                <TwitterEmbed.TwitterShareButton
                  url={window.location.href}
                  options={{ text: `#SaaStainer #${appName}` }}
                />
                <ReactFacebook.FacebookProvider appId="1240371166416093">
                  <ReactFacebook.Like
                    href={window.location.href}
                    share
                    layout="button"
                    width={50}
                  />
                </ReactFacebook.FacebookProvider>
              </div>
              {/* アプリ概要表示エリア */}
              <div className={styles.summaryTextArea}>
                <div className={styles.headline}>
                  {t("appDetailCard.summaryHeader")}
                </div>
                <div dangerouslySetInnerHTML={sanitize(summary)} />
              </div>
              {/* アプリコンテキストボタン表示エリア
                        　　解約ボタンや購入ボタンなどユーザーのログイン状態やアプリの属性によって
                        　　表示されるボタンの種類が動的に変わる　*/}
              {summaryContextButtonsArea}
              {/* 使い方・ヘルプボタン表示エリア */}
              {helpButtonArea}
            </div>
            {/* 機能表示エリア */}
            {feature ? (
              <div className={styles.featureArea}>
                <div className={styles.headline}>
                  {t("appDetailCard.featureHeader")}
                </div>
                <div
                  className={styles.indent}
                  dangerouslySetInnerHTML={sanitize(feature)}
                />
              </div>
            ) : null}
            {/* おすすめな人表示エリア */}
            {recommendedFor ? (
              <div className={styles.recommendedForArea}>
                <div className={styles.headline}>
                  {t("appDetailCard.recommendedForHeader")}
                </div>
                <div
                  className={styles.indent}
                  dangerouslySetInnerHTML={sanitize(recommendedFor)}
                />
              </div>
            ) : null}
            {/* 注意点表示エリア */}
            {notes ? (
              <div className={styles.notesArea}>
                <div className={styles.headline}>
                  {t("appDetailCard.notesHeader")}
                </div>
                <div
                  className={styles.indent}
                  dangerouslySetInnerHTML={sanitize(notes)}
                />
              </div>
            ) : null}
            {/* カテゴリー表示エリア */}
            <div className={styles.categoryArea}>
              <div className={styles.headline}>
                {t("appDetailCard.categoryHeader")}
              </div>
              <p>{t(`listApps.categories.id${app?.category.id}`)}</p>
            </div>
            {/* 価格表示エリア */}
            {displayPrice() ? (
              <div className={styles.amountArea}>
                <div className={styles.headline}>
                  {t("appDetailCard.priceHeader")}
                </div>
                <p>{displayPrice()}</p>
              </div>
            ) : null}
            {/* 使い方リンク表示エリア */}
            {app?.helpUrl ? (
              <div className={styles.helpArea}>
                <div className={styles.headline}>
                  {t("appDetailCard.helpPageHeader")}
                </div>
                <p>
                  <a
                    href={app ? app.helpUrl : undefined}
                    target="_blank"
                    rel="noopener"
                  >
                    {t("appDetailCard.help")}
                    <img src={orangeArrow} />
                  </a>
                </p>
              </div>
            ) : null}
            {/* 提供元表示エリア */}
            <div className={styles.creatorArea}>
              <div className={styles.headline}>
                {t("appDetailCard.creatorHeader")}
              </div>
              <p>
                <a
                  href={app ? app.creator.url : undefined}
                  target="_blank"
                  rel="noopener"
                >
                  {app?.creator.name}
                  <img src={orangeArrow} />
                </a>
              </p>
            </div>
            {/* カード下部のボタン表示エリア */}
            {cardButtonsArea}
          </div>
          {/* 関連アプリ表示エリア */}
          <div className={styles.relatedAppsArea}>
            <RelatedApps appId={appId} errorHandler={errorHandler} />
          </div>
          {/* 一覧に戻るボタンエリア */}
          <div className={styles.backToListArea}>
            <a
              className={styles.backToListButton}
              onClick={() => history.push("/apps")}
            >
              {t("appDetailCard.appList")}
            </a>
          </div>
        </div>
        {/* 開発依頼リンク紹介エリア */}
        <div className={styles.requestDevelopmentArea}>
          <BeforeFooterContents />
        </div>
        {/* Stickyボタンエリア（SPのみ） */}
        {stickyButtonsArea}
      </div>
    )
  }
)

// スタイル作成
const createStyles = (mq: MediaQueryType) => {
  const { isPC, pcHeaderHeight, pcWidth, spHeaderHeight, spWidth } = mq
  const headerHeight = isPC ? pcHeaderHeight : spHeaderHeight

  // メインエリアのPC・SPで異なる部分のスタイルを定義
  const mainStyle = {
    width: isPC ? pcWidth : spWidth,
    padding: isPC
      ? `${headerHeight} 80px 0px 80px`
      : `${headerHeight} 20px 0px 20px`,
  }

  // パンくずリストエリアのPC・SPで異なる部分のスタイルを定義
  const breadcrumbAreaStyle = {
    width: isPC ? "1080px" : "325px",
  }

  // アプリカードエリアのPC・SPで異なる部分のスタイルを定義
  const appCardAreaStyle = {
    width: isPC ? "1080px" : "325px",
    padding: isPC ? `72px 80px 80px 80px` : "10px",
    gridTemplate: isPC
      ? `"images_main    ...             summary       " 360px
            "...            ...             summary       " 10px
            "images_sub     ...             summary       " auto
            "images_tag     ...             summary       " auto
            "...            ...             ...           " 48px
            "feature        feature         feature       " auto
            "recommendedFor recommendedFor  recommendedFor" auto
            "notes          notes           notes         " auto
            "category       category        category      " auto
            "...            ...             ...           " 48px
            "amount         amount          amount        " auto
            "help           help            help          " auto
            "creator        creator         creator       " auto
            "...            ...             ...           " 48px
            "card_buttons   card_buttons    card_buttons  " auto
           / 360px 60px 500px`
      : `"images_main    " 295px
            "...            " 10px
            "images_sub     " auto
            "images_tag     " auto
            "...            " 10px
            "summary        " auto
            "...            " 10px
            "feature        " auto
            "recommendedFor " auto
            "notes          " auto
            "category       " auto
            "...            " 10px
            "amount         " auto
            "help           " auto
            "creator        " auto
           / 295px`,
  }

  // アプリ概要エリアのPC・SPで異なる部分のスタイルを定義
  const summaryAreaStyle = {
    gridTemplate: isPC
      ? `"app_name                app_name               " auto
            "social_buttons          social_buttons         " 80px
            "summary_text            summary_text           " 1fr
            "summary_context_buttons summary_context_buttons" auto
            "help_button             help_button            " auto
            / 200px 300px`
      : `"app_name      " auto
             "social_buttons" 80px
             "summary_text  " 1fr
            /295px`,
  }

  // アプリ概要エリアのPC・SPで異なる部分のスタイルを定義
  const relatedAppsAreaStyle = {
    width: isPC ? "1080px" : "325px",
  }

  // 開発依頼エリアのPC・SPで異なる部分のスタイルを定義
  const requestDevelopmentAreaStyle = {
    width: isPC ? pcWidth : spWidth,
  }

  // ボタン共通スタイル
  const baseButton = {
    position: "relative" as const,
    display: "block" as const,
    padding: "16px",
    color: "#305699",
    fontSize: "14px",
    fontWeight: "bold" as const,
    textAlign: "center" as const,
    borderRadius: "50px",
    boxSizing: "border-box" as const,
    textDecoration: "none" as const,
    boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    "& img": {
      position: "absolute" as const,
      right: "20px",
      top: "20px",
    },
    cursor: "pointer" as const,
  }

  // ボタンの種類によって異なるスタイルを拡張する関数を作成
  const button = (width: string, height: string) => ({
    ...baseButton,
    width,
    height,
  })

  // メインコンテンツのスタイル
  const main = (width: string, padding: string) => ({
    display: "grid" as const,
    justifyContent: "center",
    gridTemplate: `"breadcrumb         " 65px
            "app_card           " 1fr
            "related_apps       " auto
            "back_to_list       " auto
            "request_development" auto
            "sticky_buttons     " auto
            / 1fr`,
    margin: "0 auto",
    backgroundColor: "#f2f2f2",
    width,
    padding,
  })

  // パンくずリストエリアのスタイル
  const breadcrumbArea = (width: string) => ({
    gridArea: "breadcrumb",
    width,
    margin: "0 auto",
    backgroundColor: "#f2f2f2",
    lineHeight: "65px",
    "& img": {
      display: "inline-block",
      paddingRight: "5px",
      verticalAlign: "middle",
      "&[id=breadcrumb_home]": {
        height: "18px",
        cursor: "pointer",
      },
      "&[id=breadcrumb_arrow]": {
        height: "12px",
      },
    },
    "& span": {
      display: "inline-block",
      color: "#ababab",
      fontSize: "12px",
      verticalAlign: "middle",
    },
  })

  // アプリカードエリアのスタイル
  const appCardArea = (
    width: string,
    padding: string,
    gridTemplate: string
  ) => ({
    gridArea: "app_card",
    backgroundColor: "#ffffff",
    border: "1px solid #dddddd",
    boxShadow: "0px 4px 16px rgba(0,0,0,0.04)",
    borderRadius: "8px",
    padding,
    display: "grid",
    justifyContent: "center",
    width,
    margin: "0 auto",
    gridTemplate,
  })

  // アプリ概要エリアのスタイル
  const summaryArea = (gridTemplate: string) => ({
    gridArea: "summary",
    backgroundColor: "#ffffff",
    display: "grid",
    gridTemplate,
  })

  // 関連アプリ表示エリアのスタイル
  const relatedAppsArea = (width: string) => ({
    gridArea: "related_apps",
    backgroundColor: "#f2f2f2",
    width,
    margin: "0 auto",
    padding: "40px 0px",
  })

  // 開発依頼エリアのスタイル
  const requestDevelopmentArea = (width: string) => ({
    gridArea: "request_development",
    backgroundColor: "#ffffff",
    width,
    margin: "0 auto",
  })

  return makeStyles((_: Theme) => ({
    // 詳細画面全体のレイアウト
    main: { ...main(mainStyle.width, mainStyle.padding) },
    // パンくずリスト
    breadcrumbArea: { ...breadcrumbArea(breadcrumbAreaStyle.width) },
    // アプリカードエリア
    appCardArea: {
      ...appCardArea(
        appCardAreaStyle.width,
        appCardAreaStyle.padding,
        appCardAreaStyle.gridTemplate
      ),
    },
    // アプリのメイン画像エリア
    imageMainArea: {
      backgroundColor: "#ffffff",
      gridArea: "images_main",
      border: "1px solid #dddddd",
      boxShadow: "0px 4px 4px rgba(0,0,0,0.25)",
      borderRadius: "8px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        maxWidth: "80%",
        height: "auto",
      },
    },
    // アプリのサブ画像エリア
    imageSubArea: {
      backgroundColor: "#ffffff",
      gridArea: "images_sub",
      "& img": {
        maxWidth: "100%",
        height: "auto",
        padding: "20px 0px 0px 0px",
      },
    },
    // アプリのタグ画像エリア
    imageTagArea: {
      gridArea: "images_tag",
      backgroundColor: "#ffffff",
      "& img": {
        maxWidth: "100%",
        height: "auto",
        padding: "20px 0px 0px 0px",
      },
    },
    // アプリ概要説明エリア
    summaryArea: { ...summaryArea(summaryAreaStyle.gridTemplate) },
    // アプリ名エリア
    appNameArea: {
      gridArea: "app_name",
      backgroundColor: "#ffffff",
      borderBottom: "2px solid #fa7d3d",
      borderImage: "linear-gradient(to right, #fa7d3d 0%, #fec044 100%)",
      borderImageSlice: 1,
      paddingBottom: "20px",
      fontSize: "1.8rem",
    },
    // 各種SNSボタンエリア
    socialButtonsArea: {
      gridArea: "social_buttons",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingTop: "30px",
      gap: "8px",
    },
    // 使い方・ヘルプボタンエリア
    helpButtonArea: {
      gridArea: "help_button",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    // アプリ概要エリア
    summaryTextArea: {
      gridArea: "summary_text",
      backgroundColor: "#ffffff",
    },
    // アプリ概要内のコンテキストボタンエリア
    summaryContextButtonsArea: {
      gridArea: "summary_context_buttons",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    // 機能エリア
    featureArea: {
      gridArea: "feature",
      backgroundColor: "#ffffff",
      paddingBottom: isPC ? "48px" : "10px",
    },
    // おすすめな人エリア
    recommendedForArea: {
      gridArea: "recommendedFor",
      backgroundColor: "#ffffff",
      paddingBottom: isPC ? "48px" : "10px",
    },
    // ご利用にあたっての注意エリア
    notesArea: {
      gridArea: "notes",
      backgroundColor: "#ffffff",
      paddingBottom: isPC ? "48px" : "10px",
    },
    // カテゴリーエリア
    categoryArea: {
      gridArea: "category",
      backgroundColor: "#ffffff",
    },
    // 価格エリア
    amountArea: {
      gridArea: "amount",
      backgroundColor: "#ffffff",
      paddingBottom: isPC ? "48px" : "10px",
    },
    // 使い方・ヘルプエリア
    helpArea: {
      gridArea: "help",
      backgroundColor: "#ffffff",
      paddingBottom: isPC ? "48px" : "10px",
      "& img": {
        paddingLeft: "10px",
      },
    },
    // 提供元エリア
    creatorArea: {
      gridArea: "creator",
      backgroundColor: "#ffffff",
      "& img": {
        paddingLeft: "10px",
      },
    },
    // カード下部にあるボタンエリア
    cardButtonsArea: {
      gridArea: "card_buttons",
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: "20px",
      paddingTop: "40px",
    },
    // 一覧に戻るボタンエリア
    backToListArea: {
      gridArea: "back_to_list",
      backgroundColor: "#f2f2f2",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingBottom: "60px",
    },
    // 関連アプリ表示エリア
    relatedAppsArea: { ...relatedAppsArea(relatedAppsAreaStyle.width) },
    // 開発依頼リンク表示エリア
    requestDevelopmentArea: {
      ...requestDevelopmentArea(requestDevelopmentAreaStyle.width),
    },
    // Stickyボタン表示エリア
    stickyButtonArea: {
      position: "sticky",
      bottom: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "rgba(255,255,255,0.8)",
      padding: "10px 10px",
      "& div[id=context_buttons]": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
      },
      "& a[id=inquiry_button]": {
        ...button("100%", "54px"),
      },
    },
    // 使い方・ヘルプボタン
    helpButton: {
      ...button("100%", "52px"),
    },
    // 問い合わせボタン
    inquiryButton: {
      ...button("100%", "54px"),
      maxWidth: "500px",
    },
    // 一覧に戻るボタン
    backToListButton: {
      ...button("320px", "54px"),
      color: "#ffffff",
      background: "linear-gradient(top left, #527CC5, #325BA1)",
    },
    // 見出し
    headline: {
      fontSize: "18px",
      color: "#fa7d3d",
    },
    //
    indent: {
      paddingLeft: "10px",
    },
  }))
}
