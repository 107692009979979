import React, { createContext } from "react"
import {
  AdminUserListAction,
  useAdminUserListReducer,
  adminUserListInitialState,
  AdminUserListState,
} from "./reducers/useAdminUserListReducer"
import {
  AdminUserAddAction,
  useAdminUserAddReducer,
  adminUserAddInitialState,
  AdminUserAddState,
} from "./reducers/useAdminUserAddReducer"
import {
  AdminUserEditAction,
  useAdminUserEditReducer,
  adminUserEditInitialState,
  AdminUserEditState,
} from "./reducers/useAdminUserEditReducer"

/**
 * AdminUserの管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {AdminUserListState} 一覧ページのステート
 * @property {React.Dispatch<AdminUserListAction>} 一覧ページのアクションをディスパッチする関数
 * @property {AdminUserAddState} 追加ページのステート
 * @property {React.Dispatch<AdminUserAddAction>} 発行ページのアクションをディスパッチする関数
 * @property {AdminUserEditState} 編集ページのステート
 * @property {React.Dispatch<AdminUserEditAction>} 編集ページのアクションをディスパッチする関数
 */
type ContextType = {
  adminUserListState: AdminUserListState
  adminUserListDispatch: React.Dispatch<AdminUserListAction>
  adminUserAddState: AdminUserAddState
  adminUserAddDispatch: React.Dispatch<AdminUserAddAction>
  adminUserEditState: AdminUserEditState
  adminUserEditDispatch: React.Dispatch<AdminUserEditAction>
}

/**
 * AdminUserの管理に関する情報を提供するContextです。
 * @type {React.Context<ContextType>}
 */
export const AdminUserManagementContext = createContext<ContextType>({
  adminUserListState: adminUserListInitialState,
  adminUserListDispatch: (_: AdminUserListAction) => {},
  adminUserAddState: adminUserAddInitialState,
  adminUserAddDispatch: (_: AdminUserAddAction) => {},
  adminUserEditState: adminUserEditInitialState,
  adminUserEditDispatch: (_: AdminUserEditAction) => {},
})

/**
 * AdminUserの管理に関する情報を提供するContextのプロバイダーコンポーネントです。
 * @param {Object} props コンポーネントに渡すprops
 * @param {React.ReactNode} props.children 子コンポーネント
 * @returns {JSX.Element} コンテキストのProviderコンポーネント
 */
export const AdminUserManagementContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {
    adminUserListState,
    adminUserListDispatch,
  } = useAdminUserListReducer()
  const { adminUserAddState, adminUserAddDispatch } = useAdminUserAddReducer()
  const {
    adminUserEditState,
    adminUserEditDispatch,
  } = useAdminUserEditReducer()

  return (
    <AdminUserManagementContext.Provider
      value={{
        adminUserListState,
        adminUserListDispatch, // 検索ページ
        adminUserAddState,
        adminUserAddDispatch, // 発行ページ
        adminUserEditState,
        adminUserEditDispatch, // 編集ページ
      }}
    >
      {children}
    </AdminUserManagementContext.Provider>
  )
}
