import React, { useEffect } from "react"
import { ProductCodeIssueAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { ProductCodeEditAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { NumericInput } from "../../Common/NumericInput"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: number
}

/**
 * 代理店手数料を入力するコンポーネント
 */
export const AgencyfeeInput = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  initialValue = 0,
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "agencyfee", payload: initialValue } as T)
  }, [initialValue])

  /**
   * 代理店手数料変更時のハンドラー
   * @param number 変更後の代理店手数料
   */
  const handleValueChange = (value: number) => {
    dispatch({ type: "agencyfee", payload: value } as T)
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "代理店手数料を入力してください"
  }

  return (
    <NumericInput
      onValueChange={handleValueChange}
      getPlaceholder={getPlaceholder}
      initialValue={initialValue}
    />
  )
}
