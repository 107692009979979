import { DeleteApi, GetApi, PostApi, PutApi } from "./AbstractApiFactory"
import { PaginableResponse, ResponseType, SortOrder } from "../types/Common"
import {
  GroupByApp,
  Interval,
  PaymentMethodType,
  SalesSortKey,
  SaleType,
} from "../types/Sale"

// IDで指定した販売データを１件取得します。
export class GetSaleApi extends GetApi<ResponseType<SaleType>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/sale/${this.id}`
  }
}

// 全販売データの中からキーワードに一致する販売データを取得します。
export class GetSalesApi extends GetApi<PaginableResponse<SaleType[]>> {
  constructor(
    private readonly page: number,
    private readonly sortKey: SalesSortKey,
    private readonly sortOrder: SortOrder,
    private readonly keyword?: string,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    const query = `&searchKeyword=${this.encode(this.keyword)}`
    return `/sales/?page=${this.page}&sortKey=${this.sortKey}&sortOrder=${this.sortOrder}${query}`
  }
}

// 販売データを作成します。
export class PostSaleApi extends PostApi<ResponseType<string>> {
  constructor(
    appOid: string,
    companyOid: string,
    amount: number,
    paidAt: number,
    interval: Interval,
    intervalCount: number,
    paymentMethod: PaymentMethodType,
    payerEmail: string,
    relatedInfo: string,
    token?: string
  ) {
    const data = {
      appOid,
      companyOid,
      amount,
      paidAt,
      interval,
      intervalCount,
      paymentMethod,
      payerEmail,
      relatedInfo,
    }

    super(data, token)
  }
  // override
  protected getUrl(): string {
    return "/sales"
  }
}

// 販売データを編集します。
export class PutSaleApi extends PutApi<ResponseType<string>> {
  constructor(
    private readonly id: string,
    appOid: string,
    companyOid: string,
    amount: number,
    paidAt: number,
    interval: Interval,
    intervalCount: number,
    paymentMethod: PaymentMethodType,
    payerEmail: string,
    relatedInfo: string,
    token?: string
  ) {
    const data = {
      appOid,
      companyOid,
      amount,
      paidAt,
      payerEmail,
      interval,
      intervalCount,
      paymentMethod,
      relatedInfo,
    }

    super(data, token)
  }
  // override
  protected getUrl(): string {
    return `/sale/${this.id}`
  }
}

// 指定IDの販売データを削除します。
export class DeleteSaleApi extends DeleteApi<ResponseType<string>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/sale/${this.id}`
  }
}

// 指定期間のアプリごと（stripe/other）の売上金額を集計します
export class GetSalesGroupByAppApi extends GetApi<
  PaginableResponse<GroupByApp>
> {
  constructor(
    private readonly page: number,
    private readonly startJSTEpoch: number,
    private readonly endJSTEpoch: number,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/sales/total-amount/app?page=${this.page}&startDate=${this.startJSTEpoch}&endDate=${this.endJSTEpoch}`
  }
}
