import React, { useEffect } from "react"
import { SaleAddAction } from "../../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import { SaleEditAction } from "../../../../contexts/SalesManagement/reducers/useSaleEditReducer"
import { PaymentMethodType } from "../../../../types/Sale"
import { SimpleTextInput } from "../../Common/SimpleTextInput"

type Props<T extends SaleAddAction | SaleEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: PaymentMethodType
}

/**
 * 支払い方法を入力するコンポーネント
 */
export const PaymentMethodInput = <T extends SaleAddAction | SaleEditAction>({
  dispatch,
  initialValue = "other",
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "paymentMethod", payload: initialValue } as T)
  }, [initialValue])

  /**
   * 支払い方法を設定する
   * @param value 支払い方法
   */
  const handleValueChange = (value: PaymentMethodType) => {
    dispatch({ type: "paymentMethod", payload: value } as T)
  }

  return (
    <SimpleTextInput
      onValueChange={(value: unknown) => {
        if (typeof value === "string") {
          handleValueChange(value as PaymentMethodType)
        }
      }}
      getPlaceholder={() => ""}
      initialValue={initialValue}
      readonly={true}
    />
  )
}
