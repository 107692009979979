import React, { useEffect } from "react"
import { NumericInput } from "../../Common/NumericInput"

/**
 * 支払金額を入力するコンポーネント
 */
type Props<T> = {
  dispatch: React.Dispatch<T>
  initialValue?: number
}

export const PaymentAmountInput = <T,>({
  dispatch,
  initialValue = 0,
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "amount", payload: initialValue } as T)
  }, [initialValue])

  /**
   * 支払金額変更時に実行される関数
   * @param amount 変更後の日付
   */
  const handleValueChange = (amount: number) => {
    dispatch({ type: "amount", payload: amount } as T)
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "支払金額を入力してください"
  }

  return (
    <div>
      <NumericInput
        onValueChange={handleValueChange}
        getPlaceholder={getPlaceholder}
        initialValue={initialValue}
      />
    </div>
  )
}
