import { useReducer } from "react"

/**
 * Reducerのアクションタイプ
 * on: 処理中のサークルを表示する
 * off: 処理中のサークルを非表示にする
 */
export type CircularProgressAction =
  | {
      type: "on"
    }
  | {
      type: "off"
    }

/**
 * 管理者認証コンテキストの初期状態
 * id: ユーザーID
 * displayName: 表示名
 * email: Eメールアドレス
 * role: ユーザー権限
 * createdAt: 登録日時
 * updatedAt: 更新日時
 * token: 認証トークン
 */
export type CircularProgressType = { display: boolean }
export const initialCircularProgressState: CircularProgressType = {
  display: false,
}

/**
 * Reducer関数
 * @param state 現在の状態
 * @param action 実行するアクション
 * @returns 新しい状態
 */
const reducer = (
  _state: CircularProgressType,
  action: CircularProgressAction
) => {
  switch (action.type) {
    case "on":
      //console.log(action.type)
      return { display: true }
    case "off":
      //console.log(action.type)
      return { display: false }
  }
}

/**
 * 処理中表示コンテキストのカスタムフック
 * @returns stateとdispatchを持つ配列
 */
export const useCircularProgressReducer = () => {
  return useReducer(reducer, initialCircularProgressState)
}
