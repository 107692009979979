import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import { ISnackAlert } from "../typings"

export default function SnackAlert(props: ISnackAlert) {
  const { alert } = props

  if (alert == null) {
    return null
  }

  return (
    <Snackbar
      open={true}
      autoHideDuration={6000}
      onClose={alert.closeHandler}
      message={alert.message}
    />
  )
}
