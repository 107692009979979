import React, { createContext } from "react"
import {
  CircularProgressAction,
  CircularProgressType,
  initialCircularProgressState,
  useCircularProgressReducer,
} from "./reducers/useCircularProgressReducer"

/**
 * 処理中表示管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {CircularProgressType} 処理中表示の状態を定義
 * @property {React.Dispatch<CircularProgressAction>} ContextTypeで管理するActionをdispatchする関数
 */
type ContextType = {
  circularProgressState: CircularProgressType
  circularProgressDispatch: React.Dispatch<CircularProgressAction>
}

/*
 * createContextに渡す引数の構造体を定義
 */
const initCtxValue: ContextType = {
  circularProgressState: initialCircularProgressState,
  circularProgressDispatch: (_: CircularProgressAction) => {},
}

/*
 * 管理画面用基本レイアウトのコンテキスト作成
 */
export const AdminBaseLayoutContext = createContext<ContextType>(initCtxValue)

/*
 * 管理画面用基本レイアウトのコンテキストプロバイダー
 * 子コンポーネントにcontextを提供するためのもの
 */
export const AdminBaseLayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useCircularProgressReducer()
  return (
    <AdminBaseLayoutContext.Provider
      value={{
        circularProgressState: state,
        circularProgressDispatch: dispatch,
      }}
    >
      {children}
    </AdminBaseLayoutContext.Provider>
  )
}
