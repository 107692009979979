import { Box, Typography } from "@material-ui/core"
import React from "react"
import { IAppHeroProps } from "../typings"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
// #E9EBEF
// #293D62
const useStyles = makeStyles({
  root: {
    paddingTop: "5em",
    paddingBottom: "1em",
    paddingLeft: "1em",
    paddingRight: "1em",
    color: "#293D62",
    fontSize: "1.875em",
    fontWeight: "bold",
    textAlign: "center",
  },
  subtext: {
    paddingBottom: "3em",
    paddingLeft: "1em",
    paddingRight: "1em",
    color: "#293D62",
    fontSize: "1.2em",
    textAlign: "center",
  },
})

function AppHero(props: IAppHeroProps) {
  const { title, children, subtext, hide } = props

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <Box>
      <Box textAlign="center">
        {!hide && <Typography className={classes.root}>{title}</Typography>}
        {subtext && (
          <Typography component="span" className={classes.subtext}>
            <pre style={{ whiteSpace: "pre-wrap" }}>{subtext}</pre>
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  )
}

export default AppHero
