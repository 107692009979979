import React from "react"
import { useSnackbar } from "notistack"
import SingleDatePicker from "../../Common/SingleDatePicker"
import {
  ProductCodeIssueAction,
  ProductCodeIssueState,
} from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import {
  ProductCodeEditAction,
  ProductCodeEditState,
} from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  state: ProductCodeIssueState | ProductCodeEditState
  initialDate?: Date
}

/**
 * プロダクトコードの利用期限日を入力するコンポーネント
 */
export const UsageExpirationDatePicker = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  state,
  initialDate,
}: Props<T>) => {
  const { enqueueSnackbar } = useSnackbar()
  /**
   * 日付変更時のハンドラー
   * @param date 変更後の日付
   */
  const handleDateChange = (date: Date) => {
    if (state.usageStartedAt) {
      if (date < state.usageStartedAt) {
        enqueueSnackbar("利用開始日よりまえに利用期限が設定されています", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }
    dispatch({ type: "usageExpireIn", payload: date } as T)
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "プロダクトコードの利用期限日"
  }

  if (!initialDate) {
    const today = new Date()
    initialDate = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    )
  }

  return (
    <div>
      <SingleDatePicker
        onDateChange={handleDateChange}
        getPlaceholder={getPlaceholder}
        initialDate={initialDate}
      />
    </div>
  )
}
