import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormOAuth from "../components/FormOAuth"
import Grid from "@material-ui/core/Grid"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import { PublicLayoutProps } from "../typings"
import { RouterLink } from "../components/RouterLink"
import { SignInStyles } from "../styles/form"
import { useTranslation } from "react-i18next"

export default function OAuthApp(props: PublicLayoutProps) {
  const { alert } = props

  const { t } = useTranslation()

  const classes = SignInStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("oauth.title")}
        </Typography>
        <FormOAuth />
        <Grid container justify="flex-end">
          <Grid item>
            <RouterLink to="/signup" variant="body2">
              {t("oauth.signup")}
            </RouterLink>
          </Grid>
        </Grid>
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
