import { makeStyles, Theme } from "@material-ui/core"
import React from "react"

/**
 * 代表ユーザーと従業員ユーザーを表示するためのコンポーネント
 * @param props - employerユーザーのメールアドレス（string）とemployeeユーザーのメールアドレス（string[]）を受け取る
 * @returns 代表ユーザーと従業員ユーザーを表示するJSX要素
 */
export const CompanyUserList = (props: {
  employer: string | undefined
  employee: string[] | undefined
}) => {
  const styles = createStyles()()
  return (
    <div className={styles.rootArea}>
      <div className={styles.employerTitleArea}>代表ユーザー（編集不可）</div>
      <div className={styles.employerEmailAdrrArea}>{props.employer}</div>
      <div className={styles.employeeTitleArea}>従業員ユーザー（編集不可）</div>
      <div className={styles.employeeEmailAdrrsArea}>
        <ul>
          {props.employee ? (
            props.employee.length > 0 ? (
              props.employee.map((v, index) => <li key={index}>{v}</li>)
            ) : (
              <li>未登録</li>
            )
          ) : (
            ""
          )}
        </ul>
      </div>
    </div>
  )
}

// スタイル作成
const createStyles = () => {
  // ルートエリア
  const rootArea = {
    display: "grid",
    gap: "10px",
    gridTemplate: `"employerTitleArea     " auto
             "employerEmailAdrrArea " 38px
             "employeeTitleArea     " auto
             "employeeEmailAdrrsArea" 116px
            / 1fr`,
    backgroundColor: "#ffffff",
    boxSizing: "border-box" as const,
  }

  // 代表ユーザータイトル表示エリア
  const employerTitleArea = {
    gridArea: "employerTitleArea",
    fontSize: "8px",
  }

  // 代表ユーザーメールアドレス表示エリア
  const employerEmailAdrrArea = {
    gridArea: "employerEmailAdrrArea",
    boxSizing: "border-box" as const,
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    fontSize: "14px",
    border: "1px solid gray",
    borderRadius: "10px",
    backgroundColor: "#fafafa",
  }

  // 従業員ユーザータイトル表示エリア
  const employeeTitleArea = {
    gridArea: "employeeTitleArea",
    fontSize: "8px",
  }

  // 従業員ユーザーメールアドレス表示エリア
  const employeeEmailAdrrsArea = {
    gridArea: "employeeEmailAdrrsArea",
    boxSizing: "border-box" as const,
    fontSize: "14px",
    border: "1px solid gray",
    borderRadius: "10px",
    backgroundColor: "#fafafa",
    "& ul": {
      listStyleType: "none",
      padding: "5px 5px 5px 20px",
    },
    overflow: "auto",
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    employerTitleArea: { ...employerTitleArea },
    employerEmailAdrrArea: { ...employerEmailAdrrArea },
    employeeTitleArea: { ...employeeTitleArea },
    employeeEmailAdrrsArea: { ...employeeEmailAdrrsArea },
  }))
}
