import React, { ChangeEvent, MouseEventHandler, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  DialogContentText,
  Divider,
  Select,
  MenuItem,
  TextareaAutosize,
  FormControl,
} from "@material-ui/core"
import useStyles from "../styles/apps"
import { useTranslation } from "react-i18next"

type Props = {
  isOpen: boolean
  onClose: (checked: boolean, reason: string, additionalInfo: string) => void
}

/**
 * 解約確認用ダイアログ
 *
 * @param props 解約確認用ダイアログのプロパティ
 */
const AppUnsubscribingDialog: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props
  const [confirmationState, setConfirmationState] = useState(false)
  const [reason, setReason] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState("")

  const handleConfirmationStateChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmationState(event.currentTarget.checked)
  }

  const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReason(event.target.value as string)
  }

  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAdditionalInfo(event.target.value)
  }

  const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault()
    setConfirmationState(false)
    setReason("")
    setAdditionalInfo("")
    onClose(false, "", "")
  }

  const handleUnsubscribeButtonClick: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault()
    onClose(confirmationState, reason, additionalInfo)
  }

  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>
          <Typography>{t("appUnsubscribingDialog.dialogTitle")}</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/* contentTextRow1 */}
          <DialogContentText>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{t("appUnsubscribingDialog.contentTextRow1")}</span>
              <span>{t("appUnsubscribingDialog.contentTextRow2")}</span>
              <span>{t("appUnsubscribingDialog.contentTextRow3")}</span>
            </div>
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={confirmationState}
                onChange={handleConfirmationStateChange}
                name="confirm"
              />
            }
            label={
              <Link
                href="https://saastainer.zendesk.com/hc/ja/articles/4411059807769"
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("appUnsubscribingDialog.linkText")}
              </Link>
            }
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <Select value={reason} onChange={handleReasonChange} displayEmpty>
              <MenuItem value="" disabled>
                {t("appUnsubscribingDialog.selectboxLabel")}
              </MenuItem>
              <MenuItem value="利用してない">
                {t("appUnsubscribingDialog.selectboxOption1")}
              </MenuItem>
              <MenuItem value="値段が高い">
                {t("appUnsubscribingDialog.selectboxOption2")}
              </MenuItem>
              <MenuItem value="使い方がわかりづらい">
                {t("appUnsubscribingDialog.selectboxOption3")}
              </MenuItem>
              <MenuItem value="使いたい機能がない">
                {t("appUnsubscribingDialog.selectboxOption4")}
              </MenuItem>
              <MenuItem value="他の製品を見つけた">
                {t("appUnsubscribingDialog.selectboxOption5")}
              </MenuItem>
              <MenuItem value="その他">
                {t("appUnsubscribingDialog.selectboxOption6")}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <TextareaAutosize
              rowsMin={5}
              rowsMax={10}
              maxLength={200}
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
              placeholder={t("appUnsubscribingDialog.textAreaLabel")}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCancelButtonClick} autoFocus>
            {t("appUnsubscribingDialog.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={
              !confirmationState || !reason || /^\s*$/.test(additionalInfo)
            }
            onClick={handleUnsubscribeButtonClick}
          >
            {t("appUnsubscribingDialog.unsubscribe")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default AppUnsubscribingDialog
