import React from "react"
import Button from "@material-ui/core/Button"
import {
  IFormSubmitButtonProps,
  IFormEmployeeSubmitButtonProps,
} from "../typings"

// TODO Refactor, refactor, refactor

export function FormSubmitGenericButton(props: IFormSubmitButtonProps) {
  const { submitClass, t } = props
  const text = t ? t("button.submit") : "送信"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {props.text ? props.text : text}
    </Button>
  )
}

export function FormSubmitSignUpButton(props: IFormSubmitButtonProps) {
  const { submitClass, t } = props
  const text = t ? t("button.register") : "登録"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {text}
    </Button>
  )
}

export function FormSubmitSignInButtton(props: IFormSubmitButtonProps) {
  const { submitClass, t } = props
  const text = t ? t("button.login") : "ログイン"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {text}
    </Button>
  )
}

export function FormForgotPasswordButtton(props: IFormSubmitButtonProps) {
  const { submitClass, t } = props
  const text = t ? t("button.submit") : "送信"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {text}
    </Button>
  )
}

export function FormSubmitProfileButtton(props: IFormSubmitButtonProps) {
  const { submitClass, t } = props
  const text = t ? t("button.update") : "更新"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {text}
    </Button>
  )
}

export function FormSubmitEmployeeButton(
  props: IFormEmployeeSubmitButtonProps
) {
  const { submitClass, isEdit, t } = props
  const editText = t ? t("button.update") : "更新"
  const registerText = t ? t("button.register") : "登録"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {isEdit ? editText : registerText}
    </Button>
  )
}

export function FormSubmitOAuthButtton(props: IFormSubmitButtonProps) {
  const { submitClass, t } = props
  const text = t ? t("button.login") : "ログイン"
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={submitClass}
    >
      {text}
    </Button>
  )
}
