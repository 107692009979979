import React, { useContext, useRef } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import icon_search from "../img/toppage/icon_search.png"
import icon_clear from "../img/toppage/icon_clear.png"
import { AppSearchContext, MediaQueryContext } from "../App"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useState } from "react"

/*
コンポーネントの説明：
  アプリ検索部品の１つ。キーワードを指定してアプリを検索するためのコンポーネント
  サブミット後はAppSearchContext上にあるステート(keyword)を更新するのみで実際の検索処理は委譲する。
*/

// オプショナルで検索後処理を指定
type Props = {
  dropShadow?: boolean
  display?: boolean
  postFunc?: () => void
}

export const SearchByKeyword = ({
  dropShadow = true,
  display = true,
  postFunc,
}: Props) => {
  // メディアクエリー用のコンテキストからデータを取得
  const { isPC } = useContext(MediaQueryContext)

  // アプリ検索用のコンテキストからデータを取得
  const { state, dispatch } = useContext(AppSearchContext)

  // テキストボックスの活性状態
  const [disabled, setDisabled] = useState(false)

  // 国際化対応
  const { t } = useTranslation()

  // 検索テキスト参照
  const inputRef = useRef<HTMLInputElement>(null)

  // 検索エンターボタン押下イベント
  const onEnterHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // エンター以外は無効
    if (event.key !== "Enter") return
    event.preventDefault()
    updateKeyword()
  }

  // 検索ボタンクリックイベント
  const onClickHandler = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()
    updateKeyword()
  }

  const history = useHistory()

  // キーワードの更新
  const updateKeyword = () => {
    if (!inputRef.current) return

    // キーワードのリセットまたは指定キーワードで検索
    let keyword = ""
    if (!disabled) {
      keyword = inputRef.current.value.replace(/%/g, "")
    }
    setDisabled((prev) => !prev)

    // コンテキスト上の対象ステートを更新する。
    dispatch({
      type: "keyword",
      payload: { keyword: encodeURIComponent(keyword) },
    })
    // SPサイトのドロワーメニュー内のこのコンポーネントは
    // サブミット時にメニューを閉じるためのコールバックが渡されるので実行
    if (postFunc) postFunc()
    // 検索結果位置まで自動スクロール（SearchByCategoryコンポーネント内の要素）
    const element = document.getElementById("categories")
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      })
  }

  // キーワードが変更されたら画面上にあるこのコンポーネント間でキーワードを同期
  useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.value = decodeURIComponent(state.keyword)
    setDisabled(inputRef.current.value !== "")
  }, [state.keyword])

  // 横幅768px以上はPCスタイル。横幅767px以下はSPスタイル。
  const pcStyles = usePCStyles()
  let styles = useSPStyles()
  if (isPC) styles = pcStyles

  return (
    // PC・SPで共通のHTML構造（スタイルのみ分離）
    <div className={display ? styles.display : styles.noneDisplay}>
      <div
        className={
          dropShadow
            ? styles.searchTextBox
            : `${styles.searchTextBox} ${styles.dropshadowOff}`
        }
      >
        {/* キーワードで検索 */}
        <input
          ref={inputRef}
          type="text"
          maxLength={256}
          placeholder={t("header.placeholder")}
          onKeyPress={(e) => onEnterHandler(e)}
          disabled={disabled}
        />
        <button onClick={(e) => onClickHandler(e)}></button>
        <img src={disabled ? icon_clear : icon_search} />
      </div>
    </div>
  )
}

const usePCStyles = makeStyles((_: Theme) => ({
  // 表示切替
  noneDisplay: {
    display: "none",
  },
  display: {
    display: "block",
  },
  // 検索テキストボックス
  searchTextBox: {
    position: "relative",
    filter: "drop-shadow(3px 3px 3px #a75629)",
    width: "100%",
    "& input": {
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid white",
      paddingLeft: "20px",
      paddingRight: "40px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "3px solid gray",
    },
    "& img": {
      position: "absolute",
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      pointerEvents: "none",
    },
    "& button": {
      position: "absolute",
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer",
    },
  },
  dropshadowOff: {
    border: "1px solid #dddddd",
    borderRadius: "20px",
    filter: "none",
  },
}))

const useSPStyles = makeStyles((_: Theme) => ({
  // 表示切替
  noneDisplay: {
    display: "none",
  },
  display: {
    display: "block",
  },
  // 検索テキストボックス
  searchTextBox: {
    position: "relative",
    filter: "drop-shadow(3px 3px 3px #a75629)",
    width: "100%",
    "& input": {
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid white",
      paddingLeft: "20px",
      paddingRight: "40px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "3px solid gray",
    },
    "& img": {
      position: "absolute",
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      pointerEvents: "none",
    },
    "& button": {
      position: "absolute",
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer",
    },
  },
  dropshadowOff: {
    border: "1px solid #dddddd",
    borderRadius: "20px",
    filter: "none",
  },
}))
