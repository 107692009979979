import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { IFormCheckboxSignUp } from "../typings"
import { useTranslation } from "react-i18next"

export default function FormCheckboxSignUp(props: IFormCheckboxSignUp) {
  const { forwardRef, label, name, value } = props
  const { t } = useTranslation()

  return (
    <FormControlLabel
      control={<Checkbox name={name} value={value} color="primary" />}
      label={label}
      inputRef={forwardRef}
    />
  )
}
