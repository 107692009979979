import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormForgotPassword from "../components/FormForgotPassword"
import LockOpen from "@material-ui/icons/LockOpen"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import { ForgotPasswordProps } from "../typings"
import { SignInStyles } from "../styles/form"
import { useTranslation } from "react-i18next"

export default function ForgotPassword(props: ForgotPasswordProps) {
  const { t } = useTranslation()
  const { alert } = props

  const classes = SignInStyles()

  return (
    <Container component="main" style={{maxWidth: "375px"}}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpen />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("forgotPassword.title")}
        </Typography>
        <FormForgotPassword {...props} />
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
