import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  appCard: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    height: "290px",
  },
  appCardName: {
    margin: "17px 0 0 0",
    fontSize: "0.85rem",
    fontWeight: 700,
    height: "70px",
    overflow: "hidden",
  },
  appCardSeparator: {
    border: "none",
    height: "2px",
    background:
      "linear-gradient(90deg, rgba(201,179,74,0.1)0%,rgba(201,130,74,1)50%,rgba(201,179,74,0.1)100%)",
    margin: "7px 0",
  },
  appCardText: {
    color: "#999",
    margin: "30px 0 14px",
  },
  appCardButton: {
    padding: "12px 30px",
    borderRadius: "30px",
    fontSize: "12px",
    lineHeight: "1.42857143",
  },
  appDetailCard: {
    marginTop: theme.spacing(5),
    padding: "40px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  appDetailName: {
    color: "#3C4858",
    marginTop: "30px",
    fontSize: "1.8rem",
    fontWeight: "bold",
  },
  appDetailPricing: {
    color: "#3C4858",
    margin: "10px 0 25px",
    fontSize: "0.90rem",
  },
  appDetailText: {
    fontSize: "15px",
    color: "#3C4858",
  },
  appDetailHeader: {
    fontSize: "1.2rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
  appDetailSubHeader: {
    fontSize: "0.9rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
  appDetailButton: {
    padding: "10px 20px",
    // borderRadius: "30px",
    fontSize: "10px",
    lineHeight: "1.42857143",
    fontWeight: 700,
  },
  appCancelButton: {
    height: "50px",
    padding: "10px 20px",
    // borderRadius: "30px",
    fontSize: "15px",
    lineHeight: "1.42857143",
    fontWeight: 700,
  },
  appTrialOrPurchaseButton: {
    height: "50px",
    width: "100%",
    minWidth: "170px",
    padding: "10px 20px",
    // borderRadius: "30px",
    fontSize: "15px",
    lineHeight: "1.42857143",
    fontWeight: 700,
  },
  appInquiryButton: {
    height: "50px",
    width: "100%",
    padding: "10px 20px",
    // borderRadius: "30px",
    border: "1px solid #FA7D3D",
    color: "#FA7D3D",
    backgroundColor: "white",
    "&:hover": {
      background: "#fdfaf7",
    },
    fontSize: "15px",
    lineHeight: "1.42857143",
    fontWeight: 700,
  },
  appDetailStickyBox: {
    position: "sticky",
    bottom: 0,
  },
  appOpenButton: {
    height: "50px",
    width: "100%",
    padding: "10px 20px",
    // borderRadius: "30px",
    fontSize: "15px",
    lineHeight: "1.42857143",
    fontWeight: 700,
  },
  appPurchasedText: {
    backgroundColor: "white",
  },
  appDetailImage: {
    width: "100%",
  },
  tooltip: {
    fontSize: "1.2rem",
    color: "#808080",
    backgroundColor: "white",
    fontWeight: 700,
    // color: "#3C4858",
  },
  appListsNoResults: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "#808080",
  },
  appListSubHeader: {
    margin: "48px 0 0 0",
    fontSize: "0.9rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
  appListHeader: {
    fontSize: "1.2rem",
    fontWeight: 600,
    maxWidth: "150px",
  },
  appDevelopmentRrequestHeader: {
    margin: "6px",
    fontSize: "0.9rem",
    color: "#000000",
  },
  appSortHeader: {
    margin: "6px",
    marginBottom: "5px",
    fontSize: "0.9rem",
    fontWeight: 600,
  },
  appSortSelect: {
    minWidth: "140px",
    marginBottom: "5px",
  },
  toolTipAppName: {
    marginTop: "10px",
    marginBottom: "3px",
    fontSize: "0.95rem",
    fontWeight: 700,
  },
  appMyListHeader: {
    margin: "48px 0 0 0",
    fontSize: "1.2rem",
    fontWeight: 600,
  },
}))

export default useStyles
