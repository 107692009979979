import validator from "validator"
import PasswordValidator from "password-validator"

const schema = new PasswordValidator()

// prettier-ignore
schema
  .is().min(8)          // Minimum length 7
  .has().uppercase()    // Must have uppercase letters
  .has().lowercase()    // Must have lowercase letters
  .has().digits()       // Must have at least 2 digits
  .has().not().spaces() // Should not have spaces

export const companyIdRegister = (register: any) =>
  register({
    required: true,
    maxLength: 20,
    pattern: /^[a-z][a-z\d]+$/i,
  })

export const checkboxRegisterPr = (register: any) =>
  register({
    required: true,
  })

export const checkboxRegisterMail = (register: any) => register({})

export const emailRegister = (register: any) =>
  register({
    required: true,
    validate: (v: string) => validator.isEmail(v.trim()),
  })

export const requiredRegister = (register: any) =>
  register({
    required: true,
  })

export const passwordRegister = (register: any, required: boolean) =>
  register({
    required,
    validate: (v: string) => {
      if (required) return schema.validate(v)
      return v === "" || schema.validate(v)
    },
  })

export const passwordConfirmRegister = (register: any, getValues: any) =>
  register({
    validate: (v: string) => v === getValues().password,
  })
