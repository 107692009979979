import React from "react"
import * as registers from "../typings/formRegisters"
import * as FormTextField from "./FormTextField"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { EmployeeParams, FormEmployeeProps } from "../typings/companies"
import { FormSubmitEmployeeButton } from "./FormSubmitButton"
import { SignInStyles } from "../styles/form"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

export default function FormEmployee(props: FormEmployeeProps) {
  const { employee, submitHandler, isEdit, cancelHandler } = props
  const { t } = useTranslation()
  const classes = SignInStyles()

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState,
    trigger,
  } = useForm<EmployeeParams>()

  const emailRegister = registers.emailRegister(register)
  const requiredRegister = registers.requiredRegister(register)
  const passwordRegister = registers.passwordRegister(register, true)
  const confirmRegister = registers.passwordConfirmRegister(register, getValues)

  const validateConfirm = () => {
    if (formState.isSubmitted) {
      trigger("passwordConfirm")
    }
  }

  const onSubmit = (data: EmployeeParams) => {
    submitHandler(data)
  }

  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: "444px" }}
    >
      <Grid container spacing={2}>
        <FormTextField.EmployeeLastNameField
          forwardRef={requiredRegister}
          defaultValue={employee?.lastName}
          error={errors.lastName}
          t={t}
        />
        <FormTextField.EmployeeFirstNameField
          forwardRef={requiredRegister}
          defaultValue={employee?.firstName}
          error={errors.firstName}
          t={t}
        />
        <FormTextField.EmployeeEmailField
          forwardRef={emailRegister}
          defaultValue={employee?.email}
          error={errors.email}
          t={t}
        />
        {isEdit ? null : (
          <>
            <FormTextField.EmployeePasswordField
              forwardRef={passwordRegister}
              error={errors.password}
              onChange={validateConfirm}
              t={t}
            />
            <FormTextField.EmployeePasswordConfirmField
              forwardRef={confirmRegister}
              error={errors.passwordConfirm}
              t={t}
            />
          </>
        )}
        <FormSubmitEmployeeButton
          submitClass={classes.submit}
          isEdit={isEdit}
          t={t}
        />
        {isEdit ? (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.submit}
            onClick={() => cancelHandler(employee!.id)}
          >
            {t("listEmployee.cancel")}
          </Button>
        ) : null}
      </Grid>
    </form>
  )
}
