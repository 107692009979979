import { DeleteApi, GetApi, PutApi } from "./AbstractApiFactory"
import { AppSortKey, AppType } from "../types/App"
import { PaginableResponse, ResponseType, SortOrder } from "../types/Common"
import { AxiosRequestConfig } from "axios"

// 全て、またはキーワードに一致するアプリ一覧を取得します。
export class GetAppsApi extends GetApi<PaginableResponse<AppType[]>> {
  constructor(
    private readonly page: number,
    private readonly sortKey: AppSortKey,
    private readonly sortOrder: SortOrder,
    private readonly keyword?: string,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    const query = `&searchKeyword=${this.encode(this.keyword)}`
    return `/apps/?page=${this.page}&sortKey=${this.sortKey}&sortOrder=${this.sortOrder}${query}`
  }
}

// アプリ名からアプリのリストを取得します（全後方一致）
export class GetAppsByNameApi extends GetApi<PaginableResponse<AppType[]>> {
  constructor(
    private readonly name: string,
    private readonly page: number,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/apps/name/${this.name}?page=${this.page}`
  }
}

// アプリOIDからアプリを取得します
export class GetAppByIdApi extends GetApi<ResponseType<AppType>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/app/${this.id}`
  }
}

// 指定IDのアプリを編集します。
export class PutAppApi extends PutApi<ResponseType<string>> {
  constructor(private readonly id: string, data: AppType, token?: string) {
    super(data, token)
  }
  // override
  protected getUrl(): string {
    return `/app/${this.id}`
  }
}

// 指定IDのアプリを削除します。
export class DeleteAppApi extends DeleteApi<ResponseType<string>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/app/${this.id}`
  }
}

// 指定IDのアプリ利用者の一覧をCSVでダウンロードします。
export class GetAppUsersCsvApi extends GetApi<Blob> {
  constructor(
    private readonly appId: string,
    private readonly onlyMailList: boolean,
    token?: string,
    config?: AxiosRequestConfig
  ) {
    super(token, config)
  }
  // override
  protected getUrl(): string {
    return `/app/${this.appId}/users/csv?onlyMailList=${this.onlyMailList}`
  }
}
