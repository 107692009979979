import { useReducer } from "react"
import { AppType } from "../../../types/App"

/**
 * アプリ編集ページの状態管理
 */
export type AppEditState = AppType

/**
 * アプリ編集ページのアクション型
 */
export type AppEditAction =
  | {
      type: "update"
      payload: AppEditState
    }
  | {
      type: "id"
      payload: string
    }
  | {
      type: "images.main"
      payload: string
    }
  | {
      type: "images.sub"
      payload: string
    }
  | {
      type: "images.tag"
      payload: string
    }
  | {
      type: "aboutApp.summary"
      payload: string
    }
  | {
      type: "aboutApp.feature"
      payload: string
    }
  | {
      type: "aboutApp.recommendedFor"
      payload: string
    }
  | {
      type: "aboutApp.notes"
      payload: string
    }
  | {
      type: "i18nAboutApp.en.summary"
      payload: string
    }
  | {
      type: "i18nAboutApp.en.feature"
      payload: string
    }
  | {
      type: "i18nAboutApp.en.recommendedFor"
      payload: string
    }
  | {
      type: "i18nAboutApp.en.notes"
      payload: string
    }
  | {
      type: "soldOnlyViaProductCode"
      payload: boolean
    }
  | {
      type: "display"
      payload: boolean
    }
  | {
      type: "name"
      payload: string
    }
  | {
      type: "i18nNames.en"
      payload: string
    }
  | {
      type: "amount"
      payload: number
    }
  | {
      type: "mode"
      payload: "payment" | "subscription"
    }
  | {
      type: "appId"
      payload: string
    }
  | {
      type: "stripeProductId"
      payload: string
    }
  | {
      type: "stripePriceId"
      payload: string
    }
  | {
      type: "category"
      payload: { id: string; name: string }
    }
  | {
      type: "service"
      payload: string[]
    }
  | {
      type: "appUrl"
      payload: string
    }
  | {
      type: "creator.name"
      payload: string
    }
  | {
      type: "creator.url"
      payload: string
    }
  | {
      type: "helpUrl"
      payload: string
    }
  | {
      type: "subscriptionData.trialPeriodDays"
      payload: number
    }
  | {
      type: "subscriptionData.trialEnd"
      payload: Date | null
    }
  | {
      type: "subscriptionData.billingCycle"
      payload: "monthly" | "annually"
    }
  | {
      type: "taxRateId"
      payload: string
    }
  | {
      type: "free"
      payload: boolean
    }
  | {
      type: "inquiryUrl"
      payload: string
    }
  | {
      type: "popularity"
      payload: number
    }

/**
 * 状態と状態を更新するためのdispatch関数を返します。
 * @returns {{ state: AppEditState, dispatch: (value: AppEditAction) => void }}
 */
const reducer = (state: AppEditState, action: AppEditAction) => {
  let next = {
    ...appEditInitialState,
    ...state,
    category: {
      ...appEditInitialState.category,
      ...state.category,
    },
    creator: {
      ...appEditInitialState.creator,
      ...state.creator,
    },
    aboutApp: {
      ...appEditInitialState.aboutApp,
      ...state.aboutApp,
    },
    i18nAboutApp: {
      ...appEditInitialState.i18nAboutApp,
      ...state.i18nAboutApp,
      en: {
        ...appEditInitialState.i18nAboutApp.en,
        ...state.i18nAboutApp.en,
      },
    },
    i18nNames: {
      ...appEditInitialState.i18nNames,
      ...state.i18nNames,
    },
    images: {
      ...appEditInitialState.images,
      ...state.images,
    },
    ...(state.subscriptionData
      ? {
          subscriptionData: {
            ...appEditInitialState.subscriptionData,
            ...state.subscriptionData,
          },
        }
      : {}),
  }
  switch (action.type) {
    case "update":
      next = {
        ...next,
        ...action.payload,
        free: action.payload.amount === 0 ? true : false,
      }
      break
    case "id":
      next._id = action.payload
      break
    case "images.main":
      next.images.main = action.payload
      break
    case "images.sub":
      next.images.sub = action.payload
      break
    case "images.tag":
      next.images.tag = action.payload
      break
    case "aboutApp.summary":
      next.aboutApp.summary = action.payload
      break
    case "aboutApp.feature":
      next.aboutApp.feature = action.payload
      break
    case "aboutApp.recommendedFor":
      next.aboutApp.recommendedFor = action.payload
      break
    case "aboutApp.notes":
      next.aboutApp.notes = action.payload
      break
    case "i18nAboutApp.en.summary":
      next.i18nAboutApp.en.summary = action.payload
      break
    case "i18nAboutApp.en.feature":
      next.i18nAboutApp.en.feature = action.payload
      break
    case "i18nAboutApp.en.recommendedFor":
      next.i18nAboutApp.en.recommendedFor = action.payload
      break
    case "i18nAboutApp.en.notes":
      next.i18nAboutApp.en.notes = action.payload
      break
    case "soldOnlyViaProductCode":
      next.soldOnlyViaProductCode = action.payload
      break
    case "display":
      next.display = action.payload
      break
    case "name":
      next.name = action.payload
      break
    case "i18nNames.en":
      next.i18nNames.en = action.payload
      break
    case "amount":
      next.amount = action.payload
      break
    case "mode":
      next.mode = action.payload ? action.payload : "subscription"
      break
    case "appId":
      next.appId = action.payload
      break
    case "stripeProductId":
      next.stripeProductId = action.payload
      break
    case "stripePriceId":
      next.stripePriceId = action.payload
      break
    case "category":
      next.category = action.payload
      break
    case "service":
      next.service = action.payload
      break
    case "appUrl":
      next.appUrl = action.payload
      break
    case "creator.name":
      next.creator.name = action.payload
      break
    case "creator.url":
      next.creator.url = action.payload
      break
    case "helpUrl":
      next.helpUrl = action.payload
      break
    case "subscriptionData.trialPeriodDays":
      next.subscriptionData!.trialPeriodDays = action.payload
      break
    case "subscriptionData.trialEnd":
      next.subscriptionData!.trialEnd = action.payload
      break
    case "subscriptionData.billingCycle":
      next.subscriptionData!.billingCycle = action.payload
      break
    case "taxRateId":
      next.taxRateId = action.payload
      break
    case "free":
      next.free = action.payload
      break
    case "inquiryUrl":
      next.inquiryUrl = action.payload
      break
    case "popularity":
      next.popularity = action.payload
      break
  }

  return next
}

// ステートの初期値
export const appEditInitialState: AppEditState = {
  _id: "",
  images: {
    main: "",
    sub: "",
    tag: "",
  },
  aboutApp: {
    summary: "",
    feature: "",
    recommendedFor: "",
    notes: "",
  },
  i18nAboutApp: {
    en: {
      summary: "",
      feature: "",
      recommendedFor: "",
      notes: "",
    },
  },
  soldOnlyViaProductCode: false,
  display: false,
  name: "",
  amount: 0,
  mode: "subscription",
  appId: "",
  stripeProductId: "",
  stripePriceId: "",
  category: {
    id: "1",
    name: "",
  },
  service: [],
  appUrl: "",
  creator: {
    name: "",
    url: "",
  },
  helpUrl: "",
  i18nNames: {
    en: "",
  },
  subscriptionData: {
    trialPeriodDays: 0,
    trialEnd: null,
    billingCycle: "monthly",
  },
  taxRateId: "",
  free: true,
  inquiryUrl: "https://marketing.saastainer.com/SaaStainer-userinfo",
  popularity: 999,
  createdAt: "",
}

/*
 * アプリ編集ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useAppEditReducer = () => {
  const [appEditState, appEditDispatch] = useReducer(
    reducer,
    appEditInitialState
  )
  return { appEditState, appEditDispatch }
}
