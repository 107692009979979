import React from "react"
import { SortOrder } from "../../../types/Common"
import { Theme, makeStyles } from "@material-ui/core"
import { globalBorderBottomSelectStyle } from "../../globalStyles"

/**
 * SortComponentのためのプロパティです。
 */
interface SortComponentProps {
  /** 現在のソートキー */
  sortKey: string
  /** 現在のソート順序 */
  sortOrder: SortOrder
  /** 利用可能なソートキーとその表示名の配列 */
  sortKeyMap: { key: string; name: string }[]
  /** ソートキーの変更を処理する関数 */
  handleSortKeyChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  /** ソート順序の変更を処理する関数 */
  handleSortOrderChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

/**
 * ソートキーとソート順序を選択するためのコンポーネント
 */
export const SortComponent = ({
  sortKey,
  sortOrder,
  sortKeyMap,
  handleSortKeyChange,
  handleSortOrderChange,
}: SortComponentProps) => {
  const classes = useSortStyles()
  return (
    <div className={classes.sort}>
      {/* sort key */}
      <label className="label" htmlFor="sortKeys">
        ソートキー
      </label>
      <select
        className="selectbox"
        id="sortKeys"
        value={sortKey}
        onChange={(e) => handleSortKeyChange(e)}
        required={true}
      >
        {sortKeyMap.map((item) => (
          <option key={item.key} value={item.key}>
            {item.name}
          </option>
        ))}
      </select>
      {/* sort order */}
      <label className="label" htmlFor="sortOrder">
        ソート順
      </label>
      <select
        className="selectbox"
        id="sortOrder"
        value={sortOrder}
        onChange={(e) => handleSortOrderChange(e)}
        required={true}
      >
        <option key="asc" value="asc">
          昇順
        </option>
        <option key="desc" value="desc">
          降順
        </option>
      </select>
    </div>
  )
}

const useSortStyles = makeStyles((_: Theme) => {
  // 並び替え
  const sort = {
    display: "flex",
    flexFlow: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .label": {
      color: "#FFA500",
      marginRight: "10px",
      fontSize: "12px",
    },
    "& .selectbox": {
      ...globalBorderBottomSelectStyle,
    },
    "& > .selectbox:first-of-type": {
      marginRight: "10px",
    },
  }

  return { sort }
})
