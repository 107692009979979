import React, { useState } from "react"
import * as FormRegisters from "../typings/formRegisters"
import * as FormTextField from "./FormTextField"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormForgotPasswordButtton } from "./FormSubmitButton"
import { ForgotPasswordParams } from "../typings/users"
import { ForgotPasswordProps } from "../typings"
import { SignInStyles } from "../styles/form"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

export default function FormForgotPassword(props: ForgotPasswordProps) {
  const { submitHandler, errorHandler } = props
  const { t } = useTranslation()
  const classes = SignInStyles()

  const { register, handleSubmit, errors } = useForm<ForgotPasswordParams>()
  const [successfulRes, setSuccessfulRes] = useState(false)

  const emailRegister = FormRegisters.emailRegister(register)
  // const requiredRegister = FormRegisters.requiredRegister(register)

  const onSubmit = (data: ForgotPasswordParams) => {
    submitHandler(data)
      .then(() => {
        setSuccessfulRes(true)
      })
      .catch(errorHandler)
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {successfulRes ? (
          <Typography>{t("formForgotPassword.successfulRes")}</Typography>
        ) : (
          <>
            <FormTextField.ForgotPasswordEmailField
              forwardRef={emailRegister}
              error={errors.email}
              t={t}
            />
            <FormForgotPasswordButtton submitClass={classes.submit} t={t} />
          </>
        )}
      </Grid>
    </form>
  )
}
