import { makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useState } from "react"

type Props = {
  getPlaceholder: () => string
  onValueChange?: (value: string) => void
  initialValue?: string
  readonly?: boolean
}

/**
 * シンプルな角丸テキスト入力コンポーネント
 */
export const SimpleTextInput = ({
  onValueChange,
  getPlaceholder,
  initialValue = "",
  readonly = false,
}: Props) => {
  const [value, setValue] = useState<string>(initialValue)

  useEffect(() => {
    if (onValueChange) onValueChange(value)
  }, [value])

  /**
   * インプットの値が変更されたときの処理
   * @param event イベントオブジェクト
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <input
        value={value}
        type="text"
        maxLength={256}
        onChange={handleChange}
        placeholder={getPlaceholder()}
        readOnly={readonly}
      />
    </div>
  )
}

const useStyles = makeStyles((_: Theme) => ({
  root: {
    "& input[type=text]": {
      boxSizing: "border-box",
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 20px 0 20px",
      transition: "all 0.1s ease-in",
      "&::placeholder": {
        fontSize: "12px",
      },
      "&:focus": {
        outline: "none",
        border: "2px solid gray",
      },
    },
  },
}))
