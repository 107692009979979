import React, { useEffect, useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import PeopleIcon from "@material-ui/icons/People"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import Typography from "@material-ui/core/Typography"
import FormEmployee from "../components/FormEmployee"
import ListEmployee from "../components/ListEmployee"
import SnackAlert from "../components/SnackAlert"
import Tabs from "@material-ui/core/Tabs"
import Paper from "@material-ui/core/Paper"
import Tab from "@material-ui/core/Tab"
import useStyles from "../styles/employee"
import { Employee, EmployeeParams } from "../typings/companies"
import { EmployerLayoutProps } from "../typings"
import { getEmployees, showEmployee, deleteEmployee } from "../api/companies"
import { useTranslation } from "react-i18next"

export default function Employees(props: EmployerLayoutProps) {
  const { alert, token, errorHandler, submitEmployeeForm } = props

  const classes = useStyles()

  const { t } = useTranslation()

  const [tabValue, setTabValue] = React.useState(0)
  const [employees, setEmployees] = useState<Employee[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<
    Employee | undefined
  >(undefined)
  const [isLoading, setLoading] = useState(false)

  const fetchEmployees = () => {
    setLoading(true)
    getEmployees(token)
      .then((data) => {
        setLoading(false)
        setEmployees(data)
      })
      .catch(errorHandler)
  }

  useEffect(() => {
    fetchEmployees()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isEdit = selectedEmployee !== undefined && selectedEmployee !== null

  const submitForm = (employee: EmployeeParams) => {
    if (submitEmployeeForm !== undefined) {
      const selectedId = isEdit ? selectedEmployee?.id : undefined

      submitEmployeeForm(employee, selectedId).then(() => {
        setSelectedEmployee(undefined)
        fetchEmployees()
      })
    } else {
      setSelectedEmployee(undefined)
    }
  }

  const selectEmployee = (employeeId: string) => {
    if (isEdit) {
      setSelectedEmployee(undefined)
      if (!employeeId) return
    }

    setLoading(true)
    showEmployee(token, employeeId)
      .then((employee) => {
        setSelectedEmployee(employee) // NOTE: set employee until loading
        setTabValue(0)
        setLoading(false)
      })
      .catch(errorHandler)
  }

  const sendDeleteEmployee = (employeeId: string) => {
    setLoading(true)
    deleteEmployee(token, employeeId).then(() => {
      fetchEmployees()
    })
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        {isLoading ? (
          <span>
            <CircularProgress />
          </span>
        ) : (
          <>
            <Paper square className={classes.paper}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  label={
                    isEdit ? t("listEmployee.edit") : t("listEmployee.add")
                  }
                />
                <Tab label={t("listEmployee.list")} />
              </Tabs>
            </Paper>
            {tabValue === 0 ? (
              <Paper square className={classes.paper}>
                <Box>
                  <Avatar className={classes.avatar}>
                    <PersonAddIcon />
                  </Avatar>
                </Box>
                <Box>
                  <Typography
                    color="secondary"
                    component="p"
                    className={classes.textHeader}
                  >
                    {isEdit
                      ? t("listEmployee.editHeader")
                      : t("listEmployee.addHeader")}
                  </Typography>
                </Box>
                <Box width="100%" mt={1} mb={2}>
                  <Divider />
                </Box>
                <FormEmployee
                  employee={selectedEmployee}
                  submitHandler={submitForm}
                  cancelHandler={(employeeId: string) => {
                    selectEmployee("")
                    setTabValue(1)
                  }}
                  isEdit={isEdit}
                />
              </Paper>
            ) : (
              <Paper square className={classes.paper}>
                <Box>
                  <Avatar className={classes.avatar}>
                    <PeopleIcon />
                  </Avatar>
                </Box>
                <Box>
                  <Typography
                    color="secondary"
                    component="p"
                    className={classes.textHeader}
                  >
                    {t("listEmployee.listHeader")}
                  </Typography>
                </Box>
                <Box width="100%" mt={1} mb={2}>
                  <Divider />
                </Box>
                <ListEmployee
                  employees={employees}
                  clickSelectHandler={selectEmployee}
                  clickDeleteHandler={sendDeleteEmployee}
                  isEdit={isEdit}
                />
              </Paper>
            )}
          </>
        )}
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
