import { DeleteApi, GetApi, PostApi, PutApi } from "./AbstractApiFactory"
import { PaginableResponse, ResponseType, SortOrder } from "../types/Common"
import { ProductCodeType, ProductCodeSortKey } from "../types/ProductCode"

// 全プロダクトコードまたはキーワードに一致するプロダクトコードを取得します。
export class GetProductCodesApi extends GetApi<
  PaginableResponse<ProductCodeType[]>
> {
  constructor(
    private readonly page: number,
    private readonly sortKey: ProductCodeSortKey,
    private readonly sortOrder: SortOrder,
    private readonly keyword?: string,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    const query = `&searchKeyword=${this.encode(this.keyword)}`
    return `/productcodes/?page=${this.page}&sortKey=${this.sortKey}&sortOrder=${this.sortOrder}${query}`
  }
}

// 指定IDのプロダクトコードを取得します。
export class GetProductCodeApi extends GetApi<ResponseType<ProductCodeType>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/productcode/${this.id}`
  }
}

// プロダクトコードを発行します。
export class PostProductCodeApi extends PostApi<ResponseType<string>> {
  constructor(
    appId: string,
    companyId: string,
    licenseCount: number,
    amount: number,
    interval: string,
    interval_count: number,
    trialPeriodDays: number,
    usageStartedAt: string,
    usageExpireIn: string,
    agency: string,
    agencyfee: number,
    token?: string
  ) {
    const data = {
      appId: appId,
      companyId: companyId,
      licenses: licenseCount,
      amount: amount,
      interval: interval,
      interval_count: interval_count,
      trialPeriodDays: trialPeriodDays,
      startedAt: usageStartedAt,
      expireIn: usageExpireIn,
      agency: agency,
      agencyfee: agencyfee,
    }
    super(data, token)
  }
  // override
  protected getUrl(): string {
    return `/productcodes`
  }
}

// プロダクトコードを更新します。
export class PutProductCodeApi extends PutApi<ResponseType<String>> {
  constructor(
    private readonly id: string,
    licenseCount: number,
    amount: number,
    interval: string,
    interval_count: number,
    trialPeriodDays: number,
    usageStartedAt: string,
    usageExpireIn: string,
    agency: string,
    agencyfee: number,
    token?: string
  ) {
    const data = {
      licenses: licenseCount,
      amount: amount,
      interval: interval,
      interval_count: interval_count,
      trialPeriodDays: trialPeriodDays,
      startedAt: usageStartedAt,
      expireIn: usageExpireIn,
      agency: agency,
      agencyfee: agencyfee,
    }

    super(data, token)
  }

  // override
  protected getUrl(): string {
    return `/productcode/${this.id}`
  }
}

// プロダクトコードを削除します
export class DeleteProductCodeApi extends DeleteApi<ResponseType<string>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }

  // override
  protected getUrl(): string {
    return `/productcode/${this.id}`
  }
}
