import React from "react"
import { SimpleSelectInput } from "../../Common/SimpleSelectInput"
import { ROLE, roles } from "../../../../types/AdminUser"

type Props<T> = {
  dispatch: React.Dispatch<T>
  initialValue: ROLE
}

/**
 * 管理者ロールを選択して入力するコンポーネント
 */
export const RoleSelectInput = <T,>({ dispatch, initialValue }: Props<T>) => {
  /**
   * 選択されたロールを設定する
   * @param value 選択されたロール
   */
  const handleValueChange = (value: string) => {
    dispatch({ type: "role", payload: value } as T)
  }

  /**
   * 選択肢を描画する
   * @param value 選択されたロール
   */
  const renderOptions = () => {
    return roles.map((v) => {
      return (
        <option key={v} value={v}>
          {v}
        </option>
      )
    })
  }

  return (
    <div>
      <SimpleSelectInput
        onValueChange={(value: unknown) => {
          if (typeof value === "string") {
            handleValueChange(value as ROLE)
          }
        }}
        renderOptions={renderOptions}
        initialValue={initialValue}
      />
    </div>
  )
}
