import React, { createContext } from "react"
import {
  ProductCodeListAction,
  useProductCodeListReducer,
  productCodeListInitialState,
  ProductCodeListState,
} from "./reducers/useProductCodeListReducer"
import {
  ProductCodeIssueAction,
  useProductCodeIssueReducer,
  productCodeIssueInitialState,
  ProductCodeIssueState,
} from "./reducers/useProductCodeIssueReducer"
import {
  ProductCodeEditAction,
  useProductCodeEditReducer,
  productCodeEditInitialState,
  ProductCodeEditState,
} from "./reducers/useProductCodeEditReducer"

/**
 * ProductCodeの管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {ProductCodeListState} 一覧ページのステート
 * @property {React.Dispatch<ProductCodeListAction>} 一覧ページのアクションをディスパッチする関数
 * @property {ProductCodeIssueState} 発行ページのステート
 * @property {React.Dispatch<ProductCodeIssueAction>} 発行ページのアクションをディスパッチする関数
 * @property {ProductCodeEditState} 編集ページのステート
 * @property {React.Dispatch<ProductCodeEditAction>} 編集ページのアクションをディスパッチする関数
 */
type ContextType = {
  productCodeListState: ProductCodeListState
  productCodeListDispatch: React.Dispatch<ProductCodeListAction>
  productCodeIssueState: ProductCodeIssueState
  productCodeIssueDispatch: React.Dispatch<ProductCodeIssueAction>
  productCodeEditState: ProductCodeEditState
  productCodeEditDispatch: React.Dispatch<ProductCodeEditAction>
}

/**
 * ProductCodeの管理に関する情報を提供するContextです。
 * @type {React.Context<ContextType>}
 */
export const ProductCodeManagementContext = createContext<ContextType>({
  productCodeListState: productCodeListInitialState,
  productCodeListDispatch: (_: ProductCodeListAction) => {},
  productCodeIssueState: productCodeIssueInitialState,
  productCodeIssueDispatch: (_: ProductCodeIssueAction) => {},
  productCodeEditState: productCodeEditInitialState,
  productCodeEditDispatch: (_: ProductCodeEditAction) => {},
})

/**
 * ProductCodeの管理に関する情報を提供するContextのプロバイダーコンポーネントです。
 * @param {Object} props コンポーネントに渡すprops
 * @param {React.ReactNode} props.children 子コンポーネント
 * @returns {JSX.Element} コンテキストのProviderコンポーネント
 */
export const ProductCodeManagementContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {
    productCodeListState,
    productCodeListDispatch,
  } = useProductCodeListReducer()
  const {
    productCodeIssueState,
    productCodeIssueDispatch,
  } = useProductCodeIssueReducer()
  const {
    productCodeEditState,
    productCodeEditDispatch,
  } = useProductCodeEditReducer()

  return (
    <ProductCodeManagementContext.Provider
      value={{
        productCodeListState,
        productCodeListDispatch, // 検索ページ
        productCodeIssueState,
        productCodeIssueDispatch, // 発行ページ
        productCodeEditState,
        productCodeEditDispatch, // 編集ページ
      }}
    >
      {children}
    </ProductCodeManagementContext.Provider>
  )
}
