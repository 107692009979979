import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormSignIn from "../components/FormSignIn"
import Grid from "@material-ui/core/Grid"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import { RouterLink } from "../components/RouterLink"
import { SignInProps } from "../typings"
import { SignInStyles } from "../styles/form"
import { useTranslation } from "react-i18next"

export default function SignIn(props: SignInProps) {
  const { t } = useTranslation()
  const { alert } = props

  const classes = SignInStyles()

  return (
    <Container component="main" style={{maxWidth: "375px"}}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("signin.title")}
        </Typography>
        <FormSignIn {...props} />
        <Grid container justify="space-between" style={{marginBottom: "20px"}}>
          <Grid item>
            <RouterLink to="/forgot" variant="body2">
              {t("signin.forgotPassLink")}
            </RouterLink>
          </Grid>
          <Grid item>
            <RouterLink to="/signup" variant="body2">
              {t("signin.bottomLink")}
            </RouterLink>
          </Grid>
        </Grid>
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
