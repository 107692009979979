import React, { Suspense } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import LoadingFallback from "../uiParts/Dashboard/LoadingFallback"

/**
 * ダッシュボード画面
 */
export const DashboardPage = () => {
  const LasySalesGroupByApp = React.lazy(() =>
    import("../uiParts/Dashboard/SalesGroupByApp")
  )

  const LasyAppStatusHistoryAggregate = React.lazy(() =>
    import("../uiParts/Dashboard/AppStatusHistoryAggregate")
  )

  const LasyStripeSessionByStatus = React.lazy(() =>
    import("../uiParts/Dashboard/StripeSessionsByStatus")
  )

  const styles = createStyles()()

  return (
    <div className={styles.root}>
      <div className={styles.gridItem1}>
        <Suspense fallback={<LoadingFallback />}>
          <LasySalesGroupByApp />
        </Suspense>
      </div>
      <div className={styles.gridItem2}>
        <Suspense fallback={<LoadingFallback />}>
          <LasyAppStatusHistoryAggregate />
        </Suspense>
      </div>
      <div className={styles.gridItem3}>
        <Suspense fallback={<LoadingFallback />}>
          <LasyStripeSessionByStatus />
        </Suspense>
      </div>
    </div>
  )
}

// スタイル作成
const createStyles = () => {
  // ルートエリア
  const rootArea = {
    border: 0,
    borderRadius: "8px",
    padding: "20px",
    gap: "20px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)",
    overflow: "hidden",
    display: "grid",
    backgroundColor: "#ffffff",
    gridTemplateColumns: "400px 1fr",
  }

  return makeStyles((_: Theme) => ({
    root: { ...rootArea },
    gridItem1: {
      gridRowEnd: "span 1",
      gridColumnEnd: "span 1",
    },
    gridItem2: {
      gridRowEnd: "span 1",
      gridColumnEnd: "span 1",
    },
    gridItem3: {
      gridRowEnd: "span 1",
      gridColumnEnd: "span 2",
    },
  }))
}
