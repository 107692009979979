import React, { useEffect } from "react"
import { ProductCodeIssueAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { ProductCodeEditAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { NumericInput } from "../../Common/NumericInput"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: number
}

/**
 * プロダクトコードの単価を入力するコンポーネント
 */
export const UnitPriceInput = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  initialValue = 1,
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "amount", payload: initialValue } as T)
  }, [initialValue])

  /**
   * 単価変更時のハンドラー
   * @param number 変更後の単価
   */
  const handleValueChange = (unitPrice: number) => {
    dispatch({ type: "amount", payload: unitPrice } as T)
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "単価を入力してください"
  }

  return (
    <NumericInput
      onValueChange={handleValueChange}
      getPlaceholder={getPlaceholder}
      initialValue={initialValue}
    />
  )
}
