import { useReducer } from "react"
import { ROLE } from "../../../types/AdminUser"

/**
 * 管理者ユーザー編集ページの状態管理
 * @property diplayName 管理者の表示名
 * @property role 管理ページの権限
 * @property password 管理者のパスワード
 */
export type AdminUserEditState = {
  id: string | undefined
  diplayName: string | undefined
  role: ROLE | undefined
  password: string | undefined
  confirmPassword: string | undefined
}

/**
 * プロダクトコード編集ページのアクション型
 */
export type AdminUserEditAction =
  | {
      type: "id"
      payload: string | undefined
    }
  | {
      type: "displayName"
      payload: string | undefined
    }
  | {
      type: "role"
      payload: ROLE | undefined
    }
  | {
      type: "password"
      payload: string | undefined
    }
  | {
      type: "confirmPassword"
      payload: string | undefined
    }
  | {
      type: "init"
    }

/**
 * 状態と状態を更新するためのdispatch関数を返します。
 * @returns {{ state: AdminUserEditState, dispatch: (value: AdminUserEditAction) => void }}
 */
const reducer = (state: AdminUserEditState, action: AdminUserEditAction) => {
  let next = { ...state }
  switch (action.type) {
    case "id":
      next.id = action.payload
      break
    case "displayName":
      next.diplayName = action.payload
      break
    case "role":
      next.role = action.payload
      break
    case "password":
      next.password = action.payload
      break
    case "confirmPassword":
      next.confirmPassword = action.payload
      break
    case "init":
      next = adminUserEditInitialState
      break
  }

  return next
}

// ステートの初期値
export const adminUserEditInitialState: AdminUserEditState = {
  id: undefined,
  diplayName: undefined,
  role: undefined,
  password: undefined,
  confirmPassword: undefined,
}

/*
 * AdminUser編集ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useAdminUserEditReducer = () => {
  const [adminUserEditState, adminUserEditDispatch] = useReducer(
    reducer,
    adminUserEditInitialState
  )
  return { adminUserEditState, adminUserEditDispatch }
}
