import { useReducer } from "react"
import { AppType } from "../../../types/App"
import { ErrorData } from "../../../types/Common"
import { CompanyType } from "../../../types/Company"
import { Interval } from "../../../types/Sale"

/**
 * 販売編集ページの状態管理
 */
export type SaleEditState = {
  id: string | undefined
  company: CompanyType | undefined
  app: AppType | undefined
  payerEmail: string | undefined
  amount: number | undefined
  paidAt: number | undefined
  paymentMethod: "stripe" | "other" | undefined
  interval: Interval | undefined
  intervalCount: number | undefined
  relatedInfo: string | undefined
  errors: ErrorData<SaleEditState>[]
}

/**
 * 販売編集ページのアクション型
 */
export type SaleEditAction =
  | {
      type: "id"
      payload: string | undefined
    }
  | {
      type: "company"
      payload: CompanyType | undefined
    }
  | {
      type: "app"
      payload: AppType | undefined
    }
  | {
      type: "payerEmail"
      payload: string | undefined
    }
  | {
      type: "amount"
      payload: number | undefined
    }
  | {
      type: "paidAt"
      payload: number | undefined
    }
  | {
      type: "paymentMethod"
      payload: "stripe" | "other" | undefined
    }
  | {
      type: "interval"
      payload: Interval | undefined
    }
  | {
      type: "intervalCount"
      payload: number | undefined
    }
  | {
      type: "relatedInfo"
      payload: string | undefined
    }
  | {
      type: "addErrors"
      payload: ErrorData<SaleEditState>
    }
  | {
      type: "removeErrors"
      payload: Omit<ErrorData<SaleEditState>, "message">
    }
  | {
      type: "init"
    }

/**
 * 状態と状態を更新するためのdispatch関数を返します。
 * @returns {{ state: SaleEditState, dispatch: (value: SaleEditAction) => void }}
 */
const reducer = (state: SaleEditState, action: SaleEditAction) => {
  let next = { ...state }
  switch (action.type) {
    case "id":
      next.id = action.payload
      break
    case "company":
      next.company = action.payload
      break
    case "app":
      next.app = action.payload
      break
    case "payerEmail":
      next.payerEmail = action.payload
      break
    case "amount":
      next.amount = action.payload
      break
    case "paidAt":
      next.paidAt = action.payload
      break
    case "paymentMethod":
      next.paymentMethod = action.payload
      break
    case "interval":
      next.interval = action.payload
      break
    case "intervalCount":
      next.intervalCount = action.payload
      break
    case "relatedInfo":
      next.relatedInfo = action.payload
      break
    case "addErrors":
      if (
        !next.errors.some(
          (e) =>
            e.type === action.payload.type &&
            e.errorCode === action.payload.errorCode
        )
      ) {
        next.errors.push(action.payload)
      }
      break
    case "removeErrors":
      next.errors = next.errors.filter(
        (v) =>
          !(
            v.type === action.payload.type &&
            v.errorCode === action.payload.errorCode
          )
      )
      break
    case "init":
      next = saleEditInitialState
      break
  }

  return next
}

// ステートの初期値
export const saleEditInitialState: SaleEditState = {
  id: undefined,
  company: undefined,
  app: undefined,
  payerEmail: undefined,
  amount: undefined,
  paidAt: undefined,
  paymentMethod: undefined,
  interval: undefined,
  intervalCount: undefined,
  relatedInfo: undefined,
  errors: [],
}

/*
 * 販売編集ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useSaleEditReducer = () => {
  const [saleEditState, saleEditDispatch] = useReducer(
    reducer,
    saleEditInitialState
  )
  return { saleEditState, saleEditDispatch }
}
