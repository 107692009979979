import { GetApi } from "./AbstractApiFactory"
import { CompanyType } from "../types/Company"
import { PaginableResponse, ResponseType, SortOrder } from "../types/Common"

// 企業名から企業のリストを取得します（全後方一致）
export class GetCompaniesByNameApi extends GetApi<
  PaginableResponse<CompanyType[]>
> {
  constructor(
    private readonly name: string,
    private readonly page: number,
    protected readonly token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/companies/name/${this.name}?page=${this.page}`
  }
}

// 企業OIDから企業を取得します
export class GetCompanyByIdApi extends GetApi<ResponseType<CompanyType>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/company/${this.id}`
  }
}
