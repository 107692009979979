import React, { useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalRedAsterisk,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalSystemButtonDeleteActionStyle,
} from "../../globalStyles"
import { SalesManagementContext } from "../../../contexts/SalesManagement/SalesManagementContextProvider"
import { useHistory } from "react-router-dom"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { useSnackbar } from "notistack"
import { SaleType } from "../../../types/Sale"
import { Save, DeleteForever } from "@material-ui/icons"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import { DeleteSaleApi, GetSaleApi, PutSaleApi } from "../../../api/SaleApi"
import { AppSuggestInput } from "../../uiParts/Common/AppSuggestInput"
import { CompanySuggestInput } from "../../uiParts/Common/CompanySuggestInput"
import { PaymentDueDatePicker } from "../../uiParts/Sales/common/PaymentDueDatePicker"
import { PaymentAmountInput } from "../../uiParts/Sales/common/PaymentAmountInput"
import {
  SaleEditAction,
  SaleEditState,
} from "../../../contexts/SalesManagement/reducers/useSaleEditReducer"
import { CompanyUserList } from "../../uiParts/ProductCode/common/CompanyUserList"
import { PayerMailAddressInput } from "../../uiParts/Sales/common/PayerMailAddressInput"
import { RelatedInfoInput } from "../../uiParts/Sales/common/RelatedInfoInput"
import { PaymentMethodInput } from "../../uiParts/Sales/common/PaymentMethodInput"
import { BillingIntervalInput } from "../../uiParts/Common/BillingIntervalInput"

/**
 * 販売データ編集ページコンポーネント
 */
export const SaleEditPage = ({ scope }: PageAccessPermissions) => {
  const { saleEditState, saleEditDispatch } = useContext(SalesManagementContext)
  const [sale, setSale] = useState<SaleType>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { adminAuthState } = useContext(AdminAuthContext)
  const [readOnly, setReadOnly] = useState(false)
  const history = useHistory()

  // 初回処理
  useEffect(() => {
    if (!saleEditState.id) {
      history.goBack()
      enqueueSnackbar("販売データIDを指定してください", {
        variant: "error",
        preventDuplicate: true,
      })
      return
    }

    const fetchData = async (id: string) => {
      try {
        const res = await new GetSaleApi(id, adminAuthState.token).sendRequest()
        setSale(res.data.payload)
        const authorized = scope ? scope.write : false
        const editable = res.data.payload.paymentMethod === "other"
        const readOnly = !authorized || !editable
        setReadOnly(readOnly)
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    fetchData(saleEditState.id)

    // cleanup
    return () => {
      saleEditDispatch({ type: "init" })
    }
  }, [])

  const { enqueueSnackbar } = useSnackbar()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.goBack()
  }

  // 「保存」ボタンが押された時の処理
  const handleSaveButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 保存関数定義
    const saveAction = async () => {
      if (!saleEditState.errors.length) {
        try {
          //console.log("saleEditState.comany id: ", saleEditState.company?.id)

          const result = await new PutSaleApi(
            saleEditState.id!,
            saleEditState.app!._id!,
            saleEditState.company!.id!,
            saleEditState.amount!,
            saleEditState.paidAt!,
            saleEditState.interval!,
            saleEditState.intervalCount!,
            saleEditState.paymentMethod!,
            saleEditState.payerEmail!,
            saleEditState.relatedInfo!,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(`販売データ(${result.data.payload})を更新しました`, {
            variant: "success",
            preventDuplicate: true,
          })
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
          return
        }
      } else {
        enqueueSnackbar("入力が不十分です", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    setDialogMessage("販売データを上書き保存しますか？")
    showDialog(() => saveAction)
  }

  // 「削除」ボタンが押された時の処理
  const handleDeleteButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 削除関数定義
    const deleteAction = async () => {
      if (sale?.id) {
        try {
          const result = await new DeleteSaleApi(
            sale.id,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(`販売データ(${result.data.payload})を削除しました`, {
            variant: "success",
            preventDuplicate: true,
          })
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
          return
        }
      } else {
        enqueueSnackbar("入力が不十分です", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    setDialogMessage("販売データを削除しますか？")
    showDialog(() => deleteAction)
  }

  const styles = createStyles("20px")()

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>販売データ編集</h3>
      </div>
      {sale && (
        <>
          <div className={styles.inputLeftArea}>
            <label
              className={
                sale.paymentMethod === "other"
                  ? styles.requiredField
                  : undefined
              }
            >
              アプリ名
            </label>
            <AppSuggestInput<SaleEditAction, SaleEditState>
              dispatch={saleEditDispatch}
              state={saleEditState}
              initialAppOid={sale.appOid}
            />
            <label
              className={
                sale.paymentMethod === "other"
                  ? styles.requiredField
                  : undefined
              }
            >
              企業名
            </label>
            <CompanySuggestInput<SaleEditAction, SaleEditState>
              dispatch={saleEditDispatch}
              state={saleEditState}
              initialCompanyOid={sale.companyOid}
            />
            <label
              className={
                sale.paymentMethod === "other"
                  ? styles.requiredField
                  : undefined
              }
            >
              {"単価(税抜)"}
            </label>
            <PaymentAmountInput<SaleEditAction>
              dispatch={saleEditDispatch}
              initialValue={sale.amount}
            />
            <label
              className={
                sale.paymentMethod === "other"
                  ? styles.requiredField
                  : undefined
              }
            >
              {sale.paymentMethod === "stripe" ? "支払日" : "本番開始日"}
            </label>
            <PaymentDueDatePicker<SaleEditAction>
              epochTime={sale.paidAt}
              dispatch={saleEditDispatch}
            />
            <label
              className={
                sale.paymentMethod === "other"
                  ? styles.requiredField
                  : undefined
              }
            >
              支払い者メールアドレス
            </label>
            <PayerMailAddressInput<SaleEditAction>
              dispatch={saleEditDispatch}
              initialValue={sale.payerEmail}
            />
          </div>
          <div className={styles.userlistArea}>
            <CompanyUserList
              employer={sale.employerEmail}
              employee={sale.employeeEmails}
            />
          </div>
          <div className={styles.inputRightArea}>
            <label>支払い方法（編集不可）</label>
            <PaymentMethodInput
              dispatch={saleEditDispatch}
              initialValue={sale.paymentMethod}
            />
            <label
              className={
                sale.paymentMethod === "other"
                  ? styles.requiredField
                  : undefined
              }
            >
              請求間隔
            </label>
            <BillingIntervalInput<SaleEditAction>
              dispatch={saleEditDispatch}
              initialIntervalValue={sale.interval}
            />
          </div>
          <div className={styles.relatedInfoArea}>
            <label>関連情報</label>
            <RelatedInfoInput
              dispatch={saleEditDispatch}
              initialValue={sale.relatedInfo}
            />
          </div>

          <div className={styles.buttonArea}>
            <div id="buttons">
              <button
                type="button"
                className="arrow"
                onClick={handleCancelButtonClick}
              >
                戻る
                <img src={button_white_arrow} />
              </button>
              <button
                type="button"
                className={readOnly ? "actionDisabled" : "action"}
                onClick={handleSaveButtonClick}
                disabled={readOnly}
              >
                保存
                <Save />
              </button>
              <button
                type="button"
                className={readOnly ? "deleteDisabled" : "delete"}
                onClick={handleDeleteButtonClick}
                disabled={readOnly}
              >
                削除
                <DeleteForever />
              </button>
            </div>
          </div>

          <AlertDialog
            dialogOpen={dialogOpen}
            onClose={handleDialogClose}
            message={dialogMessage}
          />
        </>
      )}
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea       titleArea      " auto
                       "inputLeftArea   userlistArea   " 1fr
                       "inputLeftArea   inputRightArea " auto
                       "relatedInfoArea relatedInfoArea" auto
                       "buttonArea     buttonArea      " auto
                       / 1fr 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
  }

  // 入力エリア(左)
  const inputLeftArea = {
    gridArea: "inputLeftArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // 入力エリア(右)
  const inputRightArea = {
    gridArea: "inputRightArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // 企業ユーザーリスト表示エリア
  const userlistArea = {
    gridArea: "userlistArea",
  }

  // 関連情報表示エリア
  const relatedInfoArea = {
    gridArea: "relatedInfoArea",
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "600px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
    "& div[id=buttons] .delete": {
      ...globalSystemButtonDeleteActionStyle,
    },
    "& div[id=buttons] .deleteDisabled": {
      ...globalSystemButtonDeleteActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputLeftArea: { ...inputLeftArea },
    inputRightArea: { ...inputRightArea },
    userlistArea: { ...userlistArea },
    relatedInfoArea: { ...relatedInfoArea },
    buttonArea: { ...buttonArea },
    requiredField: { ...globalRedAsterisk },
  }))
}
