import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppSearchContext } from "../App"
import { IAppSortKey, SORT_TYPE } from "../typings/apps"

/*
コンポーネントの説明：
  アプリ検索部品の１つ。ソートキーを指定してアプリを検索するためのコンポーネント。
  sortKeysオブジェクトで定義したソート名を選択するためのセレクトボックスを生成する。
  選択後はAppSearchContext上にあるステート(sortKey)を更新するのみで実際の検索処理は委譲する。
*/
export const SearchBySortKey = () => {
  // アプリ検索用のコンテキストからデータを取得
  const { state, dispatch } = useContext(AppSearchContext)

  // 国際化対応
  const { t } = useTranslation()

  // セレクトボックスの選択肢に設定するキーと表示名
  const sortKeys: IAppSortKey[] = [
    { key: SORT_TYPE.Popularity, name: t("listApps.appSortKeys.popularity") },
    { key: SORT_TYPE.Newest, name: t("listApps.appSortKeys.newest") },
    { key: SORT_TYPE.AppNameAsc, name: t("listApps.appSortKeys.appNameAsc") },
    { key: SORT_TYPE.AppNameDesc, name: t("listApps.appSortKeys.appNameDesc") },
  ]

  // ソートキー変更ハンドラー
  const onSortKeyChanged = (sortKey: SORT_TYPE) => {
    // 検索条件の更新（並び替えキー）
    dispatch({ type: "sort", payload: { sortKey } })
  }

  // PC・SPで共通のスタイル
  const styles = useStyles()

  return (
    // PC・SPで共通のHTML構造
    <div>
      <label className={styles.label} htmlFor="sortKeys">{t("listApps.appSortHeader")}</label>
      <select
        className={styles.selectBox}
        id="sortKeys"
        value={state.sortKey}
        onChange={e => onSortKeyChanged(e.target!.value as SORT_TYPE)}
        required={true}
      >
        {sortKeys.map(sort => (
          <option key={sort.key} value={sort.key}>
            {sort.name}
          </option>
        ))}
      </select>
    </div>
  )
}

// PC・SPで共通のスタイル
const useStyles = makeStyles((_: Theme) => ({
  // ラベル
  label: {
    color: "#fa7d3d",
    marginRight: "10px"
  },
  // セレクトボックス
  selectBox: {
    position: "relative",
    backgroundColor: "transparent",
    width: "140px",
    height: "30px",
    border: 0,
    borderBottom: "1px solid rgba(0,0,0, 0.3)",
    cursor: "pointer",
    "&:focus": {
      outline: "none"
    }
  }
}))
