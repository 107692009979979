import React from "react"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import { EmployerLayoutProps } from "../typings"
import { PublicStyles } from "../styles/public"
import { RouterLink } from "../components/RouterLink"
import { useTranslation } from "react-i18next"
import { Helmet, HelmetProvider } from "react-helmet-async"

export default function AppPurchased(props: EmployerLayoutProps) {
  const { alert } = props
  const { t } = useTranslation()
  const classes = PublicStyles()

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script>
            {`
              gtag('event', 'conversion', {
                  'send_to': 'AW-11025495476/m2gnCOP2kYoYELTrrokp',
                  'transaction_id': ''
              });
            `}
          </script>
        </Helmet>
      </HelmetProvider>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography id="thanks" component="p" variant="body1">
            {t("appPurchased.purchasedText")}
          </Typography>
          <RouterLink to="/apps">{t("appPurchased.toAppList")}</RouterLink>
        </div>
        <SnackAlert alert={alert} />
      </Container>
    </>
  )
}
