import React, { useState, useEffect, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"

type OptionType = {
  key: string
  label: string
}

interface SimpleOptionGroupInputProps {
  radios: OptionType[]
  onRadioSelected: (key: string) => void
  initialSelectedKey: string // 初期選択されるキー
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  radios: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
})

/**
 * SimpleOptionGroupInput コンポーネント
 *
 * @param radios ラジオボタンの配列。各オプションは{ key, label }の形式です。
 * @param onRadioSelected オプションが選択されたときに呼び出されるコールバック関数。
 * @param initialSelectedKey 初期に選択されるオプションのキー。
 */
const SimpleRadioGroupInput: React.FC<SimpleOptionGroupInputProps> = ({
  radios,
  onRadioSelected,
  initialSelectedKey,
}) => {
  const classes = useStyles()
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    initialSelectedKey
  )

  useEffect(() => {
    setSelectedKey(initialSelectedKey)
  }, [initialSelectedKey])

  return (
    <div className={classes.root}>
      <label>サブスクリプションサイクル</label>
      <div className={classes.radios}>
        {radios.map((radio) => (
          <Fragment key={`fragment-${radio.key}`}>
            <input
              key={`radio-${radio.key}`}
              type="radio"
              id={radio.key}
              name="radio"
              value={radio.key}
              checked={radio.key === selectedKey}
              onChange={() => {
                setSelectedKey(radio.key)
                onRadioSelected(radio.key)
              }}
            />
            <label key={`label-${radio.key}`}>{radio.label}</label>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default SimpleRadioGroupInput
