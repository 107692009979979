import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import FormInquiry from "../components/FormInquiry"
import HelpOutline from "@material-ui/icons/HelpOutline"
import SnackAlert from "../components/SnackAlert"
import Typography from "@material-ui/core/Typography"
import { InquiryProps } from "../typings"
import { SignUpStyles } from "../styles/form"
import { useTranslation } from "react-i18next"

export default function Inquiry(props: InquiryProps) {
  const { alert } = props
  const { t } = useTranslation()

  const classes = SignUpStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <HelpOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("inquiry.inquiry")}
        </Typography>
        <FormInquiry />
      </div>
      <SnackAlert alert={alert} />
    </Container>
  )
}
