import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

// APIファクトリー抽象クラス
// サーバーリクエストのためのAPIを作成
abstract class AbstractApiFactory<T> {
  constructor(
    protected readonly token?: string,
    protected readonly config?: AxiosRequestConfig
  ) {}
  protected readonly ax = axios.create({
    baseURL: "/api/v1/admin",
    headers: { "Content-Type": "application/json" },
    responseType: "json",
  })
  // リソースのURLを取得
  protected abstract getUrl(): string
  // template method
  public abstract sendRequest(): Promise<AxiosResponse<T>>
  // リクエストコンフィグ作成
  protected createConfig(): AxiosRequestConfig | undefined {
    if (!this.token) return undefined

    return {
      ...this.config,
      headers: {
        ...this.config?.headers,
        Authorization: `Bearer ${this.token}`,
      },
    }
  }
}

// HTTP-GET
export abstract class GetApi<T> extends AbstractApiFactory<T> {
  constructor(
    protected readonly token?: string,
    protected readonly config?: AxiosRequestConfig
  ) {
    super(token)
  }
  //override
  public sendRequest(): Promise<AxiosResponse<T>> {
    return this.ax.get<T>(this.getUrl(), this.createConfig())
  }

  protected encode(keyword: string | undefined): string {
    return keyword ? encodeURIComponent(keyword) : ""
  }
}

// HTTP-POST
export abstract class PostApi<T> extends AbstractApiFactory<T> {
  constructor(
    protected readonly data: { [key: string]: any },
    protected readonly token?: string
  ) {
    super(token)
  }
  //override
  public sendRequest(): Promise<AxiosResponse<T>> {
    return this.ax.post<T>(this.getUrl(), this.data, this.createConfig())
  }
}

// HTTP-PUT
export abstract class PutApi<T> extends AbstractApiFactory<T> {
  constructor(
    protected readonly data?: { [key: string]: any },
    protected readonly token?: string
  ) {
    super(token)
  }
  //override
  public sendRequest(): Promise<AxiosResponse<T>> {
    return this.ax.put<T>(this.getUrl(), this.data, this.createConfig())
  }
}

// HTTP-DELETE
export abstract class DeleteApi<T> extends AbstractApiFactory<T> {
  constructor(protected readonly token?: string) {
    super(token)
  }
  //override
  public sendRequest(): Promise<AxiosResponse<T>> {
    return this.ax.delete<T>(this.getUrl(), this.createConfig())
  }
}
