import { useReducer } from "react"
import { AdminUserType } from "../../../types/AdminUser"
import { ResponseType } from "../../../types/Common"

/**
 * Reducerのアクションタイプ
 * refresh: ユーザー情報を更新する
 * signout: ユーザーをログアウトする
 */
export type AdminAuthAction =
  | {
      type: "refresh"
      data: ResponseType<AdminUserType>
    }
  | {
      type: "signout"
    }

/**
 * 管理者認証コンテキストの初期状態
 * id: ユーザーID
 * displayName: 表示名
 * email: Eメールアドレス
 * role: ユーザー権限
 * createdAt: 登録日時
 * updatedAt: 更新日時
 * token: 認証トークン
 */
export const initialState: ResponseType<AdminUserType> = {
  payload: {
    id: "",
    displayName: "",
    email: "",
    role: "member",
    createdAt: "",
    updatedAt: "",
  },
  token: undefined,
}

/**
 * Reducer関数
 * @param state 現在の状態
 * @param action 実行するアクション
 * @returns 新しい状態
 */
const reducer = (
  _state: ResponseType<AdminUserType>,
  action: AdminAuthAction
) => {
  switch (action.type) {
    case "refresh":
      return { payload: action.data.payload, token: action.data.token }
    case "signout":
      return initialState
  }
}

/**
 * 管理者認証コンテキストのカスタムフック
 * @returns stateとdispatchを持つ配列
 */
export const useAdminAuthReducer = () => {
  return useReducer(reducer, initialState)
}
