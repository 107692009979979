import React, { createContext } from "react"
import {
  AppListAction,
  useAppListReducer,
  appListInitialState,
  AppListState,
} from "./reducers/useAppListReducer"
import {
  AppEditAction,
  useAppEditReducer,
  appEditInitialState,
  AppEditState,
} from "./reducers/useAppEditReducer"

/**
 * アプリの管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {AppListState} 一覧ページのステート
 * @property {React.Dispatch<AppListAction>} 一覧ページのアクションをディスパッチする関数
 * @property {AppEditState} 編集ページのステート
 * @property {React.Dispatch<AppEditAction>} 編集ページのアクションをディスパッチする関数
 */
type ContextType = {
  appListState: AppListState
  appListDispatch: React.Dispatch<AppListAction>
  appEditState: AppEditState
  appEditDispatch: React.Dispatch<AppEditAction>
}

/**
 * アプリの管理に関する情報を提供するContextです。
 * @type {React.Context<ContextType>}
 */
export const AppManagementContext = createContext<ContextType>({
  appListState: appListInitialState,
  appListDispatch: (_: AppListAction) => {},
  appEditState: appEditInitialState,
  appEditDispatch: (_: AppEditAction) => {},
})

/**
 * アプリの管理に関する情報を提供するContextのプロバイダーコンポーネントです。
 * @param {Object} props コンポーネントに渡すprops
 * @param {React.ReactNode} props.children 子コンポーネント
 * @returns {JSX.Element} コンテキストのProviderコンポーネント
 */
export const AppManagementContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { appListState, appListDispatch } = useAppListReducer()

  const { appEditState, appEditDispatch } = useAppEditReducer()

  return (
    <AppManagementContext.Provider
      value={{
        appListState,
        appListDispatch, // 検索ページ
        appEditState,
        appEditDispatch, // 編集ページ
      }}
    >
      {children}
    </AppManagementContext.Provider>
  )
}
