import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { IAlertDialog } from "../typings"
import { useTranslation } from "react-i18next"

export default function AlertDialog(props: IAlertDialog) {
  const { open, handleClose, title, contentText } = props
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(true)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} autoFocus>
          {t("alertDialog.cancel")}
        </Button>
        <Button onClick={() => handleClose(false)} color="primary">
          {t("alertDialog.execute")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
