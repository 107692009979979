import React from "react"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import useStyles from "../styles/teams"

export default function SupportServiceTerms() {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          第1章　総則
        </Box>
        <Box fontWeight="fontWeightBold">第１条（適用範囲等）</Box>
        <div className={classes.paragraph}>
          この規約（以下「本規約」といいます）は、株式会社ストラテジット（以下「当社」といいます）が契約者に対して、契約者の希望するアプリケーションを契約者が使用を開始するために必要な導入支援、データ移行、データクレンジング作業等を行うサービス（以下「本サービス」といいます）を提供する一切の取引に適用されます。
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第2条（定義）</Box>
        <div className={classes.paragraph}>
          本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
        </div>
        <ul className={classes.ul}>
          <li>
            契約者：本規約に同意の上、当社と本契約を締結した法人、団体又は個人を意味します。
          </li>
          <li>
            契約者情報：契約者が、本契約の締結に際して又は締結後に、当社に対して提供した情報又は当社が本契約の履行に関して知った契約者に関する情報（個人情報を含みます）を意味します。
          </li>
          <li>
            コンテンツ：プログラム・文章・画像・映像等の情報を意味しますが、これらに限りません。
          </li>
          <li>
            本契約：当社と契約者との間で締結する、当社が契約者に対して本サービスを提供することに関する契約（本規約の内容を含みます）をいいます。
          </li>
          <li>
            本サイト：当社が運営する「」のサイトを意味し、理由の如何を問わず、URLが変更された場合は、当該変更後のURL先のサイトを意味するものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第3条（通知）</Box>
        <ul className={classes.ul}>
          <li>
            当社が契約者に対して本サービスに関する各種通知を行う必要があると判断した場合、本サイトへの掲載、電子メール、書面、又は当社が適当と判断する方法により随時これを行います。
          </li>
          <li>
            前項の通知は、電子メール及び書面により行う場合は、当社が発信した時点、本サイトへの掲載により行う場合は、当該通知の内容を本サイトに掲載した時点をもって、全ての契約者に到達したものとみなします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第4条（本利用規約の変更）</Box>
        <ul className={classes.ul}>
          <li>
            当社は、当社の判断により、本規約をいつでも任意の理由で追加・変更・削除（以下「追加等」といいます）することができるものとし、契約者は、当社が本規約を追加等すること及び本サービスの提供を受ける条件は追加等後の規約に定める内容によることを予め承諾します。
          </li>
          <li>
            当社が本規約を変更した場合は、本サイトにて公開される本規約の変更時点より効力を生じるものとします。変更後、契約者が本契約の履行を継続した場合又は当社の定める期間（個別に定めなかった場合には、30日間とします）内に当社に対して異議を述べなかった場合には、契約者は、本規約の変更に同意したものとみなします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          第2章　本サービス
        </Box>
        <Box fontWeight="fontWeightBold">第５条（契約の締結等）</Box>
        <ul className={classes.ul}>
          <li>
            本サービスの利用を希望する者（以下「契約希望者」といいます）は、本規約を遵守することに同意し、発注書の提出その他当社の定める方法に従い、当社の求める情報を当社に提供することにより、本サービス及び本契約の締結の申込みをすることができます。
          </li>
          <li>
            当社は、前項の申込みがあった場合、別途当社の定める審査基準に従って審査し、当該申請を承諾する場合には、契約希望者に対して発注書兼発注請書の締結その他の通知を行います。発注書兼発注請書に当社及び契約者が記名押印若しくは署名押印に代わる電磁的処理を行った時点又は当該通知が契約希望者に到達した時点で本契約が成立するものとします。
          </li>
          <li>
            前項の場合、当社は、審査に必要な書類の提出を求めることがあり、契約希望者は、当該書類を速やかに提出するものとします。契約希望者が当該書類を提出しない場合、以下の各号に該当する場合、その他当社の定める審査基準に適合しない場合には、当社は、契約希望者の申込みを拒否することができるものとします。
            <ul className={classes.ul}>
              <li>
                当社に提供された情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
              </li>
              <li>
                当該契約希望者が、本契約を締結するための法的権利又は地位を有しない場合
              </li>
              <li>
                当該契約希望者が、本サービス又は当社の別のサービスの利用に際して、債務不履行又は契約違反を行っている場合
              </li>
              <li>
                反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味し、以下も同様とします）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っている、又は過去にこれらに該当していたと当社が判断した場合
              </li>
              <li>その他、当社が登録を適当でないと判断した場合</li>
            </ul>
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第６条（本サービスの提供）</Box>
        <ul className={classes.ul}>
          <li>当社は、本サービスを、善良なる管理者の注意をもって行います。</li>
          <li>
            当社及び契約者は、実際に行われる本サービスの内容にかかわらず、別途両者間で合意しない限り、当社が本サービスを民法第656条の準委任契約として契約者より受託し、本契約に明記がない事項については、準委任にかかる法律上の規律が適用されることを確認します。
          </li>
          <li>
            当社及び契約者は、両当事者が一旦合意した事項（発注書兼発注請書の内容を含みますが、これに限りません）を事後的に変更することが必要である場合があることに鑑み、一方当事者より変更の要請があったときは、速やかに協議に応じなければなりません。
          </li>
          <li>
            当社は、当社の責任において、契約者の承諾なく、本サービスの全部又は一部を第三者へ再委託することができます。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第７条（本サービスの内容）</Box>
        <ul className={classes.ul}>
          <li>
            本サービスの具体的な内容は、別途当社と契約者との間で合意する発注書兼発注請書（これらの前提となる見積書を含みます。）に記載のとおりとします。
          </li>
          <li>
            契約者は、当社から本サービスの提供完了の通知を受けた場合又は納品物の提供を受けた場合には、速やかに本サービスの内容又は納品物を検収し、当社に対してその結果を連絡するものとします。当該通知又は納品物の提供から3営業日以内に当社に対して異議を述べなかった場合、検収は完了したものとみなされます。
          </li>
          <li>
            契約者は以下の事項を了承の上、本サービスを利用するものとします。
            <ul className={classes.ul}>
              <li>
                本サービスについて、当社に起因又は関連しない不具合が発生する場合があること
              </li>
              <li>前号の不具合について、当社は一切の責任を負わないこと</li>
            </ul>
          </li>
          <li>
            当社は、別途合意しない限り、契約者に対して以下のサービス及び物品を提供しないものとします。
            <ul className={classes.ul}>
              <li>
                ソフトウェア及びハードウェアに関する問い合わせ及び障害対応等
              </li>
              <li>データの記憶媒体、その他の消耗品</li>
              <li>本サービスに係るデータの内容、変更等に関する問い合わせ</li>
            </ul>
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          第８条（本サービスの変更、追加、廃止及び中断等）
        </Box>
        <ul className={classes.ul}>
          <li>
            当社は、以下各号の事由が生じた場合には、契約者に事前に通知することなく、本サービスの一部又は全部を一時的に中断することができます。
            <ul className={classes.ul}>
              <li>電気通信事業者の役務が提供されない場合</li>
              <li>
                当社の使用するサーバその他のシステム機器に著しい負荷や障害が与えられることによって正常なサービスを提供することが困難な場合
              </li>
              <li>天災等の不可抗力により本サービスの提供が困難な場合</li>
              <li>
                火災、停電、その他の不慮の事故により本サービスの提供が困難な場合
              </li>
              <li>
                戦争、紛争、動乱、暴動、労働争議等により本サービスの提供が困難な場合
              </li>
              <li>その他前各号に準じ当社が必要と判断した場合</li>
            </ul>
          </li>
          <li>
            当社は、前項に基づく本サービスの中断による損害について、当社に故意又は重過失のない場合、一切の責任を負わないものとします。また、本サービスの中断中も本サービスに対する報酬は発生するものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第９条（報酬）</Box>
        <ul className={classes.ul}>
          <li>
            本サービスは原則として有料で提供するものとし、具体的な報酬その他の料金、費用等（以下「報酬等」といいます）は発注書兼発注請書記載のとおりとし、その他適宜個別契約を締結して定めるものとします。
          </li>
          <li>
            契約者は、報酬等を、別途当社の指定する銀行口座に振り込む方法により支払うものとします。支払いに要する手数料は契約者の負担とします。
          </li>
          <li>
            前項に従い当社に対し支払われた報酬等は、本サービスの提供が完了した場合は、いかなる理由（本契約の途中での解約又は解除が行われた場合を含みますが、これらに限られません）があっても返還を行わないものとし、契約者はこれに異議を述べないものとします。
          </li>
          <li>
            契約者が本条に基づく報酬等その他の本契約に基づく金銭債務の支払いを遅延したときは、当該時点で当社に対する全ての債務について期限の利益を失うものとし、支払期日の翌日から支払済みに至るまで、年14.6%（年365日日割計算）の割合による遅延損害金を支払うものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          第３章　契約者の責任等
        </Box>
        <Box fontWeight="fontWeightBold">第10条（契約者の義務）</Box>
        <ul className={classes.ul}>
          <li>
            契約者は、契約者情報に変更があった場合には、遅滞なく当社に対して書面により報告するものとします。
          </li>
          <li>
            契約者は、前項の報告を怠ったことにより当社からの通知が不到達となった場合、当該通知は通常到達すべき時に到達したとみなされることを予め異議なく承諾するものとします。契約者が前項の変更を怠ったことにより契約者又は第三者に生じた損害について、当社は一切責任を負わないものとします。
          </li>
          <li>
            契約者は、契約者が本サービスにおいて提供、伝送するデータ等については、自らの費用と責任で同一のデータをバックアップとして保存しておくものとし、本契約に基づき当社がデータのバックアップに関するサービスを提供する場合を除き、当社はかかるデータの保管、保存、バックアップ等に関して、一切責任を負わないものとします。
          </li>
          <li>
            契約者は、本契約に関して、当社又は第三者に対して損害を与えた場合には、自己の責任と費用をもって処理及び解決するものとします。また、契約者の行為により第三者から当社が損害賠償請求を受けた場合には、契約者は当社に対し、当社に生じた損害及び費用（合理的範囲内の弁護士費用等、間接的な損害及び費用を含みます）を補償するものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第11条（禁止事項）</Box>
        <div className={classes.paragraph}>
          契約者は、以下の行為を行ってはならないものとします。契約者が以下の行為を行った場合、当社は、契約者に対して、当該行為により当社に生じた一切の損害等の賠償を求めることができるものとします。
        </div>
        <ul className={classes.ul}>
          <li>
            当社による本サービスの提供に支障をきたす行為、又はそれらのおそれのある行為
          </li>
          <li>
            他の契約者、第三者、及び当社の著作権、プライバシーその他の権利を侵害する行為、又はそれらのおそれのある行為
          </li>
          <li>
            公序良俗に反する行為その他法令に違反する行為、又はそれらのおそれのある行為
          </li>
          <li>当社又は契約者が所属する業界団体の内部規則に違反する行為</li>
          <li>
            コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
          </li>
          <li>本サービスに関し利用し又は利用しうる情報を改ざんする行為</li>
          <li>
            当社が定める一定のデータ容量以上のデータを当社又は当社の使用するサーバ等に送信する行為
          </li>
          <li>その他当社が不適当と判断する行為</li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          第12条（本サービスの中断及び解除）
        </Box>
        <ul className={classes.ul}>
          <li>
            当社は、契約者が下記の事項の何れか一つにでも該当することが判明した場合、本サービスを中断し、又は本契約を催告なく解除することができるものとし、契約者は予め異議無くこれを承諾するものとします。
            <ul className={classes.ul}>
              <li>
                契約者が本契約に違反する行為を行い、当社が相当な期間を定めて催告を行ったにもかかわらず、改善されなかった場合
              </li>
              <li>契約者が本契約の重大な義務に違反した場合</li>
              <li>
                本サービスの提供上、必要であると当社が合理的に判断した場合
              </li>
              <li>
                当社が指定する決済方法の不正使用が判明し又は決済サービス会社より契約者の決済を停止若しくは無効扱いとされた場合
              </li>
              <li>契約者情報に虚偽の事実があることが判明した場合</li>
              <li>手段の如何を問わず、本サービスの提供を妨害した場合</li>
              <li>
                支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
              </li>
              <li>
                自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
              </li>
              <li>
                差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
              </li>
              <li>租税公課の滞納処分を受けた場合</li>
              <li>
                反社会的勢力等である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
              </li>
              <li>その他前各号に類する事由があると当社が判断した場合</li>
            </ul>
          </li>
          <li>
            当社は、本条に基づく本サービスの中断又は解除及びこれらに伴う行為により契約者その他の第三者に生じた損害につき、一切責任を負わず、本サービスの中断期間中も当社に対する報酬は発生するものとします。
          </li>
          <li>
            本条に基づく解除は将来に向かってのみ効力を生じ、契約者は当社に対して当該解除の効力発生日までに生じた報酬等を支払う義務を負い、他方で当社は既に契約者から受領している報酬等を返還する義務を負いません。
          </li>
          <li>
            本条に基づく解除は、契約者に対する損害賠償請求を妨げないものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box
          mb={1}
          fontWeight="fontWeightBold"
          display="flex"
          justifyContent="center"
        >
          第4章　一般条項
        </Box>
        <Box fontWeight="fontWeightBold">第13条（秘密保持）</Box>
        <ul className={classes.ul}>
          <li>
            本契約において「秘密情報」とは、本契約に関連して、当社又は契約者が、相手方より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、相手方の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。
          </li>
          <li>
            当社及び契約者は、秘密情報を本契約の履行の目的のみに利用するとともに、相手方の書面による承諾なしに第三者(当社の委託先を含みません)に当社の秘密情報を提供、開示又は漏洩しないものとします。
          </li>
          <li>
            前項の定めに拘わらず、当社又は契約者は、法令等、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知しなければなりません。
          </li>
          <li>
            当社及び契約者は、相手方から求められた場合にはいつでも、遅滞なく、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。ただし、次条第4項に基づき当社が既に廃棄した情報を除きます。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">
          第14条（個人情報及び契約者情報等の取り扱い）
        </Box>
        <ul className={classes.ul}>
          <li>
            当社は、契約者から受領する個人情報を、別途当社が定めるプライバシーポリシーに従って適切に取り扱います。
          </li>
          <li>
            当社は、契約者が当社に提供した契約者情報（個人情報を含みます）を、当社の裁量で、本サービスの提供及び運用、サービス内容の改良及び向上、本サービスの利用状況の把握等の目的のために利用し、又は個人を特定できない形での統計的な情報として、企業に対する提案又はコンサルティング、新サービスの開発その他の目的のために利用することができるものとします。
          </li>
          <li>
            当社は、契約者の名称を当社の取引企業として公開することができるものとします。この場合、当社は取引の存在を公開するために必要な範囲で契約者のロゴ及び商標等を無償で使用できるものとします。
          </li>
          <li>
            当社は、本契約の終了後も契約者情報を削除又は保存する義務を負うものではなく、本契約の終了後も、当社は契約者情報を利用することができ、またいつでも契約者情報を削除できるものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第15条（著作権等の帰属）</Box>
        <ul className={classes.ul}>
          <li>
            本サービスにおいて、当社が契約者に提供するコンテンツに関する著作権その他一切の知的財産権（著作権法第27条及び第28条の権利を含みます）は、契約者又は第三者が本契約締結前から有していたものを除き、当社又は当社に権利の使用を許諾したライセンサーに帰属します。契約者は、著作権法に定められた範囲を超えて、当社及びライセンサーの許諾を得ずにコンテンツの翻訳、編集及び改変等を行い、又は第三者に使用させたり公開したりすることはできません。
          </li>
          <li>
            契約者が前項の規定に違反して問題が生じた場合、契約者は、自己の費用と責任において問題を解決するものとします。
          </li>
          <li>
            当社ホームページ上には商標、ロゴ及びサービスマーク等が表示される場合がありますが、当社は、本契約により契約者その他の第三者に対し何ら当該商標の使用を許諾するものではありません。
          </li>
          <li>
            契約者は、本サービスに関わる発明その他の知的財産に関し特許権、実用新案権、意匠権、商標権の出願を申請したり、著作権に関する登録を申請したりしてはならないものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第16条（有効期間等）</Box>
        <div className={classes.paragraph}>
          本契約の有効期間は、発注書兼発注請書その他個別契約に別段の定めがない限り、本契約の成立日から第7条第2項に基づき検収が完了する日までとします。
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第17条（契約者の解約）</Box>
        <ul className={classes.ul}>
          <li>
            契約者は、前条第1項の有効期間中、当社の書面による承諾を得ることなく、中途で本契約を解約することはできません。
          </li>
          <li>
            契約者は、当社の承諾を得て中途で本契約を解約する場合、解約日までに、契約者は、前条第1項に定める契約期間が終了するまでの報酬等のうち既払い分を除く全残額を当社に支払うものとします。当該支払が完了するまでは、本契約は終了しないものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第18条（責任の範囲）</Box>
        <ul className={classes.ul}>
          <li>
            契約者は、本サービスの提供を受けることが、契約者又は当社に適用のある法令、第三者との契約に違反しないこと、これらの者の権利を侵害するものでないことを自己の責任と費用に基づいて調査及び確認するものとします。
          </li>
          <li>
            本サービスの提供を受けるための契約者の準備状況の不具合に起因又は関連して、本サービスが遅延したり、十分に提供されなかったりした場合も、当社は一切責任を負わないものとします。
          </li>
          <li>
            本契約の履行又は本サービスの提供に関連して、当社又は契約者と第三者との間において生じたトラブル、連絡、紛争等については、契約者の責任と費用において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
          </li>
          <li>
            当社は、天災地変、戦争、暴動、内乱、テロリズム、重大な疾病、感染症リスク若しくはこれらに類するもの、争議行為その他当社の責めに帰すことのない事由に起因又は関連する本サービスの提供に関する債務不履行について、契約者に対して責任を負わないものとします。
          </li>
          <li>
            当社は、当社による本契約の履行若しくは本サービスの提供、又はこれらの中断、停止、終了、変更、契約者情報の削除又は消失、その他本契約若しくは本サービスに起因又は関連して契約者が被った損害（債務不履行責任に基づくものに限らず、不法行為責任その他の法律上の請求原因に基づくものを含む。次項においても同じ）につき、当社に故意又は重過失のない場合、賠償する責任を一切負わないものとします。
          </li>
          <li>
            当社に故意又は重過失がある場合その他特別法の適用等の理由で、当社が契約者に対して損害賠償責任を負う場合も、当社の賠償責任は、直接かつ現実に被った通常損害に限られ、かつ当該損害が発生する原因となった取引に係る発注書兼発注請書記載の取引金額を上限とします。当社は、かかる場合も、契約者又は第三者に発生した機会逸失、業務の中断その他の損害（間接損害や逸失利益を含みます）に対して、損害の可能性を事前に通知されていたとしても、一切の責任を負わないものとします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第19条（本契約上の地位の譲渡等）</Box>
        <ul className={classes.ul}>
          <li>
            契約者は、当社の書面による事前の承諾なく、本契約上の地位又は本契約に基づく権利義務の全部若しくは一部を、第三者に貸与、譲渡（合併、会社分割等による包括承継も含みますが、これらに限られません）、名義変更し、又は担保の目的に供することはできません。
          </li>
          <li>
            当社は、当社が本契約についての事業を他社に承継させる場合（事業譲渡、合併、会社分割による場合を含みますが、これらに限られません）には、本契約上の地位、本契約に基づく権利及び義務ならびに契約者情報その他の顧客情報を譲受人に承継させることができるものとし、契約者は、かかる承継について予め同意したものとみなします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第20条（分離可能性及び有効性）</Box>
        <ul className={classes.ul}>
          <li>
            本規約のいずれかの条項又は一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、当該判断は他の部分に影響を及ぼさず、継続して完全に効力を有するものとします。当社及び契約者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
          </li>
          <li>
            本規約のいずれかの条項又は一部が、ある契約者との関係で無効とされ、又は取り消された場合でも、当該条項又は一部は、その他の契約者との関係では有効とします。
          </li>
        </ul>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第21条（存続規定等）</Box>
        <div className={classes.paragraph}>
          第７項第3項、第8条第2項、第9条第2項乃至第4項、第10条第2項乃至第4項、第12条第2項乃至第4項、第13条乃至第15条、第18条第2項乃至第6項、及び第19条乃至第24条の規定は本契約の終了後も有効に存続するものとします。
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第22条（準拠法）</Box>
        <div className={classes.paragraph}>
          本契約及びこれに関する一切の事項については、日本国法を準拠法とし、本契約は、日本国法に従って解釈されるものとします。
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第23条（合意管轄裁判所）</Box>
        <div className={classes.paragraph}>
          本契約に起因又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </div>
      </Box>
      <Box mb={2}>
        <Box fontWeight="fontWeightBold">第24条（誠実協議）</Box>
        <div className={classes.paragraph}>
          本契約に関連して、契約者と当社の間で問題が生じた場合、両者は誠意を持って協議するものとする。
        </div>
      </Box>
      <Box mb={2}>
        <div>制定日：2021年12月1日</div>
      </Box>
    </Container>
  )
}
