import { PutApi } from "./AbstractApiFactory"
import { ResponseType } from "../types/Common"
import { ROLE } from "../types/AdminUser"

// プロフィールを設定します。
export class PutProfileApi extends PutApi<ResponseType<string>> {
  constructor(
    protected readonly data: { [key in "displayName" | "password"]: string } & {
      role: ROLE
    },
    protected readonly token?: string,
    private readonly id?: string
  ) {
    super(data, token)
  }
  // override
  protected getUrl(): string {
    return `/profile/${this.id}`
  }
}
