import { useReducer } from "react"
import { SortOrder } from "../../../types/Common"
import { SaaStainerUserSortKey } from "../../../types/SaaStainerUser"

/**
 * SaaStainerUserListStateは、アプリユーザーの検索条件の状態を表します。
 * @property keyword キーワード検索に使用する文字列。
 * @property sortKey ソートに使用する項目のキーを表す列挙型。
 * @property sortOrder ソート順を表す昇順または降順のいずれかの文字列。
 * @property page 検索結果のページ番号。
 */
export type SaaStainerUserListState = {
  keyword: string // キーワード検索
  sortKey: SaaStainerUserSortKey // ソートする項目
  sortOrder: SortOrder // ソート順
  page: number // ページネーション
  isStrategitExcluded: boolean // strategitドメインをリストから除外
}

/**
 * Actionは、SaaStainerUserListStateの更新に対応するアクションを表します。
 * @typedef keyword キーワード検索に使用する文字列を更新するアクション。
 * @property {string} payload.keyword キーワード検索に使用する文字列。
 * @typedef sortkey ソートに使用する項目を更新するアクション。
 * @property {ProductCodeSortKey} payload.sortKey ソートに使用する項目のキーを表す列挙型。
 * @typedef sortorder ソート順を更新するアクション。
 * @property {SortOrder} payload.sortOrder ソート順を表す昇順または降順のいずれかの文字列。
 * @typedef page ページ番号を更新するアクション。
 * @property {number} payload.page 検索結果のページ番号。
 * @property {boolean} payload.isStrategitExcluded strategitドメインをリストから除外するかどうか。
 */
export type SaaStainerUserListAction =
  | {
      type: "keyword"
      payload: { keyword: string }
    }
  | {
      type: "sortkey"
      payload: { sortKey: SaaStainerUserSortKey }
    }
  | {
      type: "sortorder"
      payload: { sortOrder: SortOrder }
    }
  | {
      type: "page"
      payload: { page: number }
    }
  | {
      type: "isStrategitExcluded"
      payload: { isStrategitExcluded: boolean }
    }

/**
 * SaaStainerUserListReducerは、SaaStainerUserListPage.tsxで利用されるカスタムフックです。
 * SaaStainerUserListStateの状態と、その状態を更新するためのdispatch関数を返します。
 * @returns {{ state: AdminUserListState, dispatch: (value: AdminUserListAction) => void }} SaaStainerUserListStateの状態と、その状態を更新するためのdispatch関数。
 */
const reducer = (
  state: SaaStainerUserListState,
  action: SaaStainerUserListAction
) => {
  const next = { ...state }
  if (action.type !== "page") next.page = 1

  switch (action.type) {
    case "keyword":
      next.keyword = action.payload.keyword
      break
    case "sortkey":
      next.sortKey = action.payload.sortKey
      break
    case "sortorder":
      next.sortOrder = action.payload.sortOrder
      break
    case "page":
      next.page = action.payload.page
      break
    case "isStrategitExcluded":
      next.isStrategitExcluded = action.payload.isStrategitExcluded
      break
  }

  return next
}

// 検索条件の初期値
export const saastainerUserListInitialState: SaaStainerUserListState = {
  keyword: "",
  sortKey: "createdAt",
  sortOrder: "desc",
  page: 1,
  isStrategitExcluded: true,
}

/*
 * SaaStainerUser検索ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useSaaStainerUserListReducer = () => {
  const [saastainerUserListState, saastainerUserListDispatch] = useReducer(
    reducer,
    saastainerUserListInitialState
  )
  return { saastainerUserListState, saastainerUserListDispatch }
}
