import React, { useEffect } from "react"
import { ProductCodeEditAction } from "../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { ProductCodeIssueAction } from "../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { SaleAddAction } from "../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import { SaleEditAction } from "../../../contexts/SalesManagement/reducers/useSaleEditReducer"
import { Interval, intervals } from "../../../types/Sale"
import { SimpleSelectInput } from "./SimpleSelectInput"

type ActionType =
  | ProductCodeIssueAction
  | ProductCodeEditAction
  | SaleAddAction
  | SaleEditAction

type Props<T extends ActionType> = {
  dispatch: React.Dispatch<T>
  initialIntervalValue?: Interval
}

/**
 * 請求間隔の数値入力とインターバル選択するコンポーネント
 */
export const BillingIntervalInput = <T extends ActionType>({
  dispatch,
  initialIntervalValue = "month",
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "interval", payload: initialIntervalValue } as T)
  }, [initialIntervalValue])

  /**
   * 選択された請求間隔を設定する
   * @param value 選択された請求間隔
   */
  const handleIntervalChange = (value: Interval) => {
    dispatch({ type: "interval", payload: value } as T)
    if (value === "none") {
      dispatch({ type: "intervalCount", payload: 0 } as T)
    } else {
      dispatch({ type: "intervalCount", payload: 1 } as T)
    }
  }

  /**
   * 選択肢を描画する
   * @param value 選択されたロール
   */
  const renderOptions = () => {
    return intervals.map((v) => {
      return (
        <option key={v.key} value={v.key}>
          {v.name}
        </option>
      )
    })
  }

  return (
    <SimpleSelectInput
      onValueChange={(value: unknown) => {
        if (typeof value === "string") {
          handleIntervalChange(value as Interval)
        }
      }}
      renderOptions={renderOptions}
      initialValue={initialIntervalValue}
    />
  )
}
