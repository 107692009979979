import React, { useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalSystemButtonDeleteActionStyle,
  globalRedAsterisk,
} from "../../globalStyles"
import { SalesManagementContext } from "../../../contexts/SalesManagement/SalesManagementContextProvider"
import { useHistory } from "react-router-dom"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { useSnackbar } from "notistack"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import { PostSaleApi } from "../../../api/SaleApi"
import { AppSuggestInput } from "../../uiParts/Common/AppSuggestInput"
import { CompanySuggestInput } from "../../uiParts/Common/CompanySuggestInput"
import { PaymentDueDatePicker } from "../../uiParts/Sales/common/PaymentDueDatePicker"
import { PaymentAmountInput } from "../../uiParts/Sales/common/PaymentAmountInput"
import {
  SaleAddAction,
  SaleAddState,
} from "../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import { CompanyUserList } from "../../uiParts/ProductCode/common/CompanyUserList"
import { PayerMailAddressInput } from "../../uiParts/Sales/common/PayerMailAddressInput"
import { RelatedInfoInput } from "../../uiParts/Sales/common/RelatedInfoInput"
import { PaymentMethodInput } from "../../uiParts/Sales/common/PaymentMethodInput"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { BillingIntervalInput } from "../../uiParts/Common/BillingIntervalInput"

/**
 * 販売データ追加ページコンポーネント
 */
export const SaleAddPage = ({ scope }: PageAccessPermissions) => {
  const { saleAddState, saleAddDispatch } = useContext(SalesManagementContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { adminAuthState } = useContext(AdminAuthContext)
  const [readOnly, setReadOnly] = useState(false)
  const history = useHistory()

  // 初回処理
  useEffect(() => {
    const authorized = scope ? scope.write : false
    setReadOnly(!authorized)
    // cleanup
    return () => {
      saleAddDispatch({ type: "init" })
    }
  }, [])

  const { enqueueSnackbar } = useSnackbar()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.goBack()
  }

  // 「登録」ボタンが押された時の処理
  const handleRegistButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 登録関数定義
    const registAction = async () => {
      if (!saleAddState.errors.length) {
        try {
          const result = await new PostSaleApi(
            saleAddState.app!._id!,
            saleAddState.company!.id!,
            saleAddState.amount!,
            saleAddState.paidAt!,
            saleAddState.interval!,
            saleAddState.intervalCount!,
            saleAddState.paymentMethod!,
            saleAddState.payerEmail!,
            saleAddState.relatedInfo!,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(`販売データ(${result.data.payload})を登録しました`, {
            variant: "success",
            preventDuplicate: true,
          })
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
          return
        }
      } else {
        enqueueSnackbar("入力が不十分です", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    setDialogMessage("販売データを登録しますか？")
    showDialog(() => registAction)
  }

  const styles = createStyles("20px")()

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>販売データ登録</h3>
      </div>
      <div className={styles.inputLeftArea}>
        <label className={styles.requiredField}>アプリ名</label>
        <AppSuggestInput<SaleAddAction, SaleAddState>
          dispatch={saleAddDispatch}
          state={saleAddState}
        />
        <label className={styles.requiredField}>企業名</label>
        <CompanySuggestInput<SaleAddAction, SaleAddState>
          dispatch={saleAddDispatch}
          state={saleAddState}
        />
        <label className={styles.requiredField}>{"単価(税抜)"}</label>
        <PaymentAmountInput<SaleAddAction> dispatch={saleAddDispatch} />
        <label className={styles.requiredField}>本番開始日</label>
        <PaymentDueDatePicker<SaleAddAction> dispatch={saleAddDispatch} />
        <label className={styles.requiredField}>支払い者メールアドレス</label>
        <PayerMailAddressInput<SaleAddAction> dispatch={saleAddDispatch} />
      </div>
      <div className={styles.userlistArea}>
        <CompanyUserList
          employer={saleAddState.company?.employerEmailAddr}
          employee={saleAddState.company?.employeeEmailAddrs}
        />
      </div>
      <div className={styles.inputRightArea}>
        <label>支払い方法（編集不可）</label>
        <PaymentMethodInput dispatch={saleAddDispatch} />
        <label className={styles.requiredField}>請求間隔</label>
        <BillingIntervalInput<SaleAddAction> dispatch={saleAddDispatch} />
      </div>
      <div className={styles.relatedInfoArea}>
        <label>関連情報</label>
        <RelatedInfoInput dispatch={saleAddDispatch} />
      </div>

      <div className={styles.buttonArea}>
        <div id="buttons">
          <button
            type="button"
            className="arrow"
            onClick={handleCancelButtonClick}
          >
            戻る
            <img src={button_white_arrow} />
          </button>
          <button
            type="button"
            className={readOnly ? "actionDisabled" : "action"}
            onClick={handleRegistButtonClick}
            disabled={readOnly}
          >
            登録
            <AddCircleIcon />
          </button>
        </div>
      </div>

      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={handleDialogClose}
        message={dialogMessage}
      />
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea       titleArea      " auto
                       "inputLeftArea   userlistArea   " 1fr
                       "inputLeftArea   inputRightArea " auto
                       "relatedInfoArea relatedInfoArea" auto
                       "buttonArea     buttonArea      " auto
                       / 1fr 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
  }

  // 入力エリア(左)
  const inputLeftArea = {
    gridArea: "inputLeftArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // 入力エリア(右)
  const inputRightArea = {
    gridArea: "inputRightArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
  }

  // 企業ユーザーリスト表示エリア
  const userlistArea = {
    gridArea: "userlistArea",
  }

  // 関連情報表示エリア
  const relatedInfoArea = {
    gridArea: "relatedInfoArea",
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "600px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
    "& div[id=buttons] .delete": {
      ...globalSystemButtonDeleteActionStyle,
    },
    "& div[id=buttons] .deleteDisabled": {
      ...globalSystemButtonDeleteActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputLeftArea: { ...inputLeftArea },
    inputRightArea: { ...inputRightArea },
    userlistArea: { ...userlistArea },
    relatedInfoArea: { ...relatedInfoArea },
    buttonArea: { ...buttonArea },
    requiredField: { ...globalRedAsterisk },
  }))
}
