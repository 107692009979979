export type SaleType = {
  id: string
  companyOid: string
  companyName: string
  appOid: string
  appName: string
  employerEmail: string
  employeeEmails: string[]
  amount: number
  paidAt: number
  paymentMethod: PaymentMethodType
  payerEmail: string
  interval: Interval
  intervalCount: number
  relatedInfo: string
}

export type PaymentMethodType = "stripe" | "other"

// インターバル型
export type Interval = "month" | "year" | "none"

// Interval型のオブジェクトの定義
type IntervalObject = {
  key: Interval
  name: string
}

// Interval型の配列の定義
export const intervals: IntervalObject[] = [
  { key: "none", name: "１回払い" },
  { key: "month", name: "月" },
  { key: "year", name: "年" },
]

// ソートキー
export type SalesSortKey = keyof Pick<
  SaleType,
  "companyName" | "appName" | "paidAt"
>

export type GroupByPaymentMethod = {
  startJSTEpoch: number
  endJSTEpoch: number
  allPaymentMethodsTotal: number
  stripeTotal: number
  otherTotal: number
}

export type GroupByApp = {
  allAppsTotal: number
  startUTCEpoch: number
  endUTCEpoch: number
  appSales: {
    appOid: string
    appName: string
    totalAmount: number
  }[]
  paymentMethodSales: {
    stripe: number
    other: number
  }
}
