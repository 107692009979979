import React, { useContext, useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import { makeStyles, Theme } from "@material-ui/core"
import { CompanyType } from "../../../types/Company"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import {
  GetCompaniesByNameApi,
  GetCompanyByIdApi,
} from "../../../api/CompanyApi"
import { PaginableResponse } from "../../../types/Common"
import { SuggestInput } from "./SuggestInput"
import {
  ProductCodeIssueAction,
  ProductCodeIssueState,
} from "../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { ProductCodeEditAction } from "../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import {
  SaleAddAction,
  SaleAddState,
} from "../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import {
  SaleEditAction,
  SaleEditState,
} from "../../../contexts/SalesManagement/reducers/useSaleEditReducer"

type ActionType =
  | ProductCodeIssueAction
  | ProductCodeEditAction
  | SaleAddAction
  | SaleEditAction
type StateType = ProductCodeIssueState | SaleAddState | SaleEditState
type Props<T extends ActionType, S extends StateType> = {
  dispatch: React.Dispatch<T>
  state: S | undefined
  initialCompanyOid?: string
}
/**
 * 企業の提案を入力するコンポーネント
 */
export const CompanySuggestInput = <T extends ActionType, S extends StateType>({
  dispatch,
  state,
  initialCompanyOid,
}: Props<T, S>) => {
  const styles = useStyles()()
  const [company, setCompany] = useState<CompanyType>()

  /**
   * 編集ページの場合は初期表示する企業を販売管理のステートにする
   */
  useEffect(() => {
    const getCompany = async () => {
      if (!initialCompanyOid) return
      try {
        const company = await new GetCompanyByIdApi(
          initialCompanyOid,
          adminAuthState.token
        ).sendRequest()
        dispatch({ type: "company", payload: company.data.payload } as T)
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }
    getCompany()
  }, [initialCompanyOid])

  /**
   * 編集ページの場合は初期表示する企業をSuggestInputコンポーネントの
   * プロップスに渡すため、ローカルのステートを更新する
   */
  useEffect(() => {
    setCompany(state?.company)
  }, [state?.company])

  /**
   * 選択された企業を設定する
   * @param company 選択された企業
   */
  const handleSuggestionSelect = (company: CompanyType | undefined) => {
    dispatch({ type: "company", payload: company } as T)
  }

  /**
   * 表示用の値を取得する
   * @param company 選択された企業
   */
  const getDisplayValue = (company: CompanyType) => {
    return company.name
  }

  const { adminAuthState } = useContext(AdminAuthContext)
  const { enqueueSnackbar } = useSnackbar()

  /**
   * 企業名で提案を取得する関数を返す
   * @param token 認証トークン
   * @returns 企業名で提案を取得する関数
   */
  const getSuggestions = (token?: string) => async (
    inputValue: string,
    page: number = 1
  ) => {
    try {
      const result = await new GetCompaniesByNameApi(
        inputValue,
        page,
        token
      ).sendRequest()
      const paginableResp: PaginableResponse<CompanyType[]> = {
        payload: result.data.payload,
        pageInfo: result.data.pageInfo,
      }
      return paginableResp
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response
        ? errorObj.response.data
        : errorObj.message

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
      })
      return undefined
    }
  }

  /**
   * 提案をレンダリングする関数
   * @param suggestion レンダリングする提案
   * @returns 提案のレンダリング結果
   */
  const renderSuggestion = <T extends CompanyType>(suggestion: T) => {
    return (
      <div className={styles.suggesutionArea}>
        <div className={styles.companyNameArea}>{suggestion.name}</div>
        <div className={styles.employerEmailAddrArea}>
          {suggestion.employerEmailAddr}
        </div>
      </div>
    )
  }

  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  const getPlaceholder = () => {
    return "企業名を入力しサジェストから選択してください"
  }

  return (
    <div>
      <SuggestInput<CompanyType>
        getSuggestions={getSuggestions(adminAuthState.token)}
        renderSuggestion={renderSuggestion}
        onSuggestionSelect={handleSuggestionSelect}
        getDisplayValue={getDisplayValue}
        getPlaceholder={getPlaceholder}
        initialValue={company}
      />
    </div>
  )
}

const useStyles = () => {
  const suggestionArea = {
    display: "grid",
    gap: "10px",
    gridTemplate: `"company_name employer_email_addr" auto
            / 1fr 1fr`,
  }

  const companyNameArea = {
    gridArea: "company_name",
  }

  const employerEmailAddrArea = {
    gridArea: "employer_email_addr",
  }

  return makeStyles((_: Theme) => ({
    suggesutionArea: { ...suggestionArea },
    companyNameArea: { ...companyNameArea },
    employerEmailAddrArea: { ...employerEmailAddrArea },
  }))
}
