/**
 * UTC日付を整形して表示用に整形したJST日付を返します
 */
export const formatDateToJstString = (date: Date) => {
  const jstDateString = date.toLocaleString("ja-JP", {
    timeZone: "Asia/Tokyo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })

  return jstDateString
}
