import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import sasnyan from "../../images/sasnyan.png"
import button_white_arrow from "../../images/button_white_arrow.png"
import {
  globalSystemButtonArrowStyle,
  globalTextboxStyle,
} from "../globalStyles"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { LoginApi } from "../../api/LoginApi"
import { useHistory } from "react-router-dom"
import { AdminAuthContext } from "../../contexts/AdminAuthManagement/AdminAuthContext"
import { useForm } from "react-hook-form"
import { useSnackbar } from "notistack"

/**
 * ログインページ
 * 機能：
 *   ・ログインAPIへのリクエストとレスポンス（管理者ユーザーデータ）受け取り
 *   ・ログイン成功時に受け取った管理者ユーザーデータをコンテキスト(AdminAuthContext)に保存
 *   ・ログイン失敗時などのメッセージ出力（notistackライブラリによるスナックバー出力）
 */
export const LoginPage = () => {
  type FormData = {
    username: string
    password: string
  }

  const onSubmit = async (data: FormData) => {
    try {
      const result = await new LoginApi({
        username: data.username,
        password: data.password,
      }).sendRequest()

      adminAuthDispatch({
        type: "refresh",
        data: {
          payload: result.data.payload,
          token: result.data.token,
        },
      })

      history.push("/admin/dashboard")
    } catch (err) {
      const errorObj = err as any
      const errorMessage = errorObj.response
        ? errorObj.response.data
        : errorObj.message

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
      })
    }
  }

  const onPwdProtectionClick = () => {
    setPwdProtection((prev) => !prev)
  }

  const { register, handleSubmit, errors } = useForm<FormData>({
    criteriaMode: "all",
    reValidateMode: "onSubmit",
  })

  const [pwdProtection, setPwdProtection] = useState(true)
  const { adminAuthDispatch } = useContext(AdminAuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const styles = createStyles()()

  useEffect(() => {
    errors.username &&
      enqueueSnackbar("ユーザー名の入力は必須です", {
        variant: "warning",
        preventDuplicate: true,
      })
  }, [errors.username])

  useEffect(() => {
    errors.password &&
      enqueueSnackbar("パスワードの入力は必須です", {
        variant: "warning",
        preventDuplicate: true,
      })
  }, [errors.password])

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.input}>
          <div className={styles.image}>
            <img src={sasnyan} />
          </div>
          <div className={styles.title}>
            <h3>Sign in</h3>
          </div>
          <div className={styles.username}>
            <input
              name="username"
              type="text"
              maxLength={256}
              placeholder={"メールアドレス"}
              ref={register({ required: true })}
            />
          </div>
          <div className={styles.password}>
            <input
              name="password"
              type={pwdProtection ? "password" : "text"}
              maxLength={256}
              placeholder={"パスワード"}
              ref={register({ required: true })}
            />
            <button type="button" onClick={onPwdProtectionClick}></button>
            {pwdProtection ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>
          <button type="submit" className={styles.button}>
            sign in
            <img src={button_white_arrow} />
          </button>
        </div>
      </form>
    </div>
  )
}

// スタイル作成
const createStyles = () => {
  // ルートエリア
  const rootArea = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  }

  // 入力エリア
  const inputArea = {
    width: "320px",
    padding: "10px",
    border: 0,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
    overflow: "hidden",
    display: "grid",
    gap: "20px",
    gridTemplate: `"image   " auto
             "title   " 50px
             "username" 50px
             "password" 50px
             "button  " auto
            / 320px`,
  }

  // 画像エリア
  const imageArea = {
    gridArea: "image",
    backgroundColor: "#ffffff",
    "& img": {
      width: "100%",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "title",
    borderTop: "2px solid #fa7d3d",
    borderImage: "linear-gradient(to right, #fa7d3d 0%, #fec044 100%)",
    borderImageSlice: 1,
    textAlign: "center" as const,
  }

  // ユーザー名エリア
  const usernameArea = {
    gridArea: "username",
    ...globalTextboxStyle,
  }

  // パスワードエリア
  const passwordArea = {
    gridArea: "password",
    ...globalTextboxStyle,
  }
  // ボタンエリア
  const buttonArea = {
    gridArea: "button",
    margin: "15px 0px",
    ...globalSystemButtonArrowStyle,
  }
  return makeStyles((_: Theme) => ({
    root: { ...rootArea },
    input: { ...inputArea },
    image: { ...imageArea },
    title: { ...titleArea },
    username: { ...usernameArea },
    password: { ...passwordArea },
    button: { ...buttonArea },
  }))
}
