import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { SnackbarProvider } from "notistack"
import React, { ReactNode, useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { AdminAuthContext } from "../../contexts/AdminAuthManagement/AdminAuthContext"
import logo_saastainer from "../../images/logo_saastainer.png"
import { baseColorButtonStyle } from "../globalStyles"
import {
  PieChart,
  ExitToApp,
  VerifiedUser,
  Email,
  AccountCircle,
  CardGiftcard,
  BarChart,
  Apps,
  Group,
} from "@material-ui/icons"
import { AdminBaseLayoutContext } from "../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"

/*
レイアウト画面：
  管理画面全ページの基本レイアウトです。
機能：  
  ・サイドバー、パンくずリスト、読み書き権限表示、コンテンツ(各ページの内容)の表示領域を定義します。
  ・各ページから送られてきたエラーメッセージなどを監視してスナックバーに出力します。(SnackbarProvider)
*/
export const AdminBaseLayout = ({ children }: { children: ReactNode }) => {
  const { adminAuthState, adminAuthDispatch } = useContext(AdminAuthContext)
  const history = useHistory()
  const styles = createStyles()()
  const [title, setTitle] = useState(navItems[0].text)
  const { circularProgressState } = useContext(AdminBaseLayoutContext)

  const onNavItemClick = (_event: React.MouseEvent<HTMLAnchorElement>) => (
    path: string,
    title: string
  ) => {
    //console.log(path)
    setTitle(title)
    history.push(path, title)
  }

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <div className={styles.root}>
        {adminAuthState.token ? (
          <div className={styles.drawer}>
            <div className={styles.logo}>
              <a onClick={() => history.push("/admin/dashboard")}>
                <img src={logo_saastainer} />
              </a>
            </div>
            <div className={styles.profile}>
              <AccountCircle id="avator_icon" style={{ color: "#536381" }} />
              <div className="chips">
                <span>{adminAuthState.payload.displayName}</span>
                <VerifiedUser id="verified_icon" />
                <span id="role">{adminAuthState.payload.role}</span>
              </div>
              <div className="email">
                <Email id="email_icon" />
                <span id="email">{adminAuthState.payload.email}</span>
              </div>
              <div className="settings_logout_button">
                <button
                  type="button"
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  logout
                  <ExitToApp />
                </button>
              </div>
            </div>
            <div className={styles.menu}>
              {navItems.map((item) => {
                return (
                  <a
                    key={item.path}
                    style={
                      item.text === title
                        ? { backgroundColor: "#6D8891", color: "white" }
                        : undefined
                    }
                    onClick={(event) =>
                      onNavItemClick(event)(item.path, item.text)
                    }
                  >
                    {item.text === title ? item.selectedIcon : item.icon}
                    {item.text}
                  </a>
                )
              })}
            </div>
          </div>
        ) : null}
        <div className={styles.contents}>{children}</div>
      </div>
      <Backdrop
        className={styles.backdrop}
        open={circularProgressState.display}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </SnackbarProvider>
  )
}

// ナビゲーションメニューアイテム
const navItems = [
  {
    text: "ダッシュボード",
    icon: <PieChart color="secondary" />,
    selectedIcon: <PieChart style={{ color: "orange" }} />,
    path: "/admin/dashboard",
  },
  {
    text: "SaaStainer登録者管理",
    icon: <Group color="secondary" />,
    selectedIcon: <Group style={{ color: "orange" }} />,
    path: "/admin/saastaineruser-management/list",
  },
  {
    text: "アプリ管理",
    icon: <Apps color="secondary" />,
    selectedIcon: <Apps style={{ color: "orange" }} />,
    path: "/admin/app-management/list",
  },
  {
    text: "プロダクトコード管理",
    icon: <CardGiftcard color="secondary" />,
    selectedIcon: <CardGiftcard style={{ color: "orange" }} />,
    path: `/admin/productcode-management/list`,
  },
  {
    text: "販売管理",
    icon: <BarChart color="secondary" />,
    selectedIcon: <BarChart style={{ color: "orange" }} />,
    path: "/admin/sale-management/list",
  },
  {
    text: "管理者ユーザー管理",
    icon: <VerifiedUser color="secondary" />,
    selectedIcon: <VerifiedUser style={{ color: "orange" }} />,
    path: "/admin/adminuser-management/list",
  },
  {
    text: "プロフィール設定",
    icon: <AccountCircle color="secondary" />,
    selectedIcon: <AccountCircle style={{ color: "orange" }} />,
    path: "/admin/profile",
  },
]

// スタイル作成
const createStyles = () => {
  // ルートエリア
  const rootArea = {
    margin: "0 auto",
    width: "95vw",
    minHeight: "95vh",
    border: "2px solid gray",
    borderRadius: "10px",
    display: "grid",
    gridTemplate: `"drawer contents" auto
      / auto 1fr`,
  }

  // ドロワーエリア
  const drawerArea = {
    gridArea: "drawer",
    display: "grid" as const,
    padding: "0 10px 10px 10px",
    backgroundColor: "#293c50",
    gridTemplate: `"logo   " 100px
       "profile" auto
       "menu   " 1fr
      / minmax(250px,auto)`,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  }

  // ロゴエリア
  const logoArea = {
    gridArea: "logo",
    display: "flex" as const,
    justifyContent: "center" as const,
    alignItems: "center" as const,
    "& img": {
      width: "164px",
      height: "26px",
    },
    "& a": {
      textDecoration: "none" as const,
      filter: "drop-shadow(3px 3px 3px #4b2612)",
      cursor: "pointer" as const,
    },
  }

  // プロファイルエリア
  const profileArea = {
    gridArea: "profile",
    padding: "30px 10px 30px 10px",
    position: "relative" as const,
    backgroundColor: "#ffffff",
    borderRadius: "10px 10px 0 0",
    "&::before": {
      content: "''",
      position: "absolute" as const,
      top: "-18px",
      left: "calc(100% / 2 - 20px)",
      width: "40px",
      height: "20px",
      borderRadius: "100px 100px 0 0",
      background: "#ffffff",
    },
    display: "flex" as const,
    flexDirection: "column" as const,
    "& svg[id=avator_icon]": {
      position: "absolute" as const,
      top: "-15px",
      left: "calc(100% /2 - 15px)",
      height: "auto",
      width: "30px",
    },
    "& .chips": {
      display: "flex" as const,
      fontWeight: "bold" as const,
      fontSize: "13px",
      "& svg[id=verified_icon]": {
        height: "auto",
        width: "13px",
      },
      "& span[id=role]": {
        color: "blue",
      },
    },
    "& .email": {
      display: "flex" as const,
      fontSize: "12px",
      color: "gray",
      "& svg[id=email_icon]": {
        height: "auto",
        width: "13px",
      },
    },
    "& .settings_logout_button": {
      display: "flex" as const,
      paddingTop: "20px",
      height: "100%",
      justifyContent: "center" as const,
      alignItems: "center" as const,
      gap: "10px",
      "& button": {
        ...baseColorButtonStyle("100%", "30px", "15px", "20px"),
      },
    },
  }

  // メニューエリア
  const menuArea = {
    // position: "relative" as const,
    padding: "30px 0 10px 0",
    gridArea: "menu",
    display: "flex" as const,
    flexDirection: "column" as const,
    backgroundColor: "#536381",
    borderRadius: "0 0 10px 10px",
    "& a": {
      display: "flex" as const,
      alignItems: "center" as const,
      height: "30px",
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      color: "#bcbdcc",
      fontSize: "12px",
      boxSizing: "border-box" as const,
      textDecoration: "none",
      backgroundColor: "#536381",
      transition: ".2s cubic-bezier(0.45, 0, 0.55, 1)",
      "& svg": {
        display: "inline-block",
        width: "18px",
        height: "18px",
        margin: "0 10px 0 10px",
        verticalAlign: "middle",
        color: "#bcbdcc",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#7BA3CC",
      },
      cursor: "pointer" as const,
    },
  }

  // コンテンツエリア
  const contentsArea = {
    gridArea: "contents",
    backgroundColor: "#CFD8DC",
    height: "100%",
    borderRadius: "10px",
  }

  return makeStyles((theme: Theme) => ({
    root: { ...rootArea },
    drawer: { ...drawerArea },
    logo: { ...logoArea },
    profile: { ...profileArea },
    menu: { ...menuArea },
    contents: { ...contentsArea },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }))
}
