import React from "react"
import AlertDialog from "./AlertDialog"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import UndoIcon from "@material-ui/icons/Undo"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import useStyles from "../styles/employee"
import { ListEmployeeProps } from "../typings/companies"
import { useState } from "react"
import { useTranslation } from "react-i18next"
const ROWS_PER_PAGE = 10

export default function ListEmployee(props: ListEmployeeProps) {
  const { employees, clickSelectHandler, clickDeleteHandler, isEdit } = props

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [employeeId, setEmployeeId] = useState<null | string>(null)
  const [page, setPage] = useState(0)
  const numberOfPages = Math.ceil(employees.length / ROWS_PER_PAGE)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const classes = useStyles()

  const clickSelect = (id: string) => {
    if (employeeId) {
      setEmployeeId(null)
    } else {
      setEmployeeId(id)
    }
    setEmployeeId(id)
    clickSelectHandler(id)
  }

  const clickDelete = (id: string) => {
    setEmployeeId(id)
    setIsOpen(true)
  }

  const handleClose = (canceled: boolean) => {
    if (!canceled && employeeId !== null) {
      clickDeleteHandler(employeeId)
    }
    setIsOpen(false)
    setEmployeeId(null)
  }

  return (
    <>
      {employees.length ? (
        <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>{t("listEmployee.email")}</TableCell>
                  <TableCell>{t("listEmployee.lastName")}</TableCell>
                  <TableCell>{t("listEmployee.firstName")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees
                  .slice(
                    page * ROWS_PER_PAGE,
                    page * ROWS_PER_PAGE + ROWS_PER_PAGE
                  )
                  .map((employee) => (
                    <TableRow key={employee.id}>
                      <TableCell>{employee.email}</TableCell>
                      <TableCell>{employee.lastName}</TableCell>
                      <TableCell>{employee.firstName}</TableCell>
                      <TableCell align="right">
                        {isEdit && employeeId === employee.id ? (
                          <Tooltip title={t<string>("listEmployee.unSelect")}>
                            <IconButton
                              color="inherit"
                              onClick={() => clickSelect(employee.id)}
                            >
                              <UndoIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={t<string>("listEmployee.select")}>
                            <IconButton
                              color="inherit"
                              onClick={() => clickSelect(employee.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title={t<string>("listEmployee.delete")}>
                          <IconButton
                            color="inherit"
                            onClick={() => clickDelete(employee.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              count={employees.length}
              component="div"
              rowsPerPageOptions={[ROWS_PER_PAGE]}
              rowsPerPage={ROWS_PER_PAGE}
              labelDisplayedRows={({ from, to, count }) =>
                `${page + 1}/${numberOfPages}`
              }
              page={page}
              onChangePage={handleChangePage}
            />
          </TableContainer>

          <AlertDialog
            open={isOpen}
            title={t("listEmployee.confirmDelete")}
            contentText={t("listEmployee.confirmDeleteText")}
            handleClose={handleClose}
          />
        </>
      ) : (
        <Box display="flex" mx="auto" mt={5}>
          <Typography className={classes.noEmployees}>
            {t("listEmployee.noEmployees")}
          </Typography>
        </Box>
      )}
    </>
  )
}
