import { useReducer } from "react"
import { AppType } from "../../../types/App"
import { ErrorData } from "../../../types/Common"
import { CompanyType } from "../../../types/Company"
import { Interval } from "../../../types/Sale"

/**
 * プロダクトコード発行と編集ページの状態管理
 * @property app アプリ
 * @property company　企業
 * @property licenseCount ライセンス数
 * @property amount 金額
 * @property interval サブスクリプションの請求頻度（単発の場合はnone）
 * @property intervalCount 「interval」属性で指定された期間の数
 * @property trialPeriodDays トライアル日数
 * @property usageStartDate 利用開始日
 * @property usageExpirationDate　利用期限
 * @property agency　代理店
 * @property agencyfee　代理店手数料
 */
export type ProductCodeIssueState = {
  app: AppType | undefined
  company: CompanyType | undefined
  licenseCount: number | undefined
  amount: number | undefined
  interval: Interval | undefined
  intervalCount: number | undefined
  trialPeriodDays: number | undefined
  usageStartedAt: Date | undefined
  usageExpireIn: Date | undefined
  agency: string | undefined
  agencyfee: number | undefined
  errors: ErrorData<ProductCodeIssueState>[]
}

/**
 * プロダクトコード発行ページのアクション型
 */
export type ProductCodeIssueAction =
  | {
      type: "app"
      payload: AppType | undefined
    }
  | {
      type: "company"
      payload: CompanyType | undefined
    }
  | {
      type: "licenseCount"
      payload: number | undefined
    }
  | {
      type: "amount"
      payload: number | undefined
    }
  | {
      type: "interval"
      payload: Interval | undefined
    }
  | {
      type: "intervalCount"
      payload: number | undefined
    }
  | {
      type: "trialPeriodDays"
      payload: number | undefined
    }
  | {
      type: "usageStartedAt"
      payload: Date | undefined
    }
  | {
      type: "usageExpireIn"
      payload: Date | undefined
    }
  | {
      type: "agency"
      payload: string | undefined
    }
  | {
      type: "agencyfee"
      payload: number | undefined
    }
  | {
      type: "addErrors"
      payload: ErrorData<ProductCodeIssueState>
    }
  | {
      type: "removeErrors"
      payload: Omit<ErrorData<ProductCodeIssueState>, "message">
    }
  | {
      type: "init"
    }

/**
 * 状態と状態を更新するためのdispatch関数を返します。
 * @returns {{ state: ProductCodeIssueState, dispatch: (value: ProductCodeIssueAction) => void }}
 */
const reducer = (
  state: ProductCodeIssueState,
  action: ProductCodeIssueAction
) => {
  let next = { ...state }
  switch (action.type) {
    case "app":
      next.app = action.payload
      break
    case "company":
      next.company = action.payload
      break
    case "licenseCount":
      next.licenseCount = action.payload
      break
    case "amount":
      next.amount = action.payload
      break
    case "interval":
      next.interval = action.payload
      break
    case "intervalCount":
      next.intervalCount = action.payload
      break
    case "trialPeriodDays":
      next.trialPeriodDays = action.payload
      break
    case "usageStartedAt":
      next.usageStartedAt = action.payload
      break
    case "usageExpireIn":
      next.usageExpireIn = action.payload
      break
    case "agency":
      next.agency = action.payload
      break
    case "agencyfee":
      next.agencyfee = action.payload
      break
    case "addErrors":
      if (
        !next.errors.some(
          (e) =>
            e.type === action.payload.type &&
            e.errorCode === action.payload.errorCode
        )
      ) {
        next.errors.push(action.payload)
      }
      break
    case "removeErrors":
      next.errors = next.errors.filter(
        (v) =>
          !(
            v.type === action.payload.type &&
            v.errorCode === action.payload.errorCode
          )
      )
      break
    case "init":
      next = productCodeIssueInitialState
      break
  }

  return next
}

// ステートの初期値
export const productCodeIssueInitialState: ProductCodeIssueState = {
  app: undefined,
  company: undefined,
  licenseCount: undefined,
  amount: undefined,
  interval: undefined,
  intervalCount: undefined,
  trialPeriodDays: undefined,
  usageStartedAt: undefined,
  usageExpireIn: undefined,
  agency: undefined,
  agencyfee: undefined,
  errors: [],
}

/*
 * ProductCode発行ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useProductCodeIssueReducer = () => {
  const [productCodeIssueState, productCodeIssueDispatch] = useReducer(
    reducer,
    productCodeIssueInitialState
  )
  return { productCodeIssueState, productCodeIssueDispatch }
}
