import React from "react"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import { Link as LinkBehavior } from "react-router-dom"

// TODO
export function RouterLink(props: any) {
  return <Link component={LinkBehavior} {...props} />
}

// TODO
export function RouterButton(props: any) {
  return <Button component={LinkBehavior} {...props} />
}
