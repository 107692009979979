import React, { useEffect } from "react"

export default function Privacy() {
  useEffect(() => {
    // 外部のURLにリダイレクト
    window.location.href = "https://www.strategit.jp/privacy"
  }, [])
  return (
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "1.5rem" }}
    >
      リダイレクト中...
    </div>
  )
}
