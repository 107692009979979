import React, { ChangeEvent, useEffect, useState } from "react"
import * as FormTextField from "./FormTextField"
import Grid from "@material-ui/core/Grid"
import { FormSubmitOAuthButtton } from "./FormSubmitButton"
import { SignInStyles } from "../styles/form"
import { AUTHORIZE_KEYS } from "../typings/oauth"
import { useTranslation } from "react-i18next"

function FormOAuth() {
  const classes = SignInStyles()

  const { t } = useTranslation()

  const [hasError, setError] = useState(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [inputParams, setParams] = useState<any>({})

  const setInputEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const setInputPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params: any = {}
    AUTHORIZE_KEYS.forEach((type) => {
      const value = urlParams.get(type)
      params[type] = value || ""
    })
    setParams(params)
    setError(urlParams.get("success") === "false")
  }, [])

  return (
    <form
      className={classes.form}
      noValidate
      method="post"
      action="/api/v1/oauth/authorize"
    >
      <Grid container spacing={2}>
        {AUTHORIZE_KEYS.map((key) => (
          <input
            key={key}
            readOnly
            type="hidden"
            name={key}
            value={inputParams.hasOwnProperty(key) ? inputParams[key] : ""}
          />
        ))}
        <FormTextField.OAuthAppEmailField
          changeHandler={setInputEmail}
          value={email}
          hasError={hasError}
          t={t}
        />
        <FormTextField.OAuthAppPasswordField
          changeHandler={setInputPassword}
          value={password}
          hasError={hasError}
          t={t}
        />
        <FormSubmitOAuthButtton submitClass={classes.submit} t={t} />
      </Grid>
    </form>
  )
}

export default FormOAuth
