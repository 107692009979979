import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AppSearchContext } from "../App"
import { getAllServices } from "../api/services"
import { IService } from "../typings/service"

/*
コンポーネントの説明：
  アプリ検索部品の１つ。サービス名を指定してアプリを検索するためのコンポーネント。
  servicesオブジェクトで定義したサービス名の数だけボタンを生成する。
  サブミット後はAppSearchContext上にあるステート(serviceName)を更新するのみで実際の検索処理は委譲する。
*/
export const SearchByService = React.memo(() => {
  // アプリ検索用のコンテキストからデータを取得
  const { state, dispatch } = useContext(AppSearchContext)

  // サービス関連リソースの保存
  const [services, setServices] = useState<IService[]>()

  // サービス関連データ（名前・画像URL）を取得
  useEffect(() => {
    getAllServices()
      .then((data) => {
        setServices(data)
      })
      .catch((err) => {
        throw err
      })
  }, [])

  // 国際化対応
  const { t } = useTranslation()

  // サービスボタン押下イベント
  const onServiceButtonClick = (serviceName: string) => {
    // 検索条件の更新（サービス名）
    dispatch({ type: "service", payload: { serviceName } })
  }

  // PC・SPで共通のスタイル
  const styles = useStyles()

  return (
    // PC・SPで共通のHTML構造
    <div className={styles.root}>
      <div className={styles.title}>
        <h3>{t("listApps.servicesHeader")}</h3>
      </div>
      <div className={styles.buttons}>
        {services?.map((service) => (
          <button
            className={
              state.serviceName === service.name
                ? `${styles.baseButton} ${styles.selectedButton}`
                : styles.baseButton
            }
            key={service.name}
            onClick={() => onServiceButtonClick(service.name)}
          >
            {service.name === "other" ? (
              t("listApps.servicesOther")
            ) : (
              <img src={service.url} alt={service.name} />
            )}
          </button>
        ))}
      </div>
    </div>
  )
})

// PC・SPで共通のスタイル
const useStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px",
    backgroundColor: "white",
  },
  // 検索タイトル
  title: {
    color: "#fa7d3d",
  },
  // ボタンエリア
  buttons: {
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "repeat(auto-fit, minmax(110px, 1fr))",
  },
  // 基本ボタン
  baseButton: {
    height: "60px",
    border: 0,
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(255, 198, 69, 0.4)",
    },
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  // 選択中ボタン
  selectedButton: {
    border: "solid 2px #FA7D3D",
  },
}))
