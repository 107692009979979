import { PostApi } from "./AbstractApiFactory"
import { AdminUserType } from "../types/AdminUser"
import { ResponseType } from "../types/Common"

export class LoginApi extends PostApi<ResponseType<AdminUserType>> {
  constructor(
    protected readonly data: { [key in "username" | "password"]: string }
  ) {
    super(data, undefined)
  }
  // override
  protected getUrl(): string {
    return "/signin"
  }
}
