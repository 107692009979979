import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { MediaQueryContext } from "../App"

/*
コンポーネントの説明：
  グローバルフッターコンポーネント。
*/
const GlobalFooter = React.memo(() => {
  // メディアクエリーをコンテキストから取得
  const { isPC, pcWidth, spWidth } = useContext(MediaQueryContext)

  // コンテキストからこのフッターの高さを取得
  const height = isPC ? "151px" : "299px"

  // 国際化対応
  const { t } = useTranslation()

  // 画面遷移用のフック
  const history = useHistory()

  // スタイル作成
  const styles = createStyles(isPC, pcWidth, spWidth, height)()

  return (
    <footer className={styles.footer}>
      <div className={styles.upperFooter}>
        {/* 運営会社 */}
        <a
          href="https://strategit.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("footer.company")}
        </a>
        {/* 特定商取引法に基づく表記 */}
        <a onClick={() => history.push("/trade")}>
          {t("footer.commercialTransAct")}
        </a>
        {/* 利用規約 */}
        <a onClick={() => history.push("/terms")}>
          {t("footer.termsOfService")}
        </a>
        {/* SaaStainer業務支援サービス利用規約 */}
        <a onClick={() => history.push("/supportServiceTerms")}>
          {t("footer.supportServiceTerms")}
        </a>
        {/* プライバシーポリシー */}
        <a
          href="https://www.strategit.jp/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("footer.privacy")}
        </a>
        {/* お問い合わせ */}
        <a
          href="https://marketing.saastainer.com/SaaStainer-userinfo"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("footer.inquiry")}
        </a>
      </div>
      {/* Copyright */}
      <div className={styles.lowerFooter}>
        <a
          href="https://strategit.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >{`Copyright © StrategIT ${new Date().getFullYear()}`}</a>
      </div>
    </footer>
  )
})

// スタイル定義
const createStyles = (
  isPC: boolean,
  pcWidth: string,
  spWidth: string,
  height: string
) => {
  // PCスタイル
  const pcStyles = makeStyles((_: Theme) => ({
    footer: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "start",
      alignItems: "center",
      width: pcWidth,
      height: height,
      margin: "0 auto",
      background:
        "linear-gradient(top left, rgba(250, 126, 61, 1), rgba(255, 198, 69, 1))",
    },
    upperFooter: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "space-between",
      marginTop: "30px",
      "& a": {
        color: "white",
        textDecoration: "none",
        padding: "20px",
        cursor: "pointer",
        whiteSpace: "normal",
      },
    },
    lowerFooter: {
      paddingTop: "20px",
    },
  }))

  // SPスタイル
  const spStyles = makeStyles((_: Theme) => ({
    footer: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      width: spWidth,
      height: height,
      margin: "0 auto",
      padding: "0px 20px",
      background:
        "linear-gradient(top left, rgba(250, 126, 61, 1), rgba(255, 198, 69, 1))",
    },
    upperFooter: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      "& a": {
        color: "white",
        textDecoration: "none",
        cursor: "pointer",
        marginBottom: "10px",
      },
    },
    lowerFooter: {
      display: "flex",
      justifyContent: "center",
      marginTop: "50px",
    },
  }))

  return isPC ? pcStyles : spStyles
}

export default GlobalFooter
