import { useReducer } from "react"
import { ROLE } from "../../../types/AdminUser"

/**
 * 管理者ユーザー追加の状態管理
 * @property diplayName 管理者の表示名
 * @property role 管理ページの権限
 * @property password 管理者のパスワード
 */
export type AdminUserAddState = {
  diplayName: string | undefined
  email: string | undefined
  role: ROLE | undefined
}

/**
 * 管理者ユーザー追加ページのアクション型
 */
export type AdminUserAddAction =
  | {
      type: "displayName"
      payload: string | undefined
    }
  | {
      type: "email"
      payload: string | undefined
    }
  | {
      type: "role"
      payload: ROLE | undefined
    }
  | {
      type: "init"
    }

/**
 * 状態と状態を更新するためのdispatch関数を返します。
 * @returns {{ state: AdminUserAddState, dispatch: (value: AdminUserAddAction) => void }}
 */
const reducer = (state: AdminUserAddState, action: AdminUserAddAction) => {
  let next = { ...state }
  switch (action.type) {
    case "displayName":
      next.diplayName = action.payload
      break
    case "email":
      next.email = action.payload
      break
    case "role":
      next.role = action.payload
      break
    case "init":
      next = adminUserAddInitialState
      break
  }

  return next
}

// ステートの初期値
export const adminUserAddInitialState: AdminUserAddState = {
  diplayName: undefined,
  email: undefined,
  role: undefined,
}

/*
 * AdminUser追加ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useAdminUserAddReducer = () => {
  const [adminUserAddState, adminUserAddDispatch] = useReducer(
    reducer,
    adminUserAddInitialState
  )
  return { adminUserAddState, adminUserAddDispatch }
}
