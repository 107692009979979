import React, { useEffect } from "react"
import { UserLayoutProps, EmployerLayoutProps } from "../typings"
import { AUTHORIZE_KEYS } from "../typings/oauth"

export default function OAuthRedirect(
  props: UserLayoutProps | EmployerLayoutProps
) {
  const { token } = props

  const userAction = "/api/v1/oauth/authorized"
  const urlParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    for (const key of AUTHORIZE_KEYS) {
      const inputs = document.getElementsByName(key)
      const value = urlParams.get(key)
      if (inputs.length && value !== null) {
        inputs[0].setAttribute("value", value)
      }
    }
    const form: any = document.getElementById("OAuthRedirect")
    if (form !== null) form.submit()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form id="OAuthRedirect" method="post" action={userAction}>
      <input type="hidden" name="access_token" value={token} />
      {AUTHORIZE_KEYS.map((key) => (
        <input key={key} type="hidden" name={key} value="" />
      ))}
    </form>
  )
}
