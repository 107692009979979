import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    paragraph: {
      paddingLeft: "20px",
    },
    ul: {
      margin: 0,
    },
    ol: {
      margin: 0,
    },
    parenthesesOl: {
      margin: "0",
      "& > li": {
        listStyleType: "none",
        counterIncrement: "cnt",
      },
      "& > li:before": {
        content: '"(" counter(cnt) ") "',
        display: "inline-block",
        marginLeft: "-37px",
        width: "37px",
        textAlign: "right",
      },
    },
  })
)
