import { PaginableResponse } from "../types/Common"
import { Service } from "../types/Service"
import { GetApi } from "./AbstractApiFactory"

// 全サービスを取得します。
export class GetServicesApi extends GetApi<PaginableResponse<Service[]>> {
  constructor(token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/services`
  }
}
