import React from "react"
import * as FormRegisters from "../typings/formRegisters"
import * as FormTextField from "./FormTextField"
import FormCheckbox from "./FormCheckbox"
import Grid from "@material-ui/core/Grid"
import { FormSubmitSignInButtton } from "./FormSubmitButton"
import { SignInParams } from "../typings/users"
import { SignInProps } from "../typings"
import { SignInStyles } from "../styles/form"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

export default function FormSignIn(props: SignInProps) {
  const { submitHandler } = props

  const classes = SignInStyles()

  const { t } = useTranslation()

  const { register, handleSubmit, errors } = useForm<SignInParams>()

  const emailRegister = FormRegisters.emailRegister(register)
  const requiredRegister = FormRegisters.requiredRegister(register)

  const onSubmit = (data: SignInParams) => {
    submitHandler(data)
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <FormTextField.SignInEmailField
          forwardRef={emailRegister}
          error={errors.email}
          t={t}
        />
        <FormTextField.SignInPasswordField
          forwardRef={requiredRegister}
          error={errors.password}
          t={t}
        />
        <FormCheckbox forwardRef={register} />
        <FormSubmitSignInButtton submitClass={classes.submit} t={t} />
      </Grid>
    </form>
  )
}
