import React, { useEffect } from "react"
import { ProductCodeEditAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { ProductCodeIssueAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { NumericInput } from "../../Common/NumericInput"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: number
}

/**
 * トライアル日数を入力するコンポーネント
 */
export const TrialPeriodInput = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  initialValue = 0,
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "trialPeriodDays", payload: initialValue } as T)
  }, [initialValue])

  /**
   * トライアル日数を設定する
   * @param value トライアル日数
   */
  const handleCountChange = (value: number) => {
    dispatch({ type: "trialPeriodDays", payload: value } as T)
  }

  return (
    <NumericInput
      onValueChange={handleCountChange}
      getPlaceholder={() => "トライアル日数を入力してください"}
      initialValue={initialValue}
    />
  )
}
