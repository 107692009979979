import React, { useEffect, useState } from "react"
import * as FormTextField from "./FormTextField"
import Grid from "@material-ui/core/Grid"
import { FormSubmitSignUpButton } from "./FormSubmitButton"
import { SignUpParams, ProfileData } from "../typings/users"
import { SignUpProps } from "../typings"
import { SignUpStyles } from "../styles/form"
import { useForm } from "react-hook-form"
import { useHistory, useLocation } from "react-router-dom"
import * as registers from "../typings/formRegisters"
import { useTranslation } from "react-i18next"
import FormCheckboxSignUp from "./FormCheckboxSignUp"
import { RouterLink } from "./RouterLink"
import { getSignUp } from "../api/users"

export default function FormSignUp(props: SignUpProps) {
  const { submitHandler, errorHandler } = props

  const classes = SignUpStyles()

  const history = useHistory()

  const { t } = useTranslation()

  const location = useLocation()

  const [profile, setProfile] = useState<ProfileData | null>(null)

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState,
    trigger,
  } = useForm<SignUpParams>()

  const companyIdRegister = registers.companyIdRegister(register)
  const emailRegister = registers.emailRegister(register)
  const requiredRegister = registers.requiredRegister(register)
  const passwordRegister = registers.passwordRegister(register, true)
  const confirmRegister = registers.passwordConfirmRegister(register, getValues)
  const checkboxRegisterPr = registers.checkboxRegisterPr(register)
  const checkboxRegisterMail = registers.checkboxRegisterMail(register)

  const validateConfirm = () => {
    if (formState.isSubmitted) {
      trigger("passwordConfirm")
    }
  }

  useEffect(() => {
    if (location.search) {
      getSignUp(location.search.replace("?userId=", ""))
        .then((profileData) => {
          setProfile(profileData)
        })
        .catch(errorHandler)
    }
  }, [])

  const onSubmit = (data: SignUpParams) => {
    submitHandler(data, location.search)
      .then(() => {
        history.push("/thanks")
      })
      .catch(errorHandler)
  }

  const makeSpaceId = (length: number) => {
    let result = ""
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
  }

  const spaceIdRandom = makeSpaceId(14)

  if (location.search.includes("userId=") && !profile) {
    return <></>
  }
  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <FormTextField.SignUpCompanyIdField
          forwardRef={companyIdRegister}
          error={errors.companyId}
          defaultValue={spaceIdRandom}
          t={t}
        />
        <FormTextField.SignUpCompanyNameField
          forwardRef={requiredRegister}
          error={errors.companyName}
          defaultValue={profile?.company?.name}
          t={t}
        />
        <FormTextField.SignUpLastNameField
          forwardRef={requiredRegister}
          error={errors.lastName}
          defaultValue={profile?.lastName}
          t={t}
        />
        <FormTextField.SignUpFirstNameField
          forwardRef={requiredRegister}
          error={errors.firstName}
          defaultValue={profile?.firstName}
          t={t}
        />
        <FormTextField.SignUpEmailField
          forwardRef={emailRegister}
          error={errors.email}
          defaultValue={profile?.email}
          t={t}
        />
        <FormTextField.SignUpPasswordField
          forwardRef={passwordRegister}
          error={errors.password}
          onChange={validateConfirm}
          t={t}
        />
        <FormTextField.SignUpPasswordConfirmField
          forwardRef={confirmRegister}
          error={errors.passwordConfirm}
          t={t}
        />
        <FormCheckboxSignUp
          name="mailList"
          forwardRef={checkboxRegisterMail}
          label={t("formCheckboxSignUp.noticeMail")}
          value={true}
        />
        <FormCheckboxSignUp
          name="privacyAgreement"
          forwardRef={checkboxRegisterPr}
          label={
            <a
              href="https://www.strategit.jp/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("formCheckboxSignUp.privacyAgreement")}
            </a>
          }
          value={true}
        />
        <FormCheckboxSignUp
          name="termsAccept"
          forwardRef={checkboxRegisterPr}
          label={
            <RouterLink target="_blank" to="/terms" color="primary">
              {t("formCheckboxSignUp.termsAccept")}
            </RouterLink>
          }
          value={true}
        />
        <FormSubmitSignUpButton submitClass={classes.submit} t={t} />
      </Grid>
    </form>
  )
}
