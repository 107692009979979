import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { IFormCheckbox } from "../typings"
import { useTranslation } from "react-i18next"

export default function FormCheckbox(props: IFormCheckbox) {
  const { forwardRef } = props
  const { t } = useTranslation()

  return (
    <FormControlLabel
      control={<Checkbox name="rememberMe" value color="primary" />}
      label={t("formCheckbox.saveLoginInfo")}
      inputRef={forwardRef}
    />
  )
}
