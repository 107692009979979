import React, { useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalRedAsterisk,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalSystemButtonDeleteActionStyle,
  globalTextboxStyle,
} from "../../globalStyles"
import { AppManagementContext } from "../../../contexts/AppManagement/AppManagementContextProvider"
import { useHistory } from "react-router-dom"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { useSnackbar } from "notistack"
import { CategoryId, categoryMap } from "../../../types/App"
import { Save, DeleteForever, CloudDownload } from "@material-ui/icons"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import {
  DeleteAppApi,
  GetAppByIdApi,
  GetAppUsersCsvApi,
  PutAppApi,
} from "../../../api/AppApi"
import { NumericInput } from "../../uiParts/Common/NumericInput"
import { SimpleSelectInput } from "../../uiParts/Common/SimpleSelectInput"
import { useTranslation } from "react-i18next"
import { SimpleTextAreaInput } from "../../uiParts/Common/SimpleTextAreaInput"
import { SimpleCheckBoxInput } from "../../uiParts/Common/SimpleCheckboxInput"
import SimpleRadioGroupInput from "../../uiParts/Common/SimpleRadioGroupInput"
import ServiceSelector from "../../uiParts/App/ServiceSelector"
import saveAs from "file-saver"
import { AdminBaseLayoutContext } from "../../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"

/**
 * アプリ編集ページコンポーネント
 */
export const AppEditPage = ({ scope }: PageAccessPermissions) => {
  type DialogValues = {
    isAgree: boolean
    action: (checked?: boolean) => unknown
    message: string
    checkboxLabel?: string
    checkboxValue?: boolean
  }

  const { appEditState, appEditDispatch } = useContext(AppManagementContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogValues, setDialogValues] = useState<DialogValues>({
    isAgree: false,
    action: () => {},
    message: "",
  })
  const [paidSettingOpen, setPaidSettingOpen] = useState(false)
  const { adminAuthState } = useContext(AdminAuthContext)
  const history = useHistory()

  // 初回処理
  useEffect(() => {
    if (!appEditState._id) {
      history.goBack()
      enqueueSnackbar("アプリIDを指定してください", {
        variant: "error",
        preventDuplicate: true,
      })
      return
    }

    const fetchData = async (id: string) => {
      try {
        const res = await new GetAppByIdApi(
          id,
          adminAuthState.token
        ).sendRequest()

        appEditDispatch({
          type: "update",
          payload: res.data.payload,
        })
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    fetchData(appEditState._id)
  }, [])

  useEffect(() => {
    setPaidSettingOpen(
      !appEditState.free || appEditState.soldOnlyViaProductCode
    )
  }, [appEditState.free, appEditState.soldOnlyViaProductCode])

  // ダイアログを閉じた時の処理
  useEffect(() => {
    if (dialogValues.isAgree) {
      dialogValues.action(dialogValues.checkboxValue)
      setDialogValues({ ...dialogValues, isAgree: false })
    }
  }, [dialogValues && dialogValues.isAgree === true])

  const { enqueueSnackbar } = useSnackbar()
  const { circularProgressDispatch } = useContext(AdminBaseLayoutContext)

  // 「戻る」ボタンが押された時の処理
  const handleCancelButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.goBack()
  }

  // 「保存」ボタンが押された時の処理
  const handleSaveButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 保存関数定義
    const saveAction = async () => {
      if (!appEditState._id) {
        enqueueSnackbar("アプリOIDが指定されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.appId) {
        enqueueSnackbar("アプリIDが入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.images.main) {
        enqueueSnackbar("メイン画像が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.aboutApp.summary) {
        enqueueSnackbar("日本語対応のアプリ概要が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.i18nAboutApp.en.summary) {
        enqueueSnackbar("英語対応のアプリ概要が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.name) {
        enqueueSnackbar("日本語対応のアプリ名が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.i18nNames.en) {
        enqueueSnackbar("英語対応のアプリ名が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.appId) {
        enqueueSnackbar("アプリIDが入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.category.name) {
        enqueueSnackbar("カテゴリーが入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (appEditState.service.length === 0) {
        enqueueSnackbar("サービスが入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.creator.name || !appEditState.creator.url) {
        enqueueSnackbar("提供元情報が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.helpUrl) {
        enqueueSnackbar("提供元情報が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.inquiryUrl) {
        enqueueSnackbar("お問い合わせURLが入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!appEditState.popularity) {
        enqueueSnackbar("人気順が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      try {
        const res = await new PutAppApi(
          appEditState._id,
          appEditState,
          adminAuthState.token
        ).sendRequest()

        history.goBack()

        enqueueSnackbar(`アプリ(${res.data.payload})を更新しました`, {
          variant: "success",
          preventDuplicate: true,
        })
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    setDialogValues({
      ...dialogValues,
      action: saveAction,
      message: "入力した内容で上書き保存しますか？",
      checkboxLabel: undefined,
    })
    setDialogOpen(true)
  }

  // 「削除」ボタンが押された時の処理
  const handleDeleteButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 削除関数定義
    const deleteAction = async () => {
      if (!appEditState?._id) {
        enqueueSnackbar("アプリOIDが指定されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      try {
        const result = await new DeleteAppApi(
          appEditState._id,
          adminAuthState.token
        ).sendRequest()

        history.goBack()

        enqueueSnackbar(`アプリ(${result.data.payload})を削除しました`, {
          variant: "success",
          preventDuplicate: true,
        })
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }
    setDialogValues({
      ...dialogValues,
      action: deleteAction,
      message: "アプリを削除しますか？",
      checkboxLabel: undefined,
    })
    setDialogOpen(true)
  }

  // 「ユーザーリスト」ボタンが押された時の処理
  const handleCreateAppUsersCsvButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // ユーザーリスト作成関数定義
    const createAppUsersCsvAction = async (checked?: boolean) => {
      if (!appEditState?.appId) {
        enqueueSnackbar("アプリIDが指定されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }
      try {
        circularProgressDispatch({ type: "on" })
        const res = await new GetAppUsersCsvApi(
          appEditState.appId,
          checked!,
          adminAuthState.token,
          {
            responseType: "blob",
          }
        ).sendRequest()

        const blob = new Blob([res.data], { type: "text/csv" })
        saveAs(blob, `${appEditState.appId}-users.csv`)

        enqueueSnackbar(`代表ユーザーのリストをダウンロードしました。`, {
          variant: "success",
          preventDuplicate: true,
        })
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      } finally {
        circularProgressDispatch({ type: "off" })
      }
    }

    setDialogValues({
      ...dialogValues,
      action: createAppUsersCsvAction,
      message: "代表ユーザーのリストをダウンロードしますか？",
      checkboxLabel: "メール配信希望ユーザーのみ",
    })

    setDialogOpen(true)
  }

  const styles = createStyles("20px")()
  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>アプリ編集</h3>
        <button
          type="button"
          className={appEditState.appUrl ? "action" : "actionDisabled"}
          onClick={handleCreateAppUsersCsvButtonClick}
          disabled={appEditState.appUrl ? false : true}
        >
          代表ユーザーリスト
          <CloudDownload />
        </button>
      </div>
      <div className={styles.inputArea}>
        <label className={styles.requiredField}>アプリID</label>
        <input
          type="text"
          value={appEditState.appId ?? ""}
          onChange={(event) =>
            appEditDispatch({ type: "appId", payload: event.target.value })
          }
        />
        <label className={styles.requiredField}>アプリ名 [ja]</label>
        <input
          type="text"
          value={appEditState.name ?? ""}
          onChange={(event) =>
            appEditDispatch({ type: "name", payload: event.target.value })
          }
        />
        <label className={styles.requiredField}>アプリ名 [en]</label>
        <input
          type="text"
          value={appEditState.i18nNames.en ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "i18nNames.en",
              payload: event.target.value,
            })
          }
        />
        <label className={styles.requiredField}>アプリ画像 [main]</label>
        <input
          type="text"
          value={appEditState.images.main ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "images.main",
              payload: event.target.value,
            })
          }
        />
        <label>アプリ画像 [sub]</label>
        <input
          type="text"
          value={appEditState.images.sub ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "images.sub",
              payload: event.target.value,
            })
          }
        />
        <label>アプリ画像 [tag]</label>
        <input
          type="text"
          value={appEditState.images.tag ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "images.tag",
              payload: event.target.value,
            })
          }
        />
        <label className={styles.requiredField}>アプリ概要 [ja]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.aboutApp.summary ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "aboutApp.summary",
              payload: v,
            })
          }
        />

        <label className={styles.requiredField}>アプリ概要 [en]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.i18nAboutApp.en.summary ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "i18nAboutApp.en.summary",
              payload: v,
            })
          }
        />

        <label>機能 [ja]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.aboutApp.feature ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "aboutApp.feature",
              payload: v,
            })
          }
        />

        <label>機能 [en]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.i18nAboutApp.en.feature ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "i18nAboutApp.en.feature",
              payload: v,
            })
          }
        />

        <label>おすすめな方 [ja]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.aboutApp.recommendedFor ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "aboutApp.recommendedFor",
              payload: v,
            })
          }
        />

        <label>おすすめな方 [en]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.i18nAboutApp.en.recommendedFor ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "i18nAboutApp.en.recommendedFor",
              payload: v,
            })
          }
        />

        <label>ご利用にあたっての注意 [ja]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.aboutApp.notes ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "aboutApp.notes",
              payload: v,
            })
          }
        />

        <label>ご利用にあたっての注意 [en]</label>
        <SimpleTextAreaInput
          initialValue={appEditState.i18nAboutApp.en.notes ?? ""}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "i18nAboutApp.en.notes",
              payload: v,
            })
          }
        />

        <label>アプリURL</label>
        <input
          type="text"
          value={appEditState.appUrl ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "appUrl",
              payload: event.target.value,
            })
          }
        />

        <label className={styles.requiredField}>提供者 [名称]</label>
        <input
          type="text"
          value={appEditState.creator.name ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "creator.name",
              payload: event.target.value,
            })
          }
        />

        <label className={styles.requiredField}>提供者 [URL]</label>
        <input
          type="text"
          value={appEditState.creator.url ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "creator.url",
              payload: event.target.value,
            })
          }
        />

        <label className={styles.requiredField}>ヘルプURL</label>
        <input
          type="text"
          value={appEditState.helpUrl ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "helpUrl",
              payload: event.target.value,
            })
          }
        />

        <label className={styles.requiredField}>カテゴリー</label>
        <SimpleSelectInput
          initialValue={appEditState.category.id}
          onValueChange={(value: unknown) => {
            const category = {
              id: value as CategoryId,
              name: categoryMap.find((v) => v.id == value)?.name ?? "unknown",
            }
            appEditDispatch({ type: "category", payload: category })
          }}
          renderOptions={() =>
            categoryMap.map((v) => {
              return (
                <option key={v.id} value={v.id}>
                  {v.name}
                </option>
              )
            })
          }
        />

        <label className={styles.requiredField}>サービス</label>
        <ServiceSelector
          initialSelectedIds={appEditState.service}
          onSelectionChange={(ids) =>
            appEditDispatch({
              type: "service",
              payload: ids,
            })
          }
        />

        <label className={styles.requiredField}>お問い合わせURL</label>
        <input
          type="text"
          value={appEditState.inquiryUrl ?? ""}
          onChange={(event) =>
            appEditDispatch({
              type: "inquiryUrl",
              payload: event.target.value,
            })
          }
        />
        <label className={styles.requiredField}>人気順</label>
        <NumericInput
          initialValue={appEditState.popularity}
          getPlaceholder={() => ""}
          onValueChange={(v) =>
            appEditDispatch({
              type: "popularity",
              payload: v,
            })
          }
        />

        <SimpleCheckBoxInput
          getLabel={() => "ストア公開"}
          initialValue={appEditState.display}
          onValueChange={(v) =>
            appEditDispatch({
              type: "display",
              payload: v,
            })
          }
        />

        <SimpleCheckBoxInput
          getLabel={() => "無料アプリ"}
          initialValue={!paidSettingOpen}
          onValueChange={(v) => {
            setPaidSettingOpen(!v)
            appEditDispatch({
              type: "free",
              payload: v,
            })
          }}
        />

        <div className={`config-accordion ${paidSettingOpen ? "open" : ""}`}>
          <SimpleCheckBoxInput
            getLabel={() => "プロダクトコード限定販売"}
            initialValue={appEditState.soldOnlyViaProductCode}
            onValueChange={(v) =>
              appEditDispatch({
                type: "soldOnlyViaProductCode",
                payload: v,
              })
            }
          />

          <label
            className={
              appEditState.soldOnlyViaProductCode ? "" : styles.requiredField
            }
          >
            StripeプロダクトID
          </label>
          <input
            type="text"
            value={appEditState.stripeProductId ?? ""}
            onChange={(event) =>
              appEditDispatch({
                type: "stripeProductId",
                payload: event.target.value,
              })
            }
          />

          <label
            className={
              appEditState.soldOnlyViaProductCode ? "" : styles.requiredField
            }
          >
            StripeプライスID
          </label>
          <input
            type="text"
            value={appEditState.stripePriceId ?? ""}
            onChange={(event) =>
              appEditDispatch({
                type: "stripePriceId",
                payload: event.target.value,
              })
            }
          />

          <label
            className={
              appEditState.soldOnlyViaProductCode ? "" : styles.requiredField
            }
          >
            Stripe消費税ID
          </label>
          <input
            type="text"
            value={appEditState.taxRateId ?? ""}
            onChange={(event) =>
              appEditDispatch({
                type: "taxRateId",
                payload: event.target.value,
              })
            }
          />

          <label
            className={
              appEditState.soldOnlyViaProductCode ? "" : styles.requiredField
            }
          >
            価格（税込）
          </label>
          <NumericInput
            initialValue={appEditState.amount}
            getPlaceholder={() => ""}
            onValueChange={(v) =>
              appEditDispatch({
                type: "amount",
                payload: v,
              })
            }
          />

          <label
            className={
              appEditState.soldOnlyViaProductCode ? "" : styles.requiredField
            }
          >
            トライアル日数
          </label>
          <NumericInput
            initialValue={appEditState.subscriptionData?.trialPeriodDays || 0}
            getPlaceholder={() => ""}
            onValueChange={(v) =>
              appEditDispatch({
                type: "subscriptionData.trialPeriodDays",
                payload: v,
              })
            }
          />

          <label
            className={
              appEditState.soldOnlyViaProductCode ? "" : styles.requiredField
            }
          >
            購入方式
          </label>
          <SimpleSelectInput
            initialValue={appEditState.mode}
            onValueChange={(v: unknown) => {
              appEditDispatch({
                type: "mode",
                payload: v as "payment" | "subscription",
              })
            }}
            renderOptions={() => [
              <option key="subscription" value="subscription">
                サブスクリプション
              </option>,
            ]}
            backgroundColor="#fff"
          />

          <SimpleRadioGroupInput
            radios={[
              { key: "monthly", label: "Monthly" },
              { key: "annually", label: "Annually" },
            ].map((v) => {
              return {
                key: v.key,
                label: v.label,
              }
            })}
            onRadioSelected={(key) => {
              appEditDispatch({
                type: "subscriptionData.billingCycle",
                payload: key as "monthly" | "annually",
              })
            }}
            initialSelectedKey={
              appEditState.subscriptionData?.billingCycle || "monthly"
            }
          />
        </div>
      </div>
      <div className={styles.buttonArea}>
        <div id="buttons">
          <button
            type="button"
            className="arrow"
            onClick={handleCancelButtonClick}
          >
            戻る
            <img src={button_white_arrow} />
          </button>
          <button
            type="button"
            className={readOnly ? "actionDisabled" : "action"}
            onClick={handleSaveButtonClick}
            disabled={readOnly}
          >
            保存
            <Save />
          </button>
          <button
            type="button"
            className={readOnly ? "deleteDisabled" : "delete"}
            onClick={handleDeleteButtonClick}
            disabled={readOnly}
          >
            削除
            <DeleteForever />
          </button>
        </div>
      </div>
      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={(isAgree: boolean, checked?: boolean) => {
          setDialogOpen(false)
          setDialogValues({
            ...dialogValues,
            isAgree,
            checkboxValue: checked,
          })
        }}
        message={dialogValues.message}
        checkboxLabel={dialogValues.checkboxLabel}
      />
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea " auto
             "inputArea " 1fr
             "buttonArea" auto
            / 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
    "& button.action": {
      ...globalSystemButtonActionStyle,
      width: "300px",
    },
    "& button.actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
      width: "300px",
    },
  }

  // 入力エリア
  const inputArea = {
    gridArea: "inputArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
    "& .config-accordion": {
      display: "flex",
      flexFlow: "column",
      gap: "10px",
      maxHeight: "0px",
      overflow: "hidden",
      transition: "max-height 0.3s ease-in-out",
    },
    "& .config-accordion.open": {
      maxHeight: "1000px", // これは設定項目のサイズに合わせて調整
      backgroundColor: "gainsboro",
      padding: "10px",
      borderRadius: "10px",
    },
    ...globalTextboxStyle,
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "600px",
    },
    position: "sticky" as const, // stickyポジションを適用
    bottom: 5, // 画面下部に固定
    zIndex: 100, // 他の要素より手前に表示
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
    "& div[id=buttons] .delete": {
      ...globalSystemButtonDeleteActionStyle,
    },
    "& div[id=buttons] .deleteDisabled": {
      ...globalSystemButtonDeleteActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputArea: { ...inputArea },
    buttonArea: { ...buttonArea },
    requiredField: { ...globalRedAsterisk },
  }))
}
