import React from "react"
import { deleteUsersApi, getUsersApi, postUsersApi, putUsersApi } from "./v1"
import {
  SignInParams,
  SignUpParams,
  ForgotPasswordParams,
  ResetPasswordParams,
  ProfileData,
  EmployerProfileData,
  SuccessRespond,
} from "../typings/users"

export async function sendSignIn(
  params: SignInParams
): Promise<SuccessRespond> {
  try {
    const res = await postUsersApi("signin", params)

    const { token, kind } = res.data.user

    return { token, kind }
  } catch (err) {
    throw err
  }
}

export async function getSignUp(
  userId: string
): Promise<ProfileData | EmployerProfileData> {
  try {
    const res = await getUsersApi("signup", null, userId)

    return res.data.user
  } catch (err) {
    throw err
  }
}

export async function sendSignUp(
  params: SignUpParams,
  search: string
): Promise<void> {
  try {
    const pageUrl = search.length ? `signup${search}` : `signup`

    await postUsersApi(pageUrl, params)
  } catch (err) {
    throw err
  }
}

export async function sendForgotPassword(
  params: ForgotPasswordParams
): Promise<void> {
  try {
    await postUsersApi("forgotpassword", params)
  } catch (err) {
    throw err
  }
}

export async function sendResetPassword(
  params: ResetPasswordParams,
  search: string
): Promise<void> {
  try {
    await postUsersApi(`resetpassword${search}`, params)
  } catch (err) {
    throw err
  }
}

export async function sendSignOut(token: string): Promise<void> {
  try {
    await deleteUsersApi("signout", null, token)
  } catch (err) {
    throw err
  }
}

export async function getProfile(
  token: string
): Promise<ProfileData | EmployerProfileData> {
  try {
    const res = await getUsersApi("profile", null, token)

    return res.data.user
  } catch (err) {
    throw err
  }
}

export async function updateProfile(
  token: string,
  profileData: ProfileData
): Promise<ProfileData | undefined> {
  try {
    const res = await putUsersApi("profile", profileData, token)

    return res.data.user
  } catch (err) {
    throw err
  }
}

export function useGetSignInFlag(): Boolean {
  try {
    const [signInFlag, signInFlagSet] = React.useState(false)
    React.useEffect(() => {
      const storage = window.sessionStorage
      const token = storage.getItem("token")
      const kind = storage.getItem("kind")
      const isSignedIn =
        typeof token === "string" &&
        typeof kind === "string" &&
        token !== "" &&
        kind !== ""
      signInFlagSet(isSignedIn)
    }, [signInFlag])
    return signInFlag
  } catch (err) {
    throw err
  }
}
