export enum MessageTypes {
  EMAIL_IS_TAKEN,
  COMPANY_ID_IS_TAKEN,
  INVALID_SIGNIN_REQUEST,
  INVALID_HUBSPOT_USER,
  INVALID_TOKEN,
}

export function getMessage(messageType: MessageTypes): string {
  switch (messageType) {
    case MessageTypes.EMAIL_IS_TAKEN:
      return "メールアドレスは登録済みです"
    case MessageTypes.COMPANY_ID_IS_TAKEN:
      return "企業IDは登録済みです"
    case MessageTypes.INVALID_SIGNIN_REQUEST:
      return "メールアドレスかパスワードが正しくありません"
    case MessageTypes.INVALID_HUBSPOT_USER:
      return "招待リンクのユーザIDは無効です"
    case MessageTypes.INVALID_TOKEN:
      return "トークンは無効です"
    default:
      throw new Error("unknown message types")
  }
}
