import { makeStyles, Theme } from "@material-ui/core"
import React, { useEffect, useState } from "react"

type Props = {
  getPlaceholder: () => string
  onValueChange: (value: string) => void
  initialValue?: string
  readonly?: boolean
}

/**
 * シンプルな角丸テキストエリアコンポーネント
 */
export const SimpleTextAreaInput = ({
  onValueChange,
  getPlaceholder,
  initialValue = "",
  readonly = false,
}: Props) => {
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  /**
   * テキストエリアの値が変更されたときの処理
   * @param event イベントオブジェクト
   */
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
    onValueChange(event.target.value)
  }

  const styles = useStyles()

  return (
    <div className={styles.root}>
      <textarea
        value={value}
        onChange={handleChange}
        placeholder={getPlaceholder()}
        readOnly={readonly}
      />
    </div>
  )
}

const useStyles = makeStyles((_: Theme) => ({
  root: {
    "& textarea": {
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      border: "1px solid gray",
      padding: "10px",
      overflow: "clip",
      resize: "horizontal",
      transition: "all 0.1s ease-in",
      "&::placeholder": {
        fontSize: "12px",
      },
      "&:focus": {
        outline: "none",
        border: "2px solid gray",
      },
    },
  },
}))
