import { DeleteApi, GetApi, PostApi, PutApi } from "./AbstractApiFactory"
import { AdminUserType, AdminUserSortKey, ROLE } from "../types/AdminUser"
import { PaginableResponse, ResponseType, SortOrder } from "../types/Common"

// IDで指定した管理者ユーザーを1人取得します。
export class GetAdminUserApi extends GetApi<ResponseType<AdminUserType>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/adminuser/${this.id}`
  }
}

// 全管理者ユーザーまたはキーワードに一致する管理者ユーザーを取得します。
export class GetAdminUsersApi extends GetApi<
  PaginableResponse<AdminUserType[]>
> {
  constructor(
    private readonly page: number,
    private readonly sortKey: AdminUserSortKey,
    private readonly sortOrder: SortOrder,
    private readonly keyword?: string,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    const query = `&searchKeyword=${this.encode(this.keyword)}`
    return `/adminusers/?page=${this.page}&sortKey=${this.sortKey}&sortOrder=${this.sortOrder}${query}`
  }
}

// 管理者ユーザーを作成します。
export class PostAdminUserApi extends PostApi<ResponseType<string>> {
  constructor(
    data: { displayName: string; email: string; role: ROLE },
    token?: string
  ) {
    super(data, token)
  }
  // override
  protected getUrl(): string {
    return "/adminusers"
  }
}

// 管理者ユーザーを編集します。
export class PutAdminUserApi extends PutApi<ResponseType<string>> {
  constructor(
    private readonly id: string,
    data: { displayName: string; role: ROLE; password?: string },
    token?: string
  ) {
    super(data, token)
  }
  // override
  protected getUrl(): string {
    return `/adminuser/${this.id}`
  }
}

// 指定IDの管理者ユーザーを削除します。
export class DeleteAdminUserApi extends DeleteApi<ResponseType<string>> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/adminuser/${this.id}`
  }
}

// 指定IDの管理者ユーザーのパスワードを再発行します。
export class ReissuePasswordApi extends PutApi<ResponseType<string>> {
  constructor(private readonly id: string, token?: string) {
    super(undefined, token)
  }
  // override
  protected getUrl(): string {
    return `/adminuser/${this.id}/password`
  }
}
