// 1人の管理者ユーザーを表す型。
// SaaStainer側(クライアント)には存在しない型なので管理ツール専用として定義
export type AdminUserType = {
  id: string
  displayName: string
  email: string
  role: ROLE
  createdAt: string
  updatedAt: string
}

// 管理者ロール
export type ROLE = "owner" | "admin" | "member"

// 管理者ロールの配列
export const roles: ROLE[] = ["owner", "admin", "member"]

// ソートキー
export type AdminUserSortKey = keyof Omit<AdminUserType, "id">
