import React, { useEffect } from "react"
import { ProductCodeEditAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeEditReducer"
import { ProductCodeIssueAction } from "../../../../contexts/ProductCodeManagement/reducers/useProductCodeIssueReducer"
import { SimpleTextInput } from "../../Common/SimpleTextInput"

type Props<T extends ProductCodeIssueAction | ProductCodeEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: string
}

/**
 * 代理店を入力するコンポーネント
 */
export const AgencyInput = <
  T extends ProductCodeIssueAction | ProductCodeEditAction
>({
  dispatch,
  initialValue = "",
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "agency", payload: initialValue } as T)
  }, [initialValue])

  /**
   * 代理店名を設定する
   * @param value 代理店名
   */
  const handleValueChange = (value: string) => {
    dispatch({ type: "agency", payload: value } as T)
  }

  return (
    <SimpleTextInput
      onValueChange={handleValueChange}
      getPlaceholder={() => "代理店名を入力してください"}
      initialValue={initialValue}
    />
  )
}
