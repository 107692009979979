import { makeStyles, Theme } from "@material-ui/core"
import { Pagination } from "@material-ui/lab"
import React, { useState, useEffect, useRef, useContext } from "react"
import { PaginableResponse, PaginationInfo } from "../../../types/Common"
import icon_clear from "../../../images/icon_clear.png"
import { AdminBaseLayoutContext } from "../../../contexts/AdminBaseLayoutManagement/AdminBaseLayoutContext"

type Props<T> = {
  getSuggestions: (
    inputValue: string,
    page?: number
  ) => Promise<PaginableResponse<T[]> | undefined>
  renderSuggestion: (suggestion: T) => JSX.Element
  onSuggestionSelect: (suggestion: T | undefined) => void
  getDisplayValue: (suggestion: T) => string
  getPlaceholder: () => string
  initialValue?: T
}

/**
 * 入力補完機能を提供するコンポーネント
 */
export const SuggestInput = <T,>({
  /**
   * 入力値に対して補完リストを取得するための関数
   * @param inputValue 入力値
   * @param page ページ番号
   * @returns 補完リスト情報
   */
  getSuggestions,
  /**
   * 補完リストのアイテムが選択されたときに呼ばれる関数
   * @param suggestion 補完リストのアイテム
   */
  onSuggestionSelect,
  /**
   * 補完リストのアイテムを描画するための関数
   * @param suggestion 補完リストのアイテム
   * @returns 描画結果
   */
  renderSuggestion,
  /**
   * インプット要素に表示する文字列を補完リストのアイテムから取得する関数
   * @param suggestion 補完リストのアイテム
   * @returns インプット要素に表示する文字列
   */
  getDisplayValue,
  /**
   * インプット要素に表示するプレースホルダーを取得する関数
   * @returns プレースホルダー
   */
  getPlaceholder,
  /**
   * 初期値を設定する
   * @param initialValue 初期値
   */
  initialValue,
}: Props<T>) => {
  // 入力値
  const [inputValue, setInputValue] = useState("")
  // 補完リスト
  const [suggestions, setSuggestions] = useState<
    PaginableResponse<T[]> | undefined
  >(undefined)
  // ページ情報
  const [pagination, setPagination] = useState<PaginationInfo>()
  // ローディング状態
  const [isLoading, setIsLoading] = useState(false)
  // 選択された補完リストのアイテム
  const [selectedSuggestion, setSelectedSuggestion] = useState<T | undefined>(
    undefined
  )
  // 入力欄のDOM要素への参照
  const inputRef = useRef<HTMLInputElement>(null)
  // 補完リストのアイテムのDOM要素への参照
  const suggestionRef = useRef<HTMLUListElement>(null)
  // ページネーションのDOM要素への参照
  const paginationRef = useRef<HTMLDivElement>(null)
  // 管理画面基本レイアウトコンテキスト
  const { circularProgressDispatch } = useContext(AdminBaseLayoutContext)

  /**
   * コンポーネントがマウントされたときにクリックイベントのリスナーを追加する
   */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  /**
   * 初期値があれば設定する
   */
  useEffect(() => {
    if (initialValue) {
      handleSuggestionClick(initialValue)
    }
  }, [initialValue])

  /**
   * クリックイベントが発生したときに、補完リスト以外の場所がクリックされた場合、入力値をクリアする
   * @param event クリックイベント
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (
      suggestionRef.current &&
      suggestionRef.current !== (event.target as Node) &&
      inputRef.current &&
      inputRef.current !== event.target &&
      paginationRef.current &&
      !paginationRef.current.contains(event.target as Node)
    ) {
      clearInput()
    }
  }

  /**
   * 補完リストを更新する関数
   * @param page - 取得するページ番号。デフォルトは1
   * @returns 取得したデータに基づき、suggestionsとpaginationを更新する。
   */
  const updateSuggestion = async (page: number = 1) => {
    try {
      circularProgressDispatch({ type: "on" })
      const data = await getSuggestions(inputValue, page)
      if (data && data.payload.length > 0) {
        setSuggestions(data)
        setPagination(data.pageInfo)
      } else {
        setSuggestions(undefined)
      }
    } catch (err) {
      console.log(err)
    } finally {
      circularProgressDispatch({ type: "off" })
    }
  }

  /**
   * 入力欄の値が変化した場合に、補完リストを更新します。
   * 補完リストから選択された場合は、その名前を入力欄に設定します。
   * 250ミリ秒間次の入力が無ければリクエストを送信し、入力値に基づいて候補リストを更新します。
   * 入力値が空の場合は補完リストをクリアします。
   */
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (inputValue.length > 0 && !selectedSuggestion) {
        setIsLoading(true)
        await updateSuggestion()
        setIsLoading(false)
      }
    }, 250)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  /**
   * 入力欄の値が変更されたときに実行されます。
   * 入力欄の値を更新し、選択された提案を解除します。
   * @param event React.ChangeEvent<HTMLInputElement> 型のイベントオブジェクト。
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)

    if (!value.length) setSuggestions(undefined)
  }

  /**
   * インプット欄がフォーカスを失った際のハンドラー
   * 選択済みの提案がない場合は、入力をクリアする
   * @param event - フォーカスイベント
   */
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!selectedSuggestion && !suggestions) {
      clearInput()
    }
  }

  /**
   * suggestionをクリックしたときのハンドラ
   * @param selectedValue 選択されたsuggestionオブジェクト
   */
  const handleSuggestionClick = (selectedValue: T) => {
    setInputValue(getDisplayValue(selectedValue))
    setSelectedSuggestion(selectedValue)
    onSuggestionSelect(selectedValue)
    setSuggestions(undefined)
  }

  /**
   * 入力欄をクリアする関数
   */
  const clearInput = () => {
    setInputValue("")
    setSelectedSuggestion(undefined)
    onSuggestionSelect(undefined)
    setSuggestions(undefined)
  }

  /**
   * ページネーションが変更されたときに呼び出されるハンドラー関数。
   * @param {number} page - 選択されたページ番号。
   * @returns {Promise<void>} 非同期処理が完了したら解決される Promise 。
   */
  const handlePageChage = async (page: number) => {
    await updateSuggestion(page)
  }

  const styles = useStyles()()
  // 現在の入力値がサジェストから選択済みであることを表す真偽
  const isSelectedSuggestion = selectedSuggestion !== undefined

  return (
    <div>
      <div className={styles.suggestInput}>
        <input
          type="text"
          maxLength={256}
          placeholder={getPlaceholder()}
          readOnly={isSelectedSuggestion}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          ref={inputRef}
        />
        {isSelectedSuggestion ? (
          <button type="button" onClick={clearInput}></button>
        ) : null}
        {isSelectedSuggestion ? <img src={icon_clear} /> : null}
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        suggestions && (
          <div className={styles.suggestionRoot}>
            <div className={styles.suggestList}>
              <ul
                ref={suggestionRef}
                id="suggestion-ul"
                style={{ padding: "10px" }}
              >
                {suggestions.payload.map((suggestion, index) => (
                  <li
                    id="suggestion-li"
                    tabIndex={0}
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {renderSuggestion(suggestion)}
                  </li>
                ))}
              </ul>
              {suggestions.payload.length > 0 && (
                <div className={styles.pagination}>
                  <Pagination
                    ref={paginationRef}
                    count={pagination ? pagination.totalPages : 1}
                    page={pagination ? pagination.page : 1}
                    onChange={(_, page) => handlePageChage(page)}
                    color="primary"
                    shape="rounded"
                  />
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

const useStyles = () => {
  const suggestInput = {
    position: "relative" as const,
    width: "100%",
    "& input": {
      boxSizing: "border-box" as const,
      width: "100%",
      height: "40px",
      borderRadius: "20px",
      border: "1px solid gray",
      padding: "0 40px 0 20px",
      transition: "all 0.1s ease-in",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& input:focus": {
      outline: 0,
      border: "2px solid gray",
    },
    "& img": {
      position: "absolute" as const,
      top: "13px",
      right: "20PX",
      width: "14px",
      pointerEvents: "none" as const,
    },
    "& button": {
      position: "absolute" as const,
      top: "12px",
      right: "20PX",
      width: "18px",
      height: "18px",
      opacity: "0",
      cursor: "pointer" as const,
    },
  }

  const suggestionRoot = {
    position: "absolute" as const,
    visibility: "visible" as const,
    zIndex: 100,
  }

  const suggestionInVisible = {
    visibility: "hidden" as const,
  }

  const suggestionList = {
    position: "relative" as const,
    minWidth: "400px",
    display: "inline-block",
    color: "#F5F5F5",
    border: 0,
    borderRadius: "10px",
    backgroundColor: "rgba(83, 99, 129, 0.85)",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    paddingBottom: "10px",
    marginTop: "10px",
    "&::before": {
      content: "''",
      position: "absolute" as const,
      top: "-10px",
      left: "20px",
      width: "0",
      height: "0",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid rgba(83, 99, 129, 0.7)",
    },
    "& ul[id=suggestion-ul]": {
      display: "block",
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    "& li[id=suggestion-li]": {
      padding: "5px",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "#7ba3cc",
        cursor: "pointer",
      },
    },
  }

  const pagination = {
    padding: "5px",
    display: "flex" as const,
    justifyContent: "center" as const,
    backgroundColor: "rgba(83, 99, 129, 0.3)",
  }

  return makeStyles((_: Theme) => ({
    suggestInput: { ...suggestInput }, // 入力フィールドのスタイル
    suggestList: { ...suggestionList }, // サジェストリストのスタイル
    suggestionInvisible: { ...suggestionInVisible }, // サジェストリストを非表示にするスタイル
    suggestionRoot: { ...suggestionRoot }, // サジェストリスト全体のスタイル
    pagination: { ...pagination }, //ページネーションのスタイル
  }))
}
