import React, { createContext } from "react"
import {
  SaaStainerUserListAction,
  useSaaStainerUserListReducer,
  saastainerUserListInitialState,
  SaaStainerUserListState,
} from "./reducers/useSaaStainerUserListReducer"

import {
  ChurnSurveyListAction,
  useChurnSurveyListReducer,
  churnSurveyListInitialState,
  ChurnSurveyListState,
} from "./reducers/useChurnSurveyListReducer"

/**
 * SaaStainerUserの管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {SaaStainerUserListState} 登録者一覧ページのステート
 * @property {React.Dispatch<SaaStainerUserListAction>} 一覧ページのアクションをディスパッチする関数
 * @property {ChurnSurveyListState} 解約者アンケート一覧ページのステート
 * @property {React.Dispatch<ChurnSurveyListAction>} 解約者アンケート一覧ページのアクションをディスパッチする関数
 */
type ContextType = {
  saastainerUserListState: SaaStainerUserListState
  saastainerUserListDispatch: React.Dispatch<SaaStainerUserListAction>
  churnSurveyListState: ChurnSurveyListState
  churnSurveyListDispatch: React.Dispatch<ChurnSurveyListAction>
}

/**
 * SaaStainerUserの管理に関する情報を提供するContextです。
 * @type {React.Context<ContextType>}
 */
export const SaaStainerUserManagementContext = createContext<ContextType>({
  saastainerUserListState: saastainerUserListInitialState,
  saastainerUserListDispatch: (_: SaaStainerUserListAction) => {},
  churnSurveyListState: churnSurveyListInitialState,
  churnSurveyListDispatch: (_: ChurnSurveyListAction) => {},
})

/**
 * SaaStainerUserの管理に関する情報を提供するContextのプロバイダーコンポーネントです。
 * @param {Object} props コンポーネントに渡すprops
 * @param {React.ReactNode} props.children 子コンポーネント
 * @returns {JSX.Element} コンテキストのProviderコンポーネント
 */
export const SaaStainerUserManagementContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {
    saastainerUserListState,
    saastainerUserListDispatch,
  } = useSaaStainerUserListReducer()

  const {
    churnSurveyListState,
    churnSurveyListDispatch,
  } = useChurnSurveyListReducer()

  return (
    <SaaStainerUserManagementContext.Provider
      value={{
        saastainerUserListState,
        saastainerUserListDispatch,
        churnSurveyListState,
        churnSurveyListDispatch,
      }}
    >
      {children}
    </SaaStainerUserManagementContext.Provider>
  )
}
