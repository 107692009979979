import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { AdminAuthContext } from "../../contexts/AdminAuthManagement/AdminAuthContext"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"
import { AdminUserType } from "../../types/AdminUser"
import { formatDateToJstString } from "../../utility/CommonUtil"
import { Save } from "@material-ui/icons"
import AlertDialog from "../uiParts/Common/AlertDialog"
import {
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalTextboxStyle,
} from "../globalStyles"
import { PutProfileApi } from "../../api/ProfileSettingsApi"

/**
 * プロフィール編集ページコンポーネント
 */
export const ProfileEditPage = () => {
  const { adminAuthState, adminAuthDispatch } = useContext(AdminAuthContext)
  const [profile, setProfile] = useState<
    AdminUserType & { newPassword: string; confirmPassword: string }
  >({ ...adminAuthState.payload, newPassword: "", confirmPassword: "" })
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push("/admin/dashboard")
  }

  // 「保存」ボタンが押された時の処理
  const handleSaveButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 保存関数定義
    const saveAction = async () => {
      if (profile.newPassword !== profile.confirmPassword) {
        enqueueSnackbar("確認用パスワードが一致しません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!profile.displayName) {
        enqueueSnackbar("表示名が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      try {
        const data = {
          displayName: profile.displayName,
          role: profile.role,
          password: profile.newPassword,
        }

        await new PutProfileApi(
          data,
          adminAuthState.token,
          profile.id
        ).sendRequest()

        adminAuthDispatch({
          type: "refresh",
          data: {
            token: adminAuthState.token,
            payload: { ...adminAuthState.payload, ...data },
          },
        })

        enqueueSnackbar(`プロフィールを更新しました`, {
          variant: "success",
          preventDuplicate: true,
        })
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    setDialogMessage("入力した内容で上書き保存しますか？")
    showDialog(() => saveAction)
  }

  // 表示名の変更があった時の処理
  const handleDisplayNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, displayName: event.target?.value ?? "" })
  }

  // パスワードの変更があった時の処理
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, newPassword: event.target?.value ?? "" })
  }

  // 確認用パスワードの変更があった時の処理
  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setProfile({ ...profile, confirmPassword: event.target?.value ?? "" })
  }

  const styles = createStyles("20px")()

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>プロフィール編集</h3>
      </div>
      <div className={styles.inputArea}>
        <label>表示名</label>
        <input
          type="text"
          value={profile.displayName}
          onChange={handleDisplayNameChange}
        />
        <label>新しいパスワード</label>
        <input
          type="password"
          value={profile.newPassword}
          onChange={handlePasswordChange}
        />
        <label>確認用パスワード</label>
        <input
          type="password"
          value={profile.confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        <label>メールアドレス（編集不可）</label>
        <input type="text" value={profile.email} disabled />
        <label>ロール（編集不可）</label>
        <input type="text" value={profile.role} disabled />
        <label>登録日（編集不可）</label>
        <input
          type="text"
          value={formatDateToJstString(new Date(profile.createdAt))}
          disabled
        />
        <label>更新日（編集不可）</label>
        <input
          type="text"
          value={formatDateToJstString(new Date(profile.updatedAt))}
          disabled
        />
      </div>
      <div className={styles.buttonArea}>
        <div id="buttons">
          <button
            type="button"
            className={"action"}
            onClick={handleSaveButtonClick}
          >
            保存
            <Save />
          </button>
        </div>
      </div>
      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={handleDialogClose}
        message={dialogMessage}
      />
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea " auto
             "inputArea " 1fr
             "buttonArea" auto
            / 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
    "& div[id=buttons]": {
      width: "250px",
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& span": {
      color: "red",
    },
  }

  // プロダクトコード情報の入力エリア
  const inputArea = {
    gridArea: "inputArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
    ...globalTextboxStyle,
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "300px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputArea: { ...inputArea },
    buttonArea: { ...buttonArea },
  }))
}
