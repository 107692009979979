import { makeStyles, Theme } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { AdminAuthContext } from "../../contexts/AdminAuthManagement/AdminAuthContext"

/*
コンテンツヘッダーレイアウト：
  各ページが表示されるエリアのヘッダーレイアウトです。
機能：
 ・childrenで指定されたページを表示  
  ・ページ名の表示
  ・ページに対しての権限を表示
*/
type Props = {
  children: React.ReactElement
  scope?: { read: boolean; write: boolean }
}
export const ContentsLayout = ({ children, scope }: Props) => {
  const { adminAuthState } = useContext(AdminAuthContext)
  const [page, setPage] = useState("ダッシュボード")
  const styles = createStyles()()
  const location = useLocation<string>()
  useEffect(() => {
    setPage((prev) => location.state ?? prev)
  }, [])
  return (
    <div className={styles.root}>
      {adminAuthState.token ? (
        <div className={styles.header}>
          <span>{page}</span>
          <div id="auth_status">
            {scope?.read && scope?.write ? (
              <span>このページの権限: read / write</span>
            ) : (
              <span>このページの権限: {scope?.read ? "read" : "write"}</span>
            )}
          </div>
        </div>
      ) : null}
      <div className={styles.main}>
        {React.cloneElement(children, { scope })}
      </div>
    </div>
  )
}

// スタイル作成
const createStyles = () => {
  // ルート
  const root = {
    display: "grid" as const,
    justifyContent: "center" as const,
    alignItems: "center" as const,
    backgroundColor: "#CFD8DC",
    gridTemplate: `"header" 50px
             "main  " 1fr
            / 1fr`,
  }

  // ヘッダーエリア
  const headerArea = {
    gridArea: "header",
    backgroundColor: "#293c50",
    borderLeft: "1px solid gray",
    color: "#FFF",
    padding: "0 20px",
    display: "flex" as const,
    justifyContent: "space-between",
    alignItems: "center" as const,
    fontSize: "16px",
    filter: "drop-shadow(1px 1px 1px gray)",
    height: "50px",
    "& div[id=auth_status]": {
      display: "flex",
      gap: "10px",
      fontSize: "12px",
      fontWeight: "bold" as const,
    },
    borderTopRightRadius: "8px",
  }

  // メインエリア（ページ表示部分）
  const mainArea = {
    gridArea: "main",
    padding: "20px",
  }

  return makeStyles((_: Theme) => ({
    root,
    header: headerArea,
    main: mainArea,
  }))
}
