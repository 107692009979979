/**
 * 日付のフォーマットをステートからDB向けに整形する関数
 */
export const formatStateToDb = (date?: Date): string => {
  if (!date) {
    date = new Date()
  }
  const year = date.getFullYear().toString()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")
  return `${year}${month}${day}`
}
