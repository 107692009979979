import React, { useEffect } from "react"
import { SaleAddAction } from "../../../../contexts/SalesManagement/reducers/useSaleAddReducer"
import { SaleEditAction } from "../../../../contexts/SalesManagement/reducers/useSaleEditReducer"
import { SimpleTextInput } from "../../Common/SimpleTextInput"

type Props<T extends SaleAddAction | SaleEditAction> = {
  dispatch: React.Dispatch<T>
  initialValue?: string
}

/**
 * 支払い者メールアドレスを入力するコンポーネント
 */
export const PayerMailAddressInput = <
  T extends SaleAddAction | SaleEditAction
>({
  dispatch,
  initialValue = "",
}: Props<T>) => {
  useEffect(() => {
    dispatch({ type: "payerEmail", payload: initialValue } as T)
  }, [initialValue])

  /**
   * メールアドレスを設定する
   * @param value メールアドレス
   */
  const handleValueChange = (value: string) => {
    dispatch({ type: "payerEmail", payload: value } as T)
  }

  return (
    <SimpleTextInput
      onValueChange={handleValueChange}
      getPlaceholder={() => "支払い者メールアドレスを入力してください"}
      initialValue={initialValue}
    />
  )
}
