import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { MediaQueryContext } from "../App"
import { useHistory } from "react-router-dom"
import { cancelSubscription, createNewSession } from "../api/apps"
import buttonBlueArrow from "../img/app_detail/button_blue_arrow.png"
import buttonWhiteArrow from "../img/app_detail/button_white_arrow.png"
import buttonCheckMark from "../img/app_detail/button_check_mark.png"
import AppUnsubscribingDialog from "./AppUnsubscribingDialog"
/*
コンポーネントの説明：
  アプリ詳細画面に配置するボタン。表示するボタンの種類はユーザーやアプリの状態によって変化。
  状態によって以下のボタンを表示・非表示にする。
 ・「トライアル（無料）」ボタン
 ・「購入」ボタン
 ・「購入済み」ボタン（非活性）
 ・「解約」ボタン
 ・「アプリを開く」ボタン
 ・「アプリに関するお問い合わせはこちら」ボタン
CSS定義場所：
  コンポーネント固有CSSとして、このファイル内の最下部で定義。
*/

// コンテキストボタン生成のために必要な情報
type Props = {
  // ログイン中？
  login: boolean
  // 無料アプリ？
  freeApp: boolean
  // ユーザーが所有しているアプリ？
  usersApp: boolean | undefined
  // プロダクトコード販売のみのアプリ？
  soldOnlyViaProductCode: boolean | undefined
  // トライアル可能なアプリ？
  trialAvailable: boolean | undefined
  // アプリの支払いモード
  paymentMode: "payment" | "subscription" | undefined
  // アプリのURL
  appUrl: string | undefined
  // トークン
  token: string | undefined
  // アプリID
  appId: string
  // 問い合わせURL
  inquiryUrl: string | undefined
  // エラーハンドラー
  errorHandler: (error: Error) => void
  //問い合わせボタンの表示・非表示
  showInquiryButton: boolean
}

const AppDetailContextButtons = React.memo((props: Props) => {
  const styles = useRootStyles()
  return (
    // 各ボタンの表示・非表示は各ボタン自身が判断する
    // 条件が複雑になるため他のボタンには依存しない
    props.appUrl && !props.soldOnlyViaProductCode ? (
      <div className={styles.root}>
        {/* 「トライアル（無料）」ボタン */}
        <TrialButton {...props} />
        {/* 「購入」ボタン */}
        <PurchaseButton {...props} />
        {/* 「アプリを開く」ボタン */}
        <OpenAppButton {...props} />
        {/* 「購入済み」ボタン（非活性） */}
        <PurchasedButton {...props} />
        {/* 「解約」ボタン */}
        <CancelButton {...props} />
      </div>
    ) : props.appUrl && props.soldOnlyViaProductCode ? (
      <OpenAppButton {...props} /> // TODO 制御直す
    ) : (
      //「アプリに関するお問い合わせはこちら」ボタン
      <AppInquiryButton {...props} />
    )
  )
})

//「トライアル（無料）」ボタン
const TrialButton = React.memo((props: Props) => {
  const {
    login,
    usersApp,
    soldOnlyViaProductCode,
    trialAvailable,
    token,
    appId,
    appUrl,
  } = props
  // 以下の値がundefinedの場合は条件の判定が出来ないのでボタンを非表示とする
  if (
    usersApp === undefined ||
    soldOnlyViaProductCode === undefined ||
    trialAvailable === undefined ||
    appUrl === undefined
  )
    return null

  // 表示条件１
  // ・ログイン中であること
  // ・ユーザーがこのアプリを持っていないこと
  // ・このアプリがプロダクトコード販売ではないこと
  // ・トライアルが設定されていること
  // ・アプリのURLが設定されていること
  const condition1 =
    login && !usersApp && !soldOnlyViaProductCode && trialAvailable && appUrl

  // 表示条件２
  // ・ログアウト中であること
  // ・このアプリがプロダクトコード販売ではないこと
  // ・トライアルが設定されていること
  // ・アプリのURLが設定されていること
  const condition2 =
    !login && !soldOnlyViaProductCode && trialAvailable && appUrl

  // 表示条件を１つも満たさない場合はボタン非表示
  if (!condition1 && !condition2) return null

  const { isPC } = useContext(MediaQueryContext)
  const styles = isPC ? usePcButtonStyles() : useSpButtonStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const clickPurchaseHandler =
    login && token !== undefined
      ? () => createNewSession(token, appId)
      : () =>
          history.push(
            `/signin?redirect_to=${encodeURIComponent(
              window.location.pathname
            )}`
          )

  return (
    <div style={{ width: "100%" }}>
      <a className={styles.trialButton} onClick={clickPurchaseHandler}>
        {isPC ? <img src={buttonWhiteArrow} /> : null}
        {/* トライアル（無料）*/}
        {t("appDetailCard.trial")}
      </a>
      <p className={styles.signUpRequired}>
        {t("appDetailCard.SignUpRequired")}
      </p>
    </div>
  )
})

//「購入」ボタン
const PurchaseButton = React.memo((props: Props) => {
  const {
    login,
    freeApp,
    usersApp,
    soldOnlyViaProductCode,
    trialAvailable,
    token,
    appId,
    appUrl,
  } = props

  // 以下の値がundefinedの場合は条件の判定が出来ないのでボタンを非表示とする
  if (
    freeApp === undefined ||
    usersApp === undefined ||
    soldOnlyViaProductCode === undefined ||
    trialAvailable === undefined ||
    appUrl === undefined
  )
    return null

  // 表示条件１
  // ・ログイン中であること
  // ・有料アプリであること
  // ・ユーザーがこのアプリを持っていないこと
  // ・このアプリがプロダクトコードのみの販売ではないこと
  // ・トライアルが設定されていないこと
  // ・アプリのURLが設定されていること
  const condition1 =
    login &&
    !freeApp &&
    !usersApp &&
    !soldOnlyViaProductCode &&
    !trialAvailable &&
    appUrl
  // 表示条件２
  // ・ログアウト中であること
  // ・有料アプリであること
  // ・このアプリがプロダクトコードのみの販売ではないこと
  // ・トライアルが設定されていないこと
  // ・アプリのURLが設定されていること
  const condition2 =
    !login && !freeApp && !soldOnlyViaProductCode && !trialAvailable && appUrl
  // 表示条件を１つも満たさない場合はボタン非表示
  if (!condition1 && !condition2) return null

  const { isPC } = useContext(MediaQueryContext)
  const styles = isPC ? usePcButtonStyles() : useSpButtonStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const clickPurchaseHandler =
    login && token !== undefined
      ? () => createNewSession(token, appId)
      : () =>
          history.push(
            `/signin?redirect_to=${encodeURIComponent(
              window.location.pathname
            )}`
          )

  return (
    <div style={{ width: "100%" }}>
      <a className={styles.purchaseButton} onClick={clickPurchaseHandler}>
        {!isPC ? <img src={buttonWhiteArrow} /> : null}
        {/* 購入 */}
        {t("appDetailCard.purchase")}
      </a>
      <p className={styles.signUpRequired}>
        {t("appDetailCard.SignUpRequired")}
      </p>
    </div>
  )
})

//「購入済み」ボタン（非活性）
const PurchasedButton = React.memo((props: Props) => {
  const { login, usersApp, paymentMode } = props

  // 以下の値がundefinedの場合は条件の判定が出来ないのでボタンを非表示とする
  if (usersApp === undefined || paymentMode === undefined) return null

  // 表示条件
  // ・ログイン中であること
  // ・ユーザーがこのアプリを持っていること
  // ・このアプリの支払いモードが「payment」であること
  const condition = login && usersApp && paymentMode === "payment"

  // 表示条件を満たさない場合はボタン非表示
  if (!condition) return null

  const { isPC } = useContext(MediaQueryContext)
  const styles = isPC ? usePcButtonStyles() : useSpButtonStyles()
  const { t } = useTranslation()

  return (
    <a className={styles.purchasedButton}>
      {isPC ? <img src={buttonCheckMark} /> : null}
      {/* 購入済み */}
      {t("appDetailCard.purchased")}
    </a>
  )
})

// 「解約」ボタン
const CancelButton = React.memo((props: Props) => {
  const {
    login,
    usersApp,
    paymentMode,
    soldOnlyViaProductCode,
    token,
    appId,
    errorHandler,
  } = props

  const history = useHistory()

  // 解約処理
  const clickCancelHandler = (
    checked: boolean,
    reason: string,
    additionalInfo: string
  ) => {
    if (!checked) {
      setDialogOpen(false)
      return
    }

    cancelSubscription(token!, appId, reason, additionalInfo)
      .then(() => {
        history.push("/apps")
      })
      .catch(errorHandler)
      .finally(() => setDialogOpen(false))
  }

  // 以下の値がundefinedの場合は条件の判定が出来ないのでボタンを非表示とする
  if (
    usersApp === undefined ||
    paymentMode === undefined ||
    soldOnlyViaProductCode === undefined
  )
    return null

  // 表示条件
  // ・ログイン中であること
  // ・ユーザーがこのアプリを持っていること
  // ・このアプリの支払いモードが「subscription」であること
  // ・このアプリがプロダクトコードのみの販売ではないこと
  const condition =
    login &&
    usersApp &&
    paymentMode === "subscription" &&
    !soldOnlyViaProductCode

  // 表示条件を満たさない場合はボタン非表示
  if (!condition) return null

  const [dialogOpen, setDialogOpen] = useState(false)
  const { isPC } = useContext(MediaQueryContext)
  const styles = isPC ? usePcButtonStyles() : useSpButtonStyles()
  const { t } = useTranslation()

  return (
    <div style={{ width: "100%" }}>
      <a className={styles.cancelButton} onClick={() => setDialogOpen(true)}>
        {isPC ? <img src={buttonWhiteArrow} /> : null}
        {/* 解約 */}
        {t("appDetailCard.unsubscribe")}
      </a>
      <AppUnsubscribingDialog
        isOpen={dialogOpen}
        onClose={(checked, reason, additionalInfo) =>
          clickCancelHandler(checked, reason, additionalInfo)
        }
      />
    </div>
  )
})

//「アプリを開く」ボタン
const OpenAppButton = React.memo((props: Props) => {
  const { login, usersApp, freeApp, soldOnlyViaProductCode, appUrl } = props

  // 以下の値がundefinedの場合は条件の判定が出来ないのでボタンを非表示とする
  if (
    usersApp === undefined ||
    freeApp === undefined ||
    soldOnlyViaProductCode === undefined ||
    appUrl === undefined
  )
    return null

  // 表示条件１
  // ・ログイン中であること
  // ・ユーザーがこのアプリを持っていること
  // ・アプリのURLが設定されていること
  const condition1 = login && usersApp && appUrl

  // 表示条件２
  // ・ログイン中であること
  // ・無料アプリであること
  // ・プロダクトコード販売ではない
  // ・アプリのURLが設定されていること
  const condition2 = login && freeApp && !soldOnlyViaProductCode && appUrl

  // 表示条件３
  // ・ログアウト中であること
  // ・無料アプリであること
  // ・プロダクトコード販売ではない
  // ・アプリのURLが設定されていること
  const condition3 = !login && freeApp && !soldOnlyViaProductCode && appUrl

  // 表示条件を１つも満たさない場合はボタン非表示
  if (!condition1 && !condition2 && !condition3) return null

  const { isPC } = useContext(MediaQueryContext)
  const styles = isPC ? usePcButtonStyles() : useSpButtonStyles()
  const { t } = useTranslation()

  return (
    <div style={{ width: "100%" }}>
      <a
        className={styles.openAppButton}
        href={appUrl}
        target="_blank"
        rel="noopener"
      >
        {isPC ? <img src={buttonWhiteArrow} /> : null}
        {/* アプリを開く */}
        {t("appDetailCard.openApp")}
      </a>
      <p className={styles.signUpRequired}>
        {t("appDetailCard.SignUpRequired")}
      </p>
    </div>
  )
})

//「アプリに関するお問い合わせはこちら」ボタン
const AppInquiryButton = React.memo((props: Props) => {
  const { appUrl, inquiryUrl, showInquiryButton } = props

  //　このボタンを表示しない指定があるときは条件に関わらず表示しない
  if (!showInquiryButton) return null

  // 表示条件
  // ・アプリのURLが設定されていないこと
  const condition1 = appUrl ? false : true
  // ・お問い合わせURLが設定されていること
  const condition2 = inquiryUrl ? true : false

  // 表示条件を満たさない場合はボタン非表示
  if (!(condition1 && condition2)) return null

  const { isPC } = useContext(MediaQueryContext)
  const styles = isPC ? usePcButtonStyles() : useSpButtonStyles()
  const { t } = useTranslation()

  return (
    <a
      className={styles.inquiryButton}
      href={inquiryUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {isPC ? <img src={buttonBlueArrow} /> : null}
      {/* アプリに関するお問い合わせはこちら */}
      {t("appDetailCard.inquiry")}
    </a>
  )
})

// ルートスタイル定義
const useRootStyles = makeStyles((_: Theme) => ({
  // ルート
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
  },
}))

// ボタン共通スタイル
const baseButton = {
  position: "relative" as const,
  display: "block" as const,
  padding: "16px",
  fontSize: "14px",
  fontWeight: "bold" as const,
  textAlign: "center" as const,
  borderRadius: "50px",
  boxSizing: "border-box" as const,
  textDecoration: "none" as const,
  boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
  "& img": {
    position: "absolute" as const,
    right: "20px",
    top: "20px",
  },
  cursor: "pointer" as const,
}

// 注釈「無料会員登録が必要です」共通スタイル
const signUpRequired = {
  color: "#ababab",
  fontSize: "11px",
  textAlign: "center" as const,
}

// ボタンの種類によって異なる部分のスタイルを設定する関数。ベースのスタイルとマージする。
const button = (
  color: string,
  startBackgroundColor: string,
  endBackgroundColor?: string
) => (width: string, height: string) => ({
  ...baseButton,
  width,
  height,
  color,
  ...(endBackgroundColor
    ? {
        background: `linear-gradient(top left, ${startBackgroundColor}, ${endBackgroundColor})`,
      }
    : {
        background: `linear-gradient(top left, ${startBackgroundColor}, ${startBackgroundColor})`,
      }),
})

// 汎用のボタンスタイル（「トライアル（無料）」ボタン・「購入」ボタン・「アプリを開く」ボタン・「解約」ボタン）
const genericButton = button("#ffffff", "#517bc4", "#2e5598")
// 「購入済み」ボタンのスタイル
const purchasedButton = button("#333333", "#ffffff")
// 「アプリに関するお問い合わせはこちら」ボタン
const inquiryButton = button("#305699", "#ffffff")

const pcGenericWidth = "100%"
const pcGenericHight = "54px"
const spGenericWidth = "100%"
const spGenericHight = "54px"

// 汎用ボタンのPCスタイル（「トライアル（無料）」ボタン・「購入」ボタン・「アプリを開く」ボタン・「解約」ボタン）
const pcGenericButton = genericButton(pcGenericWidth, pcGenericHight)
// 購入済みボタンのPCスタイル
const pcPurchasedButton = purchasedButton(pcGenericWidth, pcGenericHight)
// 汎用ボタンのSPスタイル（「トライアル（無料）」ボタン・「購入」ボタン・「アプリを開く」ボタン）
const spGenericButton = genericButton(spGenericWidth, spGenericHight)
// 解約ボタンのSPスタイル
const spCancelButton = genericButton("120px", spGenericHight)
// 購入済みボタンのSPスタイル
const spPurchasedButton = purchasedButton("120px", spGenericHight)
// アプリに関するお問い合わせはこちらボタンのPC共通スタイル
const pcspInquiryButton = inquiryButton("100%", "54px")

// 購入済みボタンのPC・SP共通部分のスタイル
const pcspPurchasedButton = {
  "& img": {
    width: "40px",
    position: "absolute" as const,
    right: "10px",
    top: "7px",
  },
  cursor: "not-allowed" as const,
}

// PC用ボタンスタイル
const usePcButtonStyles = makeStyles((_: Theme) => ({
  trialButton: {
    ...pcGenericButton,
  },
  purchaseButton: {
    ...pcGenericButton,
  },
  purchasedButton: {
    ...pcPurchasedButton,
    ...pcspPurchasedButton,
  },
  cancelButton: {
    ...pcGenericButton,
  },
  openAppButton: {
    ...pcGenericButton,
  },
  inquiryButton: {
    ...pcspInquiryButton,
    marginBottom: "20px",
  },
  signUpRequired: {
    ...signUpRequired,
  },
}))

// SP用ボタンスタイル
const useSpButtonStyles = makeStyles((_: Theme) => ({
  trialButton: {
    ...spGenericButton,
  },
  purchaseButton: {
    ...spGenericButton,
  },
  purchasedButton: {
    ...spPurchasedButton,
    ...pcspPurchasedButton,
  },
  cancelButton: {
    ...spCancelButton,
  },
  openAppButton: {
    ...spGenericButton,
  },
  inquiryButton: {
    ...pcspInquiryButton,
  },
  signUpRequired: {
    ...signUpRequired,
  },
}))

export default AppDetailContextButtons
