import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalTextboxStyle,
} from "../../globalStyles"
import { AdminUserManagementContext } from "../../../contexts/AdminUserManagement/AdminUserManagementContextProvider"
import { useHistory } from "react-router-dom"
import { PostAdminUserApi } from "../../../api/AdminUserApi"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { useSnackbar } from "notistack"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import { RoleSelectInput } from "../../uiParts/AdminUser/Common/RoleSelectInput"
import { AdminUserAddAction } from "../../../contexts/AdminUserManagement/reducers/useAdminUserAddReducer"

/**
 * 管理者ユーザーの登録ページです。
 */
export const AdminUserAddPage = ({ scope }: PageAccessPermissions) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { adminUserAddState, adminUserAddDispatch } = useContext(
    AdminUserManagementContext
  )
  const { adminAuthState } = useContext(AdminAuthContext)

  useEffect(() => {
    // cleanup
    return () => {
      adminUserAddDispatch({ type: "init" })
    }
  }, [])

  const history = useHistory()

  // 表示名の変更があった時の処理
  const handleDisplayNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    adminUserAddDispatch({ type: "displayName", payload: event.target.value })
  }

  // メールアドレスの変更があった時の処理
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    adminUserAddDispatch({ type: "email", payload: event.target.value })
  }

  const { enqueueSnackbar } = useSnackbar()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
    history.goBack()
  }

  // 「登録」ボタンが押された時の処理
  const handleAddClick = async (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 登録関数定義
    const addAction = async () => {
      if (
        adminUserAddState.diplayName &&
        adminUserAddState.email &&
        adminUserAddState.role
      ) {
        const data = {
          displayName: adminUserAddState.diplayName,
          email: adminUserAddState.email,
          role: adminUserAddState.role,
        }
        try {
          const result = await new PostAdminUserApi(
            data,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(
            `新しい管理者ユーザー(${result.data.payload})を登録しました`,
            { variant: "success", preventDuplicate: true }
          )
        } catch (err) {
          const errorObj = err as any
          const errorMessage = errorObj.response
            ? errorObj.response.data
            : errorObj.message

          enqueueSnackbar(errorMessage, {
            variant: "error",
            preventDuplicate: true,
          })
        }
      } else {
        enqueueSnackbar("すべての項目を入力してください", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    setDialogMessage("管理者ユーザーを登録しますか？")
    showDialog(() => addAction)
  }

  const styles = createStyles("20px")()
  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>管理者ユーザー登録</h3>
      </div>
      <div className={styles.inputArea}>
        <label>表示名</label>
        <input
          type="text"
          value={adminUserAddState.diplayName}
          onChange={handleDisplayNameChange}
        />
        <label>メールアドレス</label>
        <input
          type="text"
          value={adminUserAddState.email}
          onChange={handleEmailChange}
        />
        <label>ロール</label>
        <RoleSelectInput<AdminUserAddAction>
          dispatch={adminUserAddDispatch}
          initialValue={"admin"}
        />
      </div>
      <div className={styles.buttonArea}>
        <div id="buttons">
          <button type="button" className="arrow" onClick={handleCancelClick}>
            戻る
            <img src={button_white_arrow} />
          </button>
          <button
            type="button"
            className={readOnly ? "actionDisabled" : "action"}
            onClick={handleAddClick}
            disabled={readOnly}
          >
            登録
            <AddCircleIcon />
          </button>
        </div>
      </div>
      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={handleDialogClose}
        message={dialogMessage}
      />
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea " auto
             "inputArea " 1fr
             "buttonArea" auto
            / 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "600px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  // 管理者ユーザー情報の入力エリア
  const inputArea = {
    gridArea: "inputArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
    ...globalTextboxStyle,
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputArea: { ...inputArea },
    buttonArea: { ...buttonArea },
  }))
}
