import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core"
import {
  globalDisabledButtonStyle,
  globalSystemButtonActionStyle,
  globalSystemButtonArrowStyle,
  globalSystemButtonDeleteActionStyle,
  globalTextboxStyle,
} from "../../globalStyles"
import { AdminUserManagementContext } from "../../../contexts/AdminUserManagement/AdminUserManagementContextProvider"
import { useHistory } from "react-router-dom"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { useSnackbar } from "notistack"
import { AdminUserType } from "../../../types/AdminUser"
import { Save, DeleteForever, ContactMail } from "@material-ui/icons"
import button_white_arrow from "../../../images/button_white_arrow.png"
import AlertDialog from "../../uiParts/Common/AlertDialog"
import { PageAccessPermissions } from "../../../types/Common"
import {
  DeleteAdminUserApi,
  GetAdminUserApi,
  PutAdminUserApi,
  ReissuePasswordApi,
} from "../../../api/AdminUserApi"
import { RoleSelectInput } from "../../uiParts/AdminUser/Common/RoleSelectInput"
import { AdminUserEditAction } from "../../../contexts/AdminUserManagement/reducers/useAdminUserEditReducer"
import { formatDateToJstString } from "../../../utility/CommonUtil"

/**
 * 管理者ユーザー編集ページコンポーネント
 */
export const AdminUserEditPage = ({ scope }: PageAccessPermissions) => {
  const { adminUserEditState, adminUserEditDispatch } = useContext(
    AdminUserManagementContext
  )
  const [adminUser, setAdminUser] = useState<AdminUserType>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")
  const [action, setAction] = useState<() => unknown>()
  const { adminAuthState, adminAuthDispatch } = useContext(AdminAuthContext)
  const history = useHistory()

  // 初回処理
  useEffect(() => {
    if (!adminUserEditState.id) {
      history.goBack()
      enqueueSnackbar("管理者ユーザーのIDを指定してください", {
        variant: "error",
        preventDuplicate: true,
      })
      return
    }

    const fetchData = async (id: string) => {
      try {
        const res = await new GetAdminUserApi(
          id,
          adminAuthState.token
        ).sendRequest()
        //console.log(res)
        adminUserEditDispatch({
          type: "displayName",
          payload: res.data.payload.displayName,
        })
        adminUserEditDispatch({ type: "role", payload: res.data.payload.role })
        setAdminUser(res.data.payload)
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    fetchData(adminUserEditState.id)

    // cleanup
    return () => {
      adminUserEditDispatch({ type: "init" })
    }
  }, [])

  const isMyProfile = adminUser?.email === adminAuthState.payload.email

  const { enqueueSnackbar } = useSnackbar()

  // 確認用ダイアログ表示時の処理
  const showDialog = (action: () => void) => {
    setAction(action)
    setDialogOpen(true)
  }

  // 確認用ダイアログ内の閉じるボタンが押された時の処理
  const handleDialogClose = (isAgree: boolean) => {
    setDialogOpen(false)
    if (isAgree && action) {
      action()
    }
  }

  // 「戻る」ボタンが押された時の処理
  const handleCancelButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.goBack()
  }

  // 「保存」ボタンが押された時の処理
  const handleSaveButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 保存関数定義
    const saveAction = async () => {
      if (adminUserEditState.password !== adminUserEditState.confirmPassword) {
        //console.log(
        //  `password:${adminUserEditState.password} confirmPassword:${adminUserEditState.confirmPassword}`
        //)
        enqueueSnackbar("確認用パスワードが一致しません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!adminUser?.id) {
        enqueueSnackbar("管理者ユーザーIDが指定されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!adminUserEditState.diplayName) {
        enqueueSnackbar("表示名が入力されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (!adminUserEditState.role) {
        enqueueSnackbar("ロールが選択されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      try {
        const data = {
          displayName: adminUserEditState.diplayName,
          role: adminUserEditState.role,
          password: adminUserEditState.password,
        }

        const res = await new PutAdminUserApi(
          adminUser.id,
          data,
          adminAuthState.token
        ).sendRequest()

        if (isMyProfile) {
          adminAuthDispatch({
            type: "refresh",
            data: {
              token: adminAuthState.token,
              payload: { ...adminAuthState.payload, ...data },
            },
          })
        }

        history.goBack()

        enqueueSnackbar(`管理者ユーザー(${res.data.payload})を更新しました`, {
          variant: "success",
          preventDuplicate: true,
        })
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    setDialogMessage("入力した内容で上書き保存しますか？")
    showDialog(() => saveAction)
  }

  // 「削除」ボタンが押された時の処理
  const handleDeleteButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // 削除関数定義
    const deleteAction = async () => {
      if (!adminUser?.id) {
        enqueueSnackbar("管理者ユーザーIDが指定されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      if (adminUser.id === adminAuthState.payload.id) {
        enqueueSnackbar("自分のユーザーは削除できません", {
          variant: "warning",
          preventDuplicate: true,
        })
        return
      }

      try {
        const result = await new DeleteAdminUserApi(
          adminUser.id,
          adminAuthState.token
        ).sendRequest()

        history.goBack()

        enqueueSnackbar(
          `管理者ユーザー(${result.data.payload})を削除しました`,
          { variant: "success", preventDuplicate: true }
        )
      } catch (err) {
        const errorObj = err as any
        const errorMessage = errorObj.response
          ? errorObj.response.data
          : errorObj.message

        enqueueSnackbar(errorMessage, {
          variant: "error",
          preventDuplicate: true,
        })
        return
      }
    }

    setDialogMessage("管理者ユーザーを削除しますか？")
    showDialog(() => deleteAction)
  }

  // 「パスワード再発行」ボタンが押された時の処理
  const handleReissuePasswordButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // パスワード再発行関数定義
    const ReissuePasswordAction = async () => {
      if (adminUser?.id) {
        try {
          const result = await new ReissuePasswordApi(
            adminUser.id,
            adminAuthState.token
          ).sendRequest()

          history.goBack()

          enqueueSnackbar(
            `管理者ユーザー(${result.data.payload})のメールに仮パスワード送信しました`,
            { variant: "success", preventDuplicate: true }
          )
        } catch (err) {
          enqueueSnackbar(
            err instanceof Error
              ? (err as Error).message
              : (err as any).response?.data,
            { variant: "error", preventDuplicate: true }
          )
          return
        }
      } else {
        enqueueSnackbar("管理者ユーザーIDが指定されていません", {
          variant: "warning",
          preventDuplicate: true,
        })
      }
    }

    setDialogMessage("パスワードを再発行しますか？")
    showDialog(() => ReissuePasswordAction)
  }

  // 表示名の変更があった時の処理
  const handleDisplayNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    adminUserEditDispatch({ type: "displayName", payload: event.target.value })
  }

  const styles = createStyles("20px")()

  const readOnly = scope ? !scope.write : false

  return (
    <div className={styles.rootArea}>
      <div className={styles.titleArea}>
        <h3>管理者ユーザー編集</h3>
        {isMyProfile ? <span>ログイン中のユーザー</span> : null}
        {!isMyProfile ? (
          <div id="buttons">
            <button
              type="button"
              className={readOnly ? "actionDisabled" : "action"}
              onClick={handleReissuePasswordButtonClick}
              disabled={readOnly}
            >
              パスワード再発行
              <ContactMail />
            </button>
          </div>
        ) : null}
      </div>
      <div className={styles.inputArea}>
        <label>表示名</label>
        <input
          type="text"
          value={adminUserEditState.diplayName ?? ""}
          onChange={handleDisplayNameChange}
        />
        <label>メールアドレス（編集不可）</label>
        <input type="text" value={adminUser?.email ?? ""} disabled />
        <label>ロール</label>
        <RoleSelectInput<AdminUserEditAction>
          dispatch={adminUserEditDispatch}
          initialValue={adminUserEditState.role!}
        />
        <label>登録日（編集不可）</label>
        <input
          type="text"
          value={
            adminUser
              ? formatDateToJstString(new Date(adminUser.createdAt))
              : ""
          }
          disabled
        />
        <label>更新日（編集不可）</label>
        <input
          type="text"
          value={
            adminUser
              ? formatDateToJstString(new Date(adminUser.updatedAt))
              : ""
          }
          disabled
        />
      </div>
      <div className={styles.buttonArea}>
        <div id="buttons">
          <button
            type="button"
            className="arrow"
            onClick={handleCancelButtonClick}
          >
            戻る
            <img src={button_white_arrow} />
          </button>
          <button
            type="button"
            className={readOnly ? "actionDisabled" : "action"}
            onClick={handleSaveButtonClick}
            disabled={readOnly}
          >
            保存
            <Save />
          </button>
          <button
            type="button"
            className={readOnly ? "deleteDisabled" : "delete"}
            onClick={handleDeleteButtonClick}
            disabled={readOnly}
          >
            削除
            <DeleteForever />
          </button>
        </div>
      </div>
      <AlertDialog
        dialogOpen={dialogOpen}
        onClose={handleDialogClose}
        message={dialogMessage}
      />
    </div>
  )
}

// スタイル作成
const createStyles = (hPadding: string) => {
  // ルート
  const rootArea = {
    display: "grid",
    maxWidth: "100%",
    gap: "10px",
    gridTemplate: `"titleArea " auto
             "inputArea " 1fr
             "buttonArea" auto
            / 1fr`,
    padding: `20px ${hPadding}`,
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
    boxSizing: "border-box" as const,
    "& label": {
      fontSize: "10px",
      color: "gray",
    },
  }

  // タイトルエリア
  const titleArea = {
    gridArea: "titleArea",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "2px solid #FFA500",
    color: "#FFA500",
    fontSize: "14px",
    "& div[id=buttons]": {
      width: "250px",
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
    "& span": {
      color: "red",
    },
  }

  // プロダクトコード情報の入力エリア
  const inputArea = {
    gridArea: "inputArea",
    display: "flex",
    flexFlow: "column",
    gap: "10px",
    ...globalTextboxStyle,
  }

  // ボタンエリア
  const buttonArea = {
    gridArea: "buttonArea",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "& div[id=buttons]": {
      display: "flex",
      gap: "10px",
      width: "600px",
    },
    "& div[id=buttons] .arrow": {
      ...globalSystemButtonArrowStyle,
    },
    "& div[id=buttons] .action": {
      ...globalSystemButtonActionStyle,
    },
    "& div[id=buttons] .actionDisabled": {
      ...globalSystemButtonActionStyle,
      ...globalDisabledButtonStyle,
    },
    "& div[id=buttons] .delete": {
      ...globalSystemButtonDeleteActionStyle,
    },
    "& div[id=buttons] .deleteDisabled": {
      ...globalSystemButtonDeleteActionStyle,
      ...globalDisabledButtonStyle,
    },
  }

  return makeStyles((_: Theme) => ({
    rootArea: { ...rootArea },
    titleArea: { ...titleArea },
    inputArea: { ...inputArea },
    buttonArea: { ...buttonArea },
  }))
}
