import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { LinearProgress } from "@material-ui/core"

export default function LoadingFallback() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gap: "10px",
      padding: "10px",
      border: 0,
      borderRadius: "8px",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
      backgroundColor: "white",
    },
  })
)
