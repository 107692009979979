import { useReducer } from "react"
import { CategoryIdArrowEmpty, SORT_TYPE } from "../typings/apps"

/*
カスタムフックの説明：
  各種検索条件指定コンポーネント（SearchByKeyword/ SearchByCategory/ SearchByService/ SearchBySortKey）に
  検索条件（state）の参照と検索条件変更方法(dispatch)を提供します。
  このstateとdispatchは、コンテキストプロバイダー(AppSearchContext)を経由することで
  上に挙げた各種検索条件指定コンポーネントがプロップスドリリングなしで参照できるようになっています。
  そしてこのstateを検索結果表示コンポーネント（SearchResults）が常時監視して検索結果に即時反映します。
*/

// 検索条件の状態
type State = {
  keyword: string // キーワード検索
  serviceName: string // サービス名検索
  categoryIds: CategoryIdArrowEmpty[] // カテゴリーID検索（複数ID指定可）
  sortKey: SORT_TYPE // ソート方法
  page: number // ページネーション
}

// 各種検索条件に対応するアクションの型
type Action =
  | {
      type: "keyword"
      payload: { keyword: string }
    }
  | {
      type: "service"
      payload: { serviceName: string }
    }
  | {
      type: "category"
      payload: { categoryId: CategoryIdArrowEmpty }
    }
  | {
      type: "sort"
      payload: { sortKey: SORT_TYPE }
    }
  | {
      type: "page"
      payload: { page: number }
    }

// 指定アクション型毎の処理
const reducer = (state: State, action: Action) => {
  const next = { ...state }

  // ページ変更以外の条件が変更されたらページネーションをリセットする
  if (action.type !== "page") next.page = 1

  switch (action.type) {
    case "keyword": // キーワード更新
      next.keyword = action.payload.keyword
      break
    case "service": // サービス名更新
      // 更新前のサービス名と更新予定のサービス名が同じか確認
      if (next.serviceName === action.payload.serviceName) {
        // 同じ場合このサービス名を検索条件から除外する
        next.serviceName = ""
        break
      }
      // このサービス名を検索条件に設定する
      next.serviceName = action.payload.serviceName
      break
    case "category": // カテゴリーID更新
      // 更新予定のカテゴリーが更新前のステートに存在するか確認
      const categoryId = next.categoryIds.find(
        (v) => v === action.payload.categoryId
      )
      if (categoryId) {
        // 存在する場合このカテゴリーIDを検索条件から除外
        next.categoryIds = next.categoryIds.filter((v) => v !== categoryId)
        break
      }
      // このカテゴリーIDを検索条件に追加
      // pushでは新しい参照が作成されずオブザーバーに通知されないためconcatを使用
      next.categoryIds = next.categoryIds.concat([action.payload.categoryId])
      break
    case "sort": // 並び順更新
      next.sortKey = action.payload.sortKey
      break
    case "page": // ページネーション
      next.page = action.payload.page
      break
  }
  return next
}

// 検索条件の初期値
const initialState: State = {
  keyword: "", // キーワードで検索
  serviceName: "", // サービス名で検索
  categoryIds: [], // カテゴリーIDで検索（複数ID指定可）
  sortKey: SORT_TYPE.Popularity, // ソート方法を指定
  page: 1,
}

// カスタムフックの作成
export const useAppSearchReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return { state, dispatch }
}

// カスタムフック（useSearchReducer）が返す初期値
export const initialSearchCondition: ReturnType<typeof useAppSearchReducer> = {
  state: initialState,
  dispatch: function (_: Action): void {
    throw new Error("Function not implemented.")
  },
}
