import { useReducer } from "react"
import { SortOrder } from "../../../types/Common"
import { AdminUserSortKey } from "../../../types/AdminUser"
import { AppSortKey } from "../../../types/App"

/**
 * AppListStateは、アプリの検索条件の状態を表します。
 */
export type AppListState = {
  keyword: string // キーワード検索
  sortKey: AppSortKey // ソートする項目
  sortOrder: SortOrder // ソート順
  page: number // ページネーション
}

/**
 * Actionは、AppListStateの更新に対応するアクションを表します。
 * @typedef keyword キーワード検索に使用する文字列を更新するアクション。
 * @property {string} payload.keyword キーワード検索に使用する文字列。
 * @typedef sortkey ソートに使用する項目を更新するアクション。
 * @property {ProductCodeSortKey} payload.sortKey ソートに使用する項目のキーを表す列挙型。
 * @typedef sortorder ソート順を更新するアクション。
 * @property {SortOrder} payload.sortOrder ソート順を表す昇順または降順のいずれかの文字列。
 * @typedef page ページ番号を更新するアクション。
 * @property {number} payload.page 検索結果のページ番号。
 */
export type AppListAction =
  | {
      type: "keyword"
      payload: { keyword: string }
    }
  | {
      type: "sortkey"
      payload: { sortKey: AppSortKey }
    }
  | {
      type: "sortorder"
      payload: { sortOrder: SortOrder }
    }
  | {
      type: "page"
      payload: { page: number }
    }

/**
 * AppListReducerは、AppListPage.tsxで利用されるカスタムフックです。
 * AppListStateの状態と、その状態を更新するためのdispatch関数を返します。
 * @returns {{ state: AppListState, dispatch: (value: AppListAction) => void }} AppListStateの状態と、その状態を更新するためのdispatch関数。
 */
const reducer = (state: AppListState, action: AppListAction) => {
  const next = { ...state }
  if (action.type !== "page") next.page = 1

  switch (action.type) {
    case "keyword":
      next.keyword = action.payload.keyword
      break
    case "sortkey":
      next.sortKey = action.payload.sortKey
      break
    case "sortorder":
      next.sortOrder = action.payload.sortOrder
      break
    case "page":
      next.page = action.payload.page
      break
  }

  return next
}

// 検索条件の初期値
export const appListInitialState: AppListState = {
  keyword: "",
  sortKey: "createdAt",
  sortOrder: "desc",
  page: 1,
}

/*
 * アプリ検索ページの状態を管理するReducerのHooks
 * @returns {object} state, dispatch を含むオブジェクト
 */
export const useAppListReducer = () => {
  const [appListState, appListDispatch] = useReducer(
    reducer,
    appListInitialState
  )
  return { appListState, appListDispatch }
}
