import { DeleteApi, GetApi, PostApi, PutApi } from "./AbstractApiFactory"
import {
  SaaStainerUserType,
  SaaStainerUserSortKey,
  ChurnSurveySortKey,
  ChurnSurveyType,
} from "../types/SaaStainerUser"
import { PaginableResponse, ResponseType, SortOrder } from "../types/Common"

// IDで指定したSaaStainerユーザーを1人取得します。
export class GetSaaStainerUserApi extends GetApi<
  ResponseType<SaaStainerUserType>
> {
  constructor(private readonly id: string, token?: string) {
    super(token)
  }
  // override
  protected getUrl(): string {
    return `/saastaineruser/${this.id}`
  }
}

// 全て、またはキーワードに一致するSaaStainerユーザーの一覧を取得します。
export class GetSaaStainerUsersApi extends GetApi<
  PaginableResponse<SaaStainerUserType[]>
> {
  constructor(
    private readonly page: number,
    private readonly sortKey: SaaStainerUserSortKey,
    private readonly sortOrder: SortOrder,
    private readonly isStrategitExcluded: boolean,
    private readonly keyword?: string,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    const query = `&searchKeyword=${this.encode(this.keyword)}`
    return `/saastainerusers/?page=${this.page}&sortKey=${this.sortKey}&sortOrder=${this.sortOrder}&isStrategitExcluded=${this.isStrategitExcluded}${query}`
  }
}

// 全て、またはキーワードに一致する解約者アンケートの一覧を取得します。
export class GetChurnSurveysApi extends GetApi<
  PaginableResponse<ChurnSurveyType[]>
> {
  constructor(
    private readonly page: number,
    private readonly sortKey: ChurnSurveySortKey,
    private readonly sortOrder: SortOrder,
    private readonly keyword?: string,
    token?: string
  ) {
    super(token)
  }
  // override
  protected getUrl(): string {
    const query = `&searchKeyword=${this.encode(this.keyword)}`
    return `/saastainerusers/churnsurveys/?page=${this.page}&sortKey=${this.sortKey}&sortOrder=${this.sortOrder}${query}`
  }
}
