import React, { useContext } from "react"
import { Box } from "@material-ui/core"
import AppPurchased from "../views/AppPurchased"
import Employees from "../views/Employees"
import OAuthRedirect from "../views/OAuthRedirect"
import Profile from "../views/Profile"
import Inquiry from "../views/Inquiry"
import { EmployerLayoutProps, MenuItemEntry, InquiryProps } from "../typings"
import { Redirect, Route, Switch } from "react-router-dom"
import AppHero from "../components/AppHero"
import Trade from "../views/Trade"
import Terms from "../views/Terms"
import SupportServiceTerms from "../views/SupportServiceTerms"
import Privacy from "../views/Privacy"
import { useTranslation } from "react-i18next"
import AppsBannerSlider from "../components/AppsBannerSlider"
import MainVisual from "../components/MainVisual"
import AboutSaaStainer from "../components/AboutSaaStainer"
import BeforeFooterContents from "../components/BeforeFooterContents"
import { MediaQueryContext } from "../App"
import { SearchByService } from "../components/SearchByService"
import { SearchByCategory } from "../components/SearchByCategory"
import { SearchResults } from "../components/SearchResults"
import { AppDetailView } from "../views/AppDetailView"
import { MyApps } from "../components/MyApps"

export const EmployerMenuItems = (signoutHandler: () => void): MenuItemEntry[] => [
  {
    id: "menuitemProfile",
    text: "プロフィール編集",
    path: "/profile",
  },
  {
    id: "menuitemEmployees",
    text: "アカウント管理",
    path: "/employees",
  },
  {
    id: "menuitemApps",
    text: "連携アプリ一覧",
    path: "/apps",
  },
  {
    id: "menuitemSignout",
    text: "ログアウト",
    path: "#",
    signoutHandler,
  },
]

export default function EmployerLayout(props: EmployerLayoutProps) {
  // コンテキストからメディアクエリーに必要なリソースを取得
  const { isPC, pcWidth, spWidth } = useContext(MediaQueryContext)
  const { t } = useTranslation()
  const { alert, errorHandler, inquiryHandler } = props

  const inquiryProps: InquiryProps = {
    alert,
    submitHandler: inquiryHandler,
    errorHandler,
  }

  return (
    <Switch>
      <Route
        path="/apps/purchased"
        render={() => (
          <AppHero title={t("pageTitles.purchasePage")}>
            <AppPurchased {...props} />
          </AppHero>
        )}
      />
      {/* <Route path="/apps/search/:keyword" render={() => <SearchApps {...props} />} />
      <Route path="/apps/search/" render={() => <SearchApps {...props} />} /> */}
      <Route path="/apps/:appId" render={() => <AppDetailView {...props} />} />
      <Route
        path="/apps"
        render={() => (
          <AppHero title={t("pageTitles.appsPage")} hide={true}>
            <Box
              component="div"
              style={{
                height: "100%",
                maxWidth: isPC ? pcWidth : spWidth,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <MainVisual />
              <AboutSaaStainer />
              <MyApps {...props} />
              <SearchByService />
              <SearchByCategory />
              <SearchResults {...props} />
              <AppsBannerSlider />
              <BeforeFooterContents />
            </Box>
          </AppHero>
        )}
      />
      <Route
        path="/employees"
        render={() => (
          <AppHero title={t("pageTitles.employeesPage")} subtext={t("listEmployee.description")}>
            <Employees {...props} />
          </AppHero>
        )}
      />
      <Route
        path="/profile"
        render={() => (
          <AppHero title={t("pageTitles.editProfilePage")} subtext={t("editProfile.description")}>
            <Profile {...props} />
          </AppHero>
        )}
      />
      <Route
        path="/trade"
        render={() => (
          <AppHero title={t("pageTitles.actPage")}>
            <Trade />
          </AppHero>
        )}
      />
      <Route
        path="/terms"
        render={() => (
          <AppHero title={t("pageTitles.termsPage")}>
            <Terms />
          </AppHero>
        )}
      />
      <Route
        path="/supportServiceTerms"
        render={() => (
          <AppHero title={t("pageTitles.supportServiceTermsPage")}>
            <SupportServiceTerms />
          </AppHero>
        )}
      />
      <Route
        path="/privacy"
        render={() => (
          <AppHero title={t("pageTitles.privacyPage")}>
            <Privacy />
          </AppHero>
        )}
      />
      <Route path="/oauth" render={() => <OAuthRedirect {...props} />} />
      <Route path="/inquiry" render={() => <Inquiry {...inquiryProps} />} />
      <Redirect path="/" to="/apps" />
    </Switch>
  )
}
