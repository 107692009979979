import { makeStyles } from "@material-ui/core/styles"

export const BaseStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#fff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  wrapper: {
    backgroundColor: theme.palette.primary.main,
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textHeader: {
    fontSize: "1.2rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
  textSubHeader: {
    fontSize: "0.9rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
}))
