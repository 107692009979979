import { AppDetailData } from "../../typings/apps"

// カテゴリーID
export type CategoryId = "1" | "2" | "3" | "4" | "5" | "6" | "99"
export const categoryMap: { id: CategoryId; name: string }[] = [
  { id: "1", name: "開発" },
  { id: "2", name: "人事" },
  { id: "3", name: "会計" },
  { id: "4", name: "勤怠" },
  { id: "5", name: "営業" },
  { id: "6", name: "営業支援" },
  { id: "99", name: "その他" },
]

// ソートキー
export type AppSortKey =
  | "name"
  | "category"
  | "display"
  | "popularity"
  | "createdAt"
// アプリ型
export type AppType = {
  _id: string
  images: {
    main: string
    sub: string
    tag: string
  }
  aboutApp: {
    summary: string
    feature: string
    recommendedFor: string
    notes: string
  }
  i18nAboutApp: {
    en: {
      summary: string
      feature: string
      recommendedFor: string
      notes: string
    }
  }
  soldOnlyViaProductCode: boolean
  display: boolean
  name: string
  amount: number
  mode: "payment" | "subscription"
  appId: string
  stripeProductId: string
  stripePriceId: string
  category: {
    id: string
    name: string
  }
  service: string[]
  appUrl: string
  creator: {
    name: string
    url: string
  }
  helpUrl: string
  i18nNames: {
    en: string
  }
  subscriptionData?: {
    trialPeriodDays: number
    trialEnd: Date | null
    billingCycle: "monthly" | "annually"
  }
  taxRateId?: string
  free: boolean
  inquiryUrl: string
  popularity: number
  createdAt: string
}
