import React from "react"
import Slider from "react-slick"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import Container from "@material-ui/core/Container"
import useStyles from "../styles/apps"
import { AppBannerSlide } from "../typings/apps"
import banner1 from "../img/banner/bn_freeAPI_210622_02.png"
import banner2 from "../img/banner/saastainer002.jpg"

interface AppsBannerSliderProps {
  // TODO Maybe get these from the database in the future
  slides: AppBannerSlide[] // URLs and image sources
}

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
}

// TODO Take from DB
const appBannerSlides: AppBannerSlide[] = [
  {
    url:
      "https://sites.google.com/view/freeeapisheet/%E3%83%9B%E3%83%BC%E3%83%A0?authuser=0",
    imgSrc: banner1,
  },
  {
    url: "https://techtarget.itmedia.co.jp/tt/news/2107/14/news05.html",
    imgSrc: banner2,
  },
]

export default function AppsBannerSlider() {
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="md">
      <Box mt={6} mb={6} ml={1} mr={1}>
        <Slider {...settings}>
          {appBannerSlides.map((slide, i) => (
            <div key={`slide-${i}`}>
              <Link href={slide.url} target="_blank">
                <Box display="flex" justifyContent="center">
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={slide.imgSrc}
                  />
                </Box>
              </Link>
            </div>
          ))}
        </Slider>
      </Box>
    </Container>
  )
}
