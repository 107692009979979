import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginBottom: theme.spacing(4),
    // minWidth: "50%"
    width: "100%",
  },
  tableContainer: {
    marginTop: 50,
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: 700,
    backgroundColor: "#fafafa",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  noEmployees: {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: "#808080",
  },
  textHeader: {
    fontSize: "1.2rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
  textSubHeader: {
    fontSize: "0.9rem",
    fontWeight: 600,
    // color: "#3C4858",
  },
  Tabs: {
    minWidth: 650,
  },
}))

export default useStyles
