import React, { createContext } from "react"
import {
  SaleListAction,
  useSaleListReducer,
  saleListInitialState,
  SaleListState,
} from "./reducers/useSaleListReducer"
import {
  SaleAddAction,
  useSaleAddReducer,
  saleAddInitialState,
  SaleAddState,
} from "./reducers/useSaleAddReducer"
import {
  SaleEditAction,
  useSaleEditReducer,
  saleEditInitialState,
  SaleEditState,
} from "./reducers/useSaleEditReducer"

/**
 * 販売管理に関する情報を提供するContextTypeです。
 * @typedef ContextType
 * @property {SaleListState} 一覧ページのステート
 * @property {React.Dispatch<SaleListAction>} 一覧ページのアクションをディスパッチする関数
 * @property {SaleAddState} 発行ページのステート
 * @property {React.Dispatch<SaleAddAction>} 発行ページのアクションをディスパッチする関数
 * @property {SaleEditState} 編集ページのステート
 * @property {React.Dispatch<SaleEditAction>} 編集ページのアクションをディスパッチする関数
 */
type ContextType = {
  saleListState: SaleListState
  saleListDispatch: React.Dispatch<SaleListAction>
  saleAddState: SaleAddState
  saleAddDispatch: React.Dispatch<SaleAddAction>
  saleEditState: SaleEditState
  saleEditDispatch: React.Dispatch<SaleEditAction>
}

/**
 * 販売管理に関する情報を提供するContextです。
 * @type {React.Context<ContextType>}
 */
export const SalesManagementContext = createContext<ContextType>({
  saleListState: saleListInitialState,
  saleListDispatch: (_: SaleListAction) => {},
  saleAddState: saleAddInitialState,
  saleAddDispatch: (_: SaleAddAction) => {},
  saleEditState: saleEditInitialState,
  saleEditDispatch: (_: SaleEditAction) => {},
})

/**
 * 販売管理に関する情報を提供するContextのプロバイダーコンポーネントです。
 * @param {Object} props コンポーネントに渡すprops
 * @param {React.ReactNode} props.children 子コンポーネント
 * @returns {JSX.Element} コンテキストのProviderコンポーネント
 */
export const SalesManagementContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { saleListState, saleListDispatch } = useSaleListReducer()
  const { saleAddState, saleAddDispatch } = useSaleAddReducer()
  const { saleEditState, saleEditDispatch } = useSaleEditReducer()

  return (
    <SalesManagementContext.Provider
      value={{
        saleListState,
        saleListDispatch, // 検索ページ
        saleAddState,
        saleAddDispatch, // 登録ページ
        saleEditState,
        saleEditDispatch, // 編集ページ
      }}
    >
      {children}
    </SalesManagementContext.Provider>
  )
}
