import React, { useContext } from "react"
import { Box } from "@material-ui/core"
import OAuthApp from "../views/OAuthApp"
import SignIn from "../views/SignIn"
import ForgotPassword from "../views/ForgotPassword"
import ResetPassword from "../views/ResetPassword"
import SignUp from "../views/SignUp"
import Inquiry from "../views/Inquiry"
import {
  ForgotPasswordProps,
  ResetPasswordProps,
  PublicLayoutProps,
  SignInProps,
  SignUpProps,
  InquiryProps,
} from "../typings"
import { Redirect, Route, Switch } from "react-router-dom"
import ThanksSignUp from "../views/ThanksSignUp"
import AppHero from "../components/AppHero"
import Trade from "../views/Trade"
import Terms from "../views/Terms"
import SupportServiceTerms from "../views/SupportServiceTerms"
import Privacy from "../views/Privacy"
import { useTranslation } from "react-i18next"
import AppsBannerSlider from "../components/AppsBannerSlider"
import MainVisual from "../components/MainVisual"
import AboutSaaStainer from "../components/AboutSaaStainer"
import BeforeFooterContents from "../components/BeforeFooterContents"
import { MediaQueryContext } from "../App"
import { SearchByService } from "../components/SearchByService"
import { SearchByCategory } from "../components/SearchByCategory"
import { SearchResults } from "../components/SearchResults"
import { AppDetailView } from "../views/AppDetailView"

export default function PublicLayout(props: PublicLayoutProps) {
  // コンテキストからメディアクエリーに必要なリソースを取得
  const { isPC, pcWidth, spWidth } = useContext(MediaQueryContext)
  const { t } = useTranslation()
  const {
    alert,
    signInHandler,
    signUpHandler,
    errorHandler,
    forgotPasswordHandler,
    resetPasswordHandler,
    inquiryHandler,
  } = props

  const signUpProps: SignUpProps = {
    alert,
    submitHandler: signUpHandler,
    errorHandler,
  }

  const signInProps: SignInProps = {
    alert,
    submitHandler: signInHandler,
  }

  const forgotPasswordProps: ForgotPasswordProps = {
    alert,
    submitHandler: forgotPasswordHandler,
    errorHandler,
  }

  const resetPasswordProps: ResetPasswordProps = {
    alert,
    submitHandler: resetPasswordHandler,
    errorHandler,
  }

  const inquiryProps: InquiryProps = {
    alert,
    submitHandler: inquiryHandler,
    errorHandler,
  }

  return (
    <Switch>
      {/* <Route path="/apps/search/:keyword" render={() => <SearchApps {...props} />} />
      <Route path="/apps/search/" render={() => <SearchApps {...props} />} /> */}
      <Route path="/apps/:appId" render={() => <AppDetailView {...props} />} />
      <Route
        path="/apps"
        render={() => (
          <AppHero title={t("pageTitles.appsPage")} hide={true}>
            <Box
              component="div"
              style={{
                height: "100%",
                maxWidth: isPC ? pcWidth : spWidth,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <MainVisual />
              <AboutSaaStainer />
              <SearchByService />
              <SearchByCategory />
              <SearchResults {...props} />
              <AppsBannerSlider />
              <BeforeFooterContents />
            </Box>
          </AppHero>
        )}
      />
      <Route
        path="/trade"
        render={() => (
          <AppHero title={t("pageTitles.actPage")}>
            <Trade />
          </AppHero>
        )}
      />
      <Route
        path="/terms"
        render={() => (
          <AppHero title={t("pageTitles.termsPage")}>
            <Terms />
          </AppHero>
        )}
      />
      <Route
        path="/supportServiceTerms"
        render={() => (
          <AppHero title={t("pageTitles.supportServiceTermsPage")}>
            <SupportServiceTerms />
          </AppHero>
        )}
      />
      <Route
        path="/privacy"
        render={() => (
          <AppHero title={t("pageTitles.privacyPage")}>
            <Privacy />
          </AppHero>
        )}
      />
      <Route path="/oauth" render={() => <OAuthApp {...props} />} />
      <Redirect path="/employees" to="/signin" />
      <Redirect path="/profile" to="/signin" />
      <Route path="/thanks" render={() => <ThanksSignUp {...props} />} />
      <Route path="/signup" render={() => <SignUp {...signUpProps} />} />
      <Route path="/signin" render={() => <SignIn {...signInProps} />} />
      <Route path="/forgot" render={() => <ForgotPassword {...forgotPasswordProps} />} />
      <Route path="/password" render={() => <ResetPassword {...resetPasswordProps} />} />
      <Route path="/inquiry" render={() => <Inquiry {...inquiryProps} />} />
      <Redirect path="/" to="/apps" />
    </Switch>
  )
}
