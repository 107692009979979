import React, { useState, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { GetServicesApi } from "../../../api/ServiceApi"
import { AdminAuthContext } from "../../../contexts/AdminAuthManagement/AdminAuthContext"
import { enqueueSnackbar } from "notistack"

type ServiceType = {
  name: string
  url: string
}

interface ServiceSelectorProps {
  onSelectionChange: (selectedIds: string[]) => void
  initialSelectedIds: string[]
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  row: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
  checkbox: {
    margin: "0 10px 0 0",
  },
})

/**
 * ServiceSelector コンポーネント
 *
 * @param onSelectionChange 選択されたサービスのIDが変更されたときに呼び出されるコールバック
 * @param initialSelectedIds 初期に選択されているサービスのIDの配列
 */
const ServiceSelector: React.FC<ServiceSelectorProps> = ({
  onSelectionChange,
  initialSelectedIds,
}) => {
  const classes = useStyles()
  const [services, setServices] = useState<ServiceType[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>(
    initialSelectedIds || []
  )
  const { adminAuthState } = useContext(AdminAuthContext)

  useEffect(() => {
    new GetServicesApi(adminAuthState.token)
      .sendRequest()
      .then((res) => {
        setServices(res.data.payload)
        setSelectedIds(initialSelectedIds)
      })
      .catch((err) => {
        enqueueSnackbar("サービス一覧の取得に失敗しました。", {
          variant: "error",
          preventDuplicate: true,
        })
      })
  }, [initialSelectedIds])

  useEffect(() => {
    onSelectionChange(selectedIds)
  }, [selectedIds])

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setSelectedIds((prevSelectedIds) => {
      if (checked) {
        return [...prevSelectedIds, id]
      } else {
        return prevSelectedIds.filter((existingId) => existingId !== id)
      }
    })
  }

  return (
    <div className={classes.container}>
      {services.map((service) => (
        <div className={classes.row} key={service.name}>
          <input
            className={classes.checkbox}
            type="checkbox"
            checked={selectedIds.includes(service.name)}
            onChange={(e) =>
              handleCheckboxChange(service.name, e.target.checked)
            }
          />
          {service.name}
        </div>
      ))}
    </div>
  )
}

export default ServiceSelector
